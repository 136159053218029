import css from './Menu.module.css';

import { Fragment } from 'react';

import MenuTopBar from './MenuTopBar/MenuTopBar';
import Product from './Product/Product';
import EventsDrawer from '../Events/EventsDrawer/EventsDrawer'
import HiddenButton from '../Workbench/HiddenButton/HiddenButton';

const Menu = (props) => {
    return (
        <Fragment>
            <MenuTopBar />
            <div className={css['outer-container']}>
                <div className={css['main-container']}>
                <Product product='Invitations' title='Invitations' css={{ fontSize: '32px' }} />
                <Product product='People' title='Friends' css={{}} />                
                <Product product='Places' title='Places' css={{}} />
                <Product product='Pink' title='Love' css={{}} />
                {false && <Product product='Parties' title='Mixers' css={{}} />}                
                {false && <Product product='Lime' title='Professionals' css={{ fontSize: '28px' }} />}                                
                {false && <Product product='Parties' title='Parties' css={{}} />}
                {false && <Product product='HelpWanted' title='Wanted' topTitle='Help' css={{}} />}
                {false && <Product product='HelpOffered' title='Offered' topTitle='Help' />}
                {false && <Product product='Groups' title='Groups' css={{}} />}
                {false && <Product product='Cards' title='Cards' css={{}} />}
                </div>
            </div>
            <EventsDrawer />
            {false && <HiddenButton />}
        </Fragment>
    );
};

export default Menu;
