import css from './CornerButton.module.css';

import { useSelector } from 'react-redux';
import Icon from '../../../Icon/Icon';

const CornerButton = (props) => {
    const product = useSelector((state) => state.drawer.product);

    return (
        <div
            className={`${css['main-container']} ${product} ${css[props.side]}`}
            onClick={props.onClick}
        >
            <div className={css['inner-container']}>
                <Icon icon={props.icon} />
                <div className={css['text-container']}>{props.text}</div>
            </div>
        </div>
    );
};

export default CornerButton;
