import css from './VerticalVideoPlayer.module.css';
import VideoPlayer from '../BrowserVideos/VideoPlayer/VideoPlayer';
import VideoLoader from '../../VideoPlayer/VideoLoader/VideoLoader';
import PlaceName from './PlaceName/PlaceName';
import AudioPlayer from '../Stage/AudioPlayer';
import StageManager from '../Stage/StageManager/StageManager';
import StageLights from '../Stage/StageLights/StageLights';
import SideButtons from './SideButtons/SideButtons';
import NavigationButtons from './NavigationButtons/NavigationButtons';

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateBrowser, updateBrowserKeys } from '../../../../store/browserSlice';
import { loadPlace } from '../../../../store/drawerSlice';
import { updateAudio, blinkAudio } from '../../../../store/audioSlice';
import VibeWall from './VibeWall/VibeWall';
import ThumbnailAndMap from './ThumbnailAndMap/ThumbnailAndMap';

const VerticalVideoPlayer = (props) => {
    const dispatch = useDispatch();

    const browser = useSelector((state) => state.browser);
    const elementId = browser.places.activeId;
    const place = useSelector((state) => state.firestore.places.elements[elementId]);

    const speed = useSelector((state) => state.browser.stage.speed);
    const view = browser.places.view;

    useEffect(() => {
        updateBrowser(dispatch, 'stage', { speed: [500, 300] });

        return () => {
            updateBrowser(dispatch, 'stage', { speed: [1500, 1500] });
        };
    }, []);

    useEffect(() => {
        if (elementId) {
            const payload = {
                placeId: place.place_id,
                photos: [place.thumbnail, ...place.photos],
                hours: place.hours,
                prices: place.prices,
                menu: place.menu,
                view: place.viewDefault || selectView(place),
            };
            const newView = place.viewDefault || selectView(place);
            updateBrowser(dispatch, 'places', { view: newView });
            updateAudio(dispatch, ['music', 'song'], place.song);
            loadPlace(dispatch, payload);

            // console.log(elementId, newView);
            if (newView === 'VibeWall') {
                setTimeout(() => {
                    // console.log('-- TRIGERING PLAY ---');
                    blinkAudio(dispatch, ['music', 'action'], 'play');
                }, 100);
            }

            const voiceover = place.voiceover;
            if (voiceover) {
                updateAudio(dispatch, ['voiceover', 'track'], voiceover);
                setTimeout(() => {
                    blinkAudio(dispatch, ['voiceover', 'action'], 'play');
                }, 100);
            }
        }

        return () => {
            updateAudio(dispatch, ['music', 'song'], null);
            blinkAudio(dispatch, ['music', 'action'], 'pause');
        };
    }, [elementId]);

    useEffect(() => {
        return () => {
            updateBrowserKeys(dispatch, ['places'], {
                activeId: null,
                queuedId: null,
                queue: [],
                position: 0,
                view: null,
            });
        };
    }, []);

    if (!elementId) {
        return <></>;
    }

    return (
        <div
            className={css['main-container']}
            style={{
                '--close-speed': speed[0] + 'ms',
                '--open-speed': speed[1] + 'ms',
            }}
        >
            {view === 'VibeWall' && <VibeWall />}
            {/* {view === 'VideoPlayer' && <VideoPlayer />} */}
            {view === 'VideoPlayer' && <VideoLoader orientation='vertical'/>}
            {view === 'ThumbnailAndMap' && <ThumbnailAndMap thumbnail={place.thumbnail} />}

            <PlaceName place={place} />
            {view !== 'ThumbnailAndMap' && <SideButtons />}
            <NavigationButtons />

            <StageLights />
            <StageManager />
            <AudioPlayer />
        </div>
    );

    function selectView(p) {
        if (p.playbackId) {
            return 'VideoPlayer';
        }

        if (p.photos.length > 7) {
            return 'VibeWall';
        }

        return 'ThumbnailAndMap';
    }
};

export default VerticalVideoPlayer;
