import { prepareLabels } from '../../../tools/labels';

const tags_buckets = [
    { id: 'b_demographics', name: 'Demographics', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'b_family', name: 'Family', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'b_education', name: 'Education', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'b_work', name: 'Work', type: 'bucket', keywords: [], categories: [], items: []},
    
    { id: 'i_automotive-and-motorsports', name: 'Automotive & Motorsports', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_business-and-finance', name: 'Business & Finance', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_charity-and-volunteering', name: 'Charity & Volunteering', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_collecting', name: 'Collecting', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_content-creation', name: 'Content Creation', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_crafting', name: 'Crafting', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_diy-and-home-improvement', name: 'DIY & Home Improvement', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_learning-personal-development', name: 'Learning & Personal Development', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_fashion-and-beauty', name: 'Fashion & Beauty', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_film-and-television', name: 'Film & Television', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_food-and-drink', name: 'Food & Drink', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_gardening-and-landscaping', name: 'Gardening & L&scaping', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_geeky-stuff', name: 'Geeky Stuff', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_grphic-art-and-design', name: 'Grphic Art & Design', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_health-and-fitness', name: 'Health & Fitness', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_history-and-culture', name: 'History & Culture', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_humor-and-comedy', name: 'Humor & Comedy', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_interior-design-and-home-decor', name: 'Interior Design & Home Décor', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_language-and-linguistics', name: 'Language & Linguistics', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_music-and-dance', name: 'Music & Dance', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_outdoors-and-adventure', name: 'Outdoors & Adventure', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_parenting-and-family', name: 'Parenting & Family', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_pets-and-animals', name: 'Pets & Animals', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_philosophy-and-ethics', name: 'Philosophy & Ethics', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_politics-and-social-issues', name: 'Politics & Social Issues', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_psychology-and-mental-health', name: 'Psychology & Mental Health', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_relationships-and-dating', name: 'Relationships & Dating', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_science-and-nature', name: 'Science & Nature', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_spirituality-and-religion', name: 'Spirituality & Religion', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_sports', name: 'Sports', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_supernatural', name: 'Supernatural Stuff', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_technology', name: 'Technology', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_travel-and-exploration', name: 'Travel & Exploration', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'i_writing-and-literature', name: 'Writing & Literature', type: 'bucket', keywords: [], categories: [], items: []},
];

const tags_categories = [
    {id: 'b_demographics_culture', name:'Culture', bold: '', bucket: 'b_demographics', type: 'category', keywords: [], items: []},
    {id: 'b_demographics_language', name:'Language', bold: '', bucket: 'b_demographics', type: 'category', keywords: [], items: []},
    {id: 'b_education_field', name:'Field', bold: '', bucket: 'b_education', type: 'category', keywords: [], items: []},
    {id: 'b_family_children', name:'Parent of ____', bold: '', bucket: 'b_family', type: 'category', keywords: [], items: []},
    {id: 'b_work_function', name:'Function', bold: '', bucket: 'b_work', type: 'category', keywords: [], items: []},
    {id: 'b_work_industry', name:'Industry', bold: '', bucket: 'b_work', type: 'category', keywords: [], items: []},
    {id: 'b_work_title', name:'Title', bold: '', bucket: 'b_work', type: 'category', keywords: [], items: []},
    
    {id: 'i_automotive-and-motorsports_person', name:'People', bold: '', bucket: 'i_automotive-and-motorsports', type: 'category', keywords: [], items: []},
    {id: 'i_automotive-and-motorsports_thing', name:'Things', bold: '', bucket: 'i_automotive-and-motorsports', type: 'category', keywords: [], items: []},
    {id: 'i_business-and-finance_industry', name:'Industries', bold: '', bucket: 'i_business-and-finance', type: 'category', keywords: [], items: []},
    {id: 'i_business-and-finance_person', name:'People', bold: '', bucket: 'i_business-and-finance', type: 'category', keywords: [], items: []},
    {id: 'i_business-and-finance_topic', name:'Topics', bold: '', bucket: 'i_business-and-finance', type: 'category', keywords: [], items: []},
    {id: 'i_crafting_activity', name:'Activities', bold: '', bucket: 'i_crafting', type: 'category', keywords: [], items: []},
    {id: 'i_crafting_thing', name:'Things', bold: '', bucket: 'i_crafting', type: 'category', keywords: [], items: []},
    {id: 'i_crafting_topic', name:'Topics', bold: '', bucket: 'i_crafting', type: 'category', keywords: [], items: []},
    {id: 'i_film-and-television_activity', name:'Activities', bold: '', bucket: 'i_film-and-television', type: 'category', keywords: [], items: []},
    {id: 'i_film-and-television_movie', name:'Movies', bold: '', bucket: 'i_film-and-television', type: 'category', keywords: [], items: []},
    {id: 'i_film-and-television_person', name:'People', bold: '', bucket: 'i_film-and-television', type: 'category', keywords: [], items: []},
    {id: 'i_food-and-drink_activity', name:'Activities', bold: '', bucket: 'i_food-and-drink', type: 'category', keywords: [], items: []},
    {id: 'i_geeky-stuff_activity', name:'Activities', bold: '', bucket: 'i_geeky-stuff', type: 'category', keywords: [], items: []},
    {id: 'i_geeky-stuff_board-game', name:'Board Games', bold: '', bucket: 'i_geeky-stuff', type: 'category', keywords: [], items: []},
    {id: 'i_geeky-stuff_video-game', name:'Video Games', bold: '', bucket: 'i_geeky-stuff', type: 'category', keywords: [], items: []},
    {id: 'i_grphic-art-and-design_activity', name:'Activities', bold: '', bucket: 'i_grphic-art-and-design', type: 'category', keywords: [], items: []},
    {id: 'i_grphic-art-and-design_topic', name:'Topics', bold: '', bucket: 'i_grphic-art-and-design', type: 'category', keywords: [], items: []},
    {id: 'i_health-and-fitness_topic', name:'Topics', bold: '', bucket: 'i_health-and-fitness', type: 'category', keywords: [], items: []},
    {id: 'i_history-and-culture_book', name:'Books', bold: '', bucket: 'i_history-and-culture', type: 'category', keywords: [], items: []},
    {id: 'i_humor-and-comedy_person', name:'People', bold: '', bucket: 'i_humor-and-comedy', type: 'category', keywords: [], items: []},
    {id: 'i_learning-personal-development_activity', name:'Activities', bold: '', bucket: 'i_learning-personal-development', type: 'category', keywords: [], items: []},
    {id: 'i_learning-personal-development_book', name:'Books', bold: '', bucket: 'i_learning-personal-development', type: 'category', keywords: [], items: []},
    {id: 'i_music-and-dance_activity', name:'Activities', bold: '', bucket: 'i_music-and-dance', type: 'category', keywords: [], items: []},
    {id: 'i_music-and-dance_genere', name:'Generes', bold: '', bucket: 'i_music-and-dance', type: 'category', keywords: [], items: []},
    {id: 'i_music-and-dance_person', name:'People', bold: '', bucket: 'i_music-and-dance', type: 'category', keywords: [], items: []},
    {id: 'i_outdoors-and-adventure_activity', name:'Activities', bold: '', bucket: 'i_outdoors-and-adventure', type: 'category', keywords: [], items: []},
    {id: 'i_parenting-and-family_podcast', name:'Podcasts', bold: '', bucket: 'i_parenting-and-family', type: 'category', keywords: [], items: []},
    {id: 'i_pets-and-animals_pet', name:'Pets', bold: '', bucket: 'i_pets-and-animals', type: 'category', keywords: [], items: []},
    {id: 'i_philosophy-and-ethics_book', name:'Books', bold: '', bucket: 'i_philosophy-and-ethics', type: 'category', keywords: [], items: []},
    {id: 'i_philosophy-and-ethics_topic', name:'Topics', bold: '', bucket: 'i_philosophy-and-ethics', type: 'category', keywords: [], items: []},
    {id: 'i_politics-and-social-issues_activity', name:'Activities', bold: '', bucket: 'i_politics-and-social-issues', type: 'category', keywords: [], items: []},
    {id: 'i_politics-and-social-issues_person', name:'People', bold: '', bucket: 'i_politics-and-social-issues', type: 'category', keywords: [], items: []},
    {id: 'i_science-and-nature_person', name:'People', bold: '', bucket: 'i_science-and-nature', type: 'category', keywords: [], items: []},
    {id: 'i_science-and-nature_topic', name:'Topics', bold: '', bucket: 'i_science-and-nature', type: 'category', keywords: [], items: []},
    {id: 'i_spirituality-and-religion_book', name:'Books', bold: '', bucket: 'i_spirituality-and-religion', type: 'category', keywords: [], items: []},
    {id: 'i_spirituality-and-religion_religion', name:'Religions', bold: '', bucket: 'i_spirituality-and-religion', type: 'category', keywords: [], items: []},
    {id: 'i_sports_activity', name:'Activities', bold: '', bucket: 'i_sports', type: 'category', keywords: [], items: []},
    {id: 'i_sports_person', name:'People', bold: '', bucket: 'i_sports', type: 'category', keywords: [], items: []},
    {id: 'i_sports_sport', name:'Sports', bold: '', bucket: 'i_sports', type: 'category', keywords: [], items: []},
    {id: 'i_technology_person', name:'People', bold: '', bucket: 'i_technology', type: 'category', keywords: [], items: []},
    {id: 'i_technology_thing', name:'Things', bold: '', bucket: 'i_technology', type: 'category', keywords: [], items: []},
    {id: 'i_technology_topic', name:'Topics', bold: '', bucket: 'i_technology', type: 'category', keywords: [], items: []},
    {id: 'i_travel-and-exploration_activity', name:'Activities', bold: '', bucket: 'i_travel-and-exploration', type: 'category', keywords: [], items: []},
    {id: 'i_writing-and-literature_activity', name:'Activities', bold: '', bucket: 'i_writing-and-literature', type: 'category', keywords: [], items: []},
    {id: 'i_writing-and-literature_book', name:'Books', bold: '', bucket: 'i_writing-and-literature', type: 'category', keywords: [], items: []},
    {id: 'i_writing-and-literature_person', name:'People', bold: '', bucket: 'i_writing-and-literature', type: 'category', keywords: [], items: []},
 
];

const tags_items = [
    {id: 'b_demographics_culture_british', name:'British',  category: 'b_demographics_culture', type: 'topic', keywords: [], items: []},
    {id: 'b_demographics_culture_caribbean', name:'Caribbean',  category: 'b_demographics_culture', type: 'topic', keywords: [], items: []},
    {id: 'b_demographics_culture_european', name:'European',  category: 'b_demographics_culture', type: 'topic', keywords: [], items: []},
    {id: 'b_demographics_culture_french', name:'French',  category: 'b_demographics_culture', type: 'topic', keywords: [], items: []},
    {id: 'b_demographics_culture_immigrant', name:'Immigrant',  category: 'b_demographics_culture', type: 'topic', keywords: [], items: []},
    {id: 'b_demographics_culture_indian', name:'Indian',  category: 'b_demographics_culture', type: 'topic', keywords: [], items: []},
    {id: 'b_demographics_culture_latin', name:'Latin',  category: 'b_demographics_culture', type: 'topic', keywords: [], items: []},
    {id: 'b_demographics_culture_mexican', name:'Mexican',  category: 'b_demographics_culture', type: 'topic', keywords: [], items: []},
    {id: 'b_demographics_culture_quebecois', name:'Quebecois',  category: 'b_demographics_culture', type: 'topic', keywords: [], items: []},
    {id: 'b_demographics_language_bengali', name:'Bengali',  category: 'b_demographics_language', type: 'topic', keywords: [], items: []},
    {id: 'b_demographics_language_french', name:'French',  category: 'b_demographics_language', type: 'topic', keywords: [], items: []},
    {id: 'b_demographics_language_hindi', name:'Hindi',  category: 'b_demographics_language', type: 'topic', keywords: [], items: []},
    {id: 'b_demographics_language_portuguese', name:'Portuguese',  category: 'b_demographics_language', type: 'topic', keywords: [], items: []},
    {id: 'b_demographics_language_spanish', name:'Spanish',  category: 'b_demographics_language', type: 'topic', keywords: [], items: []},
    {id: 'b_education_field_engineering', name:'Engineering',  category: 'b_education_field', type: 'topic', keywords: [], items: []},
    {id: 'b_family_children_baby', name:'Baby',  category: 'b_family_children', type: 'topic', keywords: [], items: []},
    {id: 'b_family_children_preschool', name:'Preschool',  category: 'b_family_children', type: 'topic', keywords: [], items: []},
    {id: 'b_work_function_dev-ops', name:'Dev Ops',  category: 'b_work_function', type: 'topic', keywords: [], items: []},
    {id: 'b_work_industry_content-creation', name:'Content Creation',  category: 'b_work_industry', type: 'topic', keywords: [], items: []},
    {id: 'b_work_industry_education', name:'Education',  category: 'b_work_industry', type: 'topic', keywords: [], items: []},
    {id: 'b_work_industry_financial', name:'Financial',  category: 'b_work_industry', type: 'topic', keywords: [], items: []},
    {id: 'b_work_industry_health-care', name:'Heatlh Care',  category: 'b_work_industry', type: 'topic', keywords: [], items: []},
    {id: 'b_work_industry_online-marketing', name:'Online Marketing',  category: 'b_work_industry', type: 'topic', keywords: [], items: []},
    {id: 'b_work_industry_tech-software', name:'Tech / Software',  category: 'b_work_industry', type: 'topic', keywords: [], items: []},
    {id: 'b_work_title_business-owner', name:'Business Owner',  category: 'b_work_title', type: 'topic', keywords: [], items: []},
    {id: 'b_work_title_cloud-administrator', name:'Cloud Administrator',  category: 'b_work_title', type: 'topic', keywords: [], items: []},
    {id: 'b_work_title_director', name:'Director',  category: 'b_work_title', type: 'topic', keywords: [], items: []},
    {id: 'b_work_title_entrepreneur', name:'Entrepreneur',  category: 'b_work_title', type: 'topic', keywords: [], items: []},
    {id: 'b_work_title_event-coordinator', name:'Event Coordinator',  category: 'b_work_title', type: 'topic', keywords: [], items: []},
    {id: 'b_work_title_product-manager', name:'Product Manager',  category: 'b_work_title', type: 'topic', keywords: [], items: []},
    {id: 'b_work_title_project-manager', name:'Project Manager',  category: 'b_work_title', type: 'topic', keywords: [], items: []},
    {id: 'b_work_title_risk-analyst', name:'Risk Analyst',  category: 'b_work_title', type: 'topic', keywords: [], items: []},
    
    {id: 'i_automotive-and-motorsports_person_louis-hamilton', name:'Louis Hamilton',  category: 'i_automotive-and-motorsports_person', type: 'topic', keywords: [], items: []},
    {id: 'i_automotive-and-motorsports_thing_formula-1', name:'Formula 1',  category: 'i_automotive-and-motorsports_thing', type: 'topic', keywords: [], items: []},
    {id: 'i_business-and-finance_industry_hospitality', name:'Hospitality',  category: 'i_business-and-finance_industry', type: 'topic', keywords: [], items: []},
    {id: 'i_business-and-finance_person_ray-dalio', name:'Ray Dalio',  category: 'i_business-and-finance_person', type: 'topic', keywords: [], items: []},
    {id: 'i_business-and-finance_topic_entrepreneurship', name:'Entrepreneurship',  category: 'i_business-and-finance_topic', type: 'topic', keywords: [], items: []},
    {id: 'i_business-and-finance_topic_finance', name:'Finance',  category: 'i_business-and-finance_topic', type: 'topic', keywords: [], items: []},
    {id: 'i_crafting_activity_woodworking', name:'Woodworking',  category: 'i_crafting_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_crafting_thing_ceramics', name:'Ceramics',  category: 'i_crafting_thing', type: 'topic', keywords: [], items: []},
    {id: 'i_crafting_topic_doing-pottery', name:'Arts & Crafts',  category: 'i_crafting_topic', type: 'topic', keywords: [], items: []},
    {id: 'i_film-and-television_activity_writting-movie-scripts', name:'Movie Scripts',  category: 'i_film-and-television_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_film-and-television_movie_a-wrinkle-in-time', name:'A Wrinkle in Time',  category: 'i_film-and-television_movie', type: 'topic', keywords: [], items: []},
    {id: 'i_film-and-television_movie_the-meaning-of-life', name:'The Meaning of Life',  category: 'i_film-and-television_movie', type: 'topic', keywords: [], items: []},
    {id: 'i_film-and-television_movie_the-shawshank-redemption', name:'The Shawshank Redemption',  category: 'i_film-and-television_movie', type: 'topic', keywords: [], items: []},
    {id: 'i_film-and-television_person_ava-du-vernay', name:'Ava DuVernay',  category: 'i_film-and-television_person', type: 'topic', keywords: [], items: []},
    {id: 'i_film-and-television_person_morgan-freeman', name:'Morgan Freeman',  category: 'i_film-and-television_person', type: 'topic', keywords: [], items: []},
    {id: 'i_food-and-drink_activity_going-for-brunch', name:'Going For Brunch',  category: 'i_food-and-drink_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_food-and-drink_activity_making-cocktails', name:'Making Cocktails',  category: 'i_food-and-drink_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_food-and-drink_activity_trying-new-restaurants', name:'Trying New Restaurants',  category: 'i_food-and-drink_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_food-and-drink_activity_watching-food-tv-shows', name:'Food TV Shows',  category: 'i_food-and-drink_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_geeky-stuff_activity_playing-strategy-boardgames', name:'Playing Strategy Boardgames',  category: 'i_geeky-stuff_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_geeky-stuff_activity_playing-video-games', name:'Playing Video Games',  category: 'i_geeky-stuff_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_geeky-stuff_board-game_clank', name:'Clank',  category: 'i_geeky-stuff_board-game', type: 'topic', keywords: [], items: []},
    {id: 'i_geeky-stuff_board-game_food-chain-magnate', name:'Food Chain Magnate',  category: 'i_geeky-stuff_board-game', type: 'topic', keywords: [], items: []},
    {id: 'i_geeky-stuff_board-game_great-western-trail', name:'Great Western Trail',  category: 'i_geeky-stuff_board-game', type: 'topic', keywords: [], items: []},
    {id: 'i_geeky-stuff_board-game_modern-art', name:'Modern Art',  category: 'i_geeky-stuff_board-game', type: 'topic', keywords: [], items: []},
    {id: 'i_geeky-stuff_video-game_call-of-duty', name:'Call of Duty',  category: 'i_geeky-stuff_video-game', type: 'topic', keywords: [], items: []},
    {id: 'i_geeky-stuff_video-game_fifa', name:'FIFA',  category: 'i_geeky-stuff_video-game', type: 'topic', keywords: [], items: []},
    {id: 'i_geeky-stuff_video-game_final-fantasy', name:'Final Fantasy',  category: 'i_geeky-stuff_video-game', type: 'topic', keywords: [], items: []},
    {id: 'i_grphic-art-and-design_activity_drawing', name:'Drawing',  category: 'i_grphic-art-and-design_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_grphic-art-and-design_topic_graphic-design', name:'Graphic Design',  category: 'i_grphic-art-and-design_topic', type: 'topic', keywords: [], items: []},
    {id: 'i_grphic-art-and-design_topic_visual-arts', name:'Visual Arts',  category: 'i_grphic-art-and-design_topic', type: 'topic', keywords: [], items: []},
    {id: 'i_health-and-fitness_topic_medicine', name:'Medicine',  category: 'i_health-and-fitness_topic', type: 'topic', keywords: [], items: []},
    {id: 'i_health-and-fitness_topic_the-science-of-human-health', name:'The Science of Human Health',  category: 'i_health-and-fitness_topic', type: 'topic', keywords: [], items: []},
    {id: 'i_history-and-culture_book_sapiens-by-yuval-noah-harari', name:'Sapiens - Yuval Noah Harari',  category: 'i_history-and-culture_book', type: 'topic', keywords: [], items: []},
    {id: 'i_humor-and-comedy_person_stephen-colberlt', name:'Stephen Colberlt',  category: 'i_humor-and-comedy_person', type: 'topic', keywords: [], items: []},
    {id: 'i_learning-personal-development_activity_reading-self-improvement-books', name:'Reading Self Improvement Books',  category: 'i_learning-personal-development_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_learning-personal-development_book_atomic-habits-by-james-clear', name:'Atomic Habits - James Clear',  category: 'i_learning-personal-development_book', type: 'topic', keywords: [], items: []},
    {id: 'i_learning-personal-development_book_the-big-leap-by-gay-hendricks', name:'The Big Leap - Gay Hendricks',  category: 'i_learning-personal-development_book', type: 'topic', keywords: [], items: []},
    {id: 'i_music-and-dance_activity_playing-the-piano', name:'Playing the Piano',  category: 'i_music-and-dance_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_music-and-dance_activity_playing-the-violin', name:'Playing the Violin',  category: 'i_music-and-dance_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_music-and-dance_activity_singing', name:'Singing',  category: 'i_music-and-dance_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_music-and-dance_genere_hindi', name:'Hindi Music',  category: 'i_music-and-dance_genere', type: 'topic', keywords: [], items: []},
    {id: 'i_music-and-dance_person_mozart', name:'Mozart',  category: 'i_music-and-dance_person', type: 'topic', keywords: [], items: []},
    {id: 'i_music-and-dance_person_paul-mc-carney', name:'Paul McCarney',  category: 'i_music-and-dance_person', type: 'topic', keywords: [], items: []},
    {id: 'i_outdoors-and-adventure_activity_doing-cross-country-skiing', name:'Doing Cross Country Skiing',  category: 'i_outdoors-and-adventure_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_outdoors-and-adventure_activity_doing-extreme-sports', name:'Extreme Sports',  category: 'i_outdoors-and-adventure_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_outdoors-and-adventure_activity_doing-outdoors-sports', name:'Doing Outdoors Sports',  category: 'i_outdoors-and-adventure_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_outdoors-and-adventure_activity_surffing', name:'Surffing',  category: 'i_outdoors-and-adventure_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_parenting-and-family_podcast_parenting-hell', name:'Parenting Hell',  category: 'i_parenting-and-family_podcast', type: 'topic', keywords: [], items: []},
    {id: 'i_pets-and-animals_pet_dogs', name:'Dogs',  category: 'i_pets-and-animals_pet', type: 'topic', keywords: [], items: []},
    {id: 'i_philosophy-and-ethics_book_principles-by-ray-dalio', name:'Principles - Ray Dalio',  category: 'i_philosophy-and-ethics_book', type: 'topic', keywords: [], items: []},
    {id: 'i_philosophy-and-ethics_topic_first-principles', name:'First Principles',  category: 'i_philosophy-and-ethics_topic', type: 'topic', keywords: [], items: []},
    {id: 'i_politics-and-social-issues_activity_discussing-social-problems', name:'Discusing Social Problems',  category: 'i_politics-and-social-issues_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_politics-and-social-issues_person_brark-obama', name:'Brark Obama',  category: 'i_politics-and-social-issues_person', type: 'topic', keywords: [], items: []},
    {id: 'i_politics-and-social-issues_person_donald-trump', name:'Donald Trump',  category: 'i_politics-and-social-issues_person', type: 'topic', keywords: [], items: []},
    {id: 'i_politics-and-social-issues_person_john-f-keneddy', name:'John F. Keneddy',  category: 'i_politics-and-social-issues_person', type: 'topic', keywords: [], items: []},
    {id: 'i_science-and-nature_person_albert-einstein', name:'Albert Einstein',  category: 'i_science-and-nature_person', type: 'topic', keywords: [], items: []},
    {id: 'i_science-and-nature_topic_science', name:'Science',  category: 'i_science-and-nature_topic', type: 'topic', keywords: [], items: []},
    {id: 'i_science-and-nature_topic_the-environment', name:'The Environment',  category: 'i_science-and-nature_topic', type: 'topic', keywords: [], items: []},
    {id: 'i_science-and-nature_topic_the-ocean', name:'The Ocean',  category: 'i_science-and-nature_topic', type: 'topic', keywords: [], items: []},
    {id: 'i_spirituality-and-religion_book_the-bible', name:'The Bible',  category: 'i_spirituality-and-religion_book', type: 'topic', keywords: [], items: []},
    {id: 'i_spirituality-and-religion_religion_christianity', name:'Christianity',  category: 'i_spirituality-and-religion_religion', type: 'topic', keywords: [], items: []},
    {id: 'i_sports_activity_playing-cricket', name:'Playing Cricket',  category: 'i_sports_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_sports_activity_playing-hockey', name:'Hockey',  category: 'i_sports_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_sports_activity_playing-pool', name:'Pool',  category: 'i_sports_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_sports_person_cristiano-ronaldo', name:'Cristiano Ronaldo',  category: 'i_sports_person', type: 'topic', keywords: [], items: []},
    {id: 'i_sports_sport_hockey', name:'Hockey',  category: 'i_sports_sport', type: 'topic', keywords: [], items: []},
    {id: 'i_technology_person_elon-musk', name:'Elon Musk',  category: 'i_technology_person', type: 'topic', keywords: [], items: []},
    {id: 'i_technology_person_steve-jobs', name:'Steve Jobs',  category: 'i_technology_person', type: 'topic', keywords: [], items: []},
    {id: 'i_technology_thing_linux', name:'Linux',  category: 'i_technology_thing', type: 'topic', keywords: [], items: []},
    {id: 'i_technology_topic_artificial-intelligence', name:'Artificial Intelligence',  category: 'i_technology_topic', type: 'topic', keywords: [], items: []},
    {id: 'i_travel-and-exploration_activity_traveling', name:'Traveling',  category: 'i_travel-and-exploration_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_writing-and-literature_activity_reading-fiction-novels', name:'Fiction Novels',  category: 'i_writing-and-literature_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_writing-and-literature_activity_reading-non-fiction-books', name:'Reading Non-Fiction Books',  category: 'i_writing-and-literature_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_writing-and-literature_activity_reading-science-fiction', name:'Science Fiction',  category: 'i_writing-and-literature_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_writing-and-literature_activity_writting-fiction-stories', name:'Writting Fiction Stories',  category: 'i_writing-and-literature_activity', type: 'topic', keywords: [], items: []},
    {id: 'i_writing-and-literature_book_kafka-on-the-shore-by-haruki-murakami', name:'Kafka on the Shore by Haruki Murakami',  category: 'i_writing-and-literature_book', type: 'topic', keywords: [], items: []},
    {id: 'i_writing-and-literature_person_haruki-murakami', name:'Haruki Murakami',  category: 'i_writing-and-literature_person', type: 'topic', keywords: [], items: []},
];

export const [peopleTags, peopleTagsMasterRef] = prepareLabels(  tags_items, tags_categories, tags_buckets);
