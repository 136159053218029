import css from './BottomDrawer.module.css';

import { useSelector } from 'react-redux';
import DrawerTopBar from './DrawerTopBar/DrawerTopBar';

const BottomDrawer = (props) => {
    const drawerState = useSelector((state) => state.browser.bottomDrawer);

    const nextClass = {
        default: '',
        visible: 'show',
        hidden: 'hide',
    };

    const drawerClass = nextClass[drawerState];

    return (
        <div className={`${css['main-container']} ${css[drawerClass]}`}>
            <DrawerTopBar product={props.product} />
            <div className={css['children-container-outer']}>
                <div className={css['children-container']}>{props.children}</div>
            </div>
        </div>
    );
};

export default BottomDrawer;
