import { cloneObject } from '../../../tools/data-processing';
import { topics } from '../../../tools/pilotTopics';
import { days, months, times } from '../../../tools/timeConstants';

export function unpackInvitations(packedInvitations, people, places) {
    const invitations = { elementIds: [] };

    packedInvitations.elementIds.forEach((invitationId) => {
        const packedInvitation = packedInvitations[invitationId];

        invitations.elementIds.push(invitationId);
        const invitation = unpackInvitation(
            people[packedInvitation.who],
            packedInvitation.where,
            packedInvitation.when,
            places
        );

        invitation.id = invitationId;
        // console.log(invitation.valid, packedInvitation.valid)
        invitation.valid = packedInvitation.valid;
        // console.log(invitation.valid, packedInvitation.valid)
        invitations[invitationId] = invitation;
    });

    return invitations;
}

function unpackInvitation(packedWho, whereIds, whenIds, places) {
    const invitation = initBrowserInvitation();

    addWho();
    addWhere();
    addWhen();

    return invitation;

    function addWho() {
        invitation.id = packedWho.name;
        invitation.name = packedWho.name;
        invitation.audio = packedWho.audio;
        invitation.image = packedWho.image;

        const playbackIds = packedWho.playbackIds;
        playbackIds.forEach((topic) => {
            const topicId = topic[0];
            const unpackedTopic = cloneObject(topics[topicId]);

            unpackedTopic.id = topicId;
            unpackedTopic.playbackId = topic[1];
            unpackedTopic['tags'] = topic[2];
            unpackedTopic.shared = false; // to be deleted

            if (topicId === 'trailer') {
                unpackedTopic.audio = invitation.audio;
                unpackedTopic.shared = false;
            }

            invitation.topics[topicId] = unpackedTopic;
            invitation.topics.who.topics.push(topicId);
        });
    }

    function addWhere() {
        invitation['where'] = whereIds.slice(0, 4);

        whereIds.forEach((placeId) => {
            const place = places[placeId];
            invitation.topics.where_choice_topic.content.choices.push(placeId);

            const placeChoice = {
                id: placeId,
                name: place.name,
                default: 'empty_stage',
                image: place.thumbnail,
                topics: [],
                travelDistance: place.travelDistance,
                bill: place.prices.total.amount,
            };

            invitation.topics[placeId] = placeChoice;
        });
    }

    function addWhen() {
        invitation['when'] = whenIds.sort().slice(0, 4);

        whenIds.forEach((whenId) => {
            invitation.topics[whenId] = {
                id: whenId,
                name: '',
                type: 'info_screen',
                category: '',
                shared: false,
                playbackId: null,
                image: null,
                default: whenId,
                topics: [whenId],
                content: {
                    component: 'Date',
                    ...unpackSpecificDate(whenId),
                },
            };
        });
    }

    function addRandomWhen() {
        const today = new Date();
        let next = getRandomInt(2);

        if (today.getHours() > 20) {
            next += 1;
        }

        for (let i = 1; i < 5; i++) {
            const nextDate = new Date();
            nextDate.setDate(today.getDate() + next);
            const eventDate = createEventDate(nextDate);

            invitation.topics['date_' + i + '_choice'] = {
                id: 'date_' + i + '_choice',
                name: '',
                default: 'date_' + i,
                image: null,
                topics: ['date_' + i],
                content: eventDate,
            };

            invitation.topics['date_' + i] = {
                id: 'date_' + i,
                name: '',
                type: 'info_screen',
                category: '',
                shared: false,
                playbackId: null,
                content: {
                    component: 'Date',
                    ...eventDate,
                },
            };

            next += getRandomInt(3) + 1;
        }

        function getRandomInt(max) {
            return Math.floor(Math.random() * max);
        }

        function createEventDate(date) {
            const currentIndex = today.getHours() * 2 + 1;

            let minStartIndex = currentIndex;
            if (today.getMinutes() > 30) {
                minStartIndex += 1;
            }

            let weekday = days[date.getDay()];
            const startBase =
                weekday === 'Saturday' || weekday === 'Sunday'
                    ? 22 + getRandomInt(18)
                    : 34 + getRandomInt(6);

            const startIndex =
                date.getDate() !== today.getDate() ? startBase : Math.max(startBase, minStartIndex);

            const upperDiff = Math.min(48 - startIndex, 4);
            const lowerDiff = Math.min(startIndex - currentIndex, 3);

            if (date.getDate() === today.getDate()) {
                weekday = startIndex > 17 ? 'Tonight' : 'Today';
            }

            return {
                weekday: weekday,
                day: date.getDate(),
                month: months[date.getMonth()],
                time: times[startIndex],
                times: times.slice(startIndex - lowerDiff, startIndex + upperDiff),
            };
        }
    }
}

function initBrowserInvitation(host) {
    const invitation = {
        id: '',
        name: '',
        prediction: null,
        audio: '',
        host: { playbackId: null },

        topics: {
            sections: ['who', 'where', 'when'],

            choices: { where: 'where_choice_topic' },

            default: {
                section: 'who',
                who: 'trailer',
                where: 'where_choice_topic',
                when: 'when_choice_topic',
            },

            who: { topics: [], type: 'topics_section' },
            where: { topics: ['where_choice_topic'], type: 'topics_section' },
            when: { topics: ['when_choice_topic'], type: 'topics_section' },

            where_choice_topic: {
                id: 'where_choice_topic',
                name: 'Choose a place',
                type: 'choice',
                category: '',
                shared: false,
                playbackId: null,
                content: {
                    // audio: 'where-choice.mp3',
                    audio: null, // Moving the audio trigger to Section.js
                    component: 'ChoiceScreen',
                    choices: [],
                    section: 'where',
                },
            },

            when_choice_topic: {
                id: 'when_choice_topic',
                name: 'Pick a date',
                type: 'choice',
                category: '',
                shared: false,
                playbackId: null,
                content: {
                    // audio: 'when-choice.mp3',
                    audio: null, // Moving the audio trigger to Section.js
                    component: 'ChoiceScreen',
                    choices: ['date_1_choice', 'date_2_choice', 'date_3_choice', 'date_4_choice'],
                    section: 'when',
                },
            },
        },

        valid: [], // valid combinations of places & times.
    };

    return invitation;
}

export function unpackSpecificDate(specificDate) {
    const [dateString, timeWindow] = specificDate.split('_');
    const [wStart, wEnd] = timeWindow.split('-');

    const startIndex = times.indexOf(wStart.slice(3, 7));
    const endIndex = times.indexOf(wEnd.slice(3, 7));

    const timeOptions = times.slice(startIndex, endIndex + 1);

    const [year, monthOf, dayOf] = dateString.split('-');
    const date = new Date(year, monthOf - 1, dayOf);

    const day = date.getDate();
    const weekday = date.toLocaleDateString('en-US', { weekday: 'long' });
    const month = months[date.getMonth()];
    const time = timeOptions[Math.floor(timeOptions.length / 2)];

    return { day, weekday, month, times: timeOptions, time };
}
