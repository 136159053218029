import css from '../Bucket/Category/Category.module.css';

import { useSelector } from 'react-redux';

import FilterItem from './FilterItem';

const FilterBucket = (props) => {
    const drawer = useSelector((state) => state.drawer);
    const bucket = drawer[drawer.activeContent].masterRef[props.bucketId];
    const filteredItems = bucket.items.filter((item) => item.selected);

    if (filteredItems.length === 0) {
        return <></>;
    }

    return (
        <div className={css['main-container']}>
            <div className={css['title-container']}>{bucket.name}</div>
            <div className={css['note-container']}></div>
            <div className={css['items-container']}>
                {filteredItems.map((item) => (
                    <FilterItem key={item.id} item={item} />
                ))}
            </div>
        </div>
    );
};

export default FilterBucket;
