import css from './TopicToast.module.css';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

import { toggleToast } from '../../../../../store/browserSlice';
import { categoryIcons } from '../../../../../tools/topics';

const TopicToast = (props) => {
    const dispatch = useDispatch();
    const ref = useRef(null);

    const [animating, setAnimating] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    const topics = useSelector((state) => state.browser.topics);
    const topic = topics.masterRef[topics.active[topics.active.section]] || {
        id: 'host',
        name: 'Host',
        type: 'video',
        category: 'pending',
        shared: false,
        playbackId: null,
        audio: null,
    };

    const showToast = topics.toast;
    const topicText = topic.name;

    useEffect(() => {
        if (showToast) {
            setAnimating(true);
            const activeTimeout = setTimeout(function () {
                toggleToast(dispatch, false);
                setAnimating(false);
            }, 5000);
            setTimeoutId(activeTimeout);
        }
    }, [showToast]);

    useEffect(() => {
        if (animating) {
            cancelAnimation();
            setTimeout(function () {
                toggleToast(dispatch, true);
            }, 200);
        }
    }, [topicText]);

    function cancelAnimation() {
        if (ref.current) {
            ref.current.getAnimations().forEach((animation) => animation.cancel());
            toggleToast(dispatch, false);
            clearTimeout(timeoutId);
            setAnimating(false);
        }
    }

    const icon = categoryIcons[topic.category];
    const iconSize = (icon && icon.toastSize) || '32px';

    if (!icon || !topicText) {
        return <></>;
    }

    return (
        <div className={`${css['main-container']} ${showToast ? css['show'] : ''}`} ref={ref}>
            <div className={css['icon-container']}>
                <span
                    className={`${css['icon']} material-icons-round`}
                    style={{ fontSize: iconSize }}
                >
                    {icon ? icon.icon : ''}
                </span>
            </div>
            <div className={css['text-container']}>
                <span className={css['text-content']}>{topicText}</span>
            </div>
        </div>
    );
};

export default TopicToast;
