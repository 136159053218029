import css from './Manager.module.css';

import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ManagerTopBar from './ManagerTopBar/ManagerTopBar';
import SearchBar from './SearchBar/SearchBar';
import Thumbnail from './Thumbnail/Thumbnail';
import MiniThumbnail from './MiniThumbnail/MiniThumbail';
import { loadManager } from '../../../store/managerSlice';


const Manager = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user)
    const manager = user[props.product.toLowerCase()].manager
    
    useEffect(() => {
        loadManager(dispatch, props.product);
    }, []);

    
    return (
        <Fragment>
            <ManagerTopBar product={props.product} />
            <SearchBar />
            <div className={css['outer-flex']}>
                <div className={css['main-container']}>
                    {manager.element_ids.map((id) => (
                        <Thumbnail
                            key={id}
                            id={id} 
                            keywords={id}
                            element={manager[id]}
                        />
                    ))}
                </div>                
            </div>
            <MiniThumbnail />
        </Fragment>
    );
};

export default Manager;
