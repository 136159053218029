import css from './AcceptButton.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { updateAudioKeys, blinkAudio } from '../../../../store/audioSlice';
import { updateEventDoc } from '../../../../Firebase/Firestore/eventsFirestore';
import { initEvent } from '../../../../Firebase/Firestore/eventsFirestore';
import { getNowString } from '../../../../tools/functions/datesFunctions';

import { goTo } from '../../../../store/conductorSlice';
import { updateBrowser } from '../../../../store/browserSlice';
import { arrayUnion } from 'firebase/firestore';
import { updateEvents, updateEventsKeys } from '../../../../store/eventsSlice';
import { updateFirestoreKeys } from '../../../../store/firestoreSlice';

const AcceptButton = (props) => {
    const dispatch = useDispatch();
    const event_doc_id = useSelector((state) => state.browser.elements.activeId);
    const invitation = useSelector((state) => state.browser.invitation);

    const dissabled = !(invitation.where.selection && invitation.when.selection);
    const curtain = useSelector((state) => state.browser.stage.curtain);
    const eventsIds = useSelector((state) => state.events.elements.elementIds);

    useEffect(() => {
        let visibility = 'close';

        if (dissabled) {
            visibility = 'open';
        }

        if (curtain === 'close') {
            visibility = 'close';
        }

        if (curtain === 'closed') {
            visibility = 'closed';
        }

        updateBrowser(dispatch, 'stage', { topics: visibility });

        if (!dissabled) {
            updateBrowser(dispatch, 'stage', { audio: 'puzzle_sound.mp3', action: 'play' });
            updateAudioKeys(dispatch, ['music'], { song: 'celebration' });
        }
    }, [dissabled, curtain]);

    return (
        <div className={`${css['absolute-container']} ${dissabled && css['display-none']}`}>
            <div className={`${css['shiny-base']} ${dissabled && css['dissabled']}`}>
                <button
                    className={`${css['button-container']}`}
                    onClick={handleClick}
                    disabled={dissabled}
                >
                    <div className={css['text-container']}>
                        <div className={css['white-container']}>Let's make it happen!</div>

                        <div
                            className={`${css['shiny-container']} ${css['shiny']} ${
                                dissabled && css['dissabled']
                            }`}
                        >
                            Let's make it happen!
                        </div>
                    </div>
                </button>
            </div>
        </div>
    );

    function handleClick() {
        const eDate = invitation.when.selection.split('_')[0];
        const eWeekday = invitation.when.selection.split('_')[1].slice(0, 3);
        const eTime = invitation.when.details.content.time;
        const ePlace = invitation.where.selection;
        const ePlaceName = invitation.where.selection.split('_')[0];

        const event_id = eDate + '_' + eWeekday + eTime + '_' + ePlaceName;
        // console.log('Event id: ', event_id, event_doc_id);

        const newEvent = initEvent();
        newEvent.guest.actions.accepted = getNowString();
        newEvent.meta.event_id = event_id;
        newEvent.when.date = eDate;
        newEvent.when.time = eTime;
        newEvent.where = ePlace;

        const updatePayload = {
            status: 'happening',
            current_id: event_id,
            ['events.' + event_id]: newEvent,
            'events.element_ids': arrayUnion(event_id),
        };

        
        const newEventIds = [event_id, ...eventsIds];
        
        const localUpdatePayload = {
            [event_id]: initEventData(),
            elementIds: newEventIds,
        }
        
        updateEventsKeys(dispatch, ['elements'], localUpdatePayload);
        updateFirestoreKeys(dispatch, ['events','elements'], localUpdatePayload);

        updateEvents(dispatch, ['card', 'eventId'], event_id);

        blinkAudio(dispatch, ['music', 'action'], 'play');
        // updateEventDoc(event_doc_id, updatePayload); // tktk to uncomment after startupfest.
        goTo(dispatch, 'Events', 'EventCard');


        function initEventData() {                        
            const rawEvent = {            
                event_id,
                status: 'happening',
                who: { id: event_doc_id.split('_')[0], type: 'user' },
                where: newEvent.where,
                when: newEvent.when,                        
                event_doc_id: event_doc_id
            };
    
            return rawEvent;
        }

    }


};

export default AcceptButton;
