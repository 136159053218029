import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: 'AIzaSyCWjTRuLJ3S6jUuwHhVncJkyxTjZmuaCsM',
    authDomain: 'kins-development.firebaseapp.com',
    projectId: 'kins-development',
    storageBucket: 'kins-development.appspot.com',
    messagingSenderId: '969985491900',
    appId: '1:969985491900:web:d46886d47608a6386f61b9',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
// connectFirestoreEmulator(db, 'localhost', 8080);

// Initializes a reference to the Storage Bucket
export const storage = getStorage(app);
