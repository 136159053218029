import css from './TopicCard.module.css';

import { useDispatch, useSelector } from 'react-redux';
import { updateTopic } from '../../../../../store/browserSlice';

import { categoryIcons } from '../../../../../tools/topics';

const TopicCard = (props) => {
    const dispatch = useDispatch();

    const product = useSelector((state) => state.browser.product);

    const activeSection = useSelector((state) => state.browser.topics.active.section);
    const activeId = useSelector((state) => state.browser.topics.active[activeSection]);

    const filters = useSelector((state) => state.drawer.topics.filters);
    const tags = useSelector((state) => state.user.tags);

    const topic = props.topic;

    const text = topic.name;
    const icon = topic.category && categoryIcons[topic.category];

    const playing = topic.id === activeId ? { color: 'white', backgroundColor: 'var(--dark)' } : {};

    const isShiny = (product === 'People' || activeSection === 'who')  && checkShiny();

    function handleClick() {
        if (topic.id !== activeId) {
            updateTopic(dispatch, topic.id);
        }
    }

    const choiceStyle =
        topic.type === 'choice'
            ? {
                  width: '100%',
                  height: '60px',
                  fontSize: '32px',
                  justifyContent: 'center',
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  fontFamily: 'var(--sexy)',
                  fontWeight: 'bold',
                  textShadow: '0.4px 0.8px 0.8px hsl(0deg 0% 0% / 48%)',
                  color: 'white',
                  cursor: 'auto',
              }
            : {};

    if (text === '') {
        return <></>;
    }

    return (
        <div
            className={`${css['topic-card-container']}`}
            style={{ ...playing, ...choiceStyle }}
            onClick={handleClick}
        >
            <div className={`${css['content-container']} ${isShiny && css['shiny']}`}>
                <div className={css['icon-container']}>
                    <span
                        className={`${css['icon']} material-icons-round`}
                        style={{ ...icon.style }}
                    >
                        {icon.icon}
                    </span>
                </div>
                <div className={css['text-container']}>{text}</div>
            </div>
        </div>
    );

    function checkShiny() {
        if (filters.length === 0) {
            for (const tag of topic.tags) {
                if (tag in tags) {
                    return true;
                }
            }
        } else {
            for (const filter of filters) {
                if (topic.tags.includes(filter)) {
                    return true;
                }
            }
        }
        return false;
    }
};

export default TopicCard;
