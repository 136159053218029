import css from './EditorDrawer.module.css';
import BottomDrawer from '../../BottomDrawer/BottomDrawer';
import { useSelector } from 'react-redux';
import EditorThumbnail from './EditorThumbnail/EditorThumbnail';

const EditorDrawer = (props) => {
    const drawer = useSelector((state) => state.editor.drawer);
    const content = drawer.content;

    const user = useSelector((state) => state.user);
    const manager = content !== 'thumbnails' ? user[content].manager : drawer[content];
    const elementIds = content !== 'thumbnails' ? manager.element_ids : manager.elementIds;
    const product =
        content !== 'thumbnails'
            ? content.charAt(0).toUpperCase() + content.slice(1)
            : manager.product;

    return (
        <BottomDrawer product={product}>
            <div className={css['main-container']}>
                {elementIds.map((elementId) => (
                    <EditorThumbnail
                        key={elementId}
                        element={manager[elementId]}
                        product={product}
                    />
                ))}
            </div>
        </BottomDrawer>
    );
};

export default EditorDrawer;
