import css from './InfoBox.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { openDrawer, loadInfoBox } from '../../../../store/drawerSlice';
import { updateTopic } from '../../../../store/browserSlice';
import { useEffect } from 'react';

import MedianBill from '../../../Places/PlacesBrowser/MedianBill/MedianBill';
import PlainText from './PlainText/PlainText';
import RestaurantMenu from '../../../Places/PlacesBrowser/RestaurantMenu/RestaurantMenu';
import ElementName from '../BrowserVideos/ElementName/ElementName';
import Image from './Image/Image';
import Date from './Date/Date';
import Website from './Website/Website';

const InfoBox = (props) => {
    const content = useSelector((state) => state.browser.infoBox.content);
    const Component = components[content.component];

    return (
        <div className={css['main-container']}>
            <div className={css['name-container']}>
                <ElementName />
            </div>
            <Component content={content} />
        </div>
    );
};

const RedirectToDrawer = (props) => {
    const dispatch = useDispatch();
    
    useEffect(() => {
        loadInfoBox(dispatch, props.content)
        openDrawer(dispatch, 'infoBox');
        updateTopic(dispatch, 'empty_stage')
    }, []);

    return <></>;
};

const Nothing = (props) => {
    return <></>;
};

const components = {
    Date: Date,
    Hours: RedirectToDrawer,
    Image: Image,
    Map: RedirectToDrawer,
    MedianBill: MedianBill,
    Nothing: Nothing,
    PlainText: PlainText,
    Photos: RedirectToDrawer,
    RestaurantMenu: RestaurantMenu,
    Website,
};

export default InfoBox;
