import css from './PhoneInput.module.css';

import { cloneObject } from '../../../../tools/data-processing';

const PhoneInput = (props) => {
    const phone = cloneObject(props.value);

    function handlePhoneChange(event) {
        const number = event.target.value;
        const clean_number = ('' + number).replace(/\D/g, '');
        phone.number = clean_number;
        updatePhone(phone);
    }

    function updatePhone(phone) {
        props.onChange(phone);
        return;
    }

    const country_codes = {
        CAN: '+1',
        USA: '+1',
        MEX: '+52',
    };

    function handleCountryChange(event) {
        const country = event.target.value;
        phone.country = country;
        phone.code = country_codes[country];

        updatePhone(phone);
    }

    function formatPhoneNumber(str) {
        //Filter only numbers from the input
        let cleaned = ('' + str).replace(/\D/g, '');

        //Check if the input is of correct
        let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            //Remove the matched extension code
            //Change this to format for any country code.
            let intlCode = match[1] ? '+1 ' : '';
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }

        return cleaned;
    }

    return (
        <div className={css['input-container']}>
            <div className={css['input-icon-container']}>
                <span className={`material-icons-round ${css['input-icon']}`}>phone_iphone</span>
            </div>
            <select
                name='country_code'
                id='cars'
                form='carform'
                className={css['country-code']}
                onChange={handleCountryChange}
                value={phone.country || ''}
            >
                <option value='CAN'>🇨🇦 +1</option>
                <option value='USA'>🇺🇸 +1</option>
                <option value='MEX'>🇲🇽 +52</option>
            </select>
            <input
                className={css['input-text']}
                type={props.inputType}
                placeholder='Phone number'
                onChange={handlePhoneChange}
                value={formatPhoneNumber(phone.number)}
            />
        </div>
    );
};

export default PhoneInput;
