import css from './Curtain.module.css';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const Curtain = (props) => {
    const show = useSelector((state) => state.conductor.curtain);
    const [imgSrc, setImgSource] = useState(selectRandomImage());
    const curtainClass = show ? 'show' : 'hide';

    useEffect(() => {
        setTimeout(() => {
            setImgSource(selectRandomImage());
        }, 1500);
    }, [show]);

    return (
        <div className={`${css['main-container']} ${css[curtainClass]}`}>
            <div className={css['curtain']}>
                <img className={css['curtain-image']} src={imgSrc[0]} alt='' style={imgSrc[1]} />
            </div>
        </div>
    );
};

export default Curtain;

const images = [
    ['102bea5d0c6d1f9c39952a4c48984f55.jpg', { width: '100%' }],
    ['9277de.jpg', {}],
    ['11c7c22599551fbbbe71117198fee5e3.jpg', { width: '100%', objectPosition: 'top' }],
    ['9b0de6d8b74140607f7afb4adfa76b54.jpg', {}],
    ['1950s-Teens-On-Beach.jpg', {}],
    ['9d4682f51c9f9e54adeb98442b8eb488.jpg', { width: '100%' }],
    ['2a9dae756986989e57547e2f0a35c48d.jpg', {}],
    ['Beach-acrobatics-2.jpg', {}],
    ['39a6ac424de9dfbdf452c835f49ae086.jpg', { width: '100%', objectPosition: 'bottom' }],
    ['a75971f0ccc9b921f741ddebeb18881b.jpg', {}],
    ['49ba32b3142ff678385f7d1618d22a48.jpg', { width: '100%' }],
    ['bd074a.jpg', {}],
    ['4c15adcc860990ff93e7965a4a2918a6.jpg', {}],
    ['caaa4c4.jpg', { width: '100%', objectPosition: 'left' }],
    ['4fa75be3dc0e850f7d6f17e758aaeea8.jpg', { width: '100%', objectPosition: 'top' }],
    ['e9aa69b7372b5d65ec0c57b127277dbe.jpg', { width: '100%', objectPosition: 'right' }],
    ['50cc9eba07594c6469d8f86b508295a5.jpg', {}],
    ['ef3b43.jpg', {}],
    ['50e01686803389e74b374dd0e44f3bcd.jpg', { width: '100%' }],
    ['f309986.jpg', { width: '100%', objectPosition: 'left bottom' }],
    ['647efe2acf8fcc28eeeafafd4b467ef2.jpg', { width: '100%', objectPosition: 'right bottom' }],
    ['ironing-dog.jpg', {}],
    ['8e7e63ef933f578396f05f35d1107914.jpg', { width: '100%', objectPosition: '25% bottom' }],
    ['california_1966.webp', { width: '100%' }],
    ['a9a606ee0a08ac2a318be577f34f03cd.jpg', { width: '100%', objectPosition: 'left' }],
    ['beach-party.jpg', { width: '100%', objectPosition: 'left' }],
    // ['', {}],
];

function selectRandomImage() {
    const randIndex = Math.floor(Math.random() * images.length);
    const image = images[randIndex][0];
    return [require('../../../images/curtain/' + image), images[randIndex][1]];
}
