import { cloneObject, selectRandomElement } from './data-processing';

export const personPrediction = [null, null, null, null, null, 'KindredSpirit', 'KindredSpirit'];

export const placePrediction = [null, 'GoodTime'];

export const topics = {
    '': {
        id: '',
        name: '',
        type: 'video',
        category: '',
        audio: '.mp3',
    },

    'advice-for-my-past-self': {
        id: 'advice-for-my-past-self',
        name: 'Advice For My Past Self',
        type: 'video',
        category: 'reflection',
        audio: 'advice-for-my-past-self.mp3',
    },

    'artificial-intelligence': {
        id: 'artificial-intelligence',
        name: 'Artificial Intelligence',
        type: 'video',
        category: 'reflection',
        audio: 'artificial-intelligence.mp3',
    },

    'being-yourself': {
        id: 'being-yourself',
        name: 'Being Yourself',
        type: 'video',
        category: 'reflection',
        audio: 'being-yourself.mp3',
    },

    'countries-visited': {
        id: 'countries-visited',
        name: 'Countries Visited',
        type: 'video',
        category: 'trip',
        audio: 'countries-visited.mp3',
    },

    cricket: {
        id: 'cricket',
        name: 'Cricket',
        type: 'video',
        category: 'sport',
        audio: 'cricket.mp3',
    },

    'dream-dinner-guest': {
        id: 'dream-dinner-guest',
        name: 'Dream Dinner Guest',
        type: 'video',
        category: 'reflection',
        audio: 'dream-dinner-guest.mp3',
    },

    'dream-job': {
        id: 'dream-job',
        name: 'Dream Job',
        type: 'video',
        category: 'work',
        audio: 'dream-job.mp3',
    },

    'drinks-video': {
        id: 'drinks-video',
        type: 'video',
        category: 'trailer',
        name: 'Drinks',
        playbackId: null,
        audio: 'xx.mp3',
    },

    'favorite-topics': {
        id: 'favorite-topics',
        name: 'Favorite Topics',
        type: 'video',
        category: 'favorite',
        audio: 'favorite-topics.mp3',
    },

    'food-menu': {
        id: 'food-menu',
        name: 'Menu',
        type: 'info_screen',
        category: 'restaurant_menu',
        content: {
            component: 'RestaurantMenu',
            menu: null,
        },
    },

    'food-video': {
        id: 'food-video',
        type: 'video',
        category: 'trailer',
        name: 'Food',
        playbackId: null,
        audio: 'xx.mp3',
    },

    'free-will': {
        id: 'free-will',
        name: 'Free Will',
        type: 'video',
        category: 'reflection',
        audio: 'free-will.mp3',
    },

    'grateful-for': {
        id: 'grateful-for',
        name: 'Grateful For',
        type: 'video',
        category: 'reflection',
        audio: 'grateful-for.mp3',
    },

    hobbies: {
        id: 'hobbies',
        name: 'Hobbies',
        type: 'video',
        category: 'favorite',
        audio: 'hobbies.mp3',
    },

    hours: {
        id: 'hours',
        name: 'Hours',
        type: 'info_screen',
        category: 'hours',
        content: {
            component: 'Hours',
            hours: null,
        },
    },

    'ideal-saturday': {
        id: 'ideal-saturday',
        name: 'Ideal Saturday',
        type: 'video',
        category: 'favorite',
        audio: 'ideal-saturday.mp3',
    },

    'if-i-received-1B-dollars': {
        id: 'if-i-received-1B-dollars',
        name: 'If I Received $1B',
        type: 'video',
        category: 'reflection',
        audio: 'if-i-received-1B-dollars.mp3',
    },

    'if-i-received-10M-dollars': {
        id: 'if-i-received-10M-dollars',
        name: 'If I Received $10M',
        type: 'video',
        category: 'reflection',
        audio: 'if-I-received-10M-dollars.mp3',
    },

    introvert: {
        id: 'introvert',
        name: 'Introvert',
        type: 'video',
        category: 'battery',
        audio: 'introvert.mp3',
    },

    'life-story': {
        id: 'life-story',
        name: 'Life Story',
        type: 'video',
        category: 'story',
        shared: false,
        audio: 'life-story.mp3',
    },

    'location-map': {
        id: 'location_map',
        name: 'Map',
        type: 'info_screen',
        category: 'location',
        content: {
            component: 'Map',
            placeId: null,
        },
    },

    'looking-for-in-brix': {
        id: 'looking-for-in-brix',
        name: 'Looking For In Brix',
        type: 'video',
        category: 'crowd',
        audio: 'looking-for-in-brix.mp3',
    },

    'meaning-of-life': {
        name: 'The Meaning of Life',
        type: 'video',
        category: 'reflection',
        audio: 'the-meaning-of-life.mp3',
    },

    'median-bill': {
        id: 'median_bill',
        name: 'Bill',
        type: 'info_screen',
        category: 'money',
        content: {
            component: 'MedianBill',
            appetizer: null,
            main: null,
            dessert: null,
            alcohol: null,
        },
    },

    mexican: {
        id: 'mexican',
        name: 'Mexican',
        type: 'video',
        category: 'flag',
        audio: 'mexican.mp3',
    },

    'my-crowd': {
        id: 'my-crowd',
        name: 'My Crowd',
        type: 'video',
        category: 'crowd',
        audio: 'my-crowd.mp3',
    },

    'pharma-industry': {
        id: 'pharma-industry',
        name: 'The Pharma Industry',
        type: 'video',
        category: 'reflection',
        audio: 'pharma-industry.mp3',
    },

    photos: {
        id: 'photos',
        name: 'Photos',
        type: 'info_screen',
        category: 'photos',
        content: {
            component: 'Photos',
            photos: null,
        },
    },

    reading: {
        id: 'reading',
        name: 'Reading',
        type: 'video',
        category: 'book',
        audio: 'reading.mp3',
    },

    reservations: {
        id: 'reservations',
        name: 'Reservations',
        type: 'info_screen',
        category: 'reservation',
        shared: false,
        playbackId: null,
        content: {
            component: 'PlainText',
            text: null,
        },
    },

    'social-media': {
        id: 'social-media',
        name: 'Social Media',
        type: 'video',
        category: 'reflection',
        audio: 'social-media.mp3',
    },

    'spoken-languages': {
        id: 'spoken-languages',
        name: 'Spoken Languages',
        type: 'video',
        category: 'language',
        audio: 'spoken-languages.mp3',
    },

    'take-on-college': {
        id: 'take-on-college',
        name: 'Take on College',
        type: 'video',
        category: 'reflection',
        audio: 'take-on-college.mp3',
    },

    'the-last-of-us': {
        id: 'the-last-of-us',
        name: 'The Last of Us',
        type: 'video',
        category: 'game',
        audio: 'the-last-of-us.mp3',
    },

    'too-serious-to-be-joked-about': {
        id: 'too-serious-to-be-joked-about',
        name: 'Too Serious To Be Joked About',
        type: 'video',
        category: 'reflection',
        audio: 'too-serious-to-be-joked-about.mp3',
    },

    trailer: {
        id: 'trailer',
        name: 'Trailer',
        type: 'video',
        category: 'trailer',
        audio: null,
    },

    'travel-wishlist': {
        id: 'travel-wishlist',
        name: 'Travel Wishlist',
        type: 'video',
        category: 'trip',
        audio: 'travel-wishlist.mp3',
    },

    traveling: {
        id: 'traveling',
        name: 'Traveling',
        type: 'video',
        category: 'trip',
        audio: 'traveling.mp3',
    },

    'venue-video': {
        id: 'venue-video',
        type: 'video',
        category: 'trailer',
        name: 'Venue',
        playbackId: null,
        audio: 'xx.mp3',
    },

    work: {
        id: 'work',
        name: 'Work',
        type: 'video',
        category: 'work',
        audio: 'work.mp3',
    },

    'worlds-biggest-problem': {
        id: 'worlds-biggest-problem',
        name: "World's Biggest Problem",
        type: 'video',
        category: 'reflection',
        audio: 'worlds-biggest-problem.mp3',
    },

    writing: {
        id: 'writing',
        name: 'Writing',
        type: 'video',
        category: 'book',
        audio: 'writing.mp3',
    },

    '2-truths-1-lie': {
        id: '2-truths-1-lie',
        name: '2 truths 1 lie',
        type: 'video',
        category: 'comedy',
        audio: '2-truths-1-lie.mp3',
    },
    animals: {
        id: 'animals',
        name: 'Animals',
        type: 'video',
        category: 'animal',
        audio: 'animals.mp3',
    },
    'billboard-message': {
        id: 'billboard-message',
        name: 'Billboard Message',
        type: 'video',
        category: 'reflection',
        audio: 'billboard-message.mp3',
    },
    'board-games': {
        id: 'board-games',
        name: 'Board Games',
        type: 'video',
        category: 'game',
        audio: 'board-games.mp3',
    },
    communication: {
        id: 'communication',
        name: 'Communication',
        type: 'video',
        category: 'reflection',
        audio: 'communication.mp3',
    },
    'contrarian-ideas': {
        id: 'contrarian-ideas',
        name: 'Contrarian Ideas',
        type: 'video',
        category: 'reflection',
        audio: 'contrarian-ideas.mp3',
    },
    'controversial-opinions': {
        id: 'controversial-opinions',
        name: 'Controversial Opinions',
        type: 'video',
        category: 'reflection',
        audio: 'controversial-opinions.mp3',
    },
    'cross-country-skiing': {
        id: 'cross-country-skiing',
        name: 'Cross Country Skiing',
        type: 'video',
        category: 'sport',
        audio: 'cross-country-skiing.mp3',
    },
    crypto: {
        id: 'crypto',
        name: 'Crypto',
        type: 'video',
        category: 'reflection',
        audio: 'crypto.mp3',
    },
    'dad-jokes': {
        id: 'dad-jokes',
        name: 'Dad Jokes',
        type: 'video',
        category: 'comedy',
        audio: 'dad-jokes.mp3',
    },
    'dating-stuff': {
        id: 'dating-stuff',
        name: 'Dating Stuff',
        type: 'video',
        category: 'favorite',
        audio: 'dating-stuff.mp3',
    },
    'debates-i-enjoy': {
        id: 'debates-i-enjoy',
        name: 'Debates I Enjoy',
        type: 'video',
        category: 'reflection',
        audio: 'debates-i-enjoy.mp3',
    },
    'extreme-sports': {
        id: 'extreme-sports',
        name: 'Extreme Sports',
        type: 'video',
        category: 'sport',
        audio: 'extreme-sports.mp3',
    },
    extrovert: {
        id: 'extrovert',
        name: 'Extrovert',
        type: 'video',
        category: 'battery',
        audio: 'extrovert.mp3',
    },
    'favorite-bible-passage': {
        id: 'favorite-bible-passage',
        name: 'Favorite Bible Passage',
        type: 'video',
        category: 'favorite',
        audio: 'favorite-bible-passage.mp3',
    },
    'final-fantasy': {
        id: 'final-fantasy',
        name: 'Final Fantasy',
        type: 'video',
        category: 'game',
        audio: 'final-fantasy.mp3',
    },
    food: { id: 'food', name: 'Food', type: 'video', category: 'food', audio: 'food.mp3' },
    'fun-story': {
        id: 'fun-story',
        name: 'Fun Story',
        type: 'video',
        category: 'comedy',
        audio: 'fun-story.mp3',
    },
    'funny-people': {
        id: 'funny-people',
        name: 'Funny People',
        type: 'video',
        category: 'comedy',
        audio: 'funny-people.mp3',
    },
    'humans-best-and-worst-qualities': {
        id: 'humans-best-and-worst-qualities',
        name: 'Humans Best and Worst Qualities',
        type: 'video',
        category: 'reflection',
        audio: 'humans-best-and-worst-qualities.mp3',
    },
    'i-will-never-again': {
        id: 'i-will-never-again',
        name: 'I Will Never Again',
        type: 'video',
        category: 'story',
        audio: 'i-will-never-again.mp3',
    },
    'if-i-had-2-extra-hours': {
        id: 'if-i-had-2-extra-hours',
        name: 'If I Had Extra 2 Hours',
        type: 'video',
        category: 'reflection',
        audio: 'if-i-had-2-extra-hours.mp3',
    },
    introduction: {
        id: 'introduction',
        name: 'Introduction',
        type: 'video',
        category: 'person',
        audio: 'introduction.mp3',
    },
    'introvert-or-extrovert': {
        id: 'introvert-or-extrovert',
        name: 'Introvert or Extrovert',
        type: 'video',
        category: 'battery',
        audio: 'introvert-or-extrovert.mp3',
    },
    investing: {
        id: 'investing',
        name: 'Investing',
        type: 'video',
        category: 'money',
        audio: 'investing.mp3',
    },
    joke: { id: 'joke', name: 'Joke', type: 'video', category: 'comedy', audio: 'joke.mp3' },
    linux: { id: 'linux', name: 'Linux', type: 'video', category: 'computer', audio: 'linux.mp3' },
    'looking-for-people-to': {
        id: 'looking-for-people-to',
        name: 'Looking for People To',
        type: 'video',
        category: 'reflection',
        audio: 'looking-for-people-to.mp3',
    },
    'looking-forward-to': {
        id: 'looking-forward-to',
        name: 'Looking Forward To',
        type: 'video',
        category: 'reflection',
        audio: 'looking-forward-to.mp3',
    },
    'making-cocktails': {
        id: 'making-cocktails',
        name: 'Making Cocktails',
        type: 'video',
        category: 'reflection',
        audio: 'making-cocktails.mp3',
    },
    'mind-blowing-facts': {
        id: 'mind-blowing-facts',
        name: 'Mind Blowing Facts',
        type: 'video',
        category: 'reflection',
        audio: 'mind-blowing-facts.mp3',
    },
    music: { id: 'music', name: 'Music', type: 'video', category: 'music', audio: 'music.mp3' },
    'my-dream': {
        id: 'my-dream',
        name: 'My Dream',
        type: 'video',
        category: 'favorite',
        audio: 'my-dream.mp3',
    },
    'my-faith': {
        id: 'my-faith',
        name: 'My Faith',
        type: 'video',
        category: 'favorite',
        audio: 'my-faith.mp3',
    },
    'my-passion': {
        id: 'my-passion',
        name: 'My Passion',
        type: 'video',
        category: 'favorite',
        audio: 'my-passion.mp3',
    },
    'my-work-life': {
        id: 'my-work-life',
        name: 'My Work Life',
        type: 'video',
        category: 'work',
        audio: 'my-work-life.mp3',
    },

    'my-work-story': {
        id: 'my-work-story',
        name: 'My Work Story',
        type: 'video',
        category: 'work',
        audio: 'my-work-story.mp3',
    },
    'my-writing': {
        id: 'my-writing',
        name: 'My Writing',
        type: 'video',
        category: 'book',
        audio: 'my-writing.mp3',
    },
    'parenting-advice': {
        id: 'parenting-advice',
        name: 'Parenting Advice',
        type: 'video',
        category: 'reflection',
        audio: 'parenting-advice.mp3',
    },
    'parenting-struggles': {
        id: 'parenting-struggles',
        name: 'Parenting Struggles',
        type: 'video',
        category: 'reflection',
        audio: 'parenting-struggles.mp3',
    },
    'parenting-surprises': {
        id: 'parenting-surprises',
        name: 'Parenting Surprises',
        type: 'video',
        category: 'reflection',
        audio: 'parenting-surprises.mp3',
    },
    'pet-peeves': {
        id: 'pet-peeves',
        name: 'Pet Peeves',
        type: 'video',
        category: 'reflection',
        audio: 'pet-peeves.mp3',
    },
    'pineapple-on-pizza': {
        id: 'pineapple-on-pizza',
        name: 'Pineapple on Pizza',
        type: 'video',
        category: 'food',
        audio: 'pineapple-on-pizza.mp3',
    },
    'podcasts-i-like': {
        id: 'podcasts-i-like',
        name: 'Podcasts I Like',
        type: 'video',
        category: 'favorite',
        audio: 'podcasts-i-like.mp3',
    },
    politics: {
        id: 'politics',
        name: 'Politics',
        type: 'video',
        category: 'reflection',
        audio: 'politics.mp3',
    },
    pranks: {
        id: 'pranks',
        name: 'Pranks',
        type: 'video',
        category: 'comedy',
        audio: 'pranks.mp3',
    },
    'science-fiction': {
        id: 'science-fiction',
        name: 'Science Fiction',
        type: 'video',
        category: 'book',
        audio: 'science-fiction.mp3',
    },
    'special-talent': {
        id: 'special-talent',
        name: 'Special Talent',
        type: 'video',
        category: 'comedy',
        audio: 'special-talent.mp3',
    },
    studies: {
        id: 'studies',
        name: 'Studies',
        type: 'video',
        category: 'reflection',
        audio: 'studies.mp3',
    },
    'stuff-i-would-like-to-create': {
        id: 'stuff-i-would-like-to-create',
        name: "Stuff I'd Like to Create",
        type: 'video',
        category: 'reflection',
        audio: 'stuff-i-would-like-to-create.mp3',
    },
    tatoos: {
        id: 'tatoos',
        name: 'Tatoos',
        type: 'video',
        category: 'person',
        audio: 'tatoos.mp3',
    },
    'the-environment': {
        id: 'the-environment',
        name: 'The Environment',
        type: 'video',
        category: 'reflection',
        audio: 'the-environment.mp3',
    },
    'the-kardashians': {
        id: 'the-kardashians',
        name: 'The Kardashians ',
        type: 'video',
        category: 'person',
        audio: 'the-kardashians.mp3',
    },
    'the-metaverse': {
        id: 'the-metaverse',
        name: 'The Metaverse ',
        type: 'video',
        category: 'reflection',
        audio: 'the-metaverse.mp3',
    },
    'the-royal-family': {
        id: 'the-royal-family',
        name: 'The Royal Family',
        type: 'video',
        category: 'person',
        audio: 'the-royal-family.mp3',
    },
    'the-shawshank-redemption': {
        id: 'the-shawshank-redemption',
        name: 'The Shawshank Redemption',
        type: 'video',
        category: 'trailer',
        audio: 'the-shawshank-redemption.mp3',
    },
    'things-id-like-to-get-better-at': {
        id: 'things-id-like-to-get-better-at',
        name: "Things I'd Like to Get Better At",
        type: 'video',
        category: 'reflection',
        audio: 'things-id-like-to-get-better-at.mp3',
    },
    'travel-story': {
        id: 'travel-story',
        name: 'Travel Story',
        type: 'video',
        category: 'story',
        audio: 'travel-story.mp3',
    },
    twitter: {
        id: 'twitter',
        name: 'Twitter',
        type: 'video',
        category: 'reflection',
        audio: 'twitter.mp3',
    },
    'vices-i-despite': {
        id: 'vices-i-despite',
        name: 'Vices I Despite',
        type: 'video',
        category: 'reflection',
        audio: 'vices-i-despite.mp3',
    },
    'video-games': {
        id: 'video-games',
        name: 'Video Games',
        type: 'video',
        category: 'game',
        audio: 'video-games.mp3',
    },
    'virtudes-i-admire': {
        id: 'virtudes-i-admire',
        name: 'Virtudes I Admire',
        type: 'video',
        category: 'reflection',
        audio: 'virtudes-i-admire.mp3',
    },
    'why-most-relationships-fail': {
        id: 'why-most-relationships-fail',
        name: 'Why Most Relationships Fail',
        type: 'video',
        category: 'reflection',
        audio: 'why-most-relationships-fail.mp3',
    },

    sports: {
        id: 'sports',
        name: 'Sports',
        type: 'video',
        category: 'sport',
        audio: 'sports.mp3',
    },

    quebec: {
        id: 'quebec',
        name: 'Quebec',
        type: 'video',
        category: 'flag',
        audio: 'quebec.mp3',
    },

    singing: {
        id: 'singing',
        name: 'Singing',
        type: 'video',
        category: 'music',
        audio: 'singing.mp3',
    },

    'cultural-differences': {
        id: 'cultural-differences',
        name: 'Cultural Differences',
        type: 'video',
        category: 'flag',
        audio: 'cultural-differences.mp3',
    },

    hockey: {
        id: 'hockey',
        name: 'Hockey',
        type: 'video',
        category: 'sport',
        audio: 'hockey.mp3',
    },

    website: {
        id: 'website',
        name: 'Website',
        type: 'info_screen',
        category: 'pending',
        content: {
            component: 'Website',
            url: null,
        },
    },
};

export function unpackVideoTopics(elem, elementId) {
    const element = cloneObject(elem);

    element.id = elementId;
    element.prediction = selectRandomElement(personPrediction);

    element.topics = {
        videos: {
            topics: [],
            type: 'topics_section',
        },

        sections: ['videos'],

        default: {
            section: 'videos',
            videos: 'trailer',
        },
    };

    const playbackIds = element.playbackIds;
    playbackIds.forEach((topic) => {
        const topicId = topic[0];
        const unpackedTopic = cloneObject(topics[topicId]);

        unpackedTopic.id = topicId;
        unpackedTopic.playbackId = topic[1];
        unpackedTopic['tags'] = topic[2];
        unpackedTopic.shared = false; // to be deleted

        if (topicId === 'trailer') {
            unpackedTopic.audio = element.audio;
            unpackedTopic.shared = false;
        }

        element.topics[topicId] = unpackedTopic;
        element.topics.videos.topics.push(topicId);
    });

    element.host = {
        playbackId: null,
        topicsTime: 0,
        segments: {
            introduction: { id: 'introduction', start: 0, end: null, onEnded: 'loop' },
        },
    };

    return element;
}

export function unpackPlaceTopics(elem, placeId) {
    const place = packPlace(elem);
    place.id = placeId;
    place.prediction = selectRandomElement(placePrediction);

    place.topics = {
        sections: ['main'],
        main: { topics: [], type: 'topics_section' },
        default: { section: 'main', main: 'venue-video' },
    };

    const packedTopics = place.packedTopics;

    packedTopics.forEach((packedTopic) => {
        const topicId = packedTopic.id;
        const topic = cloneObject(topics[topicId]);

        Object.keys(packedTopic).forEach((key) => {
            if (key in topic) {
                topic[key] = packedTopic[key];
            } else {
                topic.content[key] = packedTopic[key];
            }
        });

        topic.shared = false;

        if (topicId === 'venue-video') {
            topic.audio = place.audio;
        }

        place.topics[topicId] = topic;
        place.topics.main.topics.push(topicId);
    });

    place.host = { playbackId: null };

    return place;

    function packPlace(rawPlace) {
        const place = {};
        place['name'] = rawPlace.name;
        place['menu'] = rawPlace.menu;
        place['view'] = selectView();
        place['audio'] = null;
        place['image'] = rawPlace.thumbnail;
        place['song'] = rawPlace.song;
        place['video'] = rawPlace.playbackId;
        place['prices'] = rawPlace.prices;
        place['travelDistance'] = rawPlace.travelDistance;
        place['packedTopics'] = [
            { id: 'venue-video', playbackId: rawPlace.playbackId },
            { id: 'photos', photos: [rawPlace.thumbnail, ...rawPlace.photos] },
            { id: 'food-menu', menu: {} },

            { id: 'location-map', placeId: rawPlace.place_id, time: 10 },
            { id: 'hours', hours: rawPlace.hours },
        ];

        return place;

        function selectView() {
            if (rawPlace.playbackId) {
                return 'VideoPlayer';
            }

            if (rawPlace.photos.length > 7) {
                return 'VibeWall';
            }

            return 'ThumbnailAndMap';
        }
    }
}
