import css from './Sections.module.css';

import Section from './Section/Section';

import { useSelector } from 'react-redux';

const Sections = (props) => {
    const sectionsIds = useSelector((state) => state.browser.topics.sections);
    return (
        <div className={css['main-container']}>
            {sectionsIds.map((sectionId) => (
                <Section key={sectionId} section={sections[sectionId]} css={props.css} />
            ))}
        </div>
    );
};

export default Sections;

export const sections = {
    main: {
        id: 'main',
        text: 'Main',
        icon: 'ondemand_video',
        iconProps: {},
        theme: null,
    }, 
    
    
    videos: {
        id: 'videos',
        text: 'Videos',
        icon: 'ondemand_video',
        iconProps: { marginBottom: '-2px' },
        theme: 'People',
    },
    address: { id: 'address', text: 'Map', icon: 'location_on', theme: null },
    info: { id: 'info', text: 'Info', icon: 'info', theme: 'Invitations' },
    restaurant_menu: { id: 'restaurant_menu', text: 'Menu', icon: 'restaurant_menu', theme: null },

    who: {
        id: 'who',
        text: 'Who',
        icon: '',
        iconProps: {},
        theme: 'People',
    },

    where: {
        id: 'where',
        text: 'Where',
        icon: '',
        iconProps: {},
        theme: 'Places',
    },

    when: {
        id: 'when',
        text: 'When',
        icon: '',
        iconProps: {},
        theme: 'Invitations',
    },
};
