import css from './Stage.module.css';

import { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import StageManager from './StageManager/StageManager';
import AudioPlayer from './AudioPlayer';
import StageCurtain from './StageCurtain/StageCurtain';
import StageLights from './StageLights/StageLights';
import { updateBrowser } from '../../../../store/browserSlice';

const Stage = (props) => {
    const dispatch = useDispatch()
    const speed = useSelector((state) => state.browser.stage.speed);

    useEffect(() => {
        updateBrowser(dispatch, 'stage', { speed: [1500, 1500] });
    }, []);

    return (
        <Fragment>
            <StageManager />
            <div
                className={css['main-container']}
                style={{
                    '--close-speed': speed[0] + 'ms',
                    '--open-speed': speed[1] + 'ms',
                }}
            >
                <StageCurtain />
                <StageLights />
                {props.children}
            </div>
            <AudioPlayer />
        </Fragment>
    );
};

export default Stage;
