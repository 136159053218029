import css from './AutocompleteInput.module.css';

import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateEditorKeys, updateEditor } from '../../../../../store/editorSlice';
import { processGooglePayload } from './processGooglePayload';
import { getNowString } from '../../../../../tools/functions/datesFunctions';

const AfterLoad = (props) => {
    const dispatch = useDispatch();


    const defaultThumbnails = useSelector((state) => state.editor.drawer.thumbnails);
    const defaultThumbnail = defaultThumbnails[defaultThumbnails.elementIds[0]].image;

    const autoCompleteRef = useRef(null);

    useEffect(() => {
        const autocomplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
            componentRestrictions: { country: ['CA'] },
            fields: [
                'place_id',
                'name',
                'address_components',
                'formatted_address',
                'types',
                'photos',
                'geometry.location',

                'opening_hours.weekday_text',
                'formatted_phone_number',
                'website',
            ],

            bounds: { north: 45.703778, south: 45.414588, east: -73.476204, west: -73.947543 },
            strictBounds: true,
        });

        autocomplete.addListener('place_changed', handlePlaceSelected);

        function handlePlaceSelected() {
            const payload = autocomplete.getPlace();
            const [place, thumbnails] = processGooglePayload(payload);
            place.google.pretty_address = autoCompleteRef.current.value;

            const shortAddress =
                place.google.formatted_address.split(',')[0] + ', ' + place.address.postal_code;
            place.info.short_address = shortAddress;

            if (thumbnails.elementIds.length > 0) {
                updateEditorKeys(dispatch, ['drawer', 'thumbnails'], thumbnails);
            }

            updateEditorKeys(dispatch, ['place'], {
                name: place.google.name,
                thumbnail:
                    thumbnails.elementIds.length > 0 ? thumbnails.photos[0] : defaultThumbnail,
                meta: {
                    id: place.media.bucket_id,
                    place_doc_id: place.media.bucket_id,
                    google_place_id: place.google.place_id,
                    created: getNowString(),
                    modified: getNowString(),
                },
            });

            updateEditor(dispatch, ['placeDoc'], place);

            autoCompleteRef.current.value = shortAddress;
        }
    }, []);

    
    return (
        <div className={css['input-container']}>
            <div className={css['input-icon-container']}>
                <span className={`material-icons ${css['input-icon']} Places`}>location_on</span>
            </div>
            
            <input
                ref={autoCompleteRef}
                className={`${css['input-text']}`}
                placeholder='Address or place name'
                defaultValue={props.shortAddress}
            />
        </div>
    );
};

const AutocompleteInput = (props) => {
    const render = (status) => {
        return <h1>{status}</h1>;
    };

    return (
        <Wrapper
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            render={render}
            libraries={['places']}
        >
            <AfterLoad shortAddress={props.shortAddress}/>
        </Wrapper>
    );
};

export default AutocompleteInput;
