import css from './UploadPhotos.module.css';
import { uploadTempPhotos } from './Storage';
import { useSelector, useDispatch } from 'react-redux';
import { updateFirestoreDoc } from '../../../../Firebase/Firestore/CRUD-functions';
import { updateAmounts } from '../../../Shared/Drawer/InfoDrawer/Prices/Prices';
import { placesTags, placesTagsMasterRef } from '../../PlacesBrowser/placesTags';
import { useEffect } from 'react';
import { loadDrawer, openDrawer, updateDrawerKeys } from '../../../../store/drawerSlice';

const UploadPhotos = (props) => {
    const dispatch = useDispatch();

    const place = useSelector((state) => state.editor.place);
    const targetBucket = place.meta.place_doc_id;

    const placeTags = useSelector((state) => state.drawer.topics.filters);
    const drawerPrices = useSelector((state) => state.drawer.infoBox.Prices);

    useEffect(() => {
        return () => {
            console.log('Clearing filters... ');
            updateDrawerKeys(dispatch, ['topics'], {
                filters: [],
                filterCount: 0,
                showFilters: false,
            });
        };
    }, []);

    function uploadPhotos() {
        // console.log('...uploading photos to: ', targetBucket);
        uploadTempPhotos(targetBucket, 0, true, 0, 'jpg');
    }

    function updateDoc() {
        console.log('... updating doc');
        const payload = {
            // 'info.name': '',
            // 'media.video': '',
            // 'media.song': getSong(),
            // 'media.voiceover': 'places/',
            // 'meta.isPublic': true,
            // 'meta.ready': true,
            tags: prepareTags(),
            prices:preparePrices(),            
        };

        updateFirestoreDoc('places', targetBucket, payload);
    }

    return (
        <div className={css['main-container']}>
            <div className={css['inner-container']} onClick={updateDoc}>
                Update Doc
            </div>

            <div className={css['inner-container']} onClick={uploadPhotos}>
                Upload Photos
            </div>

            <div
                className={css['inner-container']}
                onClick={() => {
                    loadDrawer(dispatch, 'Places', placesTags, placesTagsMasterRef, 'topics');
                    openDrawer(dispatch, 'topics');
                }}
            >
                Tags
            </div>
        </div>
    );

    function prepareTags() {
        const result = {};

        placeTags.forEach((tag) => {
            result[tag] = true;
        });

        return result;
    }

    function preparePrices() {
        const prices = {
            food: { price: 0, quantity: 1, amount: 0, concept: null, units: null },
            appetizer: { price: 0, quantity: 1, amount: 0, concept: null, units: null },
            main: { price: 0, quantity: 1, amount: 0, concept: null, units: null },
            side: { price: 0, quantity: 1, amount: 0, concept: null, units: null },
            dessert: { price: 0, quantity: 0, amount: 0, concept: null, units: null },

            drink: { price: 0, quantity: 1, amount: 0, concept: null, units: null },
            cocktail: { price: 0, quantity: 1, amount: 0, concept: null, units: null },
            beer: { price: 0, quantity: 1, amount: 0, concept: null, units: null },
            wine: { price: 0, quantity: 1, amount: 0, concept: null, units: null },
            soda: { price: 0, quantity: 1, amount: 0, concept: null, units: null },
            coffee: { price: 0, quantity: 1, amount: 0, concept: null, units: null },

            activity: { price: 0, quantity: 1, amount: 0, concept: null, units: null },

            subtotal: { price: null, quantity: null, amount: 0, concept: null, units: null },
            taxes: { price: null, quantity: 15, amount: 0, concept: null, units: null },
            gratuity: { price: null, quantity: 20, amount: 0, concept: null, units: null },
            total: { price: null, quantity: null, amount: 0, concept: null, units: null },
            notes: '',
        };

        if (drawerPrices.total.amount > 0) {
            return drawerPrices;
        }

        return updateAmounts(prices);
    }
};

function getSong() {
    const songs = [
        // 'c_dont-wanna_62s', // Used: 1 times. Liuyishol
        // 'c_fall-until-i-fly_63s', // Used: 0 times.
        // 'c_jazzin-fly-gee_67s', // Used: 1 times. Tiradito.
        // 'c_rebels-of-our-own-kind_61s', // Used: 1 times. Laser Quest
        // 'c_summertime-blues_54s', // Used: 1 times. Shay.
        // 'c_summertime-love_61s', // Used: 0 times.
        // 'c_the-rewind_61s', // Used: 0 times.
        // 'c_touch-the-sun_61s', // Used: 0 times.
        // 'c_whatever_whenever_63s', // Used: 0 times.
        // 'd_africa-dream_60s', // Used: 1 times. Queen Sheba.
        // 'd_afternoon-coffee_60', // Used: 1 times. Misoya.
        // 'd_all-the-same-streets-instrumental_57s', // Used: 0 times.
        // 'd_blackbird_60s', // Used: 1 times. Chat Pompette
        // 'd_classic-house-90s-rave-music_61s', // Used: 1 times. Miss tacos.
        // 'd_energy-intro_61s', // Used: 1 times. Randolphs.
        // 'd_every-moment-is-fresh_60s', // Used: 0 times.
        // 'd_falling-in-love_60s', // Used: 0 times.
        // 'd_fresh-fresh_61s', // Used: 1 times. Snatch.
        // 'd_fuego-bombo_55s', // Used: 0 times.
        // 'd_ill-be-with-you_61s', // Used: 0 times.
        // 'd_mexican-wedding_60s', // Used: 1 times. Tacos Frida
        // 'd_summer-beach-pop-rock_50s', // Used: 0 times.
        // 'd_summertime-dance-season_73s', // Used: 0 times.
        // 'd_under-the-indian-sun_63s', // Used: 0 times.
        // 'd_upbeat-indie-cool-groovy-bass-drums_44s', // Used: 0 times.
        // 'd_welcome-to-india_63s', // Used: 0 times.
        // 'd_wonderland_61s', // Used: 0 times.
    ];

    return songs[0];
}

export default UploadPhotos;
