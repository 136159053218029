import css from './BillButton.module.css';

import { useSelector, useDispatch } from 'react-redux';


import Button from '../../../../../Shared/Buttons/Button/Button';
import Icon from '../../../../../Shared/Icon/Icon';

import { updateContent } from '../../../../../../store/browserSlice';
import { updateAmounts } from '../../MedianBill';

import { cloneObject } from '../../../../../../tools/data-processing';

const BillButton = (props) => {
    const dispatch = useDispatch();
    const bill = useSelector((state) => state.browser.infoBox.content.bill);
    const itemId = props.itemId;

    function handleClick() {
        const newBill = updateAmounts(updateQuantity(bill, itemId, determineChange()));
        updateContent(dispatch, 'bill', newBill);
    }

    return (
        <Button
            css={css['main-container']}
            innerCss={css['inner-container']}
            product='Places'
            onClick={handleClick}
            disabled={props.icon === 'remove' && bill[itemId].quantity < 1}
        >
            <Icon icon={props.icon} css={css['icon']} />
        </Button>
    );

    function determineChange() {
        let change = props.icon === 'add' ? 1 : -1;
        if (itemId === 'gratuity') {
            change = change * 5;
        }
        return change;
    }
};

function updateQuantity(bill, itemId, change) {
    const newBill = cloneObject(bill);
    const item = newBill[itemId];
    item.quantity += change;
    return newBill;
}

export default BillButton;
