import { updateSlice, updateSliceKeys } from './update-slice';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {        
    music: {
        song: null,
        action: null,        
    },

    voiceover:{
        track: null,
        action: null
    },

    volume: null,
    muted: false,
};

const audioSlice = createSlice({
    name: 'audio',
    initialState,
    reducers: {
        updateAudio(state, action) {
            updateSlice(state, action);
        },

        updateAudioKeys(state, action) {
            updateSliceKeys(state, action);
        },
    },
});

const actions = audioSlice.actions;
export const audioReducer = audioSlice.reducer;

export function updateAudio(dispatch, path, newValue) {
    dispatch(actions.updateAudio({ path, newValue }));
}

export function updateAudioKeys(dispatch, path, payload) {
    dispatch(actions.updateAudioKeys({ path, payload }));
}

export function blinkAudio(dispatch, path, newValue) {
    updateAudio(dispatch, path, newValue);

    setTimeout(() => {
        updateAudio(dispatch, path, null);
    }, 500);
}
