import css from './Product.module.css';
import { useDispatch } from 'react-redux';
import { goTo, toggleCurtain } from '../../../store/conductorSlice';

const Product = (props) => {
    const dispatch = useDispatch();
    const product = props.product;

    function handleClick() {
        const audio = new Audio(require('../../../sounds/products/' + product + '.mp3'));
        audio.play();
        toggleCurtain(dispatch, true);
        setTimeout(() => {
            goTo(dispatch, product, product + 'Browser');
        }, 500);
    }

    return (
        <div className={`${css['main-container']} ${product}`} onClick={handleClick}>
            {props.image ? <ImageProduct /> : <TextProduct />}
        </div>
    );

    function TextProduct() {
        return (
            <div className={css['inner-box']}>
                <div className={css['title-container']}>
                    <div className={css['top-title']} style={props.css}>
                        {props.topTitle}
                    </div>
                    <div className={css['title-text']} style={props.css}>
                        {props.title}
                    </div>
                </div>
            </div>
        );
    }

    function ImageProduct() {
        const imgSrc = getImgSrc(props.image);

        return (
            <div className={css['inner-box']}>
                <img className={`${css['product-image']}`} src={imgSrc} alt='' />
            </div>
        );

        function getImgSrc(image) {
            if (image.slice(0, 4) === 'http') {
                return image;
            }
            return require('../../../images/thumbnails/' + image);
        }
    }
};

export default Product;
