export const songs = {
    // celebration: '8w7I7VjdoUHR7ecwQRCrfbr4K7fTgvLS3AqsScKFZxU',
    // 'oh-what-a-night': 'fPkgL9QPWZwemhDsKnqIqJh1aNn5vWxAfJ00rE00MhZcI',
    // 'descript_falling-in-love': 'OnnK01txZwxf4Ac4YP9yZRFDGf0002Sye01vkfTPcIZn008A',
    // 'descript_jazzing-fly-ge': 'zPI6wuHW5BnEJL2jQQGS7qEGqhlZF8UjLEtu0201S8LLA',
    // 'descript_little-pastry': 'exTVAjVTktvSal32393roz9ENL89MyI9d8cyDxRpItc',
    // 'descript_fuego-bombo': 'lXy4Xm11VmTUSVbbJqFLLpLeBSAYQ9q00dTYSgJ00uEc8',
    // 'spotify_do-you-know-a-secret':'PGb8Xz51HbfAEDKLMkaa5biWG4sGS00eXDXnI7A8gRss',

    clapping:
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/app%2Fclapping-and-cheering.mp3?alt=media&token=3bfca91e-1e2b-4094-8c3e-6e8ae91c67fd',

    'shame-shame-shame':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/app%2Fshame-shame-shame.mp3?alt=media&token=115966c2-e243-47d8-925a-6ea179524c0f',

    'thank-you-for-applying': 'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/app%2Fthank-you-for-applying.mp3?alt=media&token=575aae97-57a0-401f-bf2d-8f88bc726b5f',

    'you-didnt-say-the-magic-word': 'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/app%2Fyou-didnt-say-the-magic-word-1.mp3?alt=media&token=40843e74-2351-4941-b693-abbc1127e219',

    celebration:
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/invitations%2Fcelebration-11.mp3?alt=media&token=8a6b391d-51fb-4227-bcd1-8fbec4970378',
    'oh-what-a-night':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Foh-what-a-night.mp3?alt=media&token=69f6106e-8330-43db-8e0c-89fef468e358',
    'descript_falling-in-love':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fdescript_falling-in-love.mp3?alt=media&token=733cd321-5f39-4b71-83f7-c8876936df0b',
    'descript_jazzing-fly-ge':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fdescript_jazzin-fly-ge.mp3?alt=media&token=c8fb3805-f466-4929-91df-ea17ec9d8db2',
    'descript_little-pastry':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fdescript_little-pastry.mp3?alt=media&token=4008a73f-59b5-404d-8979-8f2440b4187e',
    'descript_fuego-bombo':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fdescript_fuego-bombo.mp3?alt=media&token=6cc02c11-1973-4911-b01d-62b93c13a69b',
    'spotify_do-you-know-a-secret':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fspotify_do-you-know-a-secret.mp3?alt=media&token=440524a6-bde5-45c8-a53f-a705267d6b82',

    'c_dont-wanna_62s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fc_dont-wanna_62s.mp3?alt=media&token=8499bf0a-b1c2-4543-859d-3e441dfd2c35',
    'c_fall-until-i-fly_63s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fc_fall-until-i-fly_63s.mp3?alt=media&token=b7c94804-1682-4cc2-9fad-fa0d026f6e65',
    'c_jazzin-fly-gee_67s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fc_jazzin-fly-gee_67s.mp3?alt=media&token=695deaa7-fe62-4824-9fdb-4c7ee6417f94',
    'c_rebels-of-our-own-kind_61s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fc_rebels-of-our-own-kind_61s.mp3?alt=media&token=081d5cc2-8154-4330-b812-a5ca382c3dc7',
    'c_summertime-blues_54s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fc_summertime-blues_54s.mp3?alt=media&token=387a427a-0168-4f7e-a5b6-d6bb4345466f',
    'c_summertime-love_61s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fc_summertime-love_61s.mp3?alt=media&token=b7e44d63-89cf-47c7-bc85-5213073da48c',
    'c_the-rewind_61s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fc_the-rewind_61s.mp3?alt=media&token=4ec77a90-67b2-4057-8d73-7d6ddd592600',
    'c_touch-the-sun_61s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fc_touch-the-sun_61s.mp3?alt=media&token=33d5bfed-d27f-4295-8e09-daff90315a76',
    c_whatever_whenever_63s:
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fc_whatever_whenever_63s.mp3?alt=media&token=44ed9c90-fd08-4edc-bc31-7f1b005d25c5',
    'd_africa-dream_60s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fd_africa-dream_60s.mp3?alt=media&token=e518fc32-d577-4e3c-8021-4bebbc9ddb7a',
    'd_afternoon-coffee_60':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fd_afternoon-coffee_60s.mp3?alt=media&token=161ab7f1-d754-48d7-95e3-33da92eaaade',
    'd_all-the-same-streets-instrumental_57s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fd_all-the-same-streets-instrumental_57s.mp3?alt=media&token=0d1f5e81-0fdd-4892-b573-81e09b52e159',
    d_blackbird_60s:
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fd_blackbird_60s.mp3?alt=media&token=738137b0-9094-4f4b-90af-0920fc0a5e06',
    'd_classic-house-90s-rave-music_61s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fd_classic-house-90s-rave-music_61s.mp3?alt=media&token=dbc10c9a-5dde-4525-8f2c-7bd638309f62',
    'd_energy-intro_61s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fd_energy-intro_61s.mp3?alt=media&token=3228a020-2e92-4b26-9b4b-147809119d99',
    'd_every-moment-is-fresh_60s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fd_every-moment-is-fresh_60s.mp3?alt=media&token=19efb158-45b6-4360-b4de-d28e4fe0b688',
    'd_falling-in-love_60s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fd_falling-in-love_60s.mp3?alt=media&token=fce4ea6d-e54d-4faf-8aee-0457e0770179',
    'd_fresh-fresh_61s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fd_fresh-fresh_61s.mp3?alt=media&token=57399e0e-063e-41dd-b8db-4fe4e6139e04',
    'd_fuego-bombo_55s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fd_fuego-bombo_55s.mp3?alt=media&token=ad864d51-2622-4cc1-a951-2d5d27ad7ab4',
    'd_ill-be-with-you_61s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fd_ill-be-with-you_61s.mp3?alt=media&token=30a642eb-8cd9-47ea-8e24-cf640c0534d2',
    'd_mexican-wedding_60s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fd_mexican-wedding_60s.mp3?alt=media&token=7bcc4e23-34de-4c33-8748-f2225defd4de',
    'd_summer-beach-pop-rock_50s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fd_summer-beach-pop-rock_50s.mp3?alt=media&token=cb81ae12-455d-48e7-95e4-c7f94f1a54f4',
    'd_summertime-dance-season_73s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fd_summertime-dance-season_73s.mp3?alt=media&token=d6a97ed8-e817-4549-bbef-b3cb36b43c48',
    'd_under-the-indian-sun_63s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fd_under-the-indian-sun_63s.mp3?alt=media&token=b9b80235-42f9-4e8b-bdfa-5f2fe54c78e6',
    'd_upbeat-indie-cool-groovy-bass-drums_44s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fd_upbeat-indie-cool-groovy-bass-drums_44s.mp3?alt=media&token=d43e3012-c751-4edb-8d10-f50cc839a382',
    'd_welcome-to-india_63s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fd_welcome-to-india_63s.mp3?alt=media&token=88d987ef-1204-44d6-a1b6-7dc830765445',
    d_wonderland_61s:
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fd_wonderland_61s.mp3?alt=media&token=bbf215a1-55b2-426b-a616-06534d8f4400',
    's_do-you-want-to-know-a-secret_27s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fs_do-you-want-to-know-a-secret_27s.mp3?alt=media&token=6125a68c-4795-4004-a394-4d4554a8609a',
    's_jump-up-superstar_29s':
        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/music%2Fs_jump-up-superstar_29s.mp3?alt=media&token=1a2f79d5-a853-42e5-b4e3-5e9399007085',
};
