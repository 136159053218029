import css from './TopicsCurtain.module.css';

import { useSelector } from 'react-redux';

const TopicsCurtain = (props) => {
    const product = useSelector((state) => state.browser.product);
    const stage = useSelector((state) => state.browser.stage);

    return (
        <div
            className={`${css['main-container']} ${css[stage.topics]}`}
            style={{
                '--close-speed': stage.speed[0] + 'ms',
                '--open-speed': stage.speed[1] + 'ms',
            }}
        >
            <div className={`${css['topics-curtain']} ${product}`}>
                <div className={css['curtain-color']} />
            </div>
        </div>
    );
};

export default TopicsCurtain;
