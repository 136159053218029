export function updateSlice(state, action) {
    const newValue = action.payload.newValue;
    const path = action.payload.path;
    const depth = path.length;

    if (depth === 1) {
        state[path[0]] = newValue;
        return;
    }

    if (depth === 2) {
        state[path[0]][path[1]] = newValue;
        return;
    }

    if (depth === 3) {
        state[path[0]][path[1]][path[2]] = newValue;
        return;
    }

    if (depth === 4) {
        state[path[0]][path[1]][path[2]][path[3]] = newValue;
        return;
    }
}

export function updateSliceKeys(state, action) {
    const payload = action.payload.payload;
    const path = action.payload.path;
    const depth = path.length;

    if (depth === 1) {
        state[path[0]] = { ...state[path[0]], ...payload };
        return;
    }

    if (depth === 2) {
        state[path[0]][path[1]] = { ...state[path[0]][path[1]], ...payload };
        return;
    }

    if (depth === 3) {
        state[path[0]][path[1]][path[2]] = { ...state[path[0]][path[1]][path[2]], ...payload };
        return;
    }

    if (depth === 4) {
        state[path[0]][path[1]][path[2]][path[3]] = {
            ...state[path[0]][path[1]][path[2]][path[3]],
            ...payload,
        };
        return;
    }

    if (depth === 0) {
        Object.assign(state, payload);
        return;
    }
    return;
}
