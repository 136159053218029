import css from './PlainText.module.css';

const PlainText = (props) => {
    const content = props.content;

    return (
        <div className={css['main-container']}>
            <div className={css['text-container']}>{content.text}</div>
        </div>
    );
};

export default PlainText;
