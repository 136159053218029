import css from './EventCanceled.module.css';
import EventCardTopBar from '../EventCardTopBar/EventCardTopBar';
import { useEffect } from 'react';

const EventCanceled = (props) => {
    useEffect(()=>{
        setTimeout(()=>{
            // window.location.reload();
        },3000)
    },[])
    
    return (
        <div className={css['main-container']}>
            <EventCardTopBar />

            <div className={css['inner-container']}>
                <div className={css['text-container']}>You canceled the event</div>

                <div className={css['image-container']}>
                    <img
                        className={css['image']}
                        src={
                            'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/app%2Fcancel-shame.gif?alt=media&token=3d4d128b-3d36-4776-b991-4d5dad6db1b3'
                        }
                        alt=''
                    />
                </div>
                <div className={css['shame-container']}>SHAME!</div>
            </div>
        </div>
    );
};

export default EventCanceled;
