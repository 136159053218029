//How variables should be named - specially when passed as props.
// element: 'Obj - The unpacked doc corresponding to that element',
// elementType: 'Str - The element type name',
// elementSet: 'Obj - A collection of elements of a given type',
// collection: 'Str- The name corresponding to the sub-collection where the element set is stored.',
// product: 'Str - Always written with upper case to match component names and CSS classes.',

const elementType_collection = {
    person: 'persons',
    location: 'places',
    activity: 'activities',
    good_time: 'good_times',
};

const elementType_displayName = {
    person: 'Person',
    location: 'Location',
    activity: 'Activity',
    good_time: 'Good time',
};

const elementType_editor = {
    person: 'PersonEditor',
    location: 'LocationEditor',
    activity: 'ActivityEditor',
    good_time: 'GoodTimeEditor',
};

const elementType_gtElement = {
    person: 'people',
    location: 'location',
    activity: 'activity',
};

const elementType_product = {
    person: 'People',
    location: 'Places',
    activity: 'Activities',
    good_time: 'GoodTimes',
    member: 'Account',
};

const collection_elementType = {
    persons: 'person',
    places: 'location',
    activities: 'activity',
    good_times: 'good_time',
};

const collection_product = {
    persons: 'People',
    places: 'Places',
    activities: 'Activities',
    good_times: 'GoodTimes',
};

const gtElement_collections = {
    people: ['persons'],
    location: ['places'],
    activity: ['activities'],
};

const product_collections = {
    People: ['persons'],
    Places: ['places'],
    Activities: ['activities'],
    GoodTimes: ['good_times'],
};

const product_elementType = {
    People: 'person',
    Places: 'place',
    Invitations: 'invitation',
}

const product_displayName = {
    Invitations: 'Invitations',
    People: 'People',
    Places: 'Places',
    Parties: 'Parties',
    HelpWanted: 'Help Wanted',
    HelpOffered: 'Help Offered',
    Groups: 'Groups',
    Cards: 'Cards',
    GoodTimes: 'Good times',

    Account: 'My Account',
    Events: 'Events',
};

const product_icon = {
    People: 'face',
    Places: 'store',
    Invitations: 'sports_bar',
};

const product_color = {
    People: '#ca91dd',
    GoodTimes: '#4fcbc9',
    Places: '#efa350',
    Invitations: '#4fcbc9',
};

const product_editor = {
    People: 'PersonEditor',
    Places: 'PlaceEditor',
    Invitations:'InvitationEditor'
};


const index = {
    elementType_collection,
    elementType_displayName,
    elementType_editor,
    elementType_gtElement,
    elementType_product,

    collection_elementType,
    collection_product,

    gtElement_collections,

    product_collections,
    product_color,
    product_displayName,
    product_editor,
    product_elementType,
    product_icon,
};

export function translate(target, fromType, toType) {
    return index[fromType + '_' + toType][target];
}
