import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../../../Firebase/Firebase';
import { updateFirestoreDoc, pushToFirestoreArray } from '../../../../Firebase/Firestore/CRUD-functions';

export async function uploadTempPhotos(bucketName, fileCount, hasThumbnail, menuCount, menuExtension = 'jpg' ) {
    const placeName = bucketName.split('_')[0];

    for (var i = 1; i <= fileCount; i++) {
        const photoPath = require('./TempPhotos/' + i + '.jpg');
        const sufix = i > 9 ? '_' + i : '_0' + i;
        const fileName = 'places/' + bucketName + '/' + placeName + sufix;
        await uploadImageFromFile(photoPath, fileName, bucketName, 'media.photos');
    }

    if(hasThumbnail){
        try {
            const photoPath = require('./TempPhotos/thumbnail/thumbnail.jpg');
            const fileName = 'places/' + bucketName + '/' + placeName + '_thumbnail';
            await uploadImageFromFile(photoPath, fileName, bucketName, 'media.thumbnail');
        } catch (error){}
        
    }
    
    for (var m = 1; m <= menuCount; m++) {        
        const photoPath = require('./menu/' + m + '.' + menuExtension);
        const sufix = m > 9 ? '_menu-' + m : '_menu-0' + m
        const fileName = 'places/' + bucketName + '/' + placeName + sufix;
        await uploadImageFromFile(photoPath, fileName, bucketName, 'media.menu');
    }

    console.log('--- PHOTO UPLOAD COMPLETED! ---')
}

// Function to upload an image from a URL to Firebase Storage
export async function uploadImageFromFile(photoPath, fileName, bucketName, firestoreTarget) {
    console.log('Begining file upload attempt... ');

    try {
        const response = await fetch(photoPath);
        const blob = await response.blob();

        // // Create a reference to the storage bucket and the file path
        const storageRef = ref(storage, fileName);

        const metadata = { cacheControl: 'public,max-age=31536000' };
        // // Upload the image data to the storage bucket
        const snapshot = await uploadBytes(storageRef, blob, metadata);
        const photoUrl = await getDownloadURL(storageRef)
        
    
        if(firestoreTarget !== 'media.thumbnail'){
            await pushToFirestoreArray('places', bucketName, firestoreTarget, photoUrl)
        } else {
            await updateFirestoreDoc('places', bucketName, { 'media.thumbnail': photoUrl });
        }

        // console.log('Image uploaded successfully');
    } catch (error) {
        console.error('Error uploading image:', error);
    }
}

