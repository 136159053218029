import css from './Tutorial.module.css';
import TutorialButton from './TutorialButton/TutorialButton';
import { useDispatch, useSelector } from 'react-redux';

const Tutorial = (props) => {
    const screen = useSelector((state) => state.conductor.screen);
    const showTutorial = useSelector((state) => state.video.tutorial.show);
    const mobileReady = useSelector((state) => state.browser.player.mobileReady);

    const showButton = screen in tutorials;

    return (
        <div className={css['main-container']}>
            {!mobileReady && showTutorial && (
                <div className={css['tap-play']}>
                    <div className={css['tap-play-text']}>Tap Play</div>
                </div>
            )}
            {showButton && <TutorialButton />}
        </div>
    );
};

export const tutorials = {
    About: 'kOErw6RRpuokc01EWl7zaanAs00qAjC1mZk4QZkN3KbmM',
    InvitationCard: 'PrPVpA2gNOhsWt02F02GsCBLSSFZMeISVfKG5pN59uV9Q',
};

export default Tutorial;
