import css from './TitleInput.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { updateEditor } from '../../../../../store/editorSlice';


const TitleInput = (props) => {
    const dispatch = useDispatch();
    const title = useSelector((state) => state.editor[props.elementType].name);

    function handleChange(event) {
        const newValue = event.target.value;
        updateEditor(dispatch, [props.elementType, 'name'], newValue);
    }

    return (

        <div className={css['outer-container']}>
            <input
                className={css['main-container']}
                value={title}
                onChange={handleChange}
                placeholder='Title'
            />
            <div className={`${css['lower-border']} ${props.product}`}></div>
        </div>
    );
};

export default TitleInput;
