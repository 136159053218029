import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateVideoKeys, updateVideo } from '../../../../store/videoSlice';
import { updateBrowserKey } from '../../../../store/browserSlice';
import css from './About.module.css';

const About = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        updateBrowserKey(
            dispatch,
            ['player', 'playbackId'],
            'kOErw6RRpuokc01EWl7zaanAs00qAjC1mZk4QZkN3KbmM'
        );
        updateVideoKeys(dispatch, ['css'], {
            opacity: 1,
            height: 'var(--currentViewHeight)',
            top: '0px',
            pointerEvents: 'all',
            zIndex: 3,
        });

        updateVideo(dispatch, ['tutorial', 'show'], true);

        setTimeout(() => {
            updateBrowserKey(dispatch, ['player', 'action'], 'play');
        }, 100);
    }, []);

    return <div className={css['about-container']}></div>;
};

export default About;
