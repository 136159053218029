import css from './CancelButton.module.css';
import BrowserButtons from '../../../Shared/Browser/BrowserButtons/BrowserButtons';
import { updateConductorKeys } from '../../../../store/conductorSlice';
import { useDispatch } from 'react-redux';
import TextActionButton from '../../../Shared/Buttons/TextActionButton/TextActionButton';

const CancelButton = (props) => {
    const dispatch = useDispatch();

    function handleClick() {
        updateConductorKeys(dispatch, ['modalDialog'], {
            show: true,
            template: 'CancelEvent',
        });
    }

    return (
        <BrowserButtons>
            <TextActionButton onClick={handleClick} text='Cancel Event' color='var(--red)' />
        </BrowserButtons>
    );
};

export default CancelButton;
