import css from './VideoLoader.module.css';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateVideoKeys } from '../../../../store/videoSlice';
import { updateBrowserKeys } from '../../../../store/browserSlice';

const VideoLoader = (props) => {
    const dispatch = useDispatch();
    const orientation = props.orientation;
    const screen = useSelector((state) => state.conductor.screen);

    useEffect(() => {
        const [height, top] = getHeight();
        updateVideoKeys(dispatch, ['css'], { opacity: 1, height, top, pointerEvents: 'all' });

        return () => {
            console.log('Unloading VideoPlayer');
            updateBrowserKeys(dispatch, ['player'], { action: 'pause' });
            updateVideoKeys(dispatch, ['css'], { opacity: 0, pointerEvents: 'none' });
        };
    }, []);

    return <div className={css['media-box']}></div>;

    function getHeight() {
        let height = 'var(--stage-size)';
        let top = 'var(--top-bar-height)';

        if (screen === 'InvitationCard') {
            top = '0px';
            if (orientation === 'vertical') {
                height = 'var(--currentViewHeight)';
            }
        } else {
            if (orientation === 'vertical') {
                height = 'calc(var(--currentViewHeight) - var(--top-bar-height))';
            }
        }

        return [height, top];
    }
};

export default VideoLoader;
