import css from './AccountCircle.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { toggleCurtain, goTo } from '../../../../store/conductorSlice';

const AccountCircle = (props) => {
    const dispatch = useDispatch();
    const imageSrc = useSelector((state) => state.user.cards.people.image);

    function handleClick() {
        toggleCurtain(dispatch, true);
        setTimeout(() => {
            goTo(dispatch, 'Account', 'Account');
        }, 500);
    }

    return (
        <div className={css['main-container']} onClick={handleClick}>
            <div className={css['image-container']}>
                <img className={css['user-image']} src={imageSrc} alt='' />
            </div>
        </div>
    );
};

export default AccountCircle;
