import css from './Drawer.module.css';

import SearchBar from './SearchBar/SearchBar';
import CurrentFilters from './CurrentFilters/CurrentFilters';
import Bucket from './Bucket/Bucket';
import CornerControls from './CornerControls/CornerControls';
import InfoDrawer from './InfoDrawer/InfoDrawer';

import { useSelector } from 'react-redux';
import { Fragment } from 'react';

const Drawer = (props) => {
    const drawer = useSelector((state) => state.drawer);
    const activeContent = drawer[drawer.activeContent];

    const nextClass = {
        default: '',
        open: 'show',
        closed: 'hide',
    };

    const Component = drawer.activeContent === 'infoBox' ? InfoDrawer : TopicsDrawer;
    const drawerClass = nextClass[drawer.status];

    return (
        <div className={`${css['main-container']} ${css[drawerClass]}`} style={activeContent.style}>
            <Component drawer={drawer} />
            {drawer.activeContent === 'menu_fees' && <CornerControls />}
        </div>
    );
};

const TopicsDrawer = (props) => {
    const drawer = props.drawer;
    const activeContent = drawer[drawer.activeContent];
    const buckets = activeContent.buckets;

    return (
        <Fragment>
            <SearchBar />
            <div className={css['max-size-outer']}>
                <div className={css['max-size-inner']}>
                    <div
                        className={css['search-area']}
                        style={activeContent.areaStyle}
                        id='search-area'
                    >
                        {activeContent.showFilters && <CurrentFilters />}
                        {buckets.map((bucket) => (
                            <Bucket key={bucket.id} bucket={bucket} />
                        ))}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Drawer;
