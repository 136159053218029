import { generateId } from '../../../tools/generateId';
import { shuffleArray } from '../../../tools/data-processing';

const peoplePhotos = [
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_people%2Fistockphoto-108271024-612x612.jpg?alt=media&token=541f641a-8ac5-4cc4-b09d-faba2ba638d7',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_people%2Fistockphoto-1158253206-612x612.jpg?alt=media&token=3a844b09-6937-48db-9421-5eff0702e19f',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_people%2Fistockphoto-119678327-612x612.jpg?alt=media&token=fdf2efdd-a7d4-4b29-8337-3eb7637aedbf',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_people%2Fistockphoto-120427365-612x612.jpg?alt=media&token=4103b4fb-b39d-4ea2-a564-b13500705bf6',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_people%2Fistockphoto-1270038785-612x612.jpg?alt=media&token=b209e95e-2c3a-488d-98ff-d0bff9d4c138',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_people%2Fistockphoto-157075856-612x612.jpg?alt=media&token=e87f28af-c3af-4a37-b0d6-d892352ea7b0',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_people%2Fistockphoto-162717437-612x612.jpg?alt=media&token=e1fc4f40-3a54-4bce-9a40-3a1e45d5dfca',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_people%2Fistockphoto-183417837-612x612.jpg?alt=media&token=23441380-610c-4a10-96f1-a6a433e9dd20',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_people%2Fistockphoto-187355060-612x612.jpg?alt=media&token=99c8274c-bd64-4a69-9075-be2c6d554a86',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_people%2Fistockphoto-476227110-612x612.jpg?alt=media&token=3d3314c1-ab44-4ac2-9a49-ddea5799e369',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_people%2Fistockphoto-486671893-612x612.jpg?alt=media&token=a4c5fecb-7673-4c62-8432-83287a20608d',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_people%2Fistockphoto-490970450-612x612.jpg?alt=media&token=15f5acc8-057a-4c44-817c-af398c3bca07',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_people%2Fistockphoto-497155263-612x612.jpg?alt=media&token=e5dca31b-66b4-4207-9f0d-327607023698',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_people%2Fistockphoto-531139187-612x612.jpg?alt=media&token=e660ceba-ff17-402e-9d9a-c6d1714b1dfd',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_people%2Fistockphoto-531461833-612x612.jpg?alt=media&token=e65fd297-a11e-40d3-8e3b-712e2eeecce5',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_people%2Fistockphoto-57417178-612x612.jpg?alt=media&token=0b7dfa1a-873f-416f-891f-23069c3c985e',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_people%2Fistockphoto-57519936-612x612.jpg?alt=media&token=e7f65ddb-9264-4974-9cb2-e1b032f7777b',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_people%2Fistockphoto-57520629-612x612.jpg?alt=media&token=874b9cac-388f-42ee-bffe-ce71293d4f3f',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_people%2Fistockphoto-607908006-612x612.jpg?alt=media&token=217d3b1c-b9a4-4f31-8ff5-372bc7461a9a',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_people%2Fistockphoto-891337934-612x612.jpg?alt=media&token=84f398c1-112a-4023-b82b-d50564e7e1e3',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_people%2Fistockphoto-996838824-612x612.jpg?alt=media&token=d89a51c2-68cd-4b74-af12-a95682e9d5f1',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_people%2Fistockphoto-998457228-612x612.jpg?alt=media&token=d22154f0-d606-4ace-8b32-393d8198784d',
];

const placesPhotos = [
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_places%2F742_Evergreen_Terrace.png?alt=media&token=d8647f6a-0058-465c-a398-f254ab0b2faa',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_places%2FMcCormickFormerResidence.webp?alt=media&token=b9e44791-b4f6-4826-9aae-280680d859fc',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_places%2FSpongebob-House-Guide.png?alt=media&token=4c1a421d-1331-43a3-ae3d-f6ef2a4d78ce',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fdummy_places%2Fca73bbf0fee408372fd5a656e0c8228f.jpg?alt=media&token=f81d72af-86d2-4a09-8bec-1ae0be8d5bcd',
]

function createDummyThumbnails(photos, elementType, product) {
    const thumbnails = { elementIds: [], product };
    photos.forEach((photo) => {
        const photoId = generateId(5);
        const thumbnail = { id: photoId, name: null, image: photo, elementType: elementType };
        thumbnails.elementIds.push(photoId);
        thumbnails[photoId] = thumbnail;
    });

    return thumbnails
}

export const dummyPeopleThumbnails = createDummyThumbnails(shuffleArray(peoplePhotos), 'person', 'People')

export const dummyPlacesThumbnails = createDummyThumbnails(shuffleArray(placesPhotos), 'place', 'Places')
