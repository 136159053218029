export const realPeople = {
    product: 'People',

    elementIds: [
        // 'valeria',
        // 'caleb',
        // 'heather',
        'cynthia',
        // 'jeremy',
        // 'tish',
        // 'jeff',
        // 'carlos',
        // 'mauricio',
        // 'raj',
        // 'kundan',
        // 'bethyna',
        // 'noah',
        // 'andrea',
        // 'anir',
        // 'dave',
    ],

    allIds: [
        'valeria',
        'caleb',
        'heather',
        'cynthia',
        'jeremy',
        'tish',
        'jeff',
        'carlos',
        'mauricio',
        'raj',
        'kundan',
        'bethyna',
        'noah',
        'andrea',
        'anir',
        'dave',
    ],

    x: {
        name: '',
        audio: '_.mp3',
        image: '',
        tags: {},
        playbackIds: [
            ['2-truths-1-lie', '', []],
            ['advice-for-my-past-self', '', []],
            ['animals', '', []],
            ['artificial-intelligence', '', []],
            ['being-yourself', '', []],
            ['billboard-message', '', []],
            ['board-games', '', []],
            ['communication', '', []],
            ['contrarian-ideas', '', []],
            ['controversial-opinions', '', []],
            ['countries-visited', '', []],
            ['cultural-differences', '', []],
            ['cricket', '', []],
            ['cross-country-skiing', '', []],
            ['crypto', '', []],
            ['dad-jokes', '', []],
            ['dating-stuff', '', []],
            ['debates-i-enjoy', '', []],
            ['dream-dinner-guest', '', []],
            ['dream-job', '', []],
            ['extreme-sports', '', []],
            ['extrovert', '', []],
            ['favorite-bible-passage', '', []],
            ['favorite-topics', '', []],
            ['final-fantasy', '', []],
            ['food', '', []],
            ['free-will', '', []],
            ['fun-story', '', []],
            ['funny-people', '', []],
            ['grateful-for', '', []],
            ['hobbies', '', []],
            ['hockey', '', []],
            ['humans-best-and-worst-qualities', '', []],
            ['i-will-never-again', '', []],
            ['ideal-saturday', '', []],
            ['if-i-received-10M-dollars', '', []],
            ['if-i-received-1B-dollars', '', []],
            ['if-i-had-2-extra-hours', '', []],
            ['introduction', '', []],
            ['introvert-or-extrovert', '', []],
            ['introvert', '', []],
            ['investing', '', []],
            ['joke', '', []],
            ['life-story', '', []],
            ['linux', '', []],
            ['looking-for-in-brix', '', []],
            ['looking-for-people-to', '', []],
            ['looking-forward-to', '', []],
            ['making-cocktails', '', []],
            ['meaning-of-life', '', []],
            ['mexican', '', []],
            ['mind-blowing-facts', '', []],
            ['music', '', []],
            ['my-crowd', '', []],
            ['my-dream', '', []],
            ['my-faith', '', []],
            ['my-passion', '', []],
            ['my-work-life', '', []],
            ['my-work-story', '', []],
            ['my-writing', '', []],
            ['parenting-advice', '', []],
            ['parenting-struggles', '', []],
            ['parenting-surprises', '', []],
            ['pet-peeves', '', []],
            ['pharma-industry', '', []],
            ['pineapple-on-pizza', '', []],
            ['podcasts-i-like', '', []],
            ['politics', '', []],
            ['pranks', '', []],
            ['reading', '', []],
            ['quebec', '', []],
            ['science-fiction', '', []],
            ['singing', '', []],
            ['social-media', '', []],
            ['special-talent', '', []],
            ['spoken-languages', '', []],
            ['sports', '', []],
            ['studies', '', []],
            ['stuff-i-would-like-to-create', '', []],
            ['take-on-college', '', []],
            ['tatoos', '', []],
            ['the-environment', '', []],
            ['the-kardashians', '', []],
            ['the-last-of-us', '', []],
            ['the-metaverse', '', []],
            ['the-royal-family', '', []],
            ['the-shawshank-redemption', '', []],
            ['things-id-like-to-get-better-at', '', []],
            ['too-serious-to-be-joked-about', '', []],
            ['trailer', '', []],
            ['travel-story', '', []],
            ['travel-wishlist', '', []],
            ['traveling', '', []],
            ['twitter', '', []],
            ['vices-i-despite', '', []],
            ['video-games', '', []],
            ['virtudes-i-admire', '', []],
            ['why-most-relationships-fail', '', []],
            ['work', '', []],
            ['worlds-biggest-problem', '', []],
            ['writing', '', []],
        ],
    },

    valeria: {
        name: 'Valeria',
        audio: '_valeria.mp3',
        image: 'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fpeople%2Fvaleria.jpg?alt=media&token=ae61080a-4968-435d-8333-0977c69cbe04',
        tags: {},
        playbackIds: [
            ['communication', 'pRvhZDczPosJye0254KdiCe01kYhQl402fPgerlhIozzZg', []],
            ['countries-visited', '7nstl6bw7Ft00odMlv1006W2aLre7ensB02NvWzkHgVI5k', []],
            [
                'dream-dinner-guest',
                'thVvcM8Eq42nTuheV201YxE9p8h00cSxCaYVaJO8CvyS00',
                [
                    'i_automotive-and-motorsports_thing_formula-1',
                    'i_automotive-and-motorsports_person_louis-hamilton',
                ],
            ],
            ['dream-job', 'OVCjqB9pXfrUy5NLhRRS4eqOfDwax7gpG9WSpqgAB3I', []],
            ['favorite-bible-passage', 'vSIPERHKLeqAafXePWj400wfOxZ2ld027ILPW00e2Wv9II', []],
            [
                'favorite-topics',
                'h3ZKzHKaOCD15vizsKClwJbb6TzotuchieQYbUNCrjU',
                ['i_food-and-drink_activity_watching-food-tv-shows'],
            ],
            ['food', 'GSwrvKDpSftRuJHhc7W4sQvMo4LRQO5dVgEM018WSCPQ', []],
            [
                'ideal-saturday',
                'PsRv64ZM5292dfZkL4AsmrcpVTtI02aYyHJf3TbzIarY',
                ['i_food-and-drink_activity_going-for-brunch'],
            ],
            ['introduction', 'bM3MGrR35L44yz02qGi0200EzWEBs5m8YXT1vylzqvefik', []],
            ['introvert-or-extrovert', 'h54wbJzjet01gD6uwOnSFUnzj5oBOlJ3aZqrKWEN00DfQ', []],
            ['my-crowd', 'nCj5yoKSz87k97lFUuoIf2D5vvDAkxNl7Mjf3n6DXXA', []],
            [
                'my-faith',
                'oiHBeQ02fPtvhjK6SC3cKZ1ycuUFKbE00U26UuJdwPiL8',
                [
                    'i_spirituality-and-religion_book_the-bible',
                    'i_spirituality-and-religion_religion_christianity',
                ],
            ],
            ['pineapple-on-pizza', 'qX101UCkIg24uZKn6Irrcoa5D00yi9W8vPPbjtTcuXMGA', []],
            [
                'spoken-languages',
                'v2dy2SAogUK2D00H95d82BmJjX3NZCxhRxnICFdN02wHA',
                ['b_demographics_language_portuguese'],
            ],
            [
                'studies',
                'ROJWn1NdTCX9olWzCjV02QH01ouIxv75H025qrzbV8N1qc',
                ['i_business-and-finance_industry_hospitality'],
            ],
            ['meaning-of-life', 'pdTBphFftdSVxK012b4g601wkYva2zfRzzc4cunqNyvHk', []],
            ['trailer', 'yEGBfKOz02A0248RFQ4mTsIUpvkxLKF5RAQJ8TeQgXL6k', []],
            ['travel-wishlist', 'm02e4kEKPltnsF6UtKeqOH301EiwXf93wV3TMZhiP7RgI', []],
            [
                'traveling',
                'VdUrocxZiAsg3Dih57NGcwocspnUazdZrjG7JLDqiLM',
                ['i_travel-and-exploration_activity_traveling'],
            ],
            ['why-most-relationships-fail', 'esyKG9Luj4u1ebRwkT101ViNVt01eBkZsn39a8IvrtvKQ', []],
            ['worlds-biggest-problem', 'hdFfwavuVTPVt02hy00t01JdZmCmfPx6W9yEMOXsaA8dco', []],
        ],
    },

    caleb: {
        name: 'Caleb',
        audio: '_caleb.mp3',
        image: 'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fpeople%2Fcaleb.jpg?alt=media&token=53a81902-5857-4e15-9655-8edc6338a267',
        tags: {},
        playbackIds: [
            ['crypto', 'Ubr01ZxS1AlFyUyui7cxxmm1zBrTle1ptx700cxe9b00do', []],
            [
                'dream-dinner-guest',
                'cM7gZOn9c02HBlFzRlDTVeYDnjyySHZJ3KsWQQX17FHI',
                [
                    'i_politics-and-social-issues_person_brark-obama',
                    'i_politics-and-social-issues_person_john-f-keneddy',
                ],
            ],
            ['dream-job', 'f2NtpcXtIl01luO008WK02XwGCH875n4sCCJJqZj7ZVrOM', []],
            ['favorite-topics', 'ax2YiV7dfHmxr5MciLgCaSODgVN8UKgTDGVOrU00aFOw', []],
            [
                'hobbies',
                'Yf501QygKvPNGfnE3ewAMYNg3gM7x02pGmuFSDTsDJsdU',
                ['i_outdoors-and-adventure_activity_surffing'],
            ],
            [
                'hockey',
                'K6xAOG8LH00AT025SgMr87LW00w1VDlAmCLG02YMd901n334',
                ['i_sports_sport_hockey', 'i_sports_activity_playing-hockey'],
            ],
            ['introduction', 'PyMhzkApJL00DcwEoYhAhwt8cH79OET01nm6rY00TgOrAU', []],
            [
                'investing',
                '4HiQOzCulxr02jYj8SUa41mybenOm9xdJra1afS95LNc',
                ['i_business-and-finance_topic_finance', 'b_work_industry_financial'],
            ],
            ['my-crowd', 'Sgc3LIdL5DtbS00T7U6hCRaMj6UvLcKLUmherZ85JOzw', []],
            [
                'my-passion',
                'mLuQ8ZNqBrrXEsASQP5icIC1agcJv5KrpKPn3OeYNeM',
                [
                    'i_outdoors-and-adventure_activity_doing-outdoors-sports',
                    'i_science-and-nature_topic_the-ocean',
                ],
            ],
            ['pineapple-on-pizza', 'rr6dIUt7S99uxXSufvV701lg2yfWECf010283Hjtkfyvtc', []],
            [
                'reading',
                'UC24cFlGeFPYx00sShxE01ajIkgmCCTFjPIuSjjyxQo8k',
                ['i_philosophy-and-ethics_book_principles-by-ray-dalio'],
            ],
            ['social-media', '4PGWR15xvZYgq5jwZzHi7yOmzeeSS2VF9AUnS2YCSgA', []],
            [
                'spoken-languages',
                'yM00s01DkMbofvCCe7PP1p9f3wTXE4yhvbOGB2Fw8VOGs',
                ['b_demographics_language_french', 'i_business-and-finance_person_ray-dalio'],
            ],
            ['the-metaverse', 'VW92ykEdb02Pa7eTy1xbCVeXXMPZrqpHkf9NhEG01S00zc', []],
            ['trailer', 'qzhvmMpE4pFPsOEJc8EhgE2x01j6WKMsqmLF301NPis14', []],
            [
                'work',
                'H2ga8havreaHc00Qrr2901Ac1uqm6fgVJM01jkDEAtWbSA',
                ['b_work_title_risk-analyst'],
            ],
            ['worlds-biggest-problem', 'Fy6clDJC58Sfnjzx02o2VNdHCIsjCtGKljZV6oqA3SPI', []],
        ],
    },

    heather: {
        name: 'Heather',
        audio: '_heather.mp3',
        image: 'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fpeople%2Fheather.jpg?alt=media&token=0c8daab0-51e2-49e4-a1fd-ec1f86fb5887',
        tags: {},
        playbackIds: [
            ['billboard-message', 'WpYtSVhd02sF01gWZgrWVUI9p00ABJtdk9sx6nyenVPJXk', []],
            ['cultural-differences', 'qb84mPLB3eLMhknsuQnUwXCdnpUdwvS01of5A01FIZENA', []],
            [
                'dream-dinner-guest',
                'Fo00sI01lMCakpW01hHsui9C6Ut601l5A01ZCiQ8g005rgaTM',
                ['i_music-and-dance_person_mozart'],
            ],
            [
                'introduction',
                '5OUggoO4vMYb4IalumWUgtxhcviNFv7VW701Wc5BKKyo',
                ['b_demographics_culture_immigrant'],
            ],
            [
                'looking-for-people-to',
                'iD02CpIaTVc5qtQhOEwbazcyN4F2FKc018mXbg00w6vtqE',
                ['b_family_children_baby'],
            ],
            ['looking-forward-to', 'ZPazPLbYqSMfSHIGUuQkx00v3zQk3rGcJnfUkl9oIpHA', []],
            [
                'music',
                'BgalBY4ZdHQQSR7JF02vpMiJ95E77nOum3O2KI5hauHs',
                ['i_music-and-dance_activity_playing-the-violin'],
            ],
            ['my-crowd', 'SMYezoX5m3jwDo9rQJJtFabg5Hb0000wZ00c02j00oJVSc01o', []],
            [
                'my-dream',
                'dCaXFy6WBi5hAEGRvz3BnJM7lZuPHB00B01013CIdQVY900',
                ['i_crafting_topic_doing-pottery', 'i_crafting_thing_ceramics'],
            ],
            [
                'my-passion',
                'juQ3i2OQ3irf8Dh5J1UwaiZ01BdQwSg59U6NA1jYH9EI',
                ['i_grphic-art-and-design_topic_visual-arts'],
            ],
            ['parenting-surprises', 'qvGb3wox7guSa9bMfmKD694S30100TkEH9Aj8ItA3GvNw', []],
            [
                'podcasts-i-like',
                'UqSiTPfzlk1QMNv20002p3p00HGghBOr7KTW5ZxfMo4YrQ',
                ['i_parenting-and-family_podcast_parenting-hell'],
            ],
            [
                'spoken-languages',
                '6vaJ02KrmEMDHAutVH4HgmO8IwjuI5k00melQ801emSmLw',
                ['b_demographics_culture_british'],
            ],
            ['meaning-of-life', 'N003qpqVS5SE5ohh01smrTbQNubBV02017HPQ01uzMVivsSE', []],
            ['trailer', '965lxZwrv01FGwQKTV5Hkg02HT81b8zT2ylAmjZHlZoWs', []],
        ],
    },

    cynthia: {
        name: 'Cynthia',
        audio: '_cynthia.mp3',
        image: 'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fpeople%2Fcynthia.jpg?alt=media&token=c95cb0bb-2422-4fca-8a25-c1e801051fca',
        tags: {},
        playbackIds: [
            ['advice-for-my-past-self', 'b101FQOvT1al00Pi00bKUMRCOVZTt6N9aOVFOsSLd00v7qk', []],
            ['controversial-opinions', 'VX7raGezUsfExFkjbxCrD2fPL2ARlQ01TLDmh7wT01Zr4', []],
            [
                'dream-dinner-guest',
                'V9iXowk1qSwqN02y8igaz9ydNMuefDHw6FZn02q17KPtk',
                ['i_politics-and-social-issues_person_donald-trump'],
            ],
            [
                'dream-job',
                '6nFd19aZx1fom2diDYWgw1FjY9YchJwuiGUmuWJV6aQ',
                ['i_grphic-art-and-design_topic_graphic-design'],
            ],
            [
                'favorite-topics',
                'O01UkHI01SeZPUZfyocx7AU00BicZ1u2Va9rIWNs01z6Dok',
                ['i_science-and-nature_topic_science'],
            ],
            [
                'hobbies',
                'KkknqTrA7fgPE01gvWExg00ClvA00bvbvVDyVSlFz4CfTs',
                ['i_music-and-dance_activity_playing-the-piano'],
            ],
            ['introduction', 'gEomWTs02plcxazwjmoGWV0092yZ44Hzgly8HH2ggHuLM', []],
            [
                'making-cocktails',
                'HJuFTlqsmUau3D8yArGgGPE5SLVPepm15t4EwD2QtgA',
                ['i_food-and-drink_activity_making-cocktails'],
            ],
            ['pineapple-on-pizza', 'TbNyGeDSFiJQgptbQbxzjDejXRXcuRXlnPpcVVOL5D4', []],
            [
                'singing',
                'nsOUrVG4dRQjpu18yUqcxYl6b7nlXzkJKYEmRfp00Hxo',
                ['i_music-and-dance_activity_singing'],
            ],
            ['special-talent', 'Yf01Nx5s01UkiXrRwXAldqwkDYWXJcYNtjAOUYpps5Pds', []],
            [
                'spoken-languages',
                'FnbDzXsU7MAJY2BGF8yr751IqRoy201nGiZ6NYMdsNPE',
                ['b_demographics_language_french'],
            ],
            [
                'tatoos',
                'w6LNyDgnIVb73LQe5I1koxojlUio01kD023i3Qzr01lvGg',
                ['b_demographics_culture_immigrant'],
            ],
            ['trailer', 'HShid9x00yQYqhvTYpl2RRwBVKZtHuLrbmLX1gtMhtio', []],
            ['traveling', 'hFYK00GrgTjPlTkmpXXCaFGV3RMJQuJ0200eKt8ldrNbt00', []],
            ['why-most-relationships-fail', '7gPH9qVErAe4eJxa8EQCUk5OxWPPJ3WDV00iUVDfpJT8', []],
            [
                'work',
                'aIiLgCa029xLByM801ojAxil1Lc3nmyvhS761WkcabSag',
                ['b_work_industry_health-care', 'i_health-and-fitness_topic_medicine'],
            ],
            ['worlds-biggest-problem', 'vd4t9FeeWwqr2PMF015tvwPsNcLh2MPXd2IM48gyOX6s', []],
        ],
    },

    jeremy: {
        name: 'Jeremy',
        audio: '_jeremy.mp3',
        image: 'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fpeople%2Fjeremy.jpg?alt=media&token=e9fce5c9-1f85-4f0a-830d-89e0e88da1c1',
        tags: {},
        playbackIds: [
            [
                'dream-dinner-guest',
                'HnXJqqKSEJXZe02WRBvYh800h12yex6jnC2X4xtH6BZss',
                ['i_film-and-television_person_morgan-freeman'],
            ],
            [
                'final-fantasy',
                'vGD4pus01Gbtwe2dNIXm6ivxQ9u9DMbGpYIpK00l8SwzE',
                ['i_geeky-stuff_video-game_final-fantasy'],
            ],
            [
                'food',
                'SzQhsMam5Ztw6ihVsT5DdV5ifYTKCAo4xrkP02uwTyQo',
                ['i_food-and-drink_activity_trying-new-restaurants'],
            ],
            ['introduction', 'GBQt3gTZEEh7RtkYC3K02o402KE8elHK28DxD4TNWUdS4', []],
            [
                'introvert',
                '02xGQ751bouOtCew4GzQTqOAiKzpQRfwEZztt01422EPE',
                ['b_demographics_culture_french'],
            ],
            [
                'my-work-story',
                '3AOCazzuwwdsehKWks01QlOwvIfgs01nYfsIWfsvGjx9I',
                ['b_work_industry_education'],
            ],
            [
                'my-writing',
                '9iMxDxAbqZdxe38016ZZb4i7b02A9krCclskR01EZg4zbE',
                ['i_film-and-television_activity_writting-movie-scripts'],
            ],
            ['pineapple-on-pizza', 'C4SGw8z57eiZumUk01lyKBTr2WUEacmhDAOGuDu7BPEQ', []],
            ['social-media', 'WaBhXDkVC6UQGnqoaZArkWxjjkS98JKFEAynwwoJL4U', []],
            [
                'spoken-languages',
                'IV02rgK9FQzMksKkgoxo100ZQN02P2Vgq6mE75B3p4QYAY',
                ['b_demographics_language_french'],
            ],
            ['sports', 'A8cbwyt9zrzDWwsb18EC02OfMKvq00DAEGQemnFN02bCxg', []],
            [
                'the-shawshank-redemption',
                'qUyuAObgGfNb01020148RRt99tvmx6rYqXSyILdE2AzPlc',
                ['i_film-and-television_movie_the-shawshank-redemption'],
            ],
            ['trailer', 'q25Bsc5fdDIW355dsjRswM2FeQXbtnRIlzg37eaUCkc', []],
            ['travel-story', 'enFUVrOd5QbABiczuQ93ClCLITAIshEKp3Xprj029qro', []],
            ['video-games', 'acAVqnHjJVNMX7HZHG8zmm9USs01t7YmU025CWun7179Y', []],
            ['worlds-biggest-problem', 'dt8Nwuhghgqokn8yZgSpjUxMEEPvy01sjCNkoo9TevaA', []],
        ],
    },

    tish: {
        name: 'Tish',
        audio: '_tish.mp3',
        image: 'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fpeople%2Ftish.jpg?alt=media&token=dafc3835-aa15-4904-b8d8-bc82aacec6c3',
        tags: {},
        playbackIds: [
            ['dream-dinner-guest', 'LomLVjO0102cFRo3asOXh00DMM9glNZxYtcIWj6GO8vLp4', []],
            [
                'favorite-topics',
                'B59LDFNuMOLlQ81F00uTD01sB300Q1xGuz7flDQJoI3lzM',
                ['b_family_children_preschool'],
            ],
            ['funny-people', '2G002Ra600WXALagNMJ01leRVJKlDtzhqROAFPvlqP00rSw', []],
            ['if-i-received-1B-dollars', 'R601etc0015TTpn3OpJBB01lt3EsSqCujY241X2F1K00kjY', []],
            ['introduction', 'Lq9a5JsP8lY02nnL4Y541M00oR3xfg9Kq3xRiYi4fAYKE', []],
            ['looking-for-people-to', '8FQeNVnzCeJCBA4Ue01NErkJKaqhdPmDpAKQKBszM6x00', []],
            [
                'my-work-life',
                'Q388IByXml00GwKspXNAj00ZbmAc6269J6ES762TQ9vfk',
                ['b_work_title_project-manager'],
            ],
            ['parenting-advice', 'dm0202MH9RM2IFzis1Wl5at4B8DOyG6IVJCjVFywI2JmI', []],
            ['parenting-struggles', 'e4eyaR01r7EUhsiiI3exSr6SVQyF5qjx3CFJptjb9xSE', []],
            [
                'reading',
                'wfIOApvxqH9n261aX00005e00MI2ISKyIggKNZX1flO01PU',
                ['i_writing-and-literature_activity_reading-science-fiction'],
            ],
            ['science-fiction', 'BtCROM5vJklWBeM6wx69EcDhRNCEzMCDW02t3mFgnRK00', []],
            [
                'spoken-languages',
                'VdltMCEK926irxcFoHiUSeu5BQMbE5GblE6Rjua461w',
                ['b_demographics_language_french', 'b_demographics_culture_british'],
            ],
            ['stuff-i-would-like-to-create', 'aVYLkiOIy7nhFVSGYCLD90001ynqgBxosib7IYE1UXEo8', []],
            [
                'the-environment',
                'KIzd6QjZxeZ8bXLC01lnQlKkOfdsxFydd00EvDkjIK7mM',
                ['i_science-and-nature_topic_the-environment'],
            ],
            ['the-royal-family', 'YaBUH01HOcYSmQEN28uiZ9nDnSgh01q4o8L005bGqjx4nY', []],
            [
                'things-id-like-to-get-better-at',
                '1b7H5XnQEQpLq01vnLn9ht00hMuaT00jYWx1nVNSuiRTw4',
                [
                    'i_outdoors-and-adventure_activity_doing-cross-country-skiing',
                    'i_grphic-art-and-design_activity_drawing',
                ],
            ],
            ['trailer', '602Ad2xlmCvY35ywcw9TS02yP00IsSX9BjsWxAlgIbhwkU', []],
        ],
    },

    jeff: {
        name: 'Jeff',
        audio: '_jeff.mp3',
        image: 'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fpeople%2Fjeff.jpg?alt=media&token=423078c4-ce8c-4240-9b0e-8e61ad382a42',
        tags: {},
        playbackIds: [
            [
                'dream-dinner-guest',
                '0002m300TAJIjiRk58nPqy74fhucNavgZg42iWvStj2jCA',
                ['i_humor-and-comedy_person_stephen-colberlt'],
            ],
            ['favorite-topics', 'kCvMSYnUjGpVgCIJIf8PBb6Ze7HXDwkZ2ZPCccwojBQ', []],
            ['hobbies', 'TiVTMkjDE5PMtM4ZGOfOxEu4YOGyBANV01LzQhBalru8', []],
            ['ideal-saturday', 't8CJKwR7K7aPsiSbo7Kq00kvig3kYSu4m01L8x4JEF0174', []],
            ['if-i-had-2-extra-hours', 'FlmGy01BNTGPno7NZA2B62zJM85gT7tDu17tnA57daz00', []],
            ['introduction', 'upfOIkx6dpnr5dQ2tPQ7H5xgAASNJNWVO8OVYG02MSps', []],
            ['introvert', 'kNkAEbaqrs5RYoEtLHLFAGRUrn6bWAmCV76L3bKS17k', []],
            [
                'linux',
                '00yLnHnfkuKgdQo9eDV5uAiuINzx1I24dADEr01FG99XU',
                ['i_technology_thing_linux'],
            ],
            [
                'meaning-of-life',
                'Yy1BU6SHoIijjSaMDj018D4LrKyPv3VZ6QZIXlOnGdC00',
                ['i_film-and-television_movie_the-meaning-of-life'],
            ],
            ['pet-peeves', 'IUnXAu6NUAuOIbEePTtewoU02VhsYv9EwirlZoweUZvI', []],
            ['pineapple-on-pizza', 'MgKFe02srjipeVwXNl00aOZxzY6ZpX3XnkD01Lp7ZIVavo', []],
            ['quebec', 'qmlmr16RdfWSPaCQKNE9pWdLgMMGaflO5XYWCZnOX5I', []],
            ['reading', 'Q1x8V01e45wiTwZNKZxGBZ59HQ86BNflmxqpe00eUQ4700', []],
            [
                'spoken-languages',
                'RtcSnhZG025k11Y9L9Hd3GT00VKUcftzdaq8AX4q79VFE',
                ['b_demographics_language_french'],
            ],
            ['trailer', 'd00KC6LDUKUGQ3A3XYGCPdQRVhLZl8VAtB02accIEAcG4', []],
            [
                'traveling',
                'iL8wjvCU02pjpDl7thYKGiIHEGfbYUWPxMruvBeo2jTU',
                ['i_travel-and-exploration_activity_traveling'],
            ],
            ['twitter', 'CbzqrXi02D02leX1kM8005YGFQAmBPtZW01X3BQpNgBUDFc', []],
            ['video-games', 'u2wsZ54UhrVFd701rJaJPJ2v2jvkcFSJTEMY9H6F3aAQ', []],
            [
                'work',
                'NJPSy7Nr01RVyQq3k501Hx6Ocx01JgmaWCAbgmJl3DKTDg',
                ['b_work_title_business-owner', 'b_work_industry_online-marketing'],
            ],
            ['worlds-biggest-problem', 'Xa4m92oxSo6IoXSuHxEOZcazzDehQsEwLyN02YkgkmJo', []],
        ],
    },

    carlos: {
        name: 'Carlos',
        audio: '_carlos.mp3',
        image: 'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fpeople%2Fcarlos.jpg?alt=media&token=1f8d9c72-b1f6-4559-9043-26df0a4c8180',
        tags: {},
        playbackIds: [
            ['2-truths-1-lie', 'jC01S01pEbBUvi4LCyXX5UFdxN3jWlqNGaZ9rZvgFN45A', []],
            ['controversial-opinions', 'iNK8eb1f8DGfuMolkAH3jpESGPu48Yrq5dc6KL1Y6wI', []],
            [
                'dream-dinner-guest',
                'F00Iv4CyvLNkj009rhJypx8uH702zboXYXCt01aWfrOJKvQ',
                ['i_technology_person_elon-musk'],
            ],
            [
                'favorite-topics',
                '9bKrj8zB028ei9o01E002LldP58onCj2BE7N35e6V4GJH8',
                ['i_science-and-nature_topic_science'],
            ],
            [
                'i-will-never-again',
                'v1CFkKsqETCIsQ1ZXbdhbjaMz9QzUru02udOSU6wMW6U',
                ['b_education_field_engineering'],
            ],
            ['ideal-saturday', 'JpBUWMTmlKyDGKIjFuodYsB37yhPttLLJTQwFGGXxhc', []],
            [
                'if-i-received-10M-dollars',
                'GNT5Dg5e337HPFWWf02HA01MAS6W01vNqn6APLUHDU01J7Q',
                ['i_crafting_activity_woodworking'],
            ],
            ['introduction', 'gQOwC4OIgYPIRx102jKhslroRzGmbLtm01c8YNEBuv3DE', []],
            ['introvert-or-extrovert', 'mQLU26LvVkvYzHJMWkBq5HJdp3UkAny1SRYDBZaB65I', []],
            [
                'life-story',
                'QcuS6D602CkAS3Sw6mfAqQmMN7Rm4VocExxANsHsKgcM',
                ['b_demographics_culture_european', 'b_demographics_culture_immigrant'],
            ],
            ['pet-peeves', 'G84DJPfcqW00XjvyuK3Ub7ZKL8oFf6HHCC01rxuXV01HZE', []],
            ['pineapple-on-pizza', 'IoLF58AmoQfQyClnRoFmD5UdiF5srOh4QPdCQ6zFfYM', []],
            ['social-media', 'DraBI8vzw1IvNOMBUTrgnHW9CgsZR6RAC4IJA48u1yA', []],
            [
                'spoken-languages',
                '9eHOebemfqNLsfE4GLbCEZFVW8qtX6TJAPmashc2X8E',
                ['b_demographics_language_spanish'],
            ],
            [
                'sports',
                '5dyPQEWbZI87Q2qzeOZ6DVEO5yQGhS5BZQ02PiZKenTE',
                ['i_outdoors-and-adventure_activity_doing-outdoors-sports'],
            ],
            ['too-serious-to-be-joked-about', 'zz2pG2Vag6kDOYxX6NcYZ7oRnNrL401mm4MjxIkVwRsI', []],
            ['trailer', 'DI7iqsP9mAsRSeR5MLCNwZUCgUIabx800ltCpqiZxCUM', []],
            ['travel-story', 'k0001OjBNrYPzuokfc85myrU3VTNfVKUfOm2SxR7CKVv4', []],
            ['travel-wishlist', '6og6pCxBke6ihrBm71tt7Xs6V3ozSp9UOcsMOtuG8aY', []],
            ['work', '02vNgG4zeyI00o01No2mnZeCv8puN01M02FefT01McGUunk74', []],
            ['worlds-biggest-problem', 'K003u51tgZJ8F7UAbai4eYU2gEzYjD6MHyIb6MrnsmeM', []],
        ],
    },

    mauricio: {
        name: 'Mauricio',
        audio: '_mauricio.mp3',
        image: 'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fpeople%2Fmauricio.jpg?alt=media&token=1ce557cd-1a18-4619-87fa-f9f2a80ff8ca',
        tags: {},
        playbackIds: [
            [
                'animals',
                'anaX1VoNuMM8Jc9TJVXoFdQkMwqE0149F4ctMFzjhAEE',
                ['i_pets-and-animals_pet_dogs'],
            ],
            ['controversial-opinions', 'Imvo5XK3TbGNIp7hmej2Q5901wjGlwEm00ZtCj3FRzR8w', []],
            ['dream-job', '4IXqtM8SHjmn00XUGFE00fmnBMUDrcB9YWnQVVS2hXEAE', []],
            [
                'extreme-sports',
                '00bTApX6fq3ko02TPnsJhX5PY8XqZZHBlbsZtLKI2fcAE',
                ['i_outdoors-and-adventure_activity_doing-extreme-sports'],
            ],
            ['extrovert', 'QMXk1WXamgq3lDqFSTOvSa7rUjqEvSTYN4400axITDGw', []],
            ['favorite-topics', 'kSk7Vq00wyXFRh24q2c5J7X8WRRpRr9XB83JETaD01lWI', []],
            ['introduction', 'QYJ501pOcXopmkIWDsZChB6qv3W00HrQsvhegKHAr9evY', []],
            [
                'life-story',
                '01RqbJrRAKmo01Jl3ICF1Z161JVG02yqkjocFbnX7Wc5uc',
                ['b_demographics_culture_mexican', 'b_demographics_culture_immigrant'],
            ],
            ['looking-for-people-to', 'olcsQpqqZxxRhH8IFA4KjueAHYGGMvSzza020279201ORU', []],
            ['meaning-of-life', 'OFPyHc8sQIl00bOqA4K100w8U1dwx01ReracyA3e4nhKlc', []],
            ['my-crowd', 'jR6L2K2r0200DFkhTEIPIZP7nRdKSki2HasWfI01toNXpI', []],
            [
                'reading',
                'qSwrs16vOe8o5PBqdNM4zABTi1RvxvKaf02c7pAF00v4c',
                ['i_writing-and-literature_activity_reading-fiction-novels'],
            ],
            ['social-media', '61qMzaxegSDLuNWUTcA4bCq6jDAyVu9y00oV01w4019Ivg', []],
            [
                'spoken-languages',
                'HAwCbqwA9B9pHmZAE6bt1mSdDzaY9h9UzzWRuHD2iuo',
                ['b_demographics_language_spanish'],
            ],
            ['tatoos', 'MPuRksvIkY4ETYh00v501EOA300IBkH4QjfvY01P02eW4Ql4', []],
            ['trailer', 'VgH2l5kWOJb1300KeBRSXLhzMKYIQaOXjmSKh2n56n18', []],
            ['vices-i-despite', '00zJgXcDabEV8376V02hlxi7l8ihiQ4wHqutXJh3psib8', []],
            ['virtudes-i-admire', '00VSIB6JTaFfveBfwHQC201DytlXYT8tpnXObIgSFCABM', []],
            ['why-most-relationships-fail', 'BNB01Sm9kRPQke3Z4sDNCLw2TafcDQ018c4tyXHjprPEo', []],
            [
                'work',
                'nkTdgRUpUkXLX8uKmqt5HhxCsZNYpbqrQkjn5uHImL8',
                ['b_work_industry_tech-software', 'b_work_title_product-manager'],
            ],
            ['worlds-biggest-problem', 'jH4aQua1d8u1CgptS4exgBLBkFk4UEpfovJhUSRz63w', []],
        ],
    },

    raj: {
        name: 'Raj',
        audio: '_raj.mp3',
        image: 'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fpeople%2Fraj.jpg?alt=media&token=5e463bbb-b864-4f4b-b49b-4738277a3f69',
        tags: {},
        playbackIds: [
            [
                'cricket',
                'lVNDzE01hHTIeRZdsg9X5EK012mvY1UzGvmMKobUPwkSY',
                ['i_sports_activity_playing-cricket'],
            ],
            [
                'dating-stuff',
                'n01IKCaqkREKonN8k3GkkkmpmYNl8FOgZW02p02BXP41M4',
                ['i_sports_activity_playing-pool'],
            ],
            [
                'dream-dinner-guest',
                'ZY3C601FTJej7TF3MPqpPMc600M1NX4YSURsqTr8iETjU',
                ['i_sports_person_cristiano-ronaldo'],
            ],
            ['dream-job', 'c2GeEtx5IxD00xOGFq3eSnZA1uBrQcXzSGpeZ5bnjI9w', []],
            [
                'humans-best-and-worst-qualities',
                'Sg9Oft8vUjHislLHHGvE6SyiwkKe02uWXdBuEOopN01rI',
                [],
            ],
            ['if-i-received-1B-dollars', 'L76t6x3Esnn6hlhrlQ14lMniP016M02NggQhy6dKTXDE8', []],
            ['introduction', 'ZcZbM1QmM00oAQURsEHexDUgP79e56I029PJzqLNOptl8', []],
            [
                'looking-for-people-to',
                'CwaOx00mqB8Qmp01nCvUtKmfTi1UDcdZR33NvSk6V5sOI',
                ['i_sports_activity_playing-pool'],
            ],
            ['meaning-of-life', '4qrEd302uilei02X6Ar8wpSioEQUTRfRlbdTGQdXw67pA', []],
            ['my-crowd', 'C01Cfi99FslFlNd5ElL4UqHOx7FbvkCoVzWaWLHgDVx8', []],
            ['politics', 'PR27rXEKFSciGq01Leu8GGh5xBYsQhY6YFzJ8y00N200c00', []],
            ['reading', '8khySFAbKi01zI9jCSGrwRGAKvSCYJ00VwO6FBFta802aM', []],
            ['social-media', 'CZiAGRS6tXQD3cfxUlrf3okSbr1NjQ5wKGkzhLxc1Dw', []],
            [
                'spoken-languages',
                'WDyXKPuEpr901202qRRhd00v3Stx01yTPOOVXKhIAcVFNNM',
                ['i_music-and-dance_genere_hindi', 'b_demographics_language_hindi'],
            ],
            ['trailer', '02L3g3rxlLJ9Og49TFL9pIDkpWUKjux8FaIMFXbisiyg', []],
            [
                'video-games',
                'G01bIZZrFQjA7RCfuXWl66ZBUD800mpAf89hZiFsbIkMY',
                [
                    'i_geeky-stuff_activity_playing-video-games',
                    'i_geeky-stuff_video-game_fifa',
                    'i_geeky-stuff_video-game_call-of-duty',
                ],
            ],
            ['why-most-relationships-fail', 'NPbPbLSb5CMv7MsbvZ02LptRbRz1vIqI8wHFuJOpwQxs', []],
            [
                'work',
                'Touo8L02x02fOom5BQx02c4VrKbDiQVqQvmkLqNMFjeLfI',
                [
                    'b_work_industry_tech-software',
                    'b_work_title_cloud-administrator',
                    'b_work_function_dev-ops',
                ],
            ],
            [
                'worlds-biggest-problem',
                '1WB71wtqIOvmA9A2wjBQQnhsTnLQ7idd3A6u0100CFmS4',
                ['i_politics-and-social-issues_activity_discussing-social-problems'],
            ],
        ],
    },

    kundan: {
        name: 'Kundan',
        audio: '_kundan.mp3',
        image: 'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fpeople%2Fkundan.jpg?alt=media&token=01c5167d-7461-44c7-8e08-9468b2ead64f',
        tags: {},
        playbackIds: [
            [
                'artificial-intelligence',
                'S901P9W8AT84iTu482zwGdQ4hGt32024dPQ8It01CLkgPw',
                ['i_technology_topic_artificial-intelligence'],
            ],
            [
                'board-games',
                'GOj96YLJ8KQye01cj0002EtlFzwIptJTExoCFZD3yfUmco',
                [
                    'i_geeky-stuff_activity_playing-strategy-boardgames',
                    'i_geeky-stuff_board-game_great-western-trail',
                    'i_geeky-stuff_board-game_clank',
                    'i_geeky-stuff_board-game_modern-art',
                    'i_geeky-stuff_board-game_food-chain-magnate',
                ],
            ],
            [
                'dream-dinner-guest',
                'ggyZb00RtTMdu01HQYMCivFjJUNlNpr5dLUF2ezuex2PQ',
                ['i_technology_person_steve-jobs', 'i_science-and-nature_person_albert-einstein'],
            ],
            [
                'favorite-topics',
                '01bfgVKsbgspbWfSHn29kmiNlgLVtvara13vn5DSyubI',
                [
                    'i_philosophy-and-ethics_topic_first-principles',
                    'i_business-and-finance_topic_entrepreneurship',
                ],
            ],
            [
                'if-i-received-1B-dollars',
                'KINDK200woFK4VR7IgOgCxObRSqqj8j4foWrvYPbqiW8',
                ['i_health-and-fitness_topic_the-science-of-human-health'],
            ],
            [
                'life-story',
                '7jm3PN8u00hpM0202CuSfS01L1D9QBN98WyqjpPmzCF00HmQ',
                ['b_demographics_culture_indian'],
            ],
            [
                'reading',
                '64Y00Rn02F01frX00D4HKN9DHVKfvqQ02rDQckjX2klBLjaI',
                [
                    'i_writing-and-literature_activity_reading-non-fiction-books',
                    'i_learning-personal-development_activity_reading-self-improvement-books',
                    'i_philosophy-and-ethics_book_principles-by-ray-dalio',
                    'i_learning-personal-development_book_atomic-habits-by-james-clear',
                    'i_history-and-culture_book_sapiens-by-yuval-noah-harari',
                    'i_learning-personal-development_book_the-big-leap-by-gay-hendricks',
                ],
            ],
            [
                'too-serious-to-be-joked-about',
                'AKn012YmVdsEcmBO01f01uWDjbEK2l7Dphyzue02HMA34pg',
                [],
            ],
            ['trailer', 'm4005ZV01ZSu02e01w57i011EqonCcY5LFHf3fq3Y96Y2sNY', []],
            [
                'work',
                '02X5jv9Rr01453oG00HB9B1fv9OfRF02sgS4027ThvJmenYU',
                ['b_work_title_director', 'b_work_industry_tech-software'],
            ],
        ],
    },

    anir: {
        name: 'Anir',
        audio: '_anir_this-is.mp3',
        image: 'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fpeople%2Fanir.jpg?alt=media&token=f10548be-87d1-46e9-9680-dafbeee79322',
        tags: {},
        playbackIds: [
            ['advice-for-my-past-self', 'nEQToS02L82ggCy3aiJ4ZU301E1zAexmcgdtPt4r68vPQ', []],
            [
                'artificial-intelligence',
                'HSYdiCItDtkd02zZCzIClL00aUN4ZqNyRYqNWwWufD56I',
                ['i_technology_topic_artificial-intelligence'],
            ],
            [
                'cricket',
                'daZLxSJ5c6h8G00YnnMQ4B012sq5TVPVO7K2e38J3BtTw',
                ['i_sports_activity_playing-cricket'],
            ],
            ['dream-dinner-guest', 'PLTXSAKVeE5ZwEcWeujtkJKGpigCfxIM9svBEZTab01M', []],
            ['favorite-topics', '3qHUpvDPriZ8QIJoSZk02LLpQC00llpIu1ODCyG2LozkQ', []],
            ['my-crowd', 'DfydJgS5jedRxd255Ext7VFpMUwLh9ORTm02hRFxtYmw', []],
            ['pharma-industry', 'EfylvyNNEJvCJxxOGkjnlL00KIMbG5Pcwf9US02ljJW74', []],
            [
                'trailer',
                'KWQ2FTUVUAU3qqg5hRUpVtY5z01lLNSa1021Gm700ux7NY',
                [
                    'b_demographics_culture_indian',
                    'b_demographics_language_hindi',
                    'b_demographics_language_bengali',
                ],
            ],
            [
                'traveling',
                'cY9bqNkYVAvD3EV8EhSjoF21e1LVuI1DrM2bI48tsXY',
                ['i_travel-and-exploration_activity_traveling'],
            ],
            ['travel-wishlist', 'HFdkmSxE00tEY88wR8D64PJnj6Z8MOE8VRbirClqfHWA', []],
            [
                'work',
                'hApau302e01Z4YKrlcNvyiKnz4MqQ02gf4mFcJlzJcFAXE',
                ['b_work_title_director', 'b_work_industry_tech-software'],
            ],
        ],
    },

    andrea: {
        name: 'Andrea',
        audio: '_andrea_this-is.mp3',
        image: 'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fpeople%2Fandrea.jpg?alt=media&token=fde2bb63-93d0-48ba-ba9f-ae6f4e2f112c',
        tags: {},
        playbackIds: [
            [
                'dream-dinner-guest',
                '02Rt7QWVWXAF985GB01BidsDpDXiKIALJSyL4P2vHVoV00',
                ['i_music-and-dance_person_paul-mc-carney'],
            ],
            ['dream-job', '00v6oAZFIcEHpVTN4sEamHymgkQtbkmviJW5RSuWVBoE', []],
            [
                'favorite-topics',
                'iVzc5MvdQMXduNZWXb10202zRYyyBrhsaUbCbf01AH1aBQ',
                ['i_politics-and-social-issues_activity_discussing-social-problems'],
            ],
            ['ideal-saturday', 'DhotpNTEJtCDW01DyiNlC00vVeNw00QGTjU1rUN02k02P00eg', []],
            ['if-i-received-1B-dollars', 'H3VbDv202RaxeJ5A5jFTrWkIesAZFF00I7sQGEDt1qD2I', []],
            ['introvert', 'p5Eqml02Xj1C8Mnnnym00B4mOp7tWgWcN33zdNa3xx2Hk', []],
            [
                'life-story',
                '5lfGQSiOIG56yZnLk02hShxtW3Zhtft4hse0102mDcHmIE',
                ['b_demographics_culture_latin'],
            ],
            ['looking-for-in-brix', 'LrJLpKyJMSwXmCjWdTw6102ziKfxqd5I8siRKPKUdv02k', []],
            ['reading', 'Bo01kII1CKWWPnExM97Egb02YZciMI1pct9thDJbNONro', []],
            ['spoken-languages', 'EZSwfN7sqaKGd6NFh1iRosLVq00PWezZFJCvzsh2jsF00', []],
            ['too-serious-to-be-joked-about', 'vN8hnLw00KJZqF5T9JRYqMujdagMX022TGPZsd2m02P8PY', []],
            ['trailer', 'x3gNlaan9SjPwZDQhHfahYfckav2rriVv2ZVnLWWhM8', []],
        ],
    },

    noah: {
        name: 'Noah',
        audio: '_noah_this-is.mp3',
        image: 'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fpeople%2Fnoah.jpg?alt=media&token=d2919c52-dc35-4b3e-825a-64a3477cecaa',
        tags: {},
        playbackIds: [
            ['being-yourself', 's021wkx1cqODTCsgJ3rWuaRXZy6GzHBNnBjObbfzVLiU', []],
            [
                'dream-dinner-guest',
                'xrZlbSwSSXSiMfhoGPyN8DZFo202NS6jnqlfObrYVWB00',
                [
                    'i_writing-and-literature_person_haruki-murakami',
                    'i_writing-and-literature_book_kafka-on-the-shore-by-haruki-murakami',
                ],
            ],
            ['dream-job', 'V2bL7feUJT2ebUbkKvxV3IIRgrsArf8T2F01njLhr66E', []],
            ['favorite-topics', 'LkPdGNycclXdE9heijd01IKAb9EeJiaVYesQE00dl1rp8', []],
            ['grateful-for', '5KMG202DMDaAHGDLKtSKU1d2DAWsZg3uFTYJhhavDKBM', []],
            [
                'life-story',
                '58Mcl00q7xG00W51u9302mOs902wPVRVCQ87HgJbStnln7U',
                ['b_demographics_culture_quebecois'],
            ],
            ['social-media', 'bVvICc101JkWBjkHa300MxgRwse966WG4okBLz00FJ9cOM', []],
            ['trailer', '01fUIS01IIaEeJr6R02GBeV00nqi0036Go9w2vaEQb8agPpc', []],
            ['worlds-biggest-problem', 'bRbTLApSqpaq4oCJlT55uxedc5HUp00vOg8ctqHEBWAE', []],
            [
                'writing',
                'NJOXoVE01XnZ7Yc900L01EydbtoYKJutmRQ6ow01sVpjHS00',
                ['i_writing-and-literature_activity_writting-fiction-stories'],
            ],
        ],
    },

    bethyna: {
        name: 'Bethyna',
        audio: '_bethyna_this-is.mp3',
        image: 'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fpeople%2Fbethyna.jpg?alt=media&token=6639e863-0ff9-4110-8423-51b597d0e9e9',
        tags: {},
        playbackIds: [
            ['trailer', 'GGzQJL101fNq01nG9UaEykQjl5P1lF00BpxvwcXdRGVIlg', []],
            ['worlds-biggest-problem', 'h9IoJ1Q77mB202Vt3wtiPr1XoQoU27rnI5JTt3c300tBg', []],
            ['meaning-of-life', 'SdfkApdCQIo9EqrW4UlU00jNWy402XW8ErvjboXQ9GnWA', []],
            [
                'spoken-languages',
                'QnH02WgLJRX6nNC9wuhG02AUFtqLgJzfSAp43W00N3VYBM',
                ['b_demographics_language_french'],
            ],
            ['social-media', '9qjTIaj7FgP5ei5ozgAq1XGdavzc1ZC1nHh5JKheg00U', []],
            ['reading', '7B602couRCUnX8DFPFGlh1mgXz005d72SOUPC7O7oQqEM', []],
            [
                'work',
                'o1q1OLeTSqEcpPXGEwHBlhmJXB9cYPidaa01fBhN2wuE',
                [
                    'b_work_title_event-coordinator',
                    'b_work_title_entrepreneur',
                    'b_work_industry_content-creation',
                ],
            ],
            [
                'life-story',
                '6f9MUFmSnG8uyWCzDGLJxqSIAEmLAHdRxhTP6cRoszQ',
                ['b_demographics_culture_caribbean', 'b_demographics_culture_immigrant'],
            ],
            ['my-crowd', 'Fxi00Jyb8I5UOonk9O9UggS6nZT8sF4pjeGQ6Mr4TtH00', []],
            ['introvert', '6533mqFqjZQzQvDwJgX772H8QmYgP00BlLYUb6F66Dek', []],
            ['if-i-received-10M-dollars', 'BjRDC7lBrDbITM4LxI8BN02fbCGfyFOfqUpePrtKNayE', []],
            ['dream-job', 'SUNg01FqLBT98OSPdZ6ftsWl00014fwKoO22L01dl2dTq2o', []],
            [
                'dream-dinner-guest',
                '01wDUAkViFv3GDRIUSRY7GgieBOXxw00XU01qe2KOcpnRU',
                [
                    'i_film-and-television_person_ava-du-vernay',
                    'i_film-and-television_movie_a-wrinkle-in-time',
                ],
            ],
            ['take-on-college', '4TtTmUXLImuq1NToD01cT5MCJd6EJ016KWcpHEbu602TPY', []],
        ],
    },

    dave: {
        name: 'Dave',
        audio: '_dave_this-is.mp3',
        image: 'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Fpeople%2Fdave.jpg?alt=media&token=43cabb6c-9652-4ac9-b151-5bf39699bd51',
        tags: {},
        playbackIds: [
            ['trailer', 'r4OgAVRL021XkCPfe5GkFbHMz4S011SD8cMaa5sTDY1f4', []],
            ['hobbies', 'pnw19FchomUUDzNgMEx32kjQgzXajgbwE00z1txHg01jI', []],
            ['the-last-of-us', 'PVOUhxO3PJQBi02SxwZRwHdB7g4ygfyPRkveE008sYTjY', []],
            ['meaning-of-life', 'wLOSC5H2PWMeMEz2SsDCybYZgnATTHPjyU8Cja7SD2I', []],
            ['free-will', 'oNSIHAI7eFQlNzEoxbjzFUciKL36B01s5NPgPmUrV4Ns', []],
            ['mexican', '1dBvcZr01sg66IpBd700kPdrjmrYHDRkW34tT4eaNDAY8', []],
            ['being-yourself', '6F52qlzF00aiAVMutkQVMNT8sjimkY6aVGSRn01v5H02BU', []],
            ['work', 'lkjSYuwGd2yNCtW5kU51xFLSEsLm5SolMUCZdV00wufQ', []],
            ['countries-visited', 'EgYUQmt3fhfyCoLfAueqSU7BNq1xv4dOtFXGTekRuoU', []],
        ],
    },
};
