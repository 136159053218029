import React, { useRef, useState, useEffect } from 'react';

const photos = [
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Finvitations%2Farcade-2.jpg?alt=media&token=ea4fd508-3cc8-4e63-94b6-0e1c5bebd34a',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Finvitations%2Fbowling.png?alt=media&token=b02de40f-75b5-4572-996e-6d9a42c7e2c4',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Finvitations%2Fcomedy-show-2.jpg?alt=media&token=31c5fd9f-a70e-4799-b640-05281f6b6bd5',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Finvitations%2Fdinner.jpg?alt=media&token=b12a55b9-84ef-4fcd-a516-7fd8f04acdd9',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Finvitations%2Fgame-night.webp?alt=media&token=0c8c0f39-babe-4008-b424-7ea2a608239e',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Finvitations%2Fgo-karts.jpg?alt=media&token=7ece0250-8558-40ef-a2ea-d1d5c2d57e17',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Finvitations%2Fgrab-a-drink.webp?alt=media&token=a0d85676-fbf0-4de2-bf03-df03479deaf7',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Finvitations%2Fgrab-coffee.jpg?alt=media&token=64d9657b-2a73-45a2-8e8d-d2dcf5ecc68e',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Finvitations%2Fhiking.jpg?alt=media&token=f0ddd5eb-0c6e-4c49-88c1-1e2aec398d97',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Finvitations%2Fkaraoke.jpeg?alt=media&token=2e9c2547-ac76-4f7f-8059-9b7ca59c9627',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Finvitations%2Flaser-tag.jpg?alt=media&token=254be6d5-ed7a-4685-83eb-c151b3f15c8a',
    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/kins_pilot%2F_thumbnails%2Finvitations%2Fmovie-night.jpg?alt=media&token=ccecb411-1faa-47fc-8443-607e0c565c7b',
];

const song = require('../../../DummyData/sounds/worlds-biggest-problem.mp3')

// function PhotoRotator({ photos, song }) {
function PhotoRotator(props) {

  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    const audioContext = new AudioContext();
    const analyserNode = audioContext.createAnalyser();
    const sourceNode = audioContext.createMediaElementSource(audio);
    sourceNode.connect(analyserNode);
    analyserNode.connect(audioContext.destination);

    const frequencyData = new Uint8Array(analyserNode.frequencyBinCount);
    const intervalId = setInterval(() => {
      analyserNode.getByteFrequencyData(frequencyData);
      const averageFrequency = frequencyData.reduce((sum, value) => sum + value, 0) / frequencyData.length;
      const newPhotoIndex = Math.floor((averageFrequency / 255) * photos.length);
      setCurrentPhotoIndex(newPhotoIndex);
    }, 50);

    return () => {
      clearInterval(intervalId);
      audioContext.close();
    };
  }, [photos]);

  useEffect(() => {
    setCurrentPhotoIndex(0);
  }, [song]);

  return (
    <>
      <audio ref={audioRef} src={song} autoPlay loop />
      {photos.map((photo, index) => (
        <img key={index} src={photo} alt='' style={{ display: currentPhotoIndex === index ? 'block' : 'none' }} />
      ))}
    </>
  );
}

export default PhotoRotator