export const categoryIcons = {
    x: { icon: '', style: {}, toastSize: null },

    animal: {
        icon: 'pets',
        style: { marginLeft: '-3px', marginRight: '3px', marginTop: '-2px' },
        toastSize: null,
    },

    battery: {
        icon: 'battery_charging_full',
        style: { marginLeft: '-3px', marginRight: '3px' },
        toastSize: null,
    },

    book: { icon: 'menu_book', style: { marginRight: '5px', marginTop: '-2px' }, toastSize: null },

    comedy: {
        icon: 'theater_comedy',
        style: { marginLeft: '-1px', marginRight: '3px' },
        toastSize: null,
    },

    computer: { icon: 'computer', style: { marginRight: '3px' }, toastSize: null },

    crowd: {
        icon: 'diversity_1',
        style: { marginRight: '5px', marginTop: '-2px' },
        toastSize: null,
    },

    favorite: { icon: 'favorite', style: { marginRight: '3px' }, toastSize: null },

    flag: { icon: 'flag', style: { marginRight: '3px' }, toastSize: null },

    food: {
        icon: 'lunch_dining',
        style: { marginRight: '3px', marginTop: '-1px', fontSize: '16px' },
        toastSize: null,
    },

    game: { icon: 'sports_esports', style: { marginRight: '3px' }, toastSize: null },

    hiking: { icon: 'hiking', style: {}, toastSize: null },

    hours: { icon: 'schedule', style: { marginRight: '3px' }, toastSize: null },

    koupon: { icon: 'local_activity', style: {}, toastSize: null },

    language: {
        icon: 'translate',
        style: { marginLeft: '-3px', marginRight: '3px' },
        toastSize: null,
    },

    location: {
        icon: 'location_on',
        style: {},
        toastSize: null,
    },

    money: {
        icon: 'attach_money',
        style: { marginLeft: '-3px', marginRight: '-3px' },
        toastSize: null,
    },

    music: { icon: 'music_note', style: { marginLeft: '-3px' }, toastSize: null },

    pending: { icon: 'question_mark', style: {}, toastSize: null },

    person: { icon: 'face', style: { marginRight: '3px' }, toastSize: null },

    photos: { icon: 'photo_library', style: { marginRight: '3px' }, toastSize: null },

    place: { icon: 'store', style: { marginRight: '3px' }, toastSize: null },

    reflection: {
        icon: 'psychology',
        style: { marginLeft: '-3px', marginTop: '-2px', marginRight: '3px' },
        toastSize: '40px',
    },

    reservation: { icon: 'event_available', style: { marginRight: '3px' }, toastSize: null },

    restaurant_menu: { icon: 'restaurant_menu', style: { marginRight: '3px' }, toastSize: null },

    sport: {
        icon: 'directions_run',
        style: { marginLeft: '-3px', marginRight: '3px' },
        toastSize: null,
    },

    story: {
        icon: 'history_edu',
        style: { marginLeft: '-3px', marginRight: '3px' },
        toastSize: null,
    },

    trailer: {
        icon: 'theaters',
        style: { marginLeft: '-3px', marginRight: '3px' },
        toastSize: null,
    },

    trip: { icon: 'flight', style: { marginLeft: '-3px', marginRight: '3px' }, toastSize: null },

    work: { icon: 'work', style: { marginRight: '3px' }, toastSize: null },
};
