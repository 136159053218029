import css from './ChoiceScreen.module.css';
import { cloneObject } from '../../../../tools/data-processing';
import { useSelector, useDispatch } from 'react-redux';
import Choice from './Choice/Choice';
import { useEffect } from 'react';
import { unpackSpecificDate } from '../../../Invitations/InvitationsBrowser/unpackInvitations';
import { updateBrowserKeys } from '../../../../store/browserSlice';

const ChoiceScreen = (props) => {
    const dispatch = useDispatch();
    const browser = useSelector((state) => state.browser);
    const activeSection = browser.topics.active.section;

    const whenSelction = browser.invitation.when.selection;
    const whereSelction = browser.invitation.where.selection;
    const invitation = browser.elements.masterRef[browser.elements.activeId];

    useEffect(() => {
        const whereOptions = whenSelction
            ? getValidOptions(whenSelction, 'when', invitation.valid)
            : invitation.where;

        updateBrowserKeys(dispatch, ['invitation', 'where'], { options: whereOptions });
        updateBrowserKeys(dispatch, ['places'], { queue: whereOptions });

        const whenOptions = whereSelction
            ? getValidOptions(whereSelction, 'where', invitation.valid)
            : invitation.when;
        updateBrowserKeys(dispatch, ['invitation', 'when'], { options: whenOptions });
    }, [whenSelction, whereSelction]);

    const options = prepareOptions();

    const audioFile = useSelector((state) => state.browser.choiceScreen.content.audio);
    useEffect(() => {
        if (audioFile) {
            const audio = new Audio(require('../../../../DummyData/sounds/' + audioFile));
            audio.play();
        }
    }, [audioFile]);

    return (
        <div className={css['outer-flex']}>
            <div className={`${css['main-container']} ${css['options-' + options.length]}`}>
                {options.map((option) => (
                    <Choice key={option.id} choice={option} />
                ))}
            </div>
        </div>
    );

    function prepareOptions() {
        if (activeSection === 'where') {
            return getWhereOptions();
        }

        if (activeSection === 'when') {
            return getWhenOptions();
        }

        function getWhereOptions() {
            const choices = browser.invitation.where.options.slice(0, 4);

            const topics = browser.elements.masterRef[browser.elements.activeId].topics;
            const result = [];
            const optionCount = choices.length;
            for (var i = 0; i < optionCount; i++) {
                const option = cloneObject(topics[choices[i]]);
                option.borderWidth = borderWidths[optionCount][i + 1];
                result.push(option);
            }

            return result;
        }

        function getWhenOptions() {
            const choices = browser.invitation.when.options.slice(0, 4);
            const result = [];
            const optionCount = choices.length;

            for (var i = 0; i < optionCount; i++) {
                const option = {
                    content: unpackSpecificDate(choices[i]),
                    default: choices[i],
                    id: choices[i],
                    image: null,
                    name: '',
                };

                option.borderWidth = borderWidths[optionCount][i + 1];
                result.push(option);
            }
            return result;
        }
    }

    function getValidOptions(selected, selectedType, valid) {
        const result = [];
        const otherType = selectedType === 'when' ? 'where' : 'when';

        valid.forEach((i) => {
            if (i[selectedType].includes(selected)) {
                const options = i[otherType];
                options.forEach((o) => {
                    if (!result.includes(o)) {
                        result.push(o);
                    }
                });
            }
        });

        return result.sort();
    }
};

const borderWidths = {
    1: {
        1: { borderWidth: '2px 2px 2px 2px' },
    },

    2: {
        1: { borderWidth: '2px 1px 2px 0px' },
        2: { borderWidth: '2px 0px 2px 1px' },
    },

    3: {
        1: { borderWidth: '0px 1px 1px 0px' },
        2: { borderWidth: '0px 0px 2px 1px' },
        3: { borderWidth: '1px 2px 0px 0px' },
    },

    4: {
        1: { borderWidth: '0px 1px 1px 0px' },
        2: { borderWidth: '0px 0px 1px 1px' },
        3: { borderWidth: '1px 1px 0px 0px' },
        4: { borderWidth: '1px 0px 0px 1px' },
    },
};

export default ChoiceScreen;
