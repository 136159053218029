import css from './Choice.module.css';

import DateOption from './DateOption/DateOption';

import { useSelector, useDispatch } from 'react-redux';
import { translate } from '../../../../../tools/translator';
import { sections } from '../../Topics/Sections/Sections';
import { selectChoice, updateBrowser } from '../../../../../store/browserSlice';
import { updateBrowserKeys } from '../../../../../store/browserSlice';
import { Fragment } from 'react';
import Icon from '../../../Icon/Icon';

const Choice = (props) => {
    const dispatch = useDispatch();
    const choice = props.choice;

    const browser = useSelector((state) => state.browser);
    const activeTheme = sections[browser.topics.active.section].theme || browser.product;
    const color = translate(activeTheme, 'product', 'color');
    const activeSection = browser.topics.active.section;
    const firestorePlaces = useSelector((state) => state.firestore.places.elements);

    return (
        <div
            className={css['main-container']}
            style={{ ...choice.borderWidth, borderColor: color }}
            onClick={handleClick}
        >
            {choice.image ? (
                <Thumbnail choice={choice} color={color} />
            ) : (
                <DateOption choice={choice} />
            )}
        </div>
    );

    function handleClick() {
        if (activeSection === 'where') {
            const playbackId = firestorePlaces[choice.id].playbackId;
            updateBrowserKeys(dispatch, ['player'], { playbackId });
            const where = browser.places.queue;

            updateBrowser(dispatch, 'places', {
                activeId: choice.id,
                position: where.indexOf(choice.id),
            });
            updateBrowser(dispatch, 'manager', { endPoint: 'VerticalPlayerPlaying' });
            updateBrowserKeys(dispatch, ['invitation', 'where'], { selection: choice.id });
            updateBrowser(dispatch, 'choiceScreen', { show: false });
            setTimeout(() => {
                updateBrowserKeys(dispatch, ['player'], { action: 'play' });
            }, 100);
        }

        if (activeSection === 'when') {
            selectChoice(dispatch, activeSection, choice.id);
        }
    }
};

const Thumbnail = (props) => {
    const choice = props.choice;
    const image = choice.image;

    const imgSrc = getImgSrc();

    return (
        <Fragment>
            <img className={`${css['thumbnail-image']}`} src={imgSrc} alt='' />
            {choice.name && (
                <div
                    className={css['text-container']}
                    style={{ WebkitTextStrokeColor: props.color }}
                >
                    <div className={css['text-name']}>{choice.name}</div>
                    <PlaceSubtitle choice={choice} />
                </div>
            )}
        </Fragment>
    );

    function getImgSrc() {
        if (image.slice(0, 4) === 'http') {
            return image;
        }

        return require('../../../../../images/thumbnails/' + choice.image);
    }
};

const PlaceSubtitle = (props) => {
    const travelDistance = props.choice.travelDistance;
    const units = travelDistance >= 1000 ? 'km' : 'm';

    const bill = props.choice.bill;

    return (
        <div className={css['place-subtitle']} style={{ color: 'var(--orange)' }}>
            <div className={css['time-container']}>
                <div className={css['time-number']}>{getPrettyDistance()}</div>
                <div className={css['time-column']}>
                    <div className={css['time-text']}>{units}</div>
                </div>
            </div>

            <div className={css['money-container']}>
                <Icon shape='filled' icon='attach_money' fontSize='14px' />
                <div className={css['money-number']}>{bill}</div>
            </div>
        </div>
    );

    function getPrettyDistance() {
        if (travelDistance < 1000) {
            return travelDistance;
        } else {
            let kilometers = travelDistance / 1000;
            return Math.round(kilometers * 10) / 10;
        }
    }
};

export default Choice;
