import defaultCss from './Bucket.module.css';
import menuCss from './Bucket_Menu.module.css';

import Category from './Category/Category';

import { useSelector } from 'react-redux';

const Bucket = (props) => {
    const bucket = props.bucket;
    const drawer = useSelector((state) => state.drawer);

    const query = drawer.query;
    const inQuery = bucket.keyConcat.includes(query);

    const activeContent = drawer.activeContent;

    const css = activeContent in customCSS ? customCSS[activeContent] : defaultCss;

    const validLanguage = checkLanguage();
    const display = validLanguage && inQuery ? 'flex' : 'none';

    return (
        <div className={css['main-container']} style={{ display: display }}>
            <div className={css['title-container']}>{bucket.name}</div>
            <div className={css['categories-container']}>
                {bucket.categories.map((category) => (
                    <Category key={category.id} category={category} />
                ))}
            </div>
        </div>
    );

    function checkLanguage() {
        if (drawer.activeContent !== 'menu_fees') {
            return true;
        }

        const validLanguage = drawer['menu_fees'].language === bucket.language;
        return validLanguage;
    }
};

const customCSS = {
    menu_fees: menuCss,
};

export default Bucket;
