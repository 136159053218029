import Browser from '../../Shared/Browser/Browser';
import BrowserButtons from '../../Shared/Browser/BrowserButtons/BrowserButtons';
import BrowserButton from '../../Shared/Browser/BrowserButtons/BrowserButton/BrowserButton';
import BrowserTopBar from '../../Shared/Browser/BrowserTopBar/BrowserTopBar';
import { loadDrawer } from '../../../store/drawerSlice';
import { peopleTags, peopleTagsMasterRef } from './peopleTags';
import { useSelector, useDispatch } from 'react-redux';
import { Fragment, useEffect } from 'react';

const PeopleBrowser = (props) => {
    const dispatch = useDispatch();
    const people = useSelector((state) => state.firestore.people);
    const ready = people.ready;

    useEffect(() => {
        loadDrawer(dispatch, 'People', peopleTags, peopleTagsMasterRef, 'topics');
    }, [ready]);

    return (
        <Fragment>
            <BrowserTopBar product='People' />
            <Browser
                elements={people.elements}
                elementIds={people.elements.elementIds}
                product='People'
                useTimer={true}
                topicsCss='light-topics'
                ready={ready}
            >
                <Buttons />
            </Browser>
        </Fragment>
    );
};

const Buttons = (props) => {
    const timer = useSelector((state) => state.browser.timer);

    const nextCountDown = timer.nextCountDown;
    const addCountDown = timer.addCountDown;
    const watch = true || timer.watch;

    return (
        <BrowserButtons boxSize='large'>
            <BrowserButton icon='reply' action='showPrevious' iconStyle={{ marginTop: '-5px' }} />

            {watch && (
                <BrowserButton
                    product='GoodTimes'
                    size='large'
                    text='Invite'
                    action='toggleDrawer'
                    timer={addCountDown > 0 && addCountDown}
                />
            )}
            {!watch && (
                <BrowserButton product='Lime' size='large' text='Watch' action='keepWatching' />
            )}

            <BrowserButton
                icon='reply'
                action='showNext'
                flip
                disabled={true}
                iconStyle={{ marginTop: '-5px' }}
                timer={nextCountDown > 0 && nextCountDown}
            />
        </BrowserButtons>
    );
};

export default PeopleBrowser;
