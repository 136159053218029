import css from './ClearChoice.module.css';
import Icon from '../../../Icon/Icon';

import { useSelector, useDispatch } from 'react-redux';

import { translate } from '../../../../../tools/translator';
import { sections } from '../../Topics/Sections/Sections';

import { clearChoice } from '../../../../../store/browserSlice';

const ClearChoice = (props) => {
    const dispatch = useDispatch();
    const browser = useSelector((state) => state.browser);
    const activeTheme = browser.topics.active.section
        ? sections[browser.topics.active.section].theme
        : browser.product;
    const color = translate(activeTheme, 'product', 'color');

    return (
        <div
            className={css['main-container']}
            style={{ WebkitTextStrokeColor: color }}
            onClick={handleClick}
        >
            <Icon icon='clear' fontSize='48px' marginTop='-10px' marginRight='-10px' />
        </div>
    );

    function handleClick() {
        clearChoice(dispatch);
    }
};

export default ClearChoice;
