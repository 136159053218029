export function initPrices() {
    const prices = {
        food: { price: 0, quantity: 1, amount: 0, concept: null, units: null },
        appetizer: { price: 0, quantity: 1, amount: 0, concept: null, units: null },
        main: { price: 0, quantity: 1, amount: 0, concept: null, units: null },
        side: { price: 0, quantity: 1, amount: 0, concept: null, units: null },
        dessert: { price: 0, quantity: 1, amount: 0, concept: null, units: null },

        drink: { price: 0, quantity: 1, amount: 0, concept: null, units: null },
        cocktail: { price: 0, quantity: 1, amount: 0, concept: null, units: null },
        beer: { price: 0, quantity: 1, amount: 0, concept: null, units: null },
        wine: { price: 0, quantity: 1, amount: 0, concept: null, units: null },
        soda: { price: 0, quantity: 1, amount: 0, concept: null, units: null },
        coffee: { price: 0, quantity: 1, amount: 0, concept: null, units: null },

        activity: { price: 0, quantity: 1, amount: 0, concept: null, units: null },

        subtotal: { price: null, quantity: null, amount: 0, concept: null, units: null },
        taxes: { price: null, quantity: 15, amount: 0, concept: null, units: null },
        gratuity: { price: null, quantity: 20, amount: 0, concept: null, units: null },
        total: { price: null, quantity: null, amount: 0, concept: null, units: null },
        notes: '',
    };

    return prices;
}
