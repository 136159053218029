export function prepareLabels(items, categories, buckets) {
    const masterRef = createMasterReference();

    items.forEach((item) => {
        
        item.keywords.push(item.name.toLowerCase());
        item.selected = false;
        const category = masterRef[item.category];
        item.bucket = category.bucket;
        category.keywords.push(...item.keywords);
        category.items.push(item);
    });

    categories.forEach((category) => {
        category.keywords.push(category.name.toLowerCase());

        const bucket = masterRef[category.bucket];
        bucket.keywords.push(bucket.name.toLowerCase());
        bucket.keywords.push(...category.keywords);

        bucket.categories.push(category);
        bucket.items.push(...category.items);
    });

    Object.keys(masterRef).forEach((key) => {
        const item = masterRef[key];
        item['keyConcat'] = item.keywords.join();
    });

    return [buckets, masterRef];

    function createMasterReference() {
        const result = {};
        items.forEach((item) => {
            result[item.id] = item;
        });
        categories.forEach((category) => {
            result[category.id] = category;
        });
        buckets.forEach((bucket) => {
            result[bucket.id] = bucket;
        });

        return result;
    }
}

const people_labels_buckets = [
    { name: 'Language & Geography', type: 'bucket', id: 'language-and-geography', keywords: [], categories: [], items: [] },    
    { name: 'Life Situation', type: 'bucket', id: 'life-situation', keywords: [], categories: [], items: [] },
    { name: 'Sex Stuff', type: 'bucket', id: 'dating', keywords: [], categories: [], items: [] },
];

const people_labels_categories = [
    
    { name: 'Gender', bucket: 'dating', type: 'category', id: 'gender', keywords: [], items: [] },
    { name: 'Sexual Orientation', bucket: 'dating', type: 'category', id: 'sexual-orientation', keywords: [], items: [] },
    
    { name: 'Parent of ___', bucket: 'life-situation', type: 'category', id: 'parent-of', keywords: [], items: [] },
    { name: 'Relationship Status', bucket: 'life-situation', type: 'category', id: 'relationship-status', keywords: [], items: [] },


    { name: 'Speaks ___', bucket: 'language-and-geography', type: 'category', id: 'speaks', keywords: [], items: [] },
    { name: 'Originally from ___', bucket: 'language-and-geography', type: 'category', id: 'origin', keywords: [], items: [] },
]

const people_labels = [
    { name: 'English', category: 'speaks', type: 'label', id: 'english', keywords: [] },
    { name: 'Français', category: 'speaks', type: 'label', id: 'french', keywords: [] },
    { name: 'Español', category: 'speaks', type: 'label', id: 'spanish', keywords: [] },
    
    { name: 'Canada', category: 'origin', type: 'label', id: 'canada', keywords: [] },
    { name: 'France', category: 'origin', type: 'label', id: 'france', keywords: [] },
    { name: 'Haiti', category: 'origin', type: 'label', id: 'haiti', keywords: [] },
    { name: 'Mexico', category: 'origin', type: 'label', id: 'mexico', keywords: [] },
    { name: 'Peru', category: 'origin', type: 'label', id: 'peru', keywords: [] },
    { name: 'Spain', category: 'origin', type: 'label', id: 'spain', keywords: [] },
    { name: 'United Kingdom', category: 'origin', type: 'label', id: 'united-kingdom', keywords: [] },
    { name: 'United States', category: 'origin', type: 'label', id: 'united-states', keywords: [] },
    
    { name: 'Single', category: 'relationship-status', type: 'label', id: 'single', keywords: [] },
    { name: 'In a relationship', category: 'relationship-status', type: 'label', id: 'in_a_relationship', keywords: [] },
    
    { name: 'Woman', category: 'gender', type: 'label', id: 'woman', keywords: [] },
    { name: 'Man', category: 'gender', type: 'label', id: 'man', keywords: [] },
    { name: 'Non-binary', category: 'gender', type: 'label', id: 'nonbinary', keywords: [] },
    
    { name: 'Heterosexual', category: 'sexual-orientation', type: 'label', id: 'heterosexual', keywords: ['straight'] },
    { name: 'Homosexual', category: 'sexual-orientation', type: 'label', id: 'homosexual', keywords: ['gay', 'lesbian'] },
    { name: 'Bisexual', category: 'sexual-orientation', type: 'label', id: 'bisexual', keywords: [] },
        
    { name: "No kids", category: 'parent-of', type: 'label', id: 'no-kids', keywords: [] },
    { name: 'Baby', category: 'parent-of', type: 'label', id: 'baby', keywords: [] },
    { name: 'Toddler', category: 'parent-of', type: 'label', id: 'toddler', keywords: [] },
    { name: 'Preschool', category: 'parent-of', type: 'label', id: 'preschool', keywords: [] },
    { name: 'School-aged', category: 'parent-of', type: 'label', id: 'school-aged', keywords: [] },
    { name: 'Adolecent', category: 'parent-of', type: 'label', id: 'adolecent', keywords: [] },
    { name: 'Adult', category: 'parent-of', type: 'label', id: 'adult', keywords: [] },
]

const [peopleLabels, peopleMasterRef] = prepareLabels(
    people_labels,
    people_labels_categories,
    people_labels_buckets
);

const places_labels_buckets = [
    { name: 'Basics', type: 'bucket', id: 'venue-basics', keywords: [], categories: [], items: [] },
    { name: 'Mood', type: 'bucket', id: 'venue-mood', keywords: [], categories: [], items: [] },
    { name: 'Venue Type', type: 'bucket', id: 'venue-type', keywords: [], categories: [], items: [] },
];

const places_labels_categories = [
    { name: 'Travel Time', bucket: 'venue-basics', type: 'category', id: 'travel-time', keywords: [], items: [] },
    { name: 'Median Cost', bucket: 'venue-basics', type: 'category', id: 'median-cost', keywords: [], items: [] },
    { name: 'Food Served', bucket: 'venue-basics', type: 'category', id: 'food-served', keywords: [], items: [] },
    { name: 'Physical Intensity', bucket: 'venue-mood', type: 'category', id: 'physical-intensity', keywords: [], items: [] },
    { name: 'Noisiness', bucket: 'venue-mood', type: 'category', id: 'noisiness', keywords: [], items: [] },
    { name: 'Vibe', bucket: 'venue-mood', type: 'category', id: 'place-vibe', keywords: [], items: [] },
    { name: '', bucket: 'venue-type', type: 'category', id: 'venue-type-category', keywords: [], items: [] },
    { name: 'Food & Drinks', bucket: 'venue-type', type: 'category', id: 'food-venue-category', keywords: [], items: [] },
    { name: 'Outdoors & Sports', bucket: 'venue-type', type: 'category', id: 'outdoors-and-sports-venue-category', keywords: [], items: [] },
    { name: 'Cultural', bucket: 'venue-type', type: 'category', id: 'cultural-venue-category', keywords: [], items: [] },
    { name: 'Fun & Games', bucket: 'venue-type', type: 'category', id: 'amusement-venue-category', keywords: [], items: [] },
    
    { name: 'Shows', bucket: 'venue-type', type: 'category', id: 'show-venue-category', keywords: [], items: [] },
    { name: 'Virtual', bucket: 'venue-type', type: 'category', id: 'virtual-venue-category', keywords: [], items: [] },
];

const places_labels = [
    { name: '15 min <', category: 'travel-time', type: 'label', id: '15-min-or-less', keywords: [] },
    { name: '30 min <', category: 'travel-time', type: 'label', id: '30-min-or-less', keywords: [] },
    { name: '60 min <', category: 'travel-time', type: 'label', id: '60-min-or-less', keywords: [] },
    
    
    { name: 'Free', category: 'median-cost', type: 'label', id: 'free-0-dol', keywords: [] },
    { name: '$25 <', category: 'median-cost', type: 'label', id: '25-dol-or-less', keywords: [] },
    { name: '$50 <', category: 'median-cost', type: 'label', id: '50-dol-or-less', keywords: [] },
    { name: '$100 <', category: 'median-cost', type: 'label', id: '100-dol-or-less', keywords: [] },
    
    { name: 'Alcohol', category: 'food-served', type: 'label', id: 'alcohol', keywords: [] },
    { name: 'Entrees & Snacks', category: 'food-served', type: 'label', id: 'entrees-and-snacks', keywords: [] },
    { name: 'Full meals', category: 'food-served', type: 'label', id: 'full-meals', keywords: [] },
    { name: 'Vegan friendly', category: 'food-served', type: 'label', id: 'vegan-friendly', keywords: [] },
    { name: 'Vegetarian friendly', category: 'food-served', type: 'label', id: 'vegetarian-friendly', keywords: [] },
    
    { name: 'Just siting', category: 'physical-intensity', type: 'label', id: 'low-physical-intensity', keywords: [] },
    { name: 'Moving a bit', category: 'physical-intensity', type: 'label', id: 'moderate-physical-intensity', keywords: [] },
    { name: 'Intense', category: 'physical-intensity', type: 'label', id: 'high-physical-intensity', keywords: [] },
    
    { name: 'Quiet', category: 'noisiness', type: 'label', id: 'quiet', keywords: [] },
    { name: 'Talking friendly', category: 'noisiness', type: 'label', id: 'talking-friendly', keywords: [] },
    { name: 'Loud', category: 'noisiness', type: 'label', id: 'loud', keywords: [] },
    { name: 'Very loud', category: 'noisiness', type: 'label', id: 'very-loud', keywords: [] },
    
    { name: 'Causual', category: 'place-vibe', type: 'label', id: 'casual-vibe', keywords: [] },
    { name: 'Fancy', category: 'place-vibe', type: 'label', id: 'fancy-vibe', keywords: [] },
    { name: 'Quirky', category: 'place-vibe', type: 'label', id: 'quirky-vibe', keywords: [] },
    { name: 'Sporty', category: 'place-vibe', type: 'label', id: 'sporty-vibe', keywords: [] },
    
    { name: 'Food & Drinks', category: 'venue-type-category', type: 'label', id: 'food-venue', keywords: [] },
    { name: 'Outdoors & Sports', category: 'venue-type-category', type: 'label', id: 'outdoors-and-sports-venue', keywords: [] },
    { name: 'Cultural', category: 'venue-type-category', type: 'label', id: 'cultural-venue', keywords: [] },
    { name: 'Amusement', category: 'venue-type-category', type: 'label', id: 'amusement-venue', keywords: [] },
    { name: 'Virtual', category: 'venue-type-category', type: 'label', id: 'virtual-venue', keywords: [] },
    { name: 'Show', category: 'venue-type-category', type: 'label', id: 'show-venue', keywords: [] },
    
    { name: 'Cafe', category: 'food-venue-category', type: 'label', id: 'cafe', keywords: [] },
    { name: 'Restaurant', category: 'food-venue-category', type: 'label', id: 'restaurant', keywords: [] },
    { name: 'Bar', category: 'food-venue-category', type: 'label', id: 'bar', keywords: [] },
    
    { name: 'Park', category: 'outdoors-and-sports-venue-category', type: 'label', id: 'public-park', keywords: [] },
    { name: 'Ice rink', category: 'outdoors-and-sports-venue-category', type: 'label', id: 'ice-rink', keywords: [] },
    
    { name: 'Museum', category: 'cultural-venue-category', type: 'label', id: 'museum', keywords: [] },
    { name: 'Art gallery', category: 'cultural-venue-category', type: 'label', id: 'art-gallery', keywords: [] },
    { name: 'Zoo', category: 'cultural-venue-category', type: 'label', id: 'zoo', keywords: [] },
    
    { name: 'Amusement park', category: 'amusement-venue-category', type: 'label', id: 'amusement-park', keywords: [] },
    { name: 'Arcade', category: 'amusement-venue-category', type: 'label', id: 'arcade', keywords: [] },
    { name: 'Boardgame store', category: 'amusement-venue-category', type: 'label', id: 'boardgame-store', keywords: [] },
    { name: 'Bowling alley', category: 'amusement-venue-category', type: 'label', id: 'bowling-alley', keywords: [] },
    { name: 'Casino', category: 'amusement-venue-category', type: 'label', id: 'casino', keywords: [] },
    { name: 'Karaoke', category: 'amusement-venue-category', type: 'label', id: 'karaoke', keywords: [] },
    { name: 'Movie theater', category: 'amusement-venue-category', type: 'label', id: 'movie-theater', keywords: [] },
    { name: 'Night club', category: 'amusement-venue-category', type: 'label', id: 'night-club', keywords: [] },
    
    { name: 'Zoom', category: 'virtual-venue-category', type: 'label', id: 'zoom', keywords: [] },
    
    { name: 'Comedy club', category: 'show-venue-category', type: 'label', id: 'comedy-club', keywords: [] },
    { name: 'Stadium', category: 'show-venue-category', type: 'label', id: 'stadium', keywords: [] },
];

const [placesLabels, placesMasterRef] = prepareLabels(
    places_labels,
    places_labels_categories,
    places_labels_buckets
);

const invitations_labels_buckets = [
    { name: 'Who', type: 'bucket', id: 'who-bucket', keywords: [], categories: [], items: [] },
    { name: 'Where', type: 'bucket', id: 'where-bucket', keywords: [], categories: [], items: [] },
    { name: 'When', type: 'bucket', id: 'when-bucket', keywords: [], categories: [], items: [] },
];

const invitations_labels_categories = [
    { name: '', bucket: 'when-bucket', type: 'category', id: 'when-category', keywords: [], items: [],},
    { name: 'Travel Time', bucket: 'where-bucket', type: 'category', id: 'travel-time', keywords: [], items: [] },
    { name: '', bucket: 'who-bucket', type: 'category', id: 'who-category', keywords: [], items: [],},
];

const invitations_labels = [
    { name: 'Today', category: 'when-category', type: 'label', id: 'today', keywords: [], shiny: true,},
    { name: 'Sunday', category: 'when-category', type: 'label', id: 'sunday', keywords: [] },
    { name: 'Monday', category: 'when-category', type: 'label', id: 'monday', keywords: [] },
    { name: 'Tuesday', category: 'when-category', type: 'label', id: 'tuesday', keywords: [] },
    { name: 'Wednesday', category: 'when-category', type: 'label', id: 'wednesday', keywords: [] },

    { name: 'Thursday', category: 'when-category', type: 'label', id: 'thursday', keywords: [] },
    { name: 'Friday', category: 'when-category', type: 'label', id: 'friday', keywords: [] },
    { name: 'Saturday', category: 'when-category', type: 'label', id: 'saturday', keywords: [] },

    { name: '15 min <', category: 'travel-time', type: 'label', id: '15-min-or-less', keywords: [] },
    { name: '30 min <', category: 'travel-time', type: 'label', id: '30-min-or-less', keywords: [] },
    { name: '60 min <', category: 'travel-time', type: 'label', id: '60-min-or-less', keywords: [] },


    { name: 'Friends', category: 'who-category', type: 'label', id: 'who-friends', keywords: [] },
    { name: 'Acquaintances', category: 'who-category', type: 'label', id: 'who-acquaintances', keywords: [] },
    { name: 'Strangers', category: 'who-category', type: 'label', id: 'who-strangers', keywords: [] },

];

const [invitationsLabels, invitationsMasterRef] = prepareLabels(
    invitations_labels,
    invitations_labels_categories,
    invitations_labels_buckets
);

export const labels = {
    Invitations: [invitationsLabels, invitationsMasterRef],
    People: [peopleLabels, peopleMasterRef],
    Places: [placesLabels, placesMasterRef],
};
