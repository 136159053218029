import css from './BackgroundImage.module.css';

const BackgroundImage = (props) => {
    return (
        <div className={css['image-container']}>
            <div className='hide-on-desktop'>
                <img
                    className={css['background-image']}
                    src={require('../../../images/' + props.mobile)}
                    alt=''
                />
            </div>

            <div className='hide-on-mobile'>
                <img
                    className={css['background-image']}
                    src={require('../../../images/' + props.desktop)}
                    alt=''
                />
            </div>
        </div>
    );
};
export default BackgroundImage;
