import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import InvitationsBrowser from './InvitationsBrowser/InvitationsBrowser';
import InvitationEditor from './InvitationEditor/InvitationEditor';
import InvitationCard from './InvitationCard/InvitationCard';
import Confirmation from './Confirmation/Confirmation';
import Manager from '../Shared/Manager/Manager';

const Invitations = (props) => {
    const screen = useSelector((state) => state.conductor.screen);

    return (
        <Fragment>
            {screen === 'Confirmation' && <Confirmation />}
            {screen === 'InvitationsBrowser' && <InvitationsBrowser />}
            {screen === 'InvitationsManager' && <Manager product='Invitations' />}
            {screen === 'InvitationEditor' && <InvitationEditor />}
            {screen === 'InvitationCard' && <InvitationCard />}
        </Fragment>
    );
};

export default Invitations;
