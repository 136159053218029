import css from './VibeWall.module.css';
import { useSelector } from 'react-redux';
import PhotoCarousel from './PhotoCarousel/PhotoCarousel';
import { useEffect, useState, useRef } from 'react';

const VibeWall = (props) => {
    return (
        <div className={css['main-container']}>
            <Photo index={1} width='6' height='calc(100% * 4/12)' delay={1200} />

            <div className={css['photo-column']}>
                <Photo index={3} width='6' height='50%' delay={2600} />
                <Photo index={5} width='6' height='50%' delay={4000} />
            </div>

            <Photo index={0} width='12' height='calc(100% * 4/12)' delay={500} />

            <div className={css['photo-column']}>
                <Photo index={4} width='6' height='50%' delay={3300} />
                <Photo index={6} width='6' height='50%' delay={4700} />
            </div>

            <Photo index={2} width='6' height='calc(100% * 4/12)' delay={1900} />
        </div>
    );
};

const Photo = (props) => {
    const photos = useSelector((state) => state.drawer.infoBox['Photos'].photos);
    const firstPhoto = photos[0];

    const timeOutRef = useRef(null);
    const [render, setRender] = useState(false);

    useEffect(() => {
        setRender(false);
        timeOutRef.current = setTimeout(() => {
            setRender(true);
        }, props.delay);

        return () => {
            clearTimeout(timeOutRef.current);
        };
    }, [firstPhoto]);

    return (
        <div
            style={{
                border: '0.5px solid white',
                width: 'calc(var(--currentViewWidth) * ' + props.width + '/ 12)',
                height: props.height,
            }}
        >
            {render && <PhotoCarousel photos={photos} index={props.index} delay={props.delay} />}
        </div>
    );
};

export default VibeWall;
