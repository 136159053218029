import css from './OffSign.module.css';

const OffSign = (props) => {
    const text = 'taking a break';

    return (
        <div className={css['main-container']}>
            <div className={css['text-container']}>{text}</div>
        </div>
    );
};

export default OffSign;
