import css from './PersonEditor.module.css';
import Editor from '../../Shared/Editor/Editor';
import EditorHead from '../../Shared/Editor/EditorHead/EditorHead';
import PersonInput from './PersonInput/PersonInput';
import DrawerPreview from '../../Shared/Editor/DrawerPreview/DrawerPreview';
import { Fragment } from 'react';

import Card from '../../Cards/Card/Card';
import { useEffect } from 'react';
import { updateEditor } from '../../../store/editorSlice';
import { useDispatch, useSelector } from 'react-redux';
import { dummyPeopleThumbnails } from './peopleThumbnails';
import { cloneObject } from '../../../tools/data-processing';
import { initPerson } from '../../../Firebase/Firestore/usersFirestore';
import { generateId } from '../../../tools/generateId';
import { updateUser, updateInvitationsInElements, updateUserAndFirestore } from '../../../store/userSlice';
import { updateFirestoreDoc } from '../../../Firebase/Firestore/CRUD-functions';
import { createEventDoc } from '../../../Firebase/Firestore/eventsFirestore';
import BrowserButtons from '../../Shared/Browser/BrowserButtons/BrowserButtons';

const PersonEditor = (props) => {
    const dispatch = useDispatch();
    const person = useSelector((state) => state.editor.person);
    const manager = useSelector((state) => state.user.people.manager);
    const doc_id = useSelector((state) => state.user.meta.doc_id);
    const invitationsManager = useSelector((state) => state.user.invitations.manager);
    const isUser = person.meta.id && person.meta.type === 'user';

    useEffect(() => {
        updateEditor(dispatch, ['editing'], 'person');

        updateEditor(dispatch, ['drawer', 'content'], 'invitations');
        // updateEditor(dispatch, ['drawer', 'people'], invitationThumbnails );
        updateEditor(dispatch, ['drawer', 'thumbnails'], dummyPeopleThumbnails);
    }, []);

    return (
        <Editor product='People' onSave={handleSave}>
            <div className={css['main-container']}>
                {isUser ? (
                    <Card product='People' elementId={person.meta.user_doc_id} />
                ) : (
                    <Fragment>
                        <EditorHead product='People' elementType='person' />
                        <div className={css['inputs-container']}>
                            <PersonInput
                                field='phone'
                                icon='phone_iphone'
                                placeholder='Phone number'
                                type='tel'
                            />
                            <PersonInput
                                field='email'
                                icon='email'
                                placeholder='Email address'
                                type='email'
                            />
                        </div>
                    </Fragment>
                )}
                <DrawerPreview product='Invitations' elementIds={person.invitations} />
            </div>
            {isUser && (
                <BrowserButtons>
                    <DrawerPreview product='Invitations' elementIds={person.invitations} />
                </BrowserButtons>
            )}
        </Editor>
    );

    function handleSave() {
        const payload = cloneObject(person);
        let newPerson = false;

        const updates = [];

        let personId = payload.meta.id;
        const oldPerson = personId ? manager[personId] : initPerson();

        if (!personId) {
            personId = generateId(5, payload.name);
            payload.meta.id = personId;
            payload.meta.type = 'guest';
            newPerson = true;
        }

        const pathArray = ['people', 'manager', personId];
        updateUser(dispatch, pathArray, payload);

        if (newPerson) {
            createGuest();
            return;
        } else {
            prepareUpdates(oldPerson, payload, 'invitations');

            const firePath = pathArray.join('.');
            updateFirestoreDoc('users', doc_id, { [firePath]: payload });

            updateInvitationsInElements(dispatch, updates);
        }

        function prepareUpdates(oldElement, newElement, drawerCollection) {
            const { added, removed } = compareArrays(
                oldElement[drawerCollection],
                newElement[drawerCollection]
            );

            added.forEach((a) => {
                updates.push([drawerCollection, a, 'people', personId, 'added']);
            });

            removed.forEach((r) => {
                updates.push([drawerCollection, r, 'people', personId, 'removed']);
            });

            function compareArrays(before, after) {
                const added = after.filter((item) => !before.includes(item));
                const removed = before.filter((item) => !after.includes(item));
                return { added, removed };
            }
        }

        function createGuest() {
            const event_doc_id = doc_id + '_' + personId
            
            const eventInfo = {
                'meta.event_doc_id': event_doc_id,
                'host.user_doc_id': doc_id,
                'guest.type': 'guest',
                'guest.user_doc_id': null,
                'guest.manager_id': personId,
            };

            const newElementIds = [personId, ...manager.element_ids];
            const updatePayload = { 'people.manager.element_ids': newElementIds };
            updateUser(dispatch, ['people', 'manager', 'element_ids'], newElementIds);

            payload.invitations.forEach((invitationId) => {
                const newPeopleArray = [personId, ...invitationsManager[invitationId].people];
                updateUser(
                    dispatch,
                    ['invitations', 'manager', invitationId, 'people'],
                    newPeopleArray
                );

                updatePayload[['invitations.manager.' + invitationId + '.people']] = newPeopleArray;
            });

            const card_id = personId.split('_')[1]
            updateUserAndFirestore(dispatch,['cards', 'urls', card_id], {type:'invitation', event_doc_id})

            createEventDoc(eventInfo, payload, updatePayload);
        }
    }
};

export default PersonEditor;
