import css from './EmptyInvitationsInbox.module.css';
import { useSelector } from 'react-redux';
const EmptyInvitationsInbox = (props) => {
    const screen = useSelector((state) => state.conductor.screen);

    const height =
        screen !== 'InvitationCard'
            ? 'calc(var(--currentViewHeight) - var(--top-bar-height))'
            : 'var(--currentViewHeight)';

    return (
        <div className={css['main-container']} style={{height}}>
            <img
                className={css['postman-image']}
                alt=''
                src={
                    'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/app%2Fpostman-hd.jpeg?alt=media&token=32ceea31-2148-4b72-a912-9d1a3b277835'
                }
            />

            <div className={css['text-container']}>
                {screen !== 'InvitationCard'
                    ? `You haven't received any invitations yet. We'll notify you as soon as you do!`
                    : `This invitation is no longer open. We'll notify you if it opens again.`}
            </div>
        </div>
    );
};

export default EmptyInvitationsInbox;
