import css from './EditorHead.module.css';

import TitleInput from './TitleInput/TitleInput';
import TitleThumbnail from './TitleThumbnail/TitleThumbnail';
import TitleToggleSwitch from './TitleToggleSwitch/TitleToggleSwitch';



const EditorHead = (props) => {
    return (
        <div className={css['main-container']}>
            <div className={css['title-container']}>
                <TitleInput product={props.product} elementType={props.elementType} />
                <TitleToggleSwitch
                    product={props.product}
                    elementType={props.elementType}
                />
            </div>

            <TitleThumbnail product={props.product} elementType={props.elementType} />
        </div>
    );
};

export default EditorHead;
