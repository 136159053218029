import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { resetAction } from '../../../../store/browserSlice';

import RestaurantMenu from '../../../Places/PlacesBrowser/RestaurantMenu/RestaurantMenu';

const Actions = (props) => {
    const dispatch = useDispatch();

    const action = useSelector((state) => state.browser.action);
    const trigger = action.trigger;

    useEffect(() => {
        if (trigger) {
            resetAction(dispatch);
        }
    }, [trigger]);

    if (!trigger) {
        return <></>;
    }

    const payload = action.payload;
    const Component = components[payload.component];

    return <Component content={payload} />;
};

const components = {
    RestaurantMenu: RestaurantMenu,
};

export default Actions;
