import css from './ApplicationSubmitted.module.css';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import LearnAboutUs from '../LearnAboutUs/LearnAboutUs';
import { updateAudio, blinkAudio } from '../../../store/audioSlice';

const ApplicationSubmitted = (props) => {
    const dispatch = useDispatch();

    useEffect(()=>{
        updateAudio(dispatch, ['music', 'song'], 'thank-you-for-applying');
        blinkAudio(dispatch, ['music', 'action'], 'play');
    },[])

    return (
        <div className={css['main-container']}>
            <div className={css['application-title']}>Thank you!</div>

            <div className={css['application-message']}>
                <b>Application submitted.</b> We'll be in touch!
            </div>

            <div className={css['image-container']}>
                <img
                    className={css['image']}
                    src={
                        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/app%2Fyou-are-awesome.gif?alt=media&token=4cf51732-2fa8-42ce-8e38-3d09281155ea'
                    }
                    alt=''
                />
            </div>

            <LearnAboutUs />
        </div>
    );
};

export default ApplicationSubmitted;
