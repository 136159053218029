import css from './DrawerThumnail.module.css';

import NameTag from '../../Manager/Thumbnail/NameTag/NameTag';

import { useSelector, useDispatch } from 'react-redux';
import { updateInvitation } from '../../../../store/userSlice';
import { queueNotification } from '../../../../store/notificationSlice';

const DrawerThumbnail = (props) => {
    const dispatch = useDispatch();
    const product = props.product;
    const query = useSelector((state) => state.manager.query);

    const imgSrc = getImgSrc(props.image);
    const inQuery = props.keywords.includes(query) ? 'block' : 'none';

    const browser = useSelector((state) => state.browser);
    const browserProduct = browser.product;
    const elementId =
        browserProduct === 'Places' ? browser.places.activeId : browser.elements.activeId;

    const element = useSelector((state) => state.browser.elements.masterRef[elementId]);
    const elementType = browserProduct ? browserProduct.toLowerCase() : undefined;
    const manager = useSelector((state) => state.user.invitations.manager);
    const targetArray = manager[props.id][elementType];
    const isOn = targetArray && targetArray.includes(elementId);
    const screen = useSelector((state) => state.conductor.screen);

    return (
        <div className={css['outer-container']} style={{ display: inQuery }} onClick={handleClick}>
            <NameTag product={product} name={props.name} />
            <div className={`${css['main-container']} ${product}`}>
                <img
                    className={`${css['thumbnail-image']} ${isOn ? '' : css['item-off']}`}
                    src={imgSrc}
                    alt=''
                />
            </div>
        </div>
    );

    function handleClick() {
        const payload = {
            action: 'addElement',
            targetInvitation: props.id,
            elementType,
            elementId: elementId,
            element,
        };

        if (isOn) {
            payload.action = 'removeElement';
        }
        updateInvitation(dispatch, payload);
        prepareNotification();

        function prepareNotification() {
            let count = payload.action === 'addElement' ? 1 : -1;
            let notification = null;

            const notificaions = {
                PeopleBrowser: 'InvitationSent',
                InvitationsBrowser: 'InvitationSent',
                PlacesBrowser: 'PlaceAdded',
            };

            manager.element_ids.forEach((id) => {
                const targetArray = manager[id][elementType];
                if (targetArray.includes(elementId)) {
                    count += 1;
                }
            });

            if (count > 0) {
                notification = notificaions[screen];
            }

            queueNotification(dispatch, notification);
        }
    }

    function getImgSrc(image) {
        if (image.slice(0, 4) === 'http') {
            return image;
        }
        return require('../../../../images/thumbnails/' + image);
    }
};

export default DrawerThumbnail;
