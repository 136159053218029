import { Fragment } from 'react';
import css from './Editor.module.css';

import EditorTopBar from './EditorTopBar/EditorTopBar';
import EditorDrawer from './EditorDrawer/EditorDrawer';

const Editor = (props) => {
    return (
        <Fragment>
            <EditorTopBar product={props.product} onSave={props.onSave}/>
            <div className={css['main-container']}>
                <div className={css['children-container']}>{props.children}</div>
            </div>
            <EditorDrawer />
        </Fragment>
    );
};

export default Editor;
