import css from './LoadingScreen.module.css';
import Loading from '../../Shared/Browser/EmptyBrowser/Loading/Loading';
import { ShinyLogo } from '../../Menu/MenuTopBar/MenuTopBar';


const LoadingScreen = (props) => {
    return (
        <div className={css['main-container']}>
            <div className={css['inner-container']}>
                <ShinyLogo size='menu' top='5px' left='16px'/>
            </div>
            <Loading />
        </div>
    );
};

export default LoadingScreen;
