import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import EventCard from './EventCard/EventCard';
import EventCanceled from './EventCard/EventCanceled/EventCanceled';

const Events = (props) => {
    const screen = useSelector((state) => state.conductor.screen);

    return (
        <Fragment>
            {screen === 'EventCard' && <EventCard />}
            {screen === 'EventCanceled' && <EventCanceled />}
        </Fragment>
    );
};

export default Events;
