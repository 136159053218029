import { createSlice } from '@reduxjs/toolkit';
import { updateSlice, updateSliceKeys } from './update-slice';

const initialState = {
    product: 'Home',
    screen: 'Landing',
    previous_product: 'Home',
    previous_screen: 'Landing',
    override: false,
    overrideTo: {
        product: 'Home',
        screen: 'Landing',
    },

    curtain: false,

    modalDialog: {
        show: false,
        template: null,
        content: {},
    },

    adminMode: false,
};

const conductorSlice = createSlice({
    name: 'conductor',
    initialState,
    reducers: {
        updateConductor(state, action) {
            updateSlice(state, action);
        },

        updateConductorKeys(state, action) {
            updateSliceKeys(state, action);
        },

        changeScreen(state, action) {
            state.previous_product = state.product;
            state.previous_screen = state.screen;

            if (!state.override) {
                state.product = action.payload.targetProduct;
                state.screen = action.payload.targetScreen;
            } else {
                state.product = state.overrideTo.product;
                state.screen = state.overrideTo.screen;
            }
        },

        goToPreviousScreen(state, action) {
            state.product = state.previous_product;
            state.screen = state.previous_screen;
        },

        setOverride(state, action) {
            state.override = action.payload.override;
            state.overrideTo.product = action.payload.product;
            state.overrideTo.screen = action.payload.screen;
        },

        toggleCurtain(state, action) {
            state.curtain = !state.curtain;
            // state.curtain = action.payload.curtainState;
        },
    },
});

export const conductor = conductorSlice.actions;

export const conductorReducer = conductorSlice.reducer;

export function updateConductor(dispatch, path, newValue) {
    dispatch(conductor.updateConductor({ path, newValue }));
}

export function updateConductorKeys(dispatch, path, payload) {
    dispatch(conductor.updateConductorKeys({ path, payload }));
}

export function goTo(dispatch, product, screen) {
    dispatch(
        conductor.changeScreen({
            targetScreen: screen,
            targetProduct: product,
        })
    );
}

export function goBack(dispatch) {
    dispatch(conductor.goToPreviousScreen());
}

export function setOverride(dispatch, override, product, screen) {
    dispatch(conductor.setOverride({ override, product, screen }));
}

export function toggleCurtain(dispatch, curtainState) {
    dispatch(conductor.toggleCurtain({ curtainState }));
}
