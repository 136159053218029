import { dayTimes, weekDays } from '../tools/timeConstants';
import { updateSlice, updateSliceKeys } from './update-slice';
import { createSlice } from '@reduxjs/toolkit';
import { initInvitation, initPerson, initPlace } from '../Firebase/Firestore/usersFirestore';

const initialState = {
    editing: 'invitation',

    drawer: {
        content: 'people',
        thumbnails: { product: null, elementIds: [] },
    },

    invitation: initInvitation(null, null, null),

    timeSelect: {
        day: '',
        hour: '',
        isMouseDown: false,
        isMouseIn: false,
        blocks: createStartingTimeBlocks(),
    },

    place: initPlace(),
    person: initPerson(),

    placeDoc: {},
};

export function createStartingTimeBlocks() {
    const timeBlocks = {};
    for (const day of weekDays) {
        for (const time of dayTimes) {
            timeBlocks[day + time[1]] = false;
            timeBlocks[day + time[2]] = false;
        }
    }
    return timeBlocks;
}

const editorSlice = createSlice({
    name: 'editor',
    initialState,
    reducers: {
        updateEditor(state, action) {
            updateSlice(state, action);
        },

        updateEditorKeys(state, action) {
            updateSliceKeys(state, action);
        },

        handleTimeBlockHover(state, action) {
            const targetBlock = action.payload.targetBlock;
            const timeSelect = state.timeSelect;

            timeSelect.day = targetBlock.slice(0, 3);
            timeSelect.hour = targetBlock.slice(3, 7);

            if (timeSelect.isMouseDown) {
                timeSelect.blocks[targetBlock] = !timeSelect.blocks[targetBlock];
            }
        },

        updateTimeBlock(state, action) {
            state.timeSelect.blocks[action.payload.targetBlock] =
                !state.timeSelect.blocks[action.payload.targetBlock];
        },
    },
});

const actions = editorSlice.actions;
export const editorReducer = editorSlice.reducer;

export function updateEditor(dispatch, path, newValue) {
    dispatch(actions.updateEditor({ path, newValue }));
}

export function updateEditorKeys(dispatch, path, payload) {
    dispatch(actions.updateEditorKeys({ path, payload }));
}

export function updateTimeBlock(dispatch, targetBlock) {
    dispatch(actions.updateTimeBlock({ targetBlock }));
}

export function handleTimeBlockHover(dispatch, targetBlock) {
    dispatch(actions.handleTimeBlockHover({ targetBlock }));
}
