import css from './ManagerTopBar.module.css';

import TopBar from '../../TopBar/TopBar';
import TopBarButton from '../../TopBar/TopBarButton/TopBarButton';
import TopBarTitle from '../../TopBar/TopBarTitle/TopBarTitle';

import { useDispatch } from 'react-redux';
import { goTo, toggleCurtain } from '../../../../store/conductorSlice';
import { openSearchBar } from '../../../../store/managerSlice';

import { translate } from '../../../../tools/translator';

const ManagerTopBar = (props) => {
    const dispatch = useDispatch();
    const product = props.product;

    function handleGoBack() {
        toggleCurtain(dispatch, false);
        setTimeout(() => {
            goTo(dispatch, 'Menu', 'Menu');
        }, 500);
    }

    function goToBrowser() {
        toggleCurtain(dispatch, true);
        setTimeout(() => {
            goTo(dispatch, product, product + 'Browser');
        }, 500);
    }

    const title = translate(props.product, 'product', 'displayName');

    return (
        <TopBar product={props.product} backArrow>
            <TopBarButton
                onClick={handleGoBack}
                width='5%'
                iconProps={{
                    icon: 'chevron_left',
                    fontSize: '40px',
                    marginLeft: '-5px',
                }}
            />

            <TopBarTitle paddingLeft='24px' width='50%' title={title} />

            <div className={css['options-container']}>
                <TopBarButton
                    onClick={goToBrowser}
                    paddingLeft='20px'
                    iconProps={{
                        icon: 'view_carousel',
                        fontSize: '30px',
                    }}
                />

                <TopBarButton
                    onClick={() => {
                        openSearchBar(dispatch);
                    }}
                    paddingLeft='60px'
                    iconProps={{
                        icon: 'search',
                        fontSize: '30px',
                    }}
                />
            </div>
        </TopBar>
    );
};

export default ManagerTopBar;
