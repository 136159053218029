import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openDrawer, loadWebsite } from '../../../../../store/drawerSlice';
import { updateTopic } from '../../../../../store/browserSlice';

const Website = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        loadWebsite(dispatch, props.content.url);
        openDrawer(dispatch, 'website');
        updateTopic(dispatch, 'empty_stage');
    }, []);

    return <></>;
};

export default Website;
