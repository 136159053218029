import { updateSlice, updateSliceKeys } from './update-slice';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    invitations: {
        ready: false,
        elements: { elementIds: [] },
    },

    people: {
        ready: false,
        elements: { elementIds: [] },
    },

    places: {
        ready: false,
        elements: { elementIds: [] },
    },

    events: {
        ready: false,
        elements: {
            elementIds: [],
            dates: { 
                // '2023-05-18': true, 
                // '2023-05-30': true, 
                // '2023-06-02': true 
            },
        },
    },
};

const firestoreSlice = createSlice({
    name: 'firestore',
    initialState,
    reducers: {
        updateFirestore(state, action) {
            updateSlice(state, action);
        },

        updateFirestoreKeys(state, action) {
            updateSliceKeys(state, action);
        },
    },
});

const actions = firestoreSlice.actions;
export const firestoreReducer = firestoreSlice.reducer;

export function updateFirestore(dispatch, path, newValue) {
    dispatch(actions.updateFirestore({ path, newValue }));
}

export function updateFirestoreKeys(dispatch, path, payload) {
    dispatch(actions.updateFirestoreKeys({ path, payload }));
}
