import css from './Topics.module.css';

import TopicCard from './TopicCard/TopicCard';
import Sections from './Sections/Sections';
import TopicsCurtain from './TopicsCurtain/TopicsCurtain';

import { useSelector } from 'react-redux';
import { sortTopics } from './sortTopics';

import { cloneObject } from '../../../../tools/data-processing';
import { sections } from './Sections/Sections';

const Topics = (props) => {
    const product = useSelector((state) => state.browser.product);
    const topics = useSelector((state) => state.browser.topics);
    const stage = useSelector((state) => state.browser.stage);
    const cards = useSelector((state) => state.conductor.product) === 'Cards';

    const activeTopicId = topics.active[topics.active.section];
    const showSections = topics.sections.length > 1;
    const activeTheme = (topics.active.section && sections[topics.active.section].theme) || product;

    const sortedTopics = activeTopicId ? sortTopics(getSectionTopics(), activeTheme) : [];

    const screen = useSelector((state) => state.conductor.screen);
    let activeTopicsHeight =
        screen.includes('Browser') || screen.includes('Editor')
            ? 'var(--topics-height)'
            : 'var(--cards-topics-height)';
    if (screen === 'InvitationCard') {
        activeTopicsHeight = 'var(--invitation-card-topics-height)';
    }

    return (
        <div
            className={css['outer-container']}
            style={{
                '--close-speed': stage.speed[0] + 'ms',
                '--open-speed': stage.speed[1] + 'ms',
                '--active-topics-height': activeTopicsHeight,
            }}
        >
            <div className={`${css['main-container']} ${activeTheme} ${css[stage.topics]} `}>
                {showSections && <Sections css={props.css} />}
                <div
                    className={`${css['topics-holder']} ${css[props.css]} ${
                        showSections && css['with-bar']
                    }`}
                >
                    {sortedTopics.map((topic) => (
                        <TopicCard key={topic.id} topic={topic} />
                    ))}
                </div>
                <TopicsCurtain />
            </div>
        </div>
    );

    function getSectionTopics() {
        const allTopics = topics.masterRef;
        const sectionIds = topics.masterRef[topics.active.section].topics;

        const result = [];
        sectionIds.forEach((topicId) => {
            const topic = cloneObject(allTopics[topicId]);
            if (cards) {
                topic.shared = false;
            }

            result.push(topic);
        });
        return result;
    }
};

export default Topics;
