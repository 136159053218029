import './TimeBlock.css';
import { useSelector, useDispatch } from 'react-redux';
import { handleTimeBlockHover } from '../../../../../store/editorSlice';

const TimeBlock = (props) => {
    const dispatch = useDispatch();
    const isSelected = useSelector((state) => state.editor.timeSelect.blocks[props.id]);

    const isOn = useSelector((state) => state.editor.invitation.isOpen);

    let css = 'time-block';
    if (props.isHalfHour) {
        css += ' half-hour';
    }
    if (isSelected) {
        css += ' selected';
    }

    if(!isOn){
        css += ' closed'
    }


    function handleMouseOver(event) {
        handleTimeBlockHover(dispatch, event.target.id);
    }

    return <div id={props.id} className={css} onMouseOver={handleMouseOver}></div>;
};

export default TimeBlock;
