import css from './TitleToggleSwitch.module.css';

import { updateEditor } from '../../../../../store/editorSlice';
import { useSelector, useDispatch } from 'react-redux';

const TitleToggleSwitch = (props) => {
    const dispatch = useDispatch()
    const isOn = useSelector(state => state.editor[props.elementType].isOpen)
    
    function handleToggle(event) {
        const newIsOn = !isOn;
        updateEditor(dispatch, [props.elementType, 'isOpen'], newIsOn)
    }

    return (
        <div className={css['main-container']}>
            <div className={css['switch-container']}>
                <label className={`${css['switch']} ${props.product}`}>
                    <input type='checkbox' onChange={handleToggle} checked={!isOn} />

                    <span className={`${css['slider']} ${css['round']} ${props.product}`}></span>
                </label>
            </div>
            <div className={`${css['text-container']}`}>
                {isOn ? <div className={`${css['open']} ${props.product}`}>Open!</div> : <div className={css['closed']}>Closed</div>}
                </div>

        </div>
    );
};

export default TitleToggleSwitch;
