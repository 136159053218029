import css from './ConfirmButton.module.css';

const ConfirmButton = (props) => {
    return (
        <div className={css['actions']} style={{marginTop:props.marginTop}}>
            <div className={props.border && css['outer-border']}>
                <button
                    className={`${css['confirm']} ${props.product}`}
                    disabled={!props.validInputs}
                    onClick={props.handleConfirm}
                >
                    {props.confirmText}
                </button>
            </div>
        </div>
    );
};

export default ConfirmButton;
