import { Fragment } from 'react';
import BackgroundImage from '../Shared/BackgroundImage/BackgroundImage';

import Landing from './Landing/Landing';
import SignIn from './SignIn/SignIn';
import SignUp from './SignIn/SignUp';

import PasswordReset from './SignIn/PasswordReset';
import AccountNotFound from './SignIn/AccountNotFound/AccountNotFound';

import Admissions from './Admissions/Admissions';
import Application from './Application/Application';
import ApplicationSubmitted from './ApplicationSubmitted/ApplicationSubmitted';
import LoadingScreen from './LoadingScreen/LoadingScreen';

import { useSelector } from 'react-redux';
import About from './Landing/About/About';


const Home = (props) => {
    const screen = useSelector((state) => state.conductor.screen);

    return (
        <Fragment>
            <BackgroundImage mobile='kins-island-mobile.jpg' desktop='kins-island-mobile.jpg' />
            {screen === 'Landing' && <Landing />}
            {screen === 'SignIn' && <SignIn />}
            {screen === 'PasswordReset' && <PasswordReset />}
            {screen === 'SignUp' && <SignUp />}
            {screen === 'About' && <About />}
            {screen === 'Admissions' && <Admissions />}
            {screen === 'Application' && <Application />}
            {screen === 'ApplicationSubmitted' && <ApplicationSubmitted />}

            {screen === 'Loading' && <LoadingScreen />}
            {screen === 'AccountNotFound' && <AccountNotFound />}
        </Fragment>
    );
};

export default Home;
