import Browser from '../../Shared/Browser/Browser';
import BrowserButtons from '../../Shared/Browser/BrowserButtons/BrowserButtons';
import BrowserButton from '../../Shared/Browser/BrowserButtons/BrowserButton/BrowserButton';
import BrowserTopBar from '../../Shared/Browser/BrowserTopBar/BrowserTopBar';

import { useSelector, useDispatch } from 'react-redux';
import { updateBrowserKeys } from '../../../store/browserSlice';
import { Fragment, useEffect } from 'react';
import { loadDrawer } from '../../../store/drawerSlice';
import { placesTags, placesTagsMasterRef } from './placesTags';

const PlacesBrowser = (props) => {
    const dispatch = useDispatch();

    const places = useSelector((state) => state.firestore.places);
    const ready = places.ready;

    useEffect(() => {
        if (ready) {
            const payload = {
                activeId: places.elements.elementIds[0],
                queue: places.elements.elementIds,
                position: 0,
            };

            updateBrowserKeys(dispatch, ['places'], payload);
            loadDrawer(dispatch, 'Places', placesTags, placesTagsMasterRef, 'topics');
        }
    }, [ready]);

    return (
        <Fragment>
            <BrowserTopBar product='Places' />
            <Browser
                elements={places.elements}
                elementIds={places.elements.elementIds}
                product='Places'
                useTimer={false}
                topicsCss='neutral-topics'
            >
                <Buttons />
            </Browser>
        </Fragment>
    );
};

const Buttons = (props) => {
    return (
        <BrowserButtons>
            <BrowserButton icon='reply' action='showPrevious' iconStyle={{ marginTop: '-5px' }} />
            <BrowserButton product='GoodTimes' size='large' text='Add' action='toggleDrawer' />
            <BrowserButton
                icon='reply'
                action='showNext'
                flip
                disabled={true}
                iconStyle={{ marginTop: '-5px' }}
            />
        </BrowserButtons>
    );
};

export default PlacesBrowser;
