import { onAuthStateChanged } from 'firebase/auth';
import { getAuth, signOut, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getDestination } from '../tools/router';
import { getOrCreateApplication } from './Firestore/applicationsFirestore';
import { callCloudFunction } from './CloudFunctions';
import { updateFirestoreKeys } from '../store/firestoreSlice';
import { updateUserKeys } from '../store/userSlice';
import { initUser } from './Firestore/usersFirestore';

import { goTo, setOverride, updateConductor } from '../store/conductorSlice';
import { getOrCreateUser } from './Firestore/usersFirestore';
import { processPlacesBrowserData } from '../components/Places/PlacesBrowser/processPlacesBrowserData';
import { unpackInvitations } from '../components/Invitations/InvitationsBrowser/unpackInvitations';
import { unpackEvents } from '../components/Events/EventsDrawer/unpackEvents';

import { app } from './Firebase';
export const auth = getAuth(app);

export async function signInWithGoogle() {
    const googleAuth = new GoogleAuthProvider();

    signInWithPopup(auth, googleAuth)
        .then((result) => {
            // console.log(result.user.uid, result.user.email);
        })
        .catch((error) => console.log(error));
}

export function handleSignOut() {
    signOut(auth)
        .then(() => {
            // Sign-out successful.
            console.log('Sign out succesfull!');
        })
        .catch((error) => {
            // An error happened.
        });
}

export async function handleAuthStateChange(currentURL, navigate, dispatch) {
    onAuthStateChanged(auth, async (currentUser) => {
        const destination = await getDestination(currentURL, navigate, dispatch);
        // console.log('DESTINATION: ', destination)

        if (currentUser) {
            if (auth.currentUser.email === 'dave@kins.ai') {
                updateConductor(dispatch, ['adminMode'], true);
            }

            const applicationData = await getOrCreateApplication(currentUser);

            if (['InvitationCard', 'EventCard'].includes(destination.screen)) {
                goTo(dispatch, destination.product, destination.screen);
                return;
            }

            if (applicationData.status === 'accepted') {
                goTo(dispatch, 'Home', 'Loading');

                const userData = await getOrCreateUser(currentUser, applicationData);
                updateUserKeys(dispatch, [], userData);

                if (destination.product === 'Home') {
                    destination.product = 'Menu';
                    destination.screen = 'Menu';
                }

                const browserData = await callCloudFunction('getBrowserData', {
                    user_id: currentUser.uid,
                });

                const places = processPlacesBrowserData(browserData.places, userData);
                const people = browserData.people;
                const invitations = unpackInvitations(browserData.invitations, people, places);
                const events = unpackEvents(browserData.events);
                updateFirestoreKeys(dispatch, [], {
                    people: { ready: true, elements: people },
                    places: { ready: true, elements: places },
                    invitations: { ready: true, elements: invitations },
                    events: { ready: true, elements: events },
                });
            } else {
                setOverride(dispatch, true, 'Home', 'AccountNotFound');
                handleSignOut();
            }
        } else {
            updateUserKeys(dispatch, [], initUser());
            console.log('There is NO current user!');
        }

        goTo(dispatch, destination.product, destination.screen);
    });
}
