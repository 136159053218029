import css from './StageCurtain.module.css';

import { useSelector, useDispatch } from 'react-redux';
import { updateBrowser } from '../../../../../store/browserSlice';

const StageCurtain = (props) => {
    const dispatch = useDispatch();

    const screen = useSelector((state) => state.conductor.screen);
    const stage = useSelector((state) => state.browser.stage);

    function handleClick() {
        console.log(screen);

        updateBrowser(dispatch, 'stage', { curtain: 'open', topics: 'open' });
        updateBrowser(dispatch, 'player', { zIndex: 1 });
        updateBrowser(dispatch, 'manager', {
            trigger: null,
            lastTrigger: null,
            step: null,
            endPoint: 'PlayerPlaying',
        });
    }
    
    return (
        <div className={css['curtain-hider']}>
            <div className={`${css['stage-curtain']} ${css[stage.curtain]}`} onClick={handleClick}>
                <div className={css['stage-shadow']}></div>
                <img
                    className={css['curtain-image']}
                    src={require('../../../../../images/wide-red-curtain.jpg')}
                    alt=''
                />
                <div className={css['text-container']}>
                    <div className={css['super-title']}>This is</div>
                    <div className={css['main-title']}>{stage.name}</div>
                </div>
                <div className={css['bottom-stage-shadow']}></div>
            </div>
        </div>
    );
};

export default StageCurtain;
