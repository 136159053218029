import css from './AvailabilityToggleSwitch.module.css';

import { updateUserAndFirestore } from '../../../../../store/userSlice';
import { useSelector, useDispatch } from 'react-redux';

const AvailabilityToggleSwitch = (props) => {
    const dispatch = useDispatch()
    const isOn = useSelector(state => state.user.availability.isOpen)
    
    function handleToggle(event) {
        const newIsOn = !isOn;
        updateUserAndFirestore(dispatch, ['availability', 'isOpen'], newIsOn)
    }

    const drawerState = useSelector(state => state.browser.bottomDrawer);
    const display = drawerState === 'visible' ? 'flex' : 'none';

    return (
        <div className={css['main-container']} style={{display:display}}>
            <div className={css['switch-container']}>
                <label className={`${css['switch']} ${'Invitations'}`}>
                    <input type='checkbox' onChange={handleToggle} checked={!isOn} />

                    <span className={`${css['slider']} ${css['round']} ${'Invitations'}`}></span>
                </label>
            </div>
            <div className={`${css['text-container']}`}>
                {isOn ? <div className={`${css['open']} ${'Invitations'}`}>Open!</div> : <div className={css['closed']}>Closed</div>}
                </div>

        </div>
    );
};

export default AvailabilityToggleSwitch;
