import './DaysRuler.css';
import { weekDays } from '../../../../../tools/timeConstants';

const DaysRuler = (props) => {
    return (
        <div className='days-ruler'>
            <div className=''></div>
            <div className=''></div>
            {weekDays.map((day) => (
                <div className='day' key={day}>
                    <span
                        className={`day-content ${
                            props.isMouseIn &&
                            props.mouseDay === day &&
                            'mouseDay'
                        }`}
                    >
                        {day}
                    </span>
                </div>
            ))}

            <div className=''></div>
            <div className=''></div>
            <div className='day-buffer'></div>
            <div className='day-buffer'></div>
            <div className='day-buffer'></div>
            <div className='day-buffer'></div>
            <div className='day-buffer'></div>
            <div className='day-buffer'></div>
            <div className='day-buffer'></div>
        </div>
    );
};

export default DaysRuler;
