import { createSlice } from '@reduxjs/toolkit';
import { months } from '../tools/timeConstants';

function getTodaysYearMonth() {
    const todaysDate = new Date();
    const year = todaysDate.getFullYear();
    const month_number = todaysDate.getMonth();
    const month = months[month_number];
    const yearMonth = year + '-' + month;
    return yearMonth;
}

const initialState = {
    yearMonth: getTodaysYearMonth(),
    availability: {},
};

const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        updateYearMonth(state, action) {
            const newYearMonth = action.payload.newYearMonth;
            state.yearMonth = newYearMonth;
        },

        loadAvailability(state, action) {
            const availability = action.payload.availability;
            state.availability = availability;
        },

        updateAvailability(state, action){
            const targetDate = action.payload.targetDate;
            const isAvailable = action.payload.isAvailable;
            state.availability[targetDate] = isAvailable;
        }
    },
});

export const calendarReducer = calendarSlice.reducer;

const calendar = calendarSlice.actions;

export function updateYearMonth(dispatch, newYearMonth) {
    dispatch(calendar.updateYearMonth({ newYearMonth }));
}

export function loadAvailability(dispatch, availability) {
    dispatch(calendar.loadAvailability({ availability }));
}

export function updateAvailability(dispatch, targetDate, isAvailable){
    dispatch(calendar.updateAvailability({targetDate, isAvailable}))
}