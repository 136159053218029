import css from './StatusMessage.module.css';
import { useSelector } from 'react-redux';
import { timeNames } from '../../../../tools/timeConstants';
import { parseDate } from '../../../../tools/functions/datesFunctions';

const StatusMessage = (props) => {
    const peopleFirestore = useSelector((state) => state.firestore.people.elements);
    const peopleManager = useSelector((state) => state.user.people.manager);
    const who = props.event.who;
    const person = who.type === 'user' ? peopleFirestore[who.id].name : peopleManager[who.id].name;
    const place = useSelector((state) => state.firestore.places.elements[props.event.where].name);

    const dateDetails = parseDate(props.event.when.date, props.event.when.time);
    const time = timeNames[props.event.when.time];

    return (
        <HappeningMessage person={person} place={place} weekday={dateDetails.weekday} time={time} />
    );
};

const HappeningMessage = (props) => {
    return (
        <div className={css['main-container']}>
            <div className={css['message-line']}>
                <span className={css['event-element']}>{props.person}</span> will be meeting you at{' '}
                <span className={css['event-element']}>{props.place}</span> {props.weekday !== 'Today' && props.weekday !== 'Tonight' && ' on '}
                <span className={css['event-element']}>{props.weekday}</span> at <span className={css['event-element']}>{props.time}</span>.
            </div>
            <br/>
            <div className={css['message-line']}> We'll let you know if anything changes.</div>
        </div>
    );
};

export default StatusMessage;
