import css from './TitleThumbnail.module.css';
import OffSign from '../../../Manager/Thumbnail/OffSign/OffSign';
import { useSelector, useDispatch } from 'react-redux';
import { updateEditor } from '../../../../../store/editorSlice';
import { toggleDrawer } from '../../../../../store/browserSlice';
import Icon from '../../../Icon/Icon';
import { translate } from '../../../../../tools/translator';

const TitleThumbnail = (props) => {
    const dispatch = useDispatch();
    const thumbnails = useSelector((state) => state.editor.drawer.thumbnails);
    const product = props.product;

    const imgSrc = useSelector((state) => state.editor[props.elementType].thumbnail);
    const isOn = useSelector(state => state.editor[props.elementType].isOpen);

    function handleClick() {
        if(thumbnails.elementIds.length === 0){
            return
        }

        updateEditor(dispatch, ['drawer', 'content'], 'thumbnails');
        toggleDrawer(dispatch, 'visible');
    }

    return (
        <div className={css['outer-container']} onClick={handleClick}>
            <div className={`${css['main-container']} ${product}`}>
                {!isOn && <OffSign />}
                {imgSrc && (
                    <img
                        className={`${css['thumbnail-image']} ${isOn ? '' : css['item-off']}`}
                        src={imgSrc}
                        alt=''
                    />
                )}
                {!imgSrc && (
                    <div className={css['icon-container']}>
                        <Icon icon={translate(props.product, 'product', 'icon')} fontSize='54px'/>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TitleThumbnail;
