import css from './PhotoWorkbench.module.css';
import PhotoRotator from './PhotoRotator';

const PhotoWorkbench = (props) => {
    return (
        <div className={css['main-container']}>
            {false && <PhotoRotator />}
        </div>
    );
};









export default PhotoWorkbench;

