import css from './PlaceFinder.module.css';
import AutocompleteInput from './AutocompleteInput/AutocompleteInput';
import { useSelector, useDispatch } from 'react-redux';
import Icon from '../../../Shared/Icon/Icon';
import { updateEditor } from '../../../../store/editorSlice';

import Map from './Map/Map';

const PlaceFinder = (props) => {
    const dispatch = useDispatch();
    const placeId = useSelector((state) => state.editor.place.meta.google_place_id);
    const notes = useSelector((state) => state.editor.place.notes);

    return (
        <div className={css['main-container']}>
            <div className={css['border-container']}>
                <AutocompleteInput shortAddress={props.shortAddress}/>
                <div className={css['map-container']}>
                    {placeId && <Map content={{ placeId: placeId }} />}
                    {!placeId && (
                        <div className={css['icon-container']}>
                            <Icon icon='map' fontSize='104px' shape='outlined' />
                        </div>
                    )}
                </div>
                <textarea
                    placeholder='Notes: e.g. Apt number 207.'
                    className={css['place-notes']}
                    value={notes}
                    onChange={(event) => {
                        updateEditor(dispatch, ['place', 'notes'], event.target.value);
                    }}
                />
            </div>
        </div>
    );
};

export default PlaceFinder;
