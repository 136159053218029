import css from './SignIn.module.css';
import LearnAboutUsCSS from '../LearnAboutUs/LearnAboutUs.module.css';

import GoogleButton from './GoogleButton/GoogleButton';
import SignInInput from './SignInInput/SignInInput';
import ConfirmButton from '../../Shared/Buttons/ConfirmButton/ConfirmButton';
import LearnAboutUs from '../LearnAboutUs/LearnAboutUs';

import { useState, Fragment } from 'react';

import { useDispatch } from 'react-redux';
import { goTo } from '../../../store/conductorSlice';

import { auth, signInWithGoogle } from '../../../Firebase/Authentication';
import { signInWithEmailAndPassword } from 'firebase/auth';

const SignIn = (props) => {
    const [email, setEmail] = useState('');
    const [isGooglePressed, setIsGooglePressed] = useState(false);
    const [password, setPassword] = useState('');
    const [validInputs, setValidInputs] = useState(false);
    const [error, setError] = useState(null);

    function handleGoogleSignIn() {
        setIsGooglePressed(true);
        signInWithGoogle();
    }

    function checkForValidInputs() {
        if (email.includes('@') && password.trim().length > 5 && !isGooglePressed) {
            setValidInputs(true);
            return;
        }
        setValidInputs(false);
    }

    function handleEmailChange(event) {
        setEmail(event.target.value);
        checkForValidInputs();
    }

    function handlePasswordChange(event) {
        setPassword(event.target.value);
        checkForValidInputs();
    }

    async function handleSubmit() {
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch (error) {
            setError(error.message);
        }
    }

    const dispatch = useDispatch();

    function goToPasswordReset() {
        goTo(dispatch, 'Home', 'PasswordReset');
    }

    // XX To replace goToAbout
    function goToAdmissions() {
        goTo(dispatch, 'Home', 'Application');
    }

    return (
        <div className={css['main-container']}>
            <div className={css['sign-in-title']}>Welcome back!</div>

            <div className={css['form-container']}>
                <GoogleButton
                    isPressed={isGooglePressed}
                    onClick={handleGoogleSignIn}
                    text='Sign in with Google'
                />
                <div className={css['or']}>or</div>
                <SignInInput
                    icon='email'
                    placeholder='Email'
                    onChange={handleEmailChange}
                    value={email}
                    inputType='email'
                />
                <SignInInput
                    icon='lock'
                    placeholder='Password'
                    inputType='text'
                    onChange={handlePasswordChange}
                    value={password}
                />
                {error && <div className={css['error-message']}>Something went wrong</div>}
                <div className={css['password-forgotten']} onClick={goToPasswordReset}>
                    Don't remember your password?
                </div>

                <ConfirmButton
                    validInputs={validInputs}
                    product='GoodTimes'
                    handleConfirm={handleSubmit}
                    confirmText='Join the party!'
                    border={true}
                    marginTop='20px'
                />
            </div>

            <div
                className={LearnAboutUsCSS['main-container']}
                onClick={() => {
                    goTo(dispatch, 'Home', 'About');
                }}
            >
                <div className={css['text-container']}>New around here?</div>
            </div>
        </div>
    );
};

export default SignIn;
