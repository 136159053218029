import css from './TextActionButton.module.css';

const TextActionButton = (props) => {
    return (
        <div className={css['button-container']} style={{ '--base-color': props.color }}>
            <button className={`${css['choice']}`} onClick={props.onClick}>
                <div className={css['choice-text']}>{props.text}</div>
            </button>
        </div>
    );
};

export default TextActionButton;
