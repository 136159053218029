import css from './ThumbnailAndMap.module.css';
import Map from '../../../../Places/PlacesBrowser/Map/Map';
import { useSelector } from 'react-redux';
import Loading from '../../EmptyBrowser/Loading/Loading';

const ThumbnailAndMap = (props) => {
    const placeId = useSelector((state) => state.drawer.infoBox.Map.placeId);

    return (
        <div className={css['main-container']}>
            <div className={css['thumbnail-container']}>
                <img className={css['thumbnail']} alt='' src={props.thumbnail} />
            </div>

            <div className={css['map-container']}>
                <Map content={{ placeId }} />
            </div>
            <div className={css['loading-container']}>
                <Loading />
            </div>
        </div>
    );
};

export default ThumbnailAndMap;
