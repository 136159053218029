import { cloneObject } from '../../../tools/data-processing';

export function processPlacesBrowserData(rawData, userData) {
    const places = cloneObject(rawData);
    const base = userData.info.base_address.coordinates;

    const distances = [];
    const placesIds = [];

    Object.keys(places).forEach((key) => {
        if (!['elementIds', 'product', 'publicTags'].includes(key)) {
            const place = places[key];
            if(place.inBrowser){
                placesIds.push(key);
            }                        
        }
    });

    placesIds.forEach((id) => {
        const place = places[id];
        const travelDistance = getTravelDistance(
            base.lat,
            base.lng,
            place.coordinates.lat,
            place.coordinates.lng
        );
        place['travelDistance'] = travelDistance;

        // false refers to not seen on browser yet.
        distances.push([id, travelDistance, false]);
    });

    sortByDistance();

    return places;

    function sortByDistance() {
        distances.sort(function (a, b) {
            if (a[2] && !b[2]) {
                return 1;
            }

            if (!a[2] && b[2]) {
                return -1;
            }

            if (a[1] < b[1]) {
                return -1;
            }
            if (a[1] > b[1]) {
                return 1;
            }
            return 0;
        });

        const newQueue = [];

        distances.forEach((a) => {
            newQueue.push(a[0]);
        });

        places.elementIds = newQueue;
    }
}

export function getTravelDistance(lat1, lon1, lat2, lon2) {
    const earthRadius = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c * 1000; // Distance in meters
    // aproximation that assumes that in a^2+b^2=c^2, a = b
    return Math.round(Math.sqrt((distance * distance) / 2) * 2);

    function deg2rad(deg) {
        // return deg * (Math.PI / 180);
        return deg * 0.017453292519943295;
    }
}
