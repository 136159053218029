import css from './CancelEvent.module.css';
import TextActionButton from '../../Buttons/TextActionButton/TextActionButton';
import { useDispatch, useSelector } from 'react-redux';
import { updateConductorKeys, goTo } from '../../../../store/conductorSlice';
import { callCloudFunction } from '../../../../Firebase/CloudFunctions';
import { updateAudio, blinkAudio } from '../../../../store/audioSlice';

const CancelEvent = (props) => {
    const dispatch = useDispatch();

    const eventId = useSelector((state) => state.events.card.eventId);
    const event_doc_id = useSelector(
        (state) => state.firestore.events.elements[eventId].event_doc_id
    );
    const user_id = useSelector((state) => state.user.meta.user_id);
    const canceled_by = user_id ? 'host' : 'guest';

    function closeModal() {
        updateConductorKeys(dispatch, ['modalDialog'], {
            show: false,
            template: null,
            content: {},
        });
    }

    function handleCancel() {
        console.log('Canceling event');
        updateAudio(dispatch, ['music', 'song'], 'shame-shame-shame');
        blinkAudio(dispatch, ['music', 'action'], 'play');
        closeModal();        
        goTo(dispatch, 'Events', 'EventCanceled');

        const cancelPayload = {eventId, canceled_by, event_doc_id}
        // callCloudFunction('cancelEvent', cancelPayload ) // tktk to uncomment after startupfest
    }

    return (
        <div className={css['main-container']}>
            <div className={css['modal-title']}>Are you sure?</div>

            <div className={css['choice-container']}>
                <TextActionButton onClick={closeModal} text='Never mind' color='var(--dark)' />

                <TextActionButton onClick={handleCancel} text='Yup, cancel!' color='var(--red)' />
            </div>

            <div className={css['text-container']}>This action cannot be undone.</div>
        </div>
    );
};

export default CancelEvent;
