import { createFirestoreDoc, getFirestoreDocFromQuery } from './CRUD-functions';

function initApplication() {
    const application = {
        user_id: '',
        name: '',
        email: '',
        phone:{},
        status: 'in_review',
        meta:{}
    };

    return application;
}
    
export async function createApplication(application) {
    const newApplication = { ...initApplication(), ...application };
    const res = await createFirestoreDoc('applications', newApplication);
    return res;
}

export async function getOrCreateApplication(currentUser) {

    const doc = await getFirestoreDocFromQuery('applications', 'user_id', '==', currentUser.uid);

    if (doc) {
        return doc;
    } else {
        const newApplication = {
            user_id: currentUser.uid,
            email: currentUser.email,
            name: currentUser.displayName,
        };

        const res = await createApplication(newApplication)
        return res
    }
}
