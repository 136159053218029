import css from './Logo.module.css';

const Logo = (props) => {
    const size = props.size;
    const color = props.color;

    return (
        <div className={`${css['kins-logo']} ${css[size]} ${css[color]}`} onClick={props.onClick}>
            <span className={`${css['logo-letter']} ${css['logo-k']} ${css[size]}`}>K</span>
            <span className={`${css['logo-letter']} ${css['logo-i']} ${css[size]}`}>i</span>
            <span className={`${css['logo-letter']} ${css['logo-n']} ${css[size]}`}>n</span>
            <span className={`${css['logo-letter']} ${css['logo-s']} ${css[size]}`}>S</span>
        </div>
    );
};

export default Logo;
