import css from './Availability.module.css';
import MinimumNotice from './MinimumNotice/MinimumNotice';
import AvailabilityToggleSwitch from './AvailabilityToggleSwitch/AvailabilityToggleSwitch';


const Availability = (props) => {
    return (
        <div className={css['main-container']}>
            <AvailabilityToggleSwitch />
            <MinimumNotice />                      
        </div>
    );
};

export default Availability;
