import css from './Loading.module.css';

const Loading = (props) => {
    const imgSrc = require('../../../../../images/loading_pacman.gif');

    return (
        <div className={css['main-container']}>
            <div className={css['inner-container']}>
                <div className={css['text-container']}>Loading</div>
                <img className={css['loading-image']} src={imgSrc} alt='' />
            </div>
        </div>
    );
};

export default Loading;
