import css from './EventsDrawer.module.css';
import BottomDrawer from '../../Shared/BottomDrawer/BottomDrawer';
import Calendar from './Calendar/Calendar';
import Availability from './Availability/Availability';
import EventPreview from '../EventPreview/EventPreview';

import { useSelector } from 'react-redux';

const EventsDrawer = (props) => {
    const events = useSelector((state) => state.firestore.events.elements);
    const { upcoming, history } = prepareDrawerEvents();

    return (
        <BottomDrawer product='GoodTimes'>
            <div className={css['main-container']}>
                <Availability />
                <Calendar />

                {upcoming.length > 0 && <SectionTitle title='Upcoming!' />}
                {upcoming.map((event) => (
                    <EventPreview key={event.event_id} event={event} />
                ))}

                {history.length > 0 && <SectionTitle title='History' />}
                {history.map((event) => (
                    <EventPreview key={event.event_id} event={event} />
                ))}
            </div>
        </BottomDrawer>
    );

    function prepareDrawerEvents() {
        const result = { upcoming: [], history: [] };
        const elementIds = events.elementIds;

        elementIds.forEach((elementId) => {
            result.upcoming.push(events[elementId]);
        });

        return result;
    }
};

const SectionTitle = (props) => {
    return <div className={css['title-container']}>{props.title}</div>;
};

export default EventsDrawer;
