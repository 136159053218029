import css from './DateOption.module.css';
import { timeNames } from '../../../../../../tools/timeConstants';
import TimeButton from './TimeButton/TimeButton';
import { useSelector } from 'react-redux';

const DateOption = (props) => {
    const content = props.choice.content;

    const infoBox = useSelector((state) => state.browser.infoBox);
    const time = infoBox.show ? timeNames[infoBox.content.time] : timeNames[content.time];

    // const time = timeNames[content.times[Math.ceil(content.times.length / 2) - 1]];

    const weekday = content.weekday;
    const shiny = weekday === 'Tonight' || weekday === 'Today';

    return (
        <div className={css['main-container']}>
            <div className={`${css['weekday-container']} ${css[props.size]}`}>
                <div className={`${css['weekday-white']}`}>{weekday}</div>
                {shiny && <div className={`${css['shiny']}`}>{weekday}</div>}
            </div>
            <div className={`${css['time-row']} ${css[props.size]}`}>
                {props.size === 'large' && <TimeButton icon='remove' />}
                <div className={`${css['time-container']} ${css[props.size]}`}>{time}</div>
                {props.size === 'large' && <TimeButton icon='add' />}
            </div>
            <DateBox day={content.day} month={content.month} size={props.size} />
        </div>
    );
};

const DateBox = (props) => {
    return (
        <div className={`${css['date-box']} ${css[props.size]}`}>
            <div className={`${css['date-month']} ${css[props.size]}`}>{props.month}</div>
            <div className={`${css['date-day']} ${css[props.size]}`}>
                <div className={`${css['date-day-text']} ${css[props.size]}`}>{props.day}</div>
            </div>
        </div>
    );
};

export default DateOption;
