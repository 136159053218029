import { updateSlice, updateSliceKeys } from './update-slice';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    css: {
        height: '100%',
        top: '0px',
        opacity: 0,
        zIndex: 1,
        pointerEvents: 'none',
    },

    tutorial: {
        show: false,
        current: {
            playbackId: null,
            second: 0,
        },
        returnTo: {
            playbackId: null,
            second: 0,
            css: {},
        },

        browserSeen: false,
    },
};

const videoSlice = createSlice({
    name: 'video',
    initialState,
    reducers: {
        updateVideo(state, action) {
            updateSlice(state, action);
        },

        updateVideoKeys(state, action) {
            updateSliceKeys(state, action);
        },
    },
});

const actions = videoSlice.actions;
export const videoReducer = videoSlice.reducer;

export function updateVideo(dispatch, path, newValue) {
    dispatch(actions.updateVideo({ path, newValue }));
}

export function updateVideoKeys(dispatch, path, payload) {
    dispatch(actions.updateVideoKeys({ path, payload }));
}
