import css from '../Bucket/Bucket.module.css';

import { useSelector } from 'react-redux';
import FilterBucket from './FilterBucket';

const CurrentFilters = (props) => {
    const drawer = useSelector((state) => state.drawer);

    const buckets = drawer[drawer.activeContent].buckets;

    const inQuery = drawer.query === '' ? 'block' : 'none';

    return (
        <div className={css['main-container']} style={{ display: inQuery }}>
            <div className={css['title-container']}>Current Filters</div>
            <div className={css['categories-container']}>
                {buckets.map((bucket) => (
                    <FilterBucket key={bucket.id} bucketId={bucket.id} />
                ))}
            </div>
        </div>
    );
};

export default CurrentFilters;
