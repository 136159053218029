import { useState } from 'react';

import { createUserWithEmailAndPassword } from 'firebase/auth';

import css from './SignIn.module.css';

import GoogleButton from './GoogleButton/GoogleButton';
import SignInInput from './SignInInput/SignInInput';
import SignInActions from './SignInActions/SignInActions';

import { auth, signInWithGoogle } from '../../../Firebase/Authentication';

import { useDispatch } from 'react-redux';
import { conductor } from '../../../store/conductorSlice';

const SignUp = (props) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isGooglePressed, setIsGooglePressed] = useState(false);
    const [password, setPassword] = useState('');
    const [validInputs, setValidInputs] = useState(false);
    const [error, setError] = useState(null);

    function handleGoogleSignIn() {
        setIsGooglePressed(true);
        signInWithGoogle();
    }

    function checkForValidInputs() {
        if (
            name.trim().length > 1 &&
            email.includes('@') &&
            password.trim().length > 5 &&
            !isGooglePressed
        ) {
            setValidInputs(true);
            return;
        }
        setValidInputs(false);
    }

    function handleNameChange(event) {
        setName(event.target.value);
        checkForValidInputs();
    }

    function handleEmailChange(event) {
        setEmail(event.target.value);
        checkForValidInputs();
    }

    function handlePasswordChange(event) {
        setPassword(event.target.value);
        checkForValidInputs();
    }

    async function handleSingUp() {
        try {
            const member = await createUserWithEmailAndPassword(auth, email, password);
            console.log(member);
        } catch (error) {
            setError(error.message);
        }
    }

    const dispatch = useDispatch();
    function goSignIn() {
        dispatch(
            conductor.changeScreen({
                targetScreen: 'SignIn',
                targetProduct: 'Home',
            })
        );
    }

    return (
        <main className={css['main-container']}>
            <div className={css['sign-in-title']}>Welcome!</div>

            <div className={css['form-container']}>
                <GoogleButton
                    isPressed={isGooglePressed}
                    onClick={handleGoogleSignIn}
                    text='Sign up with Google'
                />
                <div className={css['or']}>or</div>
                <SignInInput
                    icon='face'
                    placeholder='Name'
                    onChange={handleNameChange}
                    value={name}
                    inputType='text'
                />

                <SignInInput
                    icon='email'
                    placeholder='Email'
                    onChange={handleEmailChange}
                    value={email}
                    inputType='email'
                />
                <SignInInput
                    icon='lock'
                    placeholder='Password'
                    inputType='text'
                    onChange={handlePasswordChange}
                    value={password}
                />
                {error && (
                    <div className={css['error-message']}>
                        Something went wrong
                    </div>
                )}
                <SignInActions
                    validInputs={validInputs}
                    onClick={handleSingUp}
                    confirmText='Sign me up!'
                    cancelText='Already a member?'
                    handleCancel={goSignIn}
                />
            </div>
        </main>
    );
};

export default SignUp;
