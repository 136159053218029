import css from './TopBar.module.css';

const TopBar = (props) => {
    return (
        <div className={css['black-background']}>
            <div className={`${css['main-container']} ${props.product}`}>{props.children}</div>
        </div>
    );
};

export default TopBar;
