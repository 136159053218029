import css from './MedianBill.module.css';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateContent } from '../../../../store/browserSlice';

import BillItem from './BillItem/BillItem';

const MedianBill = (props) => {
    const dispatch = useDispatch();

    const content = props.content;
    const bill = {
        appetizer: { price: content.appetizer, quantity: 1, amount: 0 },
        main: { price: content.main, quantity: 1, amount: 0 },
        dessert: { price: content.dessert, quantity: 1, amount: 0 },
        alcohol: { price: content.alcohol, quantity: 2, amount: 0 },
        subtotal: { price: null, quantity: null, amount: 0 },
        taxes: { price: null, quantity: 15, amount: 0 },
        gratuity: { price: null, quantity: 20, amount: 0 },
        total: { price: null, quantity: null, amount: 0 },
    };

    const loadedBill = useSelector((state) => state.browser.infoBox.content.bill) ? true : false;

    useEffect(() => {
        if (!loadedBill) {
            updateContent(dispatch, 'bill', updateAmounts(bill));
        }
    }, [loadedBill]);

    return (
        <div className={css['outer-container']}>
            <div className={css['main-container']}>
                <BillItem concept='Appetizer' itemId='appetizer' css='' />
                <BillItem concept='Main' itemId='main' css='' />
                <BillItem concept='Dessert' itemId='dessert' css='' />
                <BillItem concept='Alcohol' itemId='alcohol' css='' />
                <BillItem concept='Subtotal' itemId='subtotal' css='subtotal' />
                <BillItem concept='Taxes' itemId='taxes' css='taxes' />
                <BillItem concept='Gratuity' itemId='gratuity' css='gratuity' />
                <BillItem concept='Total' itemId='total' css='total' />
            </div>
        </div>
    );
};

export function updateAmounts(oldBill) {
    let newSubtotal = 0;
    ['appetizer', 'main', 'dessert', 'alcohol'].forEach((itemId) => {
        const item = oldBill[itemId];
        item.amount = item.price * item.quantity;
        newSubtotal += item.amount;
    });
    oldBill.subtotal.amount = newSubtotal;
    oldBill.taxes.amount = Math.round(newSubtotal * (oldBill.taxes.quantity / 100));
    oldBill.gratuity.amount = Math.round(
        (oldBill.gratuity.quantity / 100) * (oldBill.subtotal.amount + oldBill.taxes.amount)
    );

    oldBill.total.amount = oldBill.subtotal.amount + oldBill.taxes.amount + oldBill.gratuity.amount;
    return oldBill;
}

export default MedianBill;
