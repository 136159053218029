import css from './ToggleSwitch.module.css';

const ToggleSwitch = (props) => {
    function handleToggle(event) {
        const newIsOn = !event.target.checked;
        props.onToggle(newIsOn);
    }

    return (
        <div className={css['switch-container']}>
            <label className={`${css['switch']} ${props.product}`}>
                <input type='checkbox' onChange={handleToggle} checked={!props.isOn} />

                <span className={`${css['slider']} ${css['round']} ${props.product}`}></span>
            </label>
        </div>
    );
};

export default ToggleSwitch;
