import { createSlice } from '@reduxjs/toolkit';
import { updateSlice, updateSliceKeys } from './update-slice';
import { removeValueFromArray } from '../tools/data-processing';
import { initPrices } from '../components/Places/PlaceEditor/UploadPhotos/initPrices';

const initialState = {
    status: 'default',
    product: null,
    query: '',
    activeContent: 'labels',

    labels: {
        masterRef: {},
        buckets: [],
        filters: [],
        filterCount: 0,
        showFilters: false,
        style: {},
    },

    topics: {
        masterRef: {},
        buckets: [],
        filters: [],
        filterCount: 0,
        showFilters: false,
        style: {},
    },

    menu_fees: {
        masterRef: {},
        buckets: [],
        filters: [],
        filterCount: 0,
        showFilters: false,

        showPhotos: false,
        language: 'english',
        style: { height: 'var(--currentViewHeight)', bottom: '0' },
        areaStyle: { padding: '3vh 0' },
    },

    photos: {
        masterRef: {},
        buckets: [],
        filters: [],
        filterCount: 0,
        showFilters: false,
        style: {},
    },

    website: {
        url: null,
        masterRef: {},
        buckets: [],
        filters: [],
        filterCount: 0,
        showFilters: false,
        style: {},
    },

    map: {
        style: {},
    },

    infoBox: {
        activeComponent: null,
        Map: { placeId: null },
        Hours: { hours: [] },
        Photos: { photos: [] },
        Menu: { photos: [] },
        Prices: initPrices(),
        style: {},
        view: null,
    },
};

const drawerSlice = createSlice({
    name: 'drawer',
    initialState,
    reducers: {
        loadDrawer(state, action) {
            state.product = action.payload.product;
            state.activeContent = action.payload.activeContent;
            const targetContents = state[state.activeContent];

            targetContents.buckets = action.payload.buckets;
            targetContents.masterRef = action.payload.masterRef;
        },

        toggleItem(state, action) {
            const itemId = action.payload.itemId;
            const isSelected = action.payload.isSelected;

            const targetContents = state[state.activeContent];

            const item = targetContents.masterRef[itemId];
            item.selected = isSelected;

            const bucketItem = targetContents.masterRef[item.bucket].items.find(
                (buckItem) => item.id === buckItem.id
            );
            bucketItem.selected = isSelected;

            const countModifier = isSelected ? 1 : -1;
            targetContents.filterCount += countModifier;
            targetContents.showFilters = targetContents.filterCount > 0;

            if (isSelected) {
                if (!targetContents.filters.includes(itemId)) {
                    targetContents.filters.push(itemId);
                }
            } else {
                targetContents.filters = removeValueFromArray(targetContents.filters, itemId);
            }
        },

        openDrawer(state, action) {
            state.activeContent = action.payload.activeContent;
            state.status = 'open';
        },

        closeDrawer(state, action) {
            state.status = 'closed';
            state.query = '';
        },

        updateQuery(state, action) {
            state.query = action.payload.query;
        },

        updateContents(state, action) {
            const targetKey = action.payload.targetKey;
            const newValue = action.payload.newValue;
            const targetContents = state[state.activeContent];
            targetContents[targetKey] = newValue;
        },

        loadWebsite(state, action) {
            state.website.url = action.payload.website;
        },

        loadInfoBox(state, action) {
            state.infoBox.content = action.payload.content;
        },

        loadPlace(state, action) {
            const place = action.payload.place;
            state.infoBox['Map'].placeId = place.placeId;
            state.infoBox['Hours'].hours = place.hours;
            state.infoBox['Photos'].photos = place.photos;
            state.infoBox['Menu'].photos = place.menu;
            state.infoBox['Prices'] = place.prices;
        },

        openInfoDrawer(state, action) {
            state.infoBox.activeComponent = action.payload.component;
            state.activeContent = 'infoBox';
            state.status = 'open';
        },

        updateDrawer(state, action) {
            updateSlice(state, action);
        },

        updateDrawerKeys(state, action) {
            updateSliceKeys(state, action);
        },
    },
});

export const drawerReducer = drawerSlice.reducer;

const drawerActions = drawerSlice.actions;

export function openDrawer(dispatch, activeContent) {
    dispatch(drawerActions.openDrawer({ activeContent }));
}

export function closeDrawer(dispatch) {
    dispatch(drawerActions.closeDrawer({}));
}

export function loadDrawer(dispatch, product, buckets, masterRef, activeContent) {
    dispatch(drawerActions.loadDrawer({ product, buckets, masterRef, activeContent }));
}

export function toggleItem(dispatch, itemId, isSelected) {
    dispatch(drawerActions.toggleItem({ itemId, isSelected }));
}

export function updateQuery(dispatch, query) {
    dispatch(drawerActions.updateQuery({ query }));
}

export function updateContents(dispatch, targetKey, newValue) {
    dispatch(drawerActions.updateContents({ targetKey, newValue }));
}

export function loadWebsite(dispatch, website) {
    dispatch(drawerActions.loadWebsite({ website }));
}

export function loadInfoBox(dispatch, content) {
    dispatch(drawerActions.loadInfoBox({ content }));
}

export function loadPlace(dispatch, place) {
    dispatch(drawerActions.loadPlace({ place }));
}

export function openInfoDrawer(dispatch, component) {
    dispatch(drawerActions.openInfoDrawer({ component }));
}

export function updateDrawer(dispatch, path, newValue) {
    dispatch(drawerActions.updateDrawer({ path, newValue }));
}

export function updateDrawerKeys(dispatch, path, payload) {
    dispatch(drawerActions.updateDrawerKeys({ path, payload }));
}
