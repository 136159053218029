import css from './EventCard.module.css';
import EventCardTopBar from './EventCardTopBar/EventCardTopBar';
import EventPreview from '../EventPreview/EventPreview';
import CountDownTimer from './CountDownTimer/CountDownTimer';
import StatusMessage from './StatusMessage/StatusMessage';
import CancelButton from './CancelButton/CancelButton';
import MuteButton from '../../Shared/Browser/VerticalVideoPlayer/SideButtons/MuteButton/MuteButton';
import { useSelector } from 'react-redux';

const EventCard = (props) => {
    const cardEventId = useSelector((state) => state.events.card.eventId);
    const events = useSelector((state) => state.firestore.events.elements);

    if (!cardEventId) {
        <div className={css['main-container']}>
            <EventCardTopBar />
            <MuteButton />
        </div>;
    }

    const rawEvent = events[cardEventId];
    
    return (
        <div className={css['main-container']}>
            <EventCardTopBar />
            <div className={css['timer-container']}>
                <CountDownTimer when={rawEvent.when} />
            </div>

            <div className={css['preview-container']}>
                <EventPreview event={rawEvent} />
            </div>
            <StatusMessage event={rawEvent} />

            <CancelButton />

            {false && (
                <div className={css['image-container']}>
                    <img
                        className={css['image']}
                        src={require('../../../images/event-confirmation/confirm-beach.jpg')}
                        alt=''
                    />
                </div>
            )}

            <MuteButton />
        </div>
    );
};

export default EventCard;

// const rawEvent = {
//     id: null,
//     status: 'happening',

//     who: {
//         id: 'canela_ZJ8Aj',
//         type: 'guest',
//     },
//     where: 'tiradito_ChIJhw0su1oayUwRbh65uWdms2E', // where id
//     when: {
//         date: '2023-06-10', //
//         time: '1900', //
//     },
//     event_doc_id: null
// };