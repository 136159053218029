import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateBrowser, triggerElementUpdate, triggerPlaceUpdate } from '../../../../../store/browserSlice';
import { continueTransition, backToHost } from '../../../../../store/browserSlice';
import { updateAudio } from '../../../../../store/audioSlice';
// This component is just responsible for managing stage transitions.

const StageManager = (props) => {
    const dispatch = useDispatch();
    const stage = useSelector((state) => state.browser.stage);
    const manager = useSelector((state) => state.browser.manager);
    const host = useSelector((state) => state.browser.host);
    const vertical = useSelector((state) => state.browser.player.vertical);
    const placesView = useSelector((state) => state.browser.places.view);

    const [transition, setTransition] = useState([]);

    const trigger = manager.trigger;
    useEffect(() => {
        if (trigger) {
            // console.log('Manager triggered by: ', trigger);
            const newEndPoint = determineEndPoint(
                trigger,
                manager.endPoint,
                host.playbackId ? true : false
            );
            const targetTransition = manager.endPoint + '_' + newEndPoint;
            // console.log('TRANSITION: ', targetTransition);
            setTransition(transitions[targetTransition]);
            updateBrowser(dispatch, 'manager', {
                step: 1,
                endPoint: newEndPoint,
                trigger: null,
                lastTrigger: trigger,
            });
        }
    }, [trigger]);

    const step = manager.step;
    useEffect(() => {
        // console.log('--- Step ', step, ' ---');
        if (step) {
            if (step <= transition.length) {
                const Step = transition[step - 1][0];
                const payload = transition[step - 1][1];
                Step(payload);

                const keepGoing = transition[step - 1][2];
                if (keepGoing) {
                    continueTransition(dispatch);
                }
            } else {
                // console.log('Transition chunk completed!');
                if (
                    ['CurtainDown', 'LightsOff'].includes(manager.endPoint) &&
                    manager.lastTrigger !== 'exitBrowser'
                ) {
                    // console.log('... tringering transition second part...');
                    updateBrowser(dispatch, 'manager', { trigger: manager.lastTrigger });
                } else {
                    // console.log('### Transition completed! ###');
                    updateBrowser(dispatch, 'manager', { step: null });
                    setTransition([]);
                }
            }
        }
    }, [step]);

    useEffect(() => {
        if (host.topicsTime > 0 && host.showTopics) {
            updateBrowser(dispatch, 'stage', { topics: 'open' });
        }
    }, [host.showTopics]);

    const transitions = {
        CurtainDown_HostPlaying: [
            [makeAnnouncement, {}, false],
            [openCurtain, {}, true],
            [updateHost, { action: 'play' }, true],
        ],

        CurtainDown_EmptyStage: [
            [makeAnnouncement, {}, false],
            [openCurtain, {}, true],
            [updateStage, { lights: 'turn-on' }, true],
        ],

        CurtainDown_PlayerPlaying: [
            [makeAnnouncement, null, false],
            [openCurtain, null, true],
            [updatePlayer, { action: 'play' }, true],
        ],

        EmptyStage_LightsOff: [[turnLightsOff, 'EmptyStage', false]],

        EmptyStage_CurtainDown: [
            [closeCurtain, 'EmptyStage', false],
            [triggerElementUpdate, dispatch, true],
        ],

        HostPlaying_CurtainDown: [
            [updateHost, { cue: trigger }, false],
            [closeCurtain, 'HostPlaying', false],
            [updateHost, { action: 'pause', segmentEnded: true }, true],
            [triggerElementUpdate, dispatch, true],
        ],

        HostPlaying_HostPlaying: [[updateHost, { cue: trigger }, true]],

        HostPlaying_LightsOff: [
            [updateHost, { cue: trigger }, false],
            [turnLightsOff, 'HostPlaying', false],
            [updateHost, { action: 'pause' }, true],
        ],

        LightsOff_HostPlaying: [
            [updateHost, { cue: trigger, action: 'play' }, true],
            [updateStage, { lights: 'turn-on' }, true],
        ],

        LightsOff_EmptyStage: [[updateStage, { lights: 'turn-on' }, true]],

        LightsOff_PlayerPlaying: [
            [makeAnnouncement, {}, false],
            [updatePlayer, { action: 'play' }, true],
            [updateStage, { lights: 'turn-on' }, true],
        ],

        PlayerPlaying_CurtainDown: [
            [closeCurtain, 'PlayerPlaying', false],
            [updatePlayer, { action: 'pause' }, true],
            [triggerElementUpdate, dispatch, true],
        ],

        PlayerPlaying_HostSayingGoodBye: [
            // 3 part transition.
            [turnLightsOff, 'PlayerPlaying', false],
            [updatePlayer, { action: 'pause' }, true],
            [backToHost, dispatch, true],
            [updateStage, { lights: 'turn-on' }, true],
            [updateHost, { cue: trigger, action: 'play' }, false],
            [closeCurtain, 'HostPlaying', false],
            [updateHost, { action: 'pause', segmentEnded: true }, true],
            [triggerElementUpdate, dispatch, true],
            [updateManager, { endPoint: 'CurtainDown' }, true], // So actual endPoint
        ],

        PlayerPlaying_LightsOff: [
            [turnLightsOff, 'PlayerPlaying', false],
            [updatePlayer, { action: 'pause' }, true],
            [backToHost, dispatch, true],
        ],

        CurtainDown_VerticalPlayerPlaying: [
            [makeAnnouncement, {}, false],
            [openCurtain, null, true],
            [updateVerticalPlayer, { action: 'play' }, true],
        ],

        VerticalPlayerPlaying_LightsOff: [
            [turnVolumeOff, null, true],
            [turnLightsOff, 'VerticalPlayerPlaying', false],
            [updatePlayer, { action: 'pause' }, true],
            [triggerPlaceUpdate, dispatch, true],
        ],

        LightsOff_VerticalPlayerPlaying: [
            [makeAnnouncement, {}, false],
            [updateVerticalPlayer, { action: 'play', volume: 'on' }, true],
            [waitAndTurnLightsOn, { lights: 'turn-on' }, true],
        ],
    };

    return <></>;

    function openCurtain() {
        // console.log('openCurtain');

        const payload = {
            curtain: 'open',
            lights: 'on',
            topics: host.playbackId && !host.showTopics ? 'closed' : 'open',
            audio: null,
        };

        updateBrowser(dispatch, 'stage', payload);
    }

    function turnLightsOff(startingPoint) {
        // console.log('turnLightsOff');
        updateBrowser(dispatch, 'stage', { lights: 'turn-off' });
        updateBrowser(dispatch, getUpdateTarget(startingPoint), { volume: 'off' });

        setTimeout(() => {
            continueTransition(dispatch);
        }, stage.speed[0]);
    }

    function waitAndTurnLightsOn() {
        setTimeout(() => {
            updateBrowser(dispatch, 'stage', { lights: 'on' });
        }, stage.speed[1]);
    }

    function makeAnnouncement() {
        // console.log('makeAnnouncement');
        updateBrowser(dispatch, 'player', { playbackId: stage.queuedPlaybackId });
        updateBrowser(dispatch, 'stage', { action: 'play' });

        if (manager.lastTrigger === 'newTopic') {
            updateBrowser(dispatch, 'topics', { toast: true });
        }
    }

    function updateHost(payload) {
        // console.log('updateHost');
        updateBrowser(dispatch, 'host', payload);
    }

    function updatePlayer(payload) {
        // console.log('updatePlayer');
        updateBrowser(dispatch, 'player', payload);
    }

    function updateVerticalPlayer(payload) {
        if (placesView === 'VideoPlayer') {
            updateBrowser(dispatch, 'player', payload);
        }
    }

    function updateStage(payload) {
        // console.log('updateStage');
        updateBrowser(dispatch, 'stage', payload);
    }

    function updateManager(payload) {
        updateBrowser(dispatch, 'manager', payload);
    }

    function turnVolumeOff() {
        updateAudio(dispatch, ['volume'], 'off');
        updateBrowser(dispatch, 'player', { volume: 'off' });
    }

    function closeCurtain(startingPoint) {
        // console.log('closeCurtain');
        updateBrowser(dispatch, 'stage', { lights: 'turn-off', curtain: 'close', topics: 'close' });

        updateBrowser(dispatch, getUpdateTarget(startingPoint), { volume: 'off' });

        setTimeout(() => {
            continueTransition(dispatch);
        }, stage.speed[0]);
    }

    function getUpdateTarget(startingPoint) {
        const showing = { HostPlaying: 'host', PlayerPlaying: 'player', EmptyStage: 'host' };
        return showing[startingPoint];
    }

    function determineEndPoint(trigger, startingPoint, host) {
        // trigger: what triggered the transition (newElement, newTopic, topicEnded, exitBrowser);
        // start: the transition starting point,
        // host: does the element has a host.

        if (startingPoint === 'CurtainDown') {
            if (stage.queuedPlaybackId) {
                if (vertical) {
                    return 'VerticalPlayerPlaying';
                }

                return 'PlayerPlaying';
            } else {
                return 'EmptyStage';
            }

            // if (host) {
            //     return 'HostPlaying';
            // } else {
            //     return 'PlayerPlaying';
            // }
        }

        if (startingPoint === 'LightsOff') {
            if (vertical) {
                return 'VerticalPlayerPlaying';
            }

            if (trigger === 'newTopic') {
                return 'PlayerPlaying';
            }

            if (trigger === 'topicEnded') {
                if (host) {
                    return 'HostPlaying';
                } else {
                    return 'EmptyStage';
                }
            }

            if (trigger === 'newElement') {
                return 'PlayerPlaying';
            }
        }

        if (startingPoint === 'EmptyStage') {
            if (trigger === 'newTopic') {
                return 'LightsOff';
            }
            if (trigger === 'newElement') {
                return 'CurtainDown';
            }
        }

        if (startingPoint === 'HostPlaying') {
            if (trigger === 'newTopic') {
                return 'LightsOff';
            }

            if (trigger === 'newElement') {
                return 'CurtainDown';
            }

            if (trigger === 'invitationSent') {
                return 'HostPlaying';
            }
        }

        if (startingPoint === 'PlayerPlaying') {
            if (trigger === 'newTopic' || trigger === 'topicEnded') {
                return 'LightsOff';
            }

            if (trigger === 'newElement') {
                if (host) {
                    return 'HostSayingGoodBye'; // to say good bye.
                } else {
                    return 'CurtainDown';
                }
            }
        }

        if (startingPoint === 'VerticalPlayerPlaying') {
            return 'LightsOff';
        }
    }
};

export default StageManager;
