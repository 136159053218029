import css from './EventThumbnail.module.css';
import { useState } from 'react';

const EventThumbnail = (props) => {
    const product = props.product;
    const [isOn, setIsOn] = useState(props.isOn);
    const imgSrc = props.image;

    const style = {
        borderBottomRightRadius: '0',
        borderTopRightRadius: '0'
    }

    if(product === 'Places'){
        style.borderTopLeftRadius = '0'
    }


    return (
        <div
            className={css['outer-container']}
            onClick={() => {
                setIsOn(!isOn);
            }}
           
        >
            <NameTag
                product={product}
                name={props.name}
                css={product === 'Places' ? { borderBottomLeftRadius: '0' }:{}}
            />
            <div className={`${css['main-container']} ${product}`}  style={style}>
                <img
                    className={`${css['thumbnail-image']} ${isOn ? '' : css['item-off']}`}
                    src={imgSrc}
                    alt=''
                />
            </div>
        </div>
    );


};

export function NameTag(props){

    return (
        <div
            className={`${css['name-tag']} ${props.product}`}
            style={props.css}
        >
            <div className={css['text-container']}>{props.name}</div>
        </div>
    );
};

export default EventThumbnail;
