import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import PeopleBrowser from './PeopleBrowser/PeopleBrowser';
import PeopleManager from './PeopleManager/PeopleManager';
import PersonEditor from './PersonEditor/PersonEditor';

const People = (props) => {
    const screen = useSelector((state) => state.conductor.screen);

    return (
        <Fragment>
            {screen === 'PeopleManager' && <PeopleManager />}
            {screen === 'PeopleBrowser' && <PeopleBrowser />}
            {screen === 'PersonEditor' && <PersonEditor />}
        </Fragment>
    );
};

export default People;
