import css from './SearchBar.module.css';

import TopBar from '../../TopBar/TopBar';
import Icon from '../../Icon/Icon';
import TopBarButton from '../../TopBar/TopBarButton/TopBarButton';

import { useDispatch, useSelector } from 'react-redux';
import { updateQuery, closeDrawer, updateDrawer } from '../../../../store/drawerSlice';
import { updateQueue, updateBrowserKeys } from '../../../../store/browserSlice';
import { Fragment } from 'react';

const SearchBar = (props) => {
    const dispatch = useDispatch();

    const screen = useSelector((state) => state.conductor.screen);

    const drawer = useSelector((state) => state.drawer);
    const product = drawer.product || 'People';
    const query = drawer.query;
    const filters = drawer[drawer.activeContent].filters;

    const browserElements = useSelector((state) => state.browser.elements);
    const firestoreElements = useSelector(
        (state) => state.firestore[product.toLowerCase()].elements
    );
    const placesQueue = useSelector((state) => state.browser.places.queue);
    const elementIds = firestoreElements.elementIds;

    function handleChange(event) {
        const query = event.target.value;
        updateQuery(dispatch, query.toLowerCase());
    }

    const showBar = drawer.activeContent !== 'photos';

    return (
        <TopBar product={product}>
            <div className={css['search-box']}>
                {showBar && (
                    <Fragment>
                        <Icon
                            icon='search'
                            css={css['search-icon']}
                            fontSize='26px'
                            marginLeft='-5px'
                            marginBottom='-2px'
                        />
                        <div className={css['border-box']}>
                            <input
                                className={css['search-input']}
                                placeholder='Search'
                                onChange={handleChange}
                                value={query}
                            />
                        </div>
                    </Fragment>
                )}
            </div>

            <TopBarButton
                onClick={() => {
                    closeDrawer(dispatch);
                    setTimeout(() => {
                        updateDrawer(dispatch, ['status'], 'default');
                    }, 500);
                    console.log('Updating queue...', product, filters);
                    filterElements();
                }}
                width='40px'
                iconProps={{
                    icon: 'chevron_right',
                    fontSize: '40px',
                }}
                css={{ justifyContent: 'flex-end' }}
            />
        </TopBar>
    );

    function filterElements() {
        if (screen === 'PlaceEditor') {
            return;
        }

        if (product === 'People') {
            filterPeople();
        }

        if (product === 'Places') {
            filterPlaces();
        }

        function filterPlaces() {
            //tktk need to take into consideration those places that are akready seen when determining the active position.
            if (filters.length === 0) {
                if (placesQueue.length < elementIds.length) {
                    updatePlacesQueue(elementIds);
                }
                return;
            }

            const filteredElements = { elementIds: [] };

            if (filters.length === 0) {
                if (browserElements.queue.length < elementIds.length) {
                    updateQueue(dispatch, elementIds);
                }
                return;
            }

            filters.forEach((filter) => {
                elementIds.forEach((elementId) => {
                    const tags = firestoreElements[elementId].tags;
                    if (filter in tags) {
                        if (elementId in filteredElements) {
                            filteredElements[elementId].push(filter);
                        } else {
                            filteredElements[elementId] = [filter];
                            filteredElements.elementIds.push(elementId);
                        }
                    }
                });
            });

            console.log(filteredElements);

            updatePlacesQueue(filteredElements.elementIds);

            function updatePlacesQueue(newQueue) {
                const newPlaybackId = firestoreElements[newQueue[0]].playbackId;

                updateBrowserKeys(dispatch, ['places'], {
                    queuedId: newQueue[0],
                    queue: newQueue,
                    position: 0,
                });

                updateBrowserKeys(dispatch, ['stage'], { queuedPlaybackId: newPlaybackId });
                updateBrowserKeys(dispatch, ['manager'], { trigger: 'newPlace' });
            }
        }

        function filterPeople() {
            if (filters.length === 0) {
                if (browserElements.queue.length < elementIds.length) {
                    updateQueue(dispatch, elementIds);
                }
                return;
            }

            const filteredElements = { elementIds: [] };

            filters.forEach((filter) => {
                elementIds.forEach((elementId) => {
                    const tags = browserElements.masterRef[elementId].tags;
                    if (filter in tags) {
                        if (elementId in filteredElements) {
                            filteredElements[elementId].push(filter);
                        } else {
                            filteredElements[elementId] = [filter];
                            filteredElements.elementIds.push(elementId);
                        }
                    }
                });
            });

            const newAffinities = [];

            filteredElements.elementIds.forEach((elementId) => {
                const affinity = browserElements.masterRef[elementId].affinity;
                newAffinities.push([
                    elementId,
                    affinity.score,
                    filteredElements[elementId].length,
                    filteredElements[elementId],
                ]);
            });

            sortByAffinity();

            const newQueue = [];

            newAffinities.forEach((item) => {
                newQueue.push(item[0]);
            });

            console.log(filteredElements, newQueue);
            updateQueue(dispatch, newQueue);

            function sortByAffinity() {
                newAffinities.sort(function (a, b) {
                    if (a[2] < b[2]) {
                        return 1;
                    }

                    if (a[2] > b[2]) {
                        return -1;
                    }

                    if (a[1] > b[1]) {
                        return -1;
                    }
                    if (a[1] < b[1]) {
                        return 1;
                    }
                    return 0;
                });
            }
        }
    }
};

export default SearchBar;
