import css from './ElementName.module.css';

import Icon from '../../../Icon/Icon';

import { useSelector } from 'react-redux';
import { translate } from '../../../../../tools/translator';
import { sections } from '../../Topics/Sections/Sections';

const ElementName = (props) => {
    const element = useSelector(
        (state) => state.browser.elements.masterRef[state.browser.elements.activeId]
    );

    const cards = useSelector(state => state.conductor.product) === 'Cards'

    const activeName = useSelector((state) => state.browser.topics.active.name);
    const activeSection = useSelector((state) => state.browser.topics.active.section);

    const name = (activeSection === 'where' && activeName) || element.name;
    const prediction = !cards && element.prediction;

    const browser = useSelector((state) => state.browser);

    const activeTheme = sections[browser.topics.active.section].theme || browser.product;
    const color = translate(activeTheme, 'product', 'color');

    const icons = { GoodTime: '', KindredSpirit: 'auto_awesome' };

    if (activeSection === 'when') {
        return <></>;
    }

    return (
        <div className={css['main-container']}>
            <div className={css['name-container']}>
                <div className={css['element-name']} style={{ WebkitTextStrokeColor: color }}>
                    {name}
                </div>

                {prediction && (
                    <Icon
                        icon={icons[prediction]}
                        css={css['prediction']}
                        marginLeft={'1px'}
                        fontSize={'14px'}
                    />
                )}
            </div>
            {prediction && <PredictionText prediction={prediction} />}
        </div>
    );
};

const PredictionText = (props) => {
    const displayText = { GoodTime: 'Good time!', KindredSpirit: 'Kindred Spirits' };
    const predText = displayText[props.prediction];

    return (
        <div className={css['prediction-text-container']}>
            <div className={css['prediction']}>{predText}</div>
        </div>
    );
};

export default ElementName;
