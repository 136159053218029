export function prepareDrawer(items, categories, buckets) {
    const masterRef = createMasterReference();

    items.forEach((item) => {
        const category = masterRef[item.category];
        
        item.keywords.push(item.name.toLowerCase());
        if ('ingredients' in item) {
            item.keywords.push(item.ingredients.toLowerCase())
        }
        item.keywords.push(category.name.toLowerCase());

        item.selected = false;    
        item.bucket = category.bucket;
        category.keywords.push(...item.keywords);
        category.items.push(item);
    });

    categories.forEach((category) => {
        category.keywords.push(category.name.toLowerCase());

        const bucket = masterRef[category.bucket];
        bucket.keywords.push(bucket.name.toLowerCase());
        bucket.keywords.push(...category.keywords);

        bucket.categories.push(category);
        bucket.items.push(...category.items)
    });


    Object.keys(masterRef).forEach(key => {
        const item = masterRef[key];
        item['keyConcat'] = item.keywords.join();
    })

    return [buckets, masterRef];

    function createMasterReference() {
        const result = {};
        items.forEach((item) => {
            result[item.id] = item;
        });
        categories.forEach((category) => {
            result[category.id] = category;
        });
        buckets.forEach((bucket) => {
            result[bucket.id] = bucket;
        });

        return result;
    }
}