import { createSlice } from '@reduxjs/toolkit';
import { dummyEvents } from '../components/Events/dummyEvents';
import { months } from '../tools/timeConstants';

const initialState = {
    events: dummyEvents,
};

const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        loadEvents(state, action) {
            const events = action.payload.events;
            state.events = events;
        },

        addEvent(state, action) {
            const event = action.payload.event;
            state.events.unshift(event);
            state.events = state.events.sort(eventSort);
        },
    },
});

function eventSort(a, b) {
    const aValue = a.when.day + months.findIndex((month) => month === a.when.month) * 10;
    const bValue = b.when.day + months.findIndex((month) => month === b.when.month) * 10;

    if (aValue < bValue) {
        return -1;
    }
    return 1;
}

export const eventReducer = eventSlice.reducer;
const eventActions = eventSlice.actions;

export function loadEvents(dispatch, events) {
    dispatch(eventActions.loadEvents({ events }));
}

export function addEvent(dispatch, event) {
    dispatch(eventActions.addEvent({ event }));
}
