import { weekDayNames, months, calendarStartDates } from '../../../../tools/timeConstants';

export function getWindowDetails(window_id) {
    const date = window_id.slice(0, 10);
    const dateDetails = getDateDetails(date);

    const startTime = window_id.slice(11, 18);
    const endTime = window_id.slice(19, 26);
    const time = 'From ' + getPrettyTime(startTime) + ' to ' + getPrettyTime(endTime);

    const time_window = { ...dateDetails, time };
    return time_window;
}

function getDateDetails(date) {
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const dateObject = getDateObject(date);

    let month_number = dateObject.getMonth();
    const month = months[month_number];

    const dateDetails = {
        date: date,
        weekday: weekDays[dateObject.getDay()],
        day: dateObject.getDate(),
        month,
    };

    dateDetails.weekday_name = weekDayNames[dateDetails.weekday];

    return dateDetails;
}

function getDateObject(date) {
    const year = Number(date.slice(0, 4));
    const month = Number(date.slice(5, 7)) - 1;
    const day = Number(date.slice(8, 10));

    const dateObject = new Date(year, month, day);
    return dateObject;
}

function getPrettyTime(timeWindow) {
    const uglyHour = Number(timeWindow.slice(3, 5));
    const minutes = timeWindow.slice(5, 7);
    let meridiem = 'am';

    let prettyHour = uglyHour;
    let prettyMinutes = minutes === '30' ? ':30 ' : ' ';

    if (uglyHour === 12) {
        prettyHour = 12;
        meridiem = 'pm';
    }

    if (uglyHour > 12) {
        prettyHour = uglyHour - 12;
        if (uglyHour !== 24) {
            meridiem = 'pm';
        }
    }

    const prettyTime = prettyHour + prettyMinutes + meridiem;
    return prettyTime;
}

export function generateCalendarWeeks(yearMonth, member) {
    const weeks = [];

    const availability = member.availability.dates;
    const eventsByDate = getEventsByDate(member.events);

    const startDate = calendarStartDates[yearMonth];

    const date = getDateObject(startDate);

    for (var i = 0; i < 6; i++) {
        const week = [];
        for (var y = 0; y < 7; y++) {
            const day = getCalendarDate(date);
            date.setDate(date.getDate() + 1);
            week.push(day);
        }
        weeks.push(week);
    }

    return weeks;

    function getCalendarDate(dateObject) {
        const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        let month_number = dateObject.getMonth();
        const month = months[month_number];
        const date = getPrettyDate(dateObject);
        const available = availability[date];
        const event = eventsByDate[date];

        const dateDetails = {
            available: available === undefined ? true : available,
            event: event === undefined ? false : true,
            weekday: weekDays[dateObject.getDay()],
            day: dateObject.getDate(),
            month,
            date,
        };

        return dateDetails;

        function getPrettyDate(dateObject) {
            let day_number = dateObject.getDate();
            if (day_number < 10) {
                day_number = '0' + day_number;
            }

            let month_number = dateObject.getMonth() + 1;
            if (month_number < 10) {
                month_number = '0' + month_number;
            }

            const year = dateObject.getFullYear();

            const date = year + '-' + month_number + '-' + day_number;

            return date;
        }
    }

    function getEventsByDate(events) {
        const eventsByDate = {};

        Object.keys(events).forEach((key) => {
            const event = events[key];
            const window_id = event.window_id;
            const eventDate = window_id.slice(0, 10);
            eventsByDate[eventDate] = event.meta.data_id;
        });

        return eventsByDate;
    }
}
