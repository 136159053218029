import css from './Account.module.css';
import AccountTopBar from './AccountTopBar/AccountTopBar';
import Card from '../Cards/Card/Card';
import { useSelector } from 'react-redux';

const Account = (props) => {    
    const user_doc_id = useSelector(state => state.user.meta.user_doc_id)
    
    return (
        <div className={css['main-container']}>
            <AccountTopBar />
            <Card product='People' elementId={user_doc_id}/>
        </div>
    );
};

export default Account;
