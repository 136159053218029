import css from './BrowserTopBar.module.css';

import TopBar from '../../TopBar/TopBar';
import TopBarButton from '../../TopBar/TopBarButton/TopBarButton';
import TopBarTitle from '../../TopBar/TopBarTitle/TopBarTitle';

import { useDispatch, useSelector } from 'react-redux';
import { openDrawer } from '../../../../store/drawerSlice';

import { goTo, toggleCurtain } from '../../../../store/conductorSlice';

import { translate } from '../../../../tools/translator';
import { Fragment } from 'react';

const BrowserTopBar = (props) => {
    const dispatch = useDispatch();
    const product = props.product;

    function handleGoBack() {
        toggleCurtain(dispatch, false);
        setTimeout(() => {
            goTo(dispatch, 'Menu', 'Menu');
        }, 500);
    }

    function goToManager() {
        toggleCurtain(dispatch, true);
        setTimeout(() => {
            goTo(dispatch, product, product + 'Manager');
        }, 500);
    }

    const title = translate(product, 'product', 'displayName');
    const cards = useSelector((state) => state.conductor.product) === 'Cards';
    const cardViewer = useSelector((state) => state.conductor.screen) === 'CardViewer';

    const iconProps =
        product === 'People'
            ? {
                  icon: 'search',
                  fontSize: '32px',
                  marginBottom: '-3px',
              }
            : {
                  icon: 'filter_alt',
                  fontSize: '28px',
                  marginBottom: '-1px',
              };

    return (
        <TopBar product={product} backArrow>
            {!cardViewer && (
                <TopBarButton
                    onClick={handleGoBack}
                    width='5%'
                    iconProps={{
                        icon: 'chevron_left',
                        fontSize: '40px',
                        marginLeft: '-5px',
                    }}
                />
            )}

            <TopBarTitle paddingLeft='24px' width='50%' title={title} />

            <div className={css['options-container']}>
                {!cards && (
                    <Fragment>
                        <TopBarButton
                            onClick={goToManager}
                            paddingLeft='20px'
                            iconProps={{
                                icon: 'grid_view',
                                fontSize: '30px',
                            }}
                        />
                        <TopBarButton
                            onClick={() => {}}
                            paddingLeft='84px'
                            iconProps={{
                                icon: '',
                                fontSize: '24px',
                                marginBottom: '-3px',
                            }}
                        />

                        <TopBarButton
                            onClick={() => {
                                openDrawer(dispatch, 'topics');
                            }}
                            paddingLeft='20px'
                            iconProps={iconProps}
                        />
                    </Fragment>
                )}
            </div>
        </TopBar>
    );
};

export default BrowserTopBar;
