import { Fragment } from 'react';
import css from './Calendar.module.css';

import { Week, WeekDays } from './Week/Week';
import { generateCalendarWeeks } from './date-time-processing';
import { useSelector, useDispatch } from 'react-redux';
import { monthNames, prevNextMonth } from '../../../../tools/timeConstants';
import { updateYearMonth } from '../../../../store/calendarSlice';
import { dummyMember } from './dummyAvailability';

const Calendar = (props) => {
    // const member = useSelector((state) => state.member);
    const member = dummyMember;
    const yearMonth = useSelector((state) => state.calendar.yearMonth);

    const weeks = generateCalendarWeeks(yearMonth, member);
    const month = yearMonth.slice(5, 8);

    return (
        <Fragment>
            <MonthSelect yearMonth={yearMonth} />
            <WeekDays />
            {weeks.map((week) => (
                <Week week={week} key={week[0].date} month={month} />
            ))}
        </Fragment>
    );
};

const MonthSelect = (props) => {
    const dispatch = useDispatch();
    const yearMonth = props.yearMonth;
    const year = yearMonth.slice(0, 4);
    const month = yearMonth.slice(5, 8);
    const monthName = monthNames[month];

    const prevMonth = prevNextMonth[yearMonth][0];
    const nextMonth = prevNextMonth[yearMonth][1];

    function setPrevMonth() {
        updateYearMonth(dispatch, prevMonth);
    }

    function setNextMonth() {
        updateYearMonth(dispatch, nextMonth);
    }

    return (
        <Fragment>
            <div className={css['month-select']}>
                {prevMonth && (
                    <div className={css['month-selector']} onClick={setPrevMonth}>
                        {'<'}
                    </div>
                )}
                <div className={css['month-title']}>
                    {monthName} {year}
                </div>
                {nextMonth && (
                    <div className={css['righ-month-selector']} onClick={setNextMonth}>
                        {'<'}
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default Calendar;
