import css from './EventDate.module.css';
import { timeNames } from '../../../../tools/timeConstants';
import { NameTag } from '../EventThumbnail/EventThumbnail';
import { parseDate } from '../../../../tools/functions/datesFunctions';

const EventDate = (props) => {
    const when = props.when;
    const time = timeNames[when.time];

    const dateDetails = parseDate(when.date);

    return (
        <div className={`${css['main-container']} ${!props.isOn && css['past-date']}`}>
            <DateBox
                day={dateDetails.day}
                month={dateDetails.month}
                size={props.size}
                isOn={props.isOn}
            />

            <div className={css['time-row']}>{time}</div>

            <NameTag
                product='Invitations'
                name={dateDetails.weekday}
                css={{ borderBottomLeftRadius: '0' }}
            />
        </div>
    );
};

const DateBox = (props) => {
    return (
        <div className={`${css['date-box']} ${!props.isOn && css['past-date']}`}>
            <div className={`${css['date-month']} ${!props.isOn && css['past-date']}`}>
                {props.month}
            </div>
            <div className={`${css['date-day']} ${!props.isOn && css['past-date']}`}>
                <div className={`${css['date-day-text']} ${!props.isOn && css['past-date']}`}>
                    {props.day}
                </div>
            </div>
        </div>
    );
};

export default EventDate;
