import css from './InvitationsBrowser.module.css';

import Browser from '../../Shared/Browser/Browser';
import BrowserButton from '../../Shared/Browser/BrowserButtons/BrowserButton/BrowserButton';
import BrowserButtons from '../../Shared/Browser/BrowserButtons/BrowserButtons';
import BrowserTopBar from '../../Shared/Browser/BrowserTopBar/BrowserTopBar';
import AcceptButton from './AcceptButton/AcceptButton';
import { useSelector } from 'react-redux';
import { Fragment } from 'react';

const InvitationsBrowser = (props) => {
    const invitations = useSelector((state) => state.firestore.invitations);
    const browser = useSelector((state) => state.browser);
    const activeSection = browser.topics.active.section;
    const showClearX = browser.invitation['where'] && browser.invitation['where'].selection;
    const hideButtons =
        (activeSection === 'where' && showClearX) || invitations.elements.elementIds.length === 0;

    return (
        <Fragment>
            <BrowserTopBar product='Invitations' />
            <Browser
                elements={invitations.elements}
                elementIds={invitations.elements.elementIds}
                product='Invitations'
                useTimer={true}
            >
                <AcceptButton />
            </Browser>
            {!hideButtons && <Buttons />}
        </Fragment>
    );
};

const Buttons = (props) => {
    const timer = useSelector((state) => state.browser.timer);
    const nextCountDown = timer.nextCountDown;

    return (
        <div className={css['invitations-buttons']}>
            <BrowserButtons>
                <BrowserButton icon='archive' iconSize='30px' action='showNext' size='verySmall' />
                <BrowserButton icon='reply' action='showPrevious' />
                <BrowserButton
                    icon='reply'
                    action='showNext'
                    timer={nextCountDown > 0 && nextCountDown}
                    flip
                />
                <BrowserButton
                    size='verySmall'
                    text='Invite'
                    textStyle={{ fontSize: '20px' }}
                    action='toggleDrawer'
                />
            </BrowserButtons>
        </div>
    );
};

export default InvitationsBrowser;
