import css from './NameTag.module.css';

import { goTo, toggleCurtain } from '../../../../../store/conductorSlice';
import { useDispatch, useSelector } from 'react-redux';
import { loadCard } from '../../../../../store/managerSlice';
import { translate } from '../../../../../tools/translator';
import { updateEditorKeys } from '../../../../../store/editorSlice';
import { getTimeBlocksFromWindows } from '../../../../Invitations/InvitationEditor/TimeSelect/timeSelectFunctions';


const NameTag = (props) => {
    const dispatch = useDispatch();
    const product = props.product;
    const element = props.element || props;

    function handleClick() {
        // loadCard(dispatch, props.id);
        // goTo(dispatch, 'Cards', 'Cards');
        
        const elementType = translate(product, 'product', 'elementType')
        updateEditorKeys(dispatch, [elementType], element);
        if(elementType === 'invitation'){
            updateEditorKeys(dispatch, ['timeSelect'], {blocks: getTimeBlocksFromWindows(element.times)})
        }

        toggleCurtain(dispatch, true);
        setTimeout(() => {
            goTo(dispatch, product, translate(product, 'product', 'editor'));
        }, 500);
    }

    return (
        <div
            className={`${css['main-container']} ${props.product}`}
            style={props.css}
            onClick={handleClick}
        >
            <div className={css['text-container']}>{element.name}</div>
        </div>
    );
};

export default NameTag;
