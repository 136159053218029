import { configureStore } from '@reduxjs/toolkit';

import { audioReducer } from './audioSlice';
import { browserReducer } from './browserSlice';
import { calendarReducer } from './calendarSlice';
import { conductorReducer } from './conductorSlice';
import { drawerReducer } from './drawerSlice';
import { editorReducer } from './editorSlice';
import { eventReducer } from './eventSlice';
import { eventsReducer } from './eventsSlice';
import { firestoreReducer } from './firestoreSlice';
import { managerReducer } from './managerSlice';
import { notiifcationReducer } from './notificationSlice';
import { userReducer } from './userSlice';
import { videoReducer } from './videoSlice';

const store = configureStore({
    reducer: {
        audio: audioReducer,
        browser: browserReducer,
        calendar: calendarReducer,
        conductor: conductorReducer,
        drawer: drawerReducer,
        editor: editorReducer,
        event: eventReducer,
        events: eventsReducer,
        firestore: firestoreReducer,
        manager: managerReducer,
        notification: notiifcationReducer,
        user: userReducer,
        video: videoReducer,
    },
});

export default store;
