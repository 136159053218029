import css from './SaveButton.module.css';
import Icon from '../../../Icon/Icon';

const SaveButton = (props) => {
    
    return (
        <div className={css['main-container']} onClick={props.onSave}>
            <div className={css['inner-container']}>
                <Icon icon='save' fontSize='28px' marginBottom='-1px' />
                {/* <div className={css['text-container']}>Save</div> */}
            </div>
        </div>
    );
};

export default SaveButton;
