import css from './GoogleButton.module.css';

const GoogleButton = (props) => {
    return (
        <div className={css['button-frame']} onClick={props.onClick}>
            <div className={css['button-icon']}>
                <img
                    src={require('../../../../images/google-signin-button/g-normal.png')}
                    alt='Sing in with Google'
                    width={23}
                    height={23}
                />
            </div>
            <div
                className={`${css['text-container']} ${
                    props.isPressed && css['pressed']
                }`}
            >
                <div className={css['button-text']}>{props.text}</div>
            </div>
        </div>
    );
};

export default GoogleButton;
