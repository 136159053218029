import css from './Confirmation.module.css';

import Logo from '../../Shared/Logo/Logo';

import { goTo } from '../../../store/conductorSlice';
import { clearInvitation, showNext } from '../../../store/browserSlice';
import { addEvent } from '../../../store/eventSlice';

import { useSelector, useDispatch } from 'react-redux';
import Icon from '../../Shared/Icon/Icon';
import MuteButton from '../../Shared/Browser/VerticalVideoPlayer/SideButtons/MuteButton/MuteButton'
import { timeNames } from '../../../tools/timeConstants';

const Confirmation = (props) => {
    const dispatch = useDispatch();
    const member = useSelector(
        (state) => state.browser.elements.masterRef[state.browser.elements.activeId]
    );

    const invitation = useSelector((state) => state.browser.invitation);
    const place = useSelector(state => state.firestore.places.elements[invitation.where.selection])

    const time = invitation.when.details.content;

    function handleClick() {
        const event = {
            id: member.name,

            who: {
                name: member.name,
                image: member.image,
            },
            where: {
                name: place.name,
                image: place.thumbnail,
            },
            when: {
                weekday: time.weekday,
                day: time.day,
                month: time.month,
                time: time.time,
                isOn: true,
            },
        };
        addEvent(dispatch, event);

        clearInvitation(dispatch);
        showNext(dispatch);
        goTo(dispatch, 'Invitations', 'InvitationsBrowser');
    }

    return (
        <main className={css['main-container']}>
            <div className={css['go-back']} onClick={handleClick}>
                <Icon icon='arrow_back' fontSize='48px' />
            </div>
            <div className={css['title-container']}>
                <div className={css['top-title']}>It's</div>
                <div className={css['event-title']}>Happening!</div>
            </div>

            <div className={css['message']}>
                <div className={css['message-box']}>
                    <div className={css['message-line']}>
                        <span className={css['event-element']}>{member.name}</span> will be meeting
                        you at <span className={css['event-element']}>{place.name}</span>{' '}
                        {time.weekday !== 'Today' && time.weekday !== 'Tonight' && ' on '}
                        <span className={css['event-element']}>{time.weekday}</span> at{' '}
                        {timeNames[time.time]}.
                    </div>
                    <div className={css['message-line']}>
                        We'll be sending you text reminders and a calendar invite.
                    </div>

                    <div className=''>
                        Please let us know if you have any questions or need to make any changes.{' '}
                    </div>
                </div>
            </div>

            <div className={css['logo-container']}>
                <Logo size='mini' color='light' />
            </div>

            <img
                className={css['image']}
                src={require('../../../images/event-confirmation/confirm-beach.jpg')}
                alt=''
            />
            <MuteButton />
        </main>
    );
};

export default Confirmation;
