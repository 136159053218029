import css from './Application.module.css';

import { useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';

import SignInInput from '../SignIn/SignInInput/SignInInput';
import PhoneInput from './PhoneInput/PhoneInput';
import ConfirmButton from '../../Shared/Buttons/ConfirmButton/ConfirmButton';
import LearnAboutUs from '../LearnAboutUs/LearnAboutUs';

import { goTo } from '../../../store/conductorSlice';
import { createApplication } from '../../../Firebase/Firestore/applicationsFirestore';


const Application = (props) => {
    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState({ country: 'CAN', code: '+1', number: '' });
    const [validInputs, setValidInputs] = useState(false);
    const [error, setError] = useState(null);

    function checkForValidInputs() {
        if (name.trim().length > 1 && email.includes('@') && phone.number.trim().length > 8) {
            setValidInputs(true);
            return;
        }
        setValidInputs(false);
    }

    function handleNameChange(event) {
        setName(event.target.value);
        checkForValidInputs();
    }

    function handleEmailChange(event) {
        setEmail(event.target.value);
        checkForValidInputs();
    }

    function handlePhoneChange(newPhone) {
        setPhone(newPhone);
        checkForValidInputs();
    }

    async function handleApply(event) {
        const application = { name, email, phone };
        await createApplication(application);
        goTo(dispatch, 'Home', 'ApplicationSubmitted');
    }

    return (
        <Fragment>
            <main className={css['main-container']}>
                <div className={css['application-title']}>Application</div>
                <div className={css['form-container']}>
                    <div className={css['application-message']}>
                        Kins is a <b>private community</b> for kind people who are willing to put themselves out there. 
                    </div>

                    <SignInInput
                        icon='face'
                        placeholder='Name'
                        onChange={handleNameChange}
                        value={name}
                        inputType='text'
                    />

                    <SignInInput
                        icon='email'
                        placeholder='Email'
                        onChange={handleEmailChange}
                        value={email}
                        inputType='email'
                    />

                    <PhoneInput onChange={handlePhoneChange} value={phone} />

                    {error && <div className={css['error-message']}>Something went wrong</div>}
                    <ConfirmButton
                        validInputs={validInputs}
                        product='GoodTimes'
                        handleConfirm={handleApply}
                        confirmText={`Submit`}
                        border={true}
                        marginTop='20px'
                    />
                </div>
                <LearnAboutUs />
            </main>
        </Fragment>
    );
};

export default Application;
