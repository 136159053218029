import css from './TinyThumbnail.module.css';

const TinyThumbnail = (props) => {
    return (
        <div className={css['main-container']}>
            <img className={css['thumbnail-image']} src={props.imgSrc} alt='' />
        </div>
    );
};

export default TinyThumbnail;
