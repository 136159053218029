import css from './SignInInput.module.css';

const SignInInput = (props) => {
    return (
        <div className={css['input-container']}>
            <div className={css['input-icon-container']}>
                <span className={`material-icons-round ${css['input-icon']}`}>{props.icon}</span>
            </div>
            <input
                className={css['input-text']}
                type={props.inputType}
                placeholder={props.placeholder}
                onChange={props.onChange}
            />
        </div>
    );
};

export default SignInInput;
