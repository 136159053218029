import css from './CountDownTimer.module.css';

import { useState, useEffect, Fragment } from 'react';

const CountDownTimer = ({ when }) => {
    const [year, month, day] = when.date.split('-');
    const hours = when.time.slice(0, 2);
    const minutes = when.time.slice(2, 4);
    const eventDate = new Date(year, month - 1, day, hours, minutes);

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const currentDate = new Date();
        const currentTimeDifference = eventDate - currentDate
        
        const timer = setInterval(() => {
            // console.log(currentDate)
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        if(currentTimeDifference <= 0){
            clearInterval(timer);
        }
        
        return () => {
            clearInterval(timer);
        };
    }, []);

    function calculateTimeLeft() {
        const currentDate = new Date();
        
        let timeDifference = eventDate - currentDate;

        if (timeDifference <= 0) {
            // Timer expired
            return {
                days: '00',
                hours: '00',
                minutes: '00',
                seconds: '00',
            };
        }

        // Calculate time components
        let seconds = Math.floor((timeDifference / 1000) % 60);
        let minutes = Math.floor((timeDifference / 1000 / 60) % 60);
        let hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
        let days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        // Add leading zeros
        days = String(days).padStart(2, '0');
        hours = String(hours).padStart(2, '0');
        minutes = String(minutes).padStart(2, '0');
        seconds = String(seconds).padStart(2, '0');

        return {
            days,
            hours,
            minutes,
            seconds,
        };
    }

    return (
        <Fragment>
            <div className={css['happening-in']}>Happening in</div>
            <div className={css['main-container']}>
                <TimerValue value={timeLeft.days} units='Days' />
                <TimerValue value={timeLeft.hours} units='Hours' />
                <TimerValue value={timeLeft.minutes} units='Mins' />
                <TimerValue value={timeLeft.seconds} units='Secs' />
            </div>
        </Fragment>
    );
};

const TimerValue = (props) => {
    const showDots = props.units !== 'Days';

    return (
        <Fragment>
            {showDots && <div className={css['time-dots']}>:</div>}
            <div className={css['timer-value']}>
                <div className={css['time-value']}>{props.value}</div>
                <div className={css['time-units']}>{props.units}</div>
            </div>
        </Fragment>
    );
};

export default CountDownTimer;
