import { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { goTo } from '../../store/conductorSlice';
import Calendly from '../Cards/Calendly/Calendly';

import { batchSetDoc, batchDeleteDoc } from '../../Firebase/Firestore/batch-functions';
import { callCloudFunction } from '../../Firebase/CloudFunctions';
import { realPeople } from '../../DummyData/People/realPeople';
import { updateFirestoreKeys } from '../../store/firestoreSlice';


const Workbench = (propos) => {
    const dispatch = useDispatch();

    useEffect(() => {
        updateFirestore();
        triggerCloudFunction();
        goTo(dispatch, 'Home', 'AccountNotFound');
    }, []);

    return (
        <Fragment>
            {/* <Calendly url='https://calendly.com/kins-ai/video-session' /> */}            
        </Fragment>
    );

    async function updateFirestore() {
        // console.log('Updating Firestore...');
        // batchSetDoc('users', ['dave'], realPeople);
        // batchSetDoc('users', realPeople.allIds, realPeople);
        // batchDeleteDoc('users', ['kundan', 'tish', 'dave']);
        // batchDeleteDoc('users', ['caleb', 'valeria', 'tish']);
    }

    async function triggerCloudFunction() {
        // console.log('Triggring cloud function...');
        // callCloudFunction();
    }
};

export default Workbench;
