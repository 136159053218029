import css from './EditorThumbnail.module.css';

import NameTag from './NameTag/NameTag';
import { useDispatch, useSelector } from 'react-redux';
import { updateEditor } from '../../../../../store/editorSlice';
import { toggleDrawer } from '../../../../../store/browserSlice';
import { removeValueFromArray } from '../../../../../tools/data-processing';

const EditorThumbnail = (props) => {
    const content = useSelector((state) => state.editor.drawer.content);

    if (content === 'thumbnails') {
        return <ImageChoice element={props.element} product={props.product} />;
    }

    return <ElementThumbnail element={props.element} product={props.product} />;
};

const ElementThumbnail = (props) => {
    const dispatch = useDispatch();

    const product = props.product;
    const lcProduct = product.toLowerCase();
    const element = props.element;

    const editing = useSelector((state) => state.editor.editing);
    const elementIds = useSelector((state) => state.editor[editing][lcProduct]);
    const elementId = element.meta.id;

    const isOn = elementIds.includes(elementId);
    const imgSrc = element.thumbnail;

    return (
        <div
            className={css['outer-container']}
            onClick={() => {
                const newValue = isOn
                    ? removeValueFromArray([...elementIds], elementId)
                    : [...elementIds, elementId];

                updateEditor(dispatch, [editing, lcProduct], newValue);
            }}
        >
            {element.name && <NameTag product={product} name={element.name} />}
            <div className={`${css['main-container']} ${product}`}>
                <img
                    className={`${css['thumbnail-image']} ${isOn ? '' : css['item-off']}`}
                    src={imgSrc}
                    alt=''
                />
            </div>
        </div>
    );
};

const ImageChoice = (props) => {
    const dispatch = useDispatch();
    const product = props.product;
    const element = props.element;
    const imgSrc = element.image;

    return (
        <div
            className={css['outer-container']}
            onClick={handleClick}
        >
            {element.name && <NameTag product={product} name={element.name} />}
            <div className={`${css['main-container']} ${product}`}>
                <img className={`${css['thumbnail-image']}`} src={imgSrc} alt='' />
            </div>
        </div>
    );

    function handleClick(){
        updateEditor(dispatch, [element.elementType, 'thumbnail'], element.image);
        if (element.name) {
            updateEditor(dispatch, [element.elementType, 'name'], element.name);
        }
        toggleDrawer(dispatch, 'hidden');
    }
};

export default EditorThumbnail;
