import './App.css';

import { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import AudioPlayer from './components/Shared/AudioPlayer/AudioPlayer';
import Account from './components/Account/Account';
import Home from './components/Home/Home';
import Menu from './components/Menu/Menu';
import Cards from './components/Cards/Cards';
import Events from './components/Events/Events';
import Invitations from './components/Invitations/Invitations';
import People from './components/People/People';
import Places from './components/Places/Places';
import Workbench from './components/Workbench/Workbench';
import Notification from './components/Shared/Notification/Notification';
import Curtain from './components/Shared/Curtain/Curtain';
import ModalDialog from './components/Shared/ModalDialog/ModalDialog';
import Tutorial from './components/Shared/Tutorial/Tutorial';

import { handleAuthStateChange } from './Firebase/Authentication';
import VideoPlayer from './components/Shared/VideoPlayer/VideoPlayer';

function App() {
    const dispatch = useDispatch();
    const currentURL = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        handleAuthStateChange(currentURL, navigate, dispatch);
    }, []);

    const product = useSelector((state) => state.conductor.product);
    const tutorialMode = useSelector(state => state.video.tutorial.show)

    let currentViewHeight = window.innerHeight + 'px';
    // console.log('Current Height: ', currentViewHeight);

    const borderRadius = tutorialMode ? '0px' : '13px';

    return (
        <div className='outer-app-main-container'>
            <div
                className='app-main-container'
                style={{
                    '--currentViewHeight': 'min(' + currentViewHeight + ', 100vh, 850px)',
                    '--currentViewWidth': 'min(100vw, 412px)',
                   borderRadius          
                }}
            >
                <AudioPlayer />
                <Curtain />
                <ModalDialog />
                <Notification />

                <Tutorial />

                {product === 'Account' && <Account />}
                {product === 'Home' && <Home />}
                {product === 'Menu' && <Menu />}

                {product === 'Events' && <Events />}

                {product === 'Invitations' && <Invitations />}
                {product === 'People' && <People />}
                {product === 'Places' && <Places />}

                {product === 'Cards' && <Cards />}
                {product === 'Workbench' && <Workbench />}

                <VideoPlayer />
            </div>
        </div>
    );
}

export default App;
