import css from './InfoDrawer.module.css';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import Map from '../../../Places/PlacesBrowser/Map/Map';
import Hours from '../../../Places/PlacesBrowser/Hours/Hours';
import Photos from './Photos/Photos';
import Prices from './Prices/Prices';

import InfoDrawerTopBar from './InfoDrawerTopBar/InfoDrawerTopBar';

const InfoDrawer = (props) => {
    const infoBox = useSelector((state) => state.drawer.infoBox);
    const content = infoBox[infoBox.activeComponent];
    const components = { Map, Hours, Photos, Menu: Photos, Prices };
    const Component = components[infoBox.activeComponent];

    return (
        <Fragment>
            <InfoDrawerTopBar />
            <div className={css['main-container']}>
                <div className={css['max-size-inner']}>
                    <Component content={content} />
                </div>
            </div>
        </Fragment>
    );
};

export default InfoDrawer;
