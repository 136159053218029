import css from './ModalDialog.module.css';
import Icon from '../Icon/Icon';
import { useSelector, useDispatch } from 'react-redux';
import { updateConductorKeys } from '../../../store/conductorSlice';
import ContactSupport from './ContactSupport/ContactSupport';
import CancelEvent from './CancelEvent/CancelEvent';

const ModalDialog = (props) => {
    const dispatch = useDispatch();

    const modal = useSelector((state) => state.conductor.modalDialog);
    const template = modal.template;

    if (!modal.show || !template) {
        return <></>;
    }

    const templates = { ContactSupport, CancelEvent };
    const Component = templates[template]

    return (
        <div className={css['main-container']}>
            <ClearX onClick={clearModal} />
            <Component />
        </div>
    );

    function clearModal() {
        updateConductorKeys(dispatch, ['modalDialog'], {
            show: false,
            template: null,
            content: {},
        });
    }
};

const ClearX = (props) => {
    return (
        <div className={css['clear-x']} style={{}} onClick={props.onClick}>
            <Icon icon='clear' fontSize='48px' marginTop='-10px' marginRight='-10px' />
        </div>
    );
};

export default ModalDialog;
