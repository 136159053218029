import css from './Hours.module.css';

const Hours = (props) => {
    const days = props.content.hours;

    return (
        <div className={css['outer-container']}>
            <div className={css['main-container']}>
                <div className={css['title']}>Opening Hours</div>
                {days.map((day) => (
                    <DayHours key={day} hours={day} />
                ))}
            </div>
        </div>
    );
};

const DayHours = (props) => {
    const hours = parseOpeningHours(props.hours);

    if (!hours.closed) {
        return (
            <div className={css['day-hours-main']}>
                <div className={css['day-name']}>{hours.dayOfWeek}</div>
                <div className={css['day-start']}>{hours.opening}</div>
                <div className={css['hours-dash']}> - </div>
                <div className={css['day-end']}>{hours.closing}</div>
            </div>
        );
    } else {
        return (
            <div className={css['day-hours-main']}>
                <div className={css['day-name']}>{hours.dayOfWeek}</div>
                <div className={css['day-end']}>Closed</div>
            </div>
        );
    }

    function parseOpeningHours(openingHoursString) {
        //Needs handling if closed.

        const openingHoursArray = openingHoursString.split(': ');
        const dayOfWeek = openingHoursArray[0];
        if (openingHoursArray[1] === 'Closed') {
            return { dayOfWeek, closed: true };
        }

        const times = openingHoursArray[1].split('–');
        const openingHour = times[0].trim();
        const closingHour = times[1].trim();
        return {
            dayOfWeek: dayOfWeek,
            opening: openingHour,
            closing: closingHour,
            closed: false,
        };
    }
};

export default Hours;
