import { getImageUrl } from '../../../tools/getImageUrl';

export const invitationThumbnails = {
    elementIds: [
        'arcade',
        'bowling',
        'comedy-show',
        'dinner',
        'game-night',
        'go-karts',
        'grab-a-drink',
        'grab-coffee',
        'hiking',
        'karaoke',
        'laser-tag',
        'movie-night',
    ],

    product: 'Invitations',

    arcade: {
        id: 'arcade',
        name: 'Arcade',
        elementType: 'invitation',
        image: getImageUrl('arcade'),
    },

    bowling: {
        id: 'bowling',
        name: 'Bowling',
        elementType: 'invitation',
        image: getImageUrl('bowling'),
    },

    'comedy-show': {
        id: 'comedy-show',
        name: 'Comedy Show',
        elementType: 'invitation',
        image: getImageUrl('comedy-show'),
    },

    dinner: {
        id: 'dinner',
        name: 'Dinner',
        elementType: 'invitation',
        image: getImageUrl('dinner'),
    },

    'game-night': {
        id: 'game-night',
        name: 'Play a Boardgame',
        elementType: 'invitation',
        image: getImageUrl('game-night'),
    },

    'go-karts': {
        id: 'go-karts',
        name: 'Go Karts',
        elementType: 'invitation',
        image: getImageUrl('go-karts'),
    },

    'grab-a-drink': {
        id: 'grab-a-drink',
        name: 'Grab a Drink',
        elementType: 'invitation',
        image: getImageUrl('grab-a-drink'),
    },

    'grab-coffee': {
        id: 'grab-coffee',
        name: 'Grab Coffee',
        elementType: 'invitation',
        image: getImageUrl('grab-coffee'),
    },

    hiking: {
        id: 'hiking',
        name: 'Hiking',
        elementType: 'invitation',
        image: getImageUrl('hiking'),
    },

    karaoke: {
        id: 'karaoke',
        name: 'Karaoke',
        elementType: 'invitation',
        image: getImageUrl('karaoke'),
    },

    'laser-tag': {
        id: 'laser-tag',
        name: 'Laser Tag',
        elementType: 'invitation',
        image: getImageUrl('laser-tag'),
    },

    'movie-night': {
        id: 'movie-night',
        name: 'Movie Night',
        elementType: 'invitation',
        image: getImageUrl('movie-night'),
    },
};
