import css from '../Bucket/Category/Item/Item.module.css';

import { useSelector, useDispatch } from 'react-redux';
import { toggleItem } from '../../../../store/drawerSlice';

const FilterItem = (props) => {
    const dispatch = useDispatch();
    const drawer = useSelector((state) => state.drawer);

    const item = props.item;

    function handleClick() {
        toggleItem(dispatch, item.id, false);
    }

    return (
        <div
            className={`${css['drawer-item']} ${drawer.product} ${item.shiny && css['large']} `}
            onClick={handleClick}
        >
            <div className={`${css['text-container']} ${item.shiny && css['shiny']}`}>
                {item.name}
            </div>
        </div>
    );
};

export default FilterItem;
