import { createSlice } from '@reduxjs/toolkit';
import { updateSliceKeys, updateSlice } from './update-slice';
import { cloneObject } from '../tools/data-processing';
import { nextCountDown, inviteCountDown } from '../tools/parameters';
import { unpackVideoTopics, unpackPlaceTopics } from '../tools/pilotTopics';

const initialState = {
    product: null,

    elements: {
        activeId: null,
        masterRef: {},
        queue: [],
        position: 0,
        count: 0,
    },

    places: {
        activeId: null,
        queuedId: null,
        queue: [],
        position: 0,
    },

    topics: {
        active: {
            name: null, // To override element name if required when chosing on Invitations.
            section: null, //section_id
            section_id: 'topic_id', // for each section what was the last active topic if any.
        },

        sections: [],
        masterRef: {},
        toast: false,
    },

    player: {
        action: null,
        playbackId: null,
        showControls: true, // for showing the controls on mobile at first.
        validNext: false,
        validPrevious: false,
        volume: 'on',
        zIndex: -1,
        vertical: false,
        muted: false,
        mobileReady: false,
    },

    infoBox: {
        show: false,
        content: {},
    },

    choiceScreen: {
        show: false,
        content: {},
    },

    invitation: {
        where: { topicId: null, selection: null, options: [] },
        when: { topicId: null, selection: null, options: [] },
    },

    action: {
        trigger: false,
        payload: {},
    },

    timer: {
        use: false,

        lastFullSec: 0,
        nextCountDown: nextCountDown,
        addCountDown: inviteCountDown,

        watch: false,
        nextLock: true,
        addLock: true,
    },

    stage: {
        name: null, // Name on curtain.
        audio: null, // announcement audio.
        action: null, // to trigger AudioPlayer;

        image: null, // Background immage on empty stage.

        curtain: 'closed',
        lights: 'on',
        topics: 'closed',

        speed: [1500, 1500], // [close, open] animation speed;

        queuedPlaybackId: null,
        queuedElementId: null,
    },

    manager: {
        trigger: null,
        lastTrigger: null,
        step: null,
        endPoint: 'CurtainDown',
    },

    host: {
        playbackId: null,
        action: null,
        volume: 'on',
        zIndex: -1,
        showControls: true,

        activeSegmentId: 'introduction',
        queuedSegmentId: null,
        segments: {},
        segmentEnded: false,

        topicsTime: 0,
        showTopics: false, // to be triggered by the host

        cue: null,
        cueEndTime: null,
    },

    bottomDrawer: 'default',
};

const empty_stage = {
    id: 'empty_stage',
    type: 'info_screen',
    category: '',
    name: '',
    shared: false,
    playbackId: null,
    audio: null,
    content: { component: 'Nothing' },
};

const browserSlice = createSlice({
    name: 'browser',
    initialState,
    reducers: {
        updateBrowserKey(state, action) {
            updateSlice(state, action);
        },

        updateBrowserKeys(state, action) {
            updateSliceKeys(state, action);
        },

        loadElements(state, action) {
            state.stage.queuedElementId = null;

            const product = action.payload.product;
            state.product = product;

            const queue = action.payload.queue;
            const elements = action.payload.elements;
            queue.forEach((id) => {
                if (product === 'People') {
                    state.elements.masterRef[id] = unpackVideoTopics(elements[id]);
                } else if (product === 'Places') {
                    state.elements.masterRef[id] = unpackPlaceTopics(elements[id], id);
                } else {
                    state.elements.masterRef[id] = elements[id];
                }
            });

            state.elements.queue = queue.slice();
            state.elements.count = queue.length;
            state.timer.use = action.payload.useTimer;

            state.choiceScreen.show = false;
            state.infoBox.show = false;
            if (queue.length > 0) {
                updateActiveElement(state, queue[0]);
                updateQueuePlaybackId(state, queue[0]);
            }

            state.stage.curtain = 'closed';
            state.stage.topics = 'closed';
            state.manager.endPoint = 'CurtainDown';
            state.manager.trigger = 'enterBrowser';
            state.manager.lastTrigger = null;
            state.manager.step = null;
        },

        showNext(state, action) {
            showNew(state, 1);
        },

        showPrevious(state, action) {
            showNew(state, -1);
        },

        updateQueue(state, action) {
            const newQueue = action.payload.newQueue;
            state.elements.queue = newQueue;
            state.elements.position = 0;
            state.elements.count = newQueue.length;
            showNew(state, 0);
        },

        triggerElementUpdate(state, action) {
            const stageId = state.stage.queuedElementId;
            const activeId = state.elements.activeId;
            if (stageId && stageId !== activeId) {
                updateActiveElement(state, stageId);
            }
        },

        triggerPlaceUpdate(state, action) {
            state.places.activeId = state.places.queuedId;
            state.places.queuedId = null;
        },

        updateTimeLock(state, action) {
            const timer = state.timer;
            const currentTime = Math.floor(action.payload.currentTime);

            if (currentTime - timer.lastFullSec === 0) {
                return;
            }

            if (currentTime - timer.lastFullSec === 1) {
                timer.lastFullSec += 1;
                timer.nextCountDown -= 1;
                timer.addCountDown -= 1;

                if (timer.nextCountDown <= 0) {
                    // if (!state.timer.watch) {
                    //     if (state.player.validNext && !state.manager.step) {
                    //         showNew(state, 1);
                    //     }
                    // }

                    timer.nextLock = false;
                }

                if (timer.addCountDown < 0) {
                    timer.addLock = false;
                }
                return;
            }

            if (currentTime - timer.lastFullSec !== 0) {
                timer.lastFullSec = currentTime;
            }
        },

        keepWatching(state, action) {
            state.timer.watch = true;
        },

        updateSection(state, action) {
            const newSectionId = action.payload.sectionId;

            const section = state.topics.masterRef[newSectionId];

            if (section.type === 'topics_section') {
                state.topics.active.section = newSectionId;
                const topicId = state.topics.active[state.topics.active.section];
                updateActiveTopic(state, topicId);
            }

            if (section.type === 'action_section') {
                state.action.trigger = true;
                state.action.payload = section.payload;
                state.player.action = 'pause';
            }
        },

        selectChoice(state, action) {
            const choiceId = action.payload.choiceId;
            const choice = state.topics.masterRef[choiceId];

            const sectionId = action.payload.sectionId;
            const section = state.topics.masterRef[sectionId];

            section.topics = choice.topics;
            const topicId = choice.default;

            state.topics.active.name = choice.name;

            state.topics.active[sectionId] = topicId;

            state.invitation[sectionId].selection = choiceId;
            state.invitation[sectionId].details = choice;

            state.stage.image = choice.image;
            updateActiveTopic(state, topicId);
        },

        clearChoice(state, action) {
            console.log('Clearing choice...');
            const sectionId = state.topics.active.section;
            state.invitation[sectionId].selection = null;
            state.invitation[sectionId].details = {};

            const topicId = state.invitation[sectionId].topicId;

            const section = state.topics.masterRef[sectionId];
            section.topics = [topicId];

            if (sectionId === 'where') {
                state.stage.image = null;
            }

            updateActiveTopic(state, topicId);
        },

        clearInvitation(state, action) {
            state.topics.active.section = 'who';

            state.invitation.where.selection = null;
            state.invitation.where.details = {};

            state.invitation.when.selection = null;
            state.invitation.when.details = {};

            state.infoBox.show = false;
            state.infoBox.content = {};
        },

        updateTopic(state, action) {
            const topicId = action.payload.topicId;

            updateActiveTopic(state, topicId);
        },

        toggleToast(state, action) {
            const showToast = action.payload.showToast;
            state.topics.toast = showToast;
        },

        toggleDrawer(state, action) {
            const targetState = action.payload.targetState;
            state.bottomDrawer = targetState;
        },

        updateContent(state, action) {
            const target = action.payload.target;
            const newValue = action.payload.newValue;
            state.infoBox.content[target] = newValue;
        },

        resetAction(state, action) {
            state.action.trigger = false;
        },

        updateBrowser(state, action) {
            const sectionKey = action.payload.sectionKey;
            const payload = action.payload.payload;
            state[sectionKey] = { ...state[sectionKey], ...payload };
        },

        backToHost(state, action) {
            if (state.manager.lastTrigger !== 'newTopic') {
                state.topics.active.videos = 'host';
            }
        },

        triggerTransition(state, action) {
            if (!state.manager.step) {
                state.manager.trigger = action.payload.trigger;
            }
        },

        continueTransition(state, action) {
            if (state.manager.step) {
                state.manager.step += 1;
            }
        },

        updateInvitationTime(state, action) {
            const newTime = action.payload.newTime;
            state.invitation.when.details.content.time = newTime;
        },
    },
});

function updateActiveElement(state, elementId) {
    const activeElement = state.elements.masterRef[elementId];
    state.elements.activeId = elementId;

    state.topics.masterRef = cloneObject(activeElement.topics);
    state.topics.masterRef.empty_stage = empty_stage;

    state.topics.sections = activeElement.topics.sections;
    state.topics.active = activeElement.topics.default;

    state.player.validNext = state.elements.position + 1 < state.elements.count;
    state.player.validPrevious = state.elements.position - 1 >= 0;

    state.stage.name = activeElement.name;
    state.stage.audio = activeElement.audio;
    state.stage.image = activeElement.image;

    const host = activeElement.host;
    state.host.playbackId = host.playbackId;
    state.host.segments = host.segments;
    state.host.index = host.index;
    state.host.activeSegmentId = 'introduction';
    state.host.topicsTime = host.topicsTime;
    state.host.showTopics = host.topicsTime === 0 ? true : false;

    if (state.product === 'Invitations') {
        state.places.queue = activeElement.where;
        state.places.position = 0;
        state.places.activeId = null;
    }

    resetTimer(state);
}

function updateQueuePlaybackId(state, elementId) {
    const element = state.elements.masterRef[elementId];
    const section = element.topics.default.section;
    const topicId = element.topics.default[section];
    const topic = element.topics[topicId];

    state.stage.name = element.name;
    state.stage.queuedPlaybackId = topic.playbackId;
}

function showNew(state, direction) {
    state.manager.trigger = 'newElement';

    let newPosition = state.elements.position + direction;
    if (newPosition >= state.elements.queue.length) {
        newPosition = 0;
    }
    if (newPosition < 0) {
        newPosition = state.elements.queue.length - 1;
    }

    state.elements.position = newPosition;

    const elementId = state.elements.queue[newPosition];
    state.stage.queuedElementId = elementId;
    updateQueuePlaybackId(state, elementId);
}

function updateActiveTopic(state, topicId) {
    state.topics.active[state.topics.active.section] = topicId;
    const topic = state.topics.masterRef[topicId];
    state.stage.audio = topic.audio;

    if (topic.type === 'video') {
        state.stage.queuedPlaybackId = topic.playbackId;
        state.choiceScreen.show = false;
        state.infoBox.show = false;
        state.manager.trigger = 'newTopic';
    }

    if (topic.type === 'info_screen') {
        state.infoBox.show = true;
        state.choiceScreen.show = false;
        state.infoBox.content = topic.content;
        state.player.action = 'pause';
    }

    if (topic.type === 'choice') {
        state.choiceScreen.show = true;
        state.infoBox.show = false;
        state.choiceScreen.content = topic.content;
        state.player.action = 'pause';

        state.invitation[topic.content.section].topicId = topic.id;
    }
}

function resetTimer(state) {
    const timer = state.timer;
    timer.lastFullSec = 0;
    timer.nextCountDown = nextCountDown;
    timer.addCountDown = inviteCountDown;
    timer.watch = false;
    timer.nextLock = true;
    timer.addLock = true;
}

export const browserReducer = browserSlice.reducer;

const browserActions = browserSlice.actions;

export function loadBrowserElements(dispatch, product, elements, queue, useTimer) {
    dispatch(browserActions.loadElements({ product, elements, queue, useTimer }));
}

export function showNext(dispatch) {
    dispatch(browserActions.showNext({}));
}

export function showPrevious(dispatch) {
    dispatch(browserActions.showPrevious({}));
}

export function updateQueue(dispatch, newQueue) {
    dispatch(browserActions.updateQueue({ newQueue }));
}

export function updateTimeLock(dispatch, currentTime) {
    dispatch(browserActions.updateTimeLock({ currentTime }));
}

export function keepWatching(dispatch) {
    dispatch(browserActions.keepWatching({}));
}

export function updateSection(dispatch, sectionId) {
    dispatch(browserActions.updateSection({ sectionId }));
}

export function selectChoice(dispatch, sectionId, choiceId) {
    dispatch(browserActions.selectChoice({ sectionId, choiceId }));
}

export function clearChoice(dispatch) {
    dispatch(browserActions.clearChoice({}));
}

export function clearInvitation(dispatch) {
    dispatch(browserActions.clearInvitation({}));
}

export function updateTopic(dispatch, topicId) {
    dispatch(browserActions.updateTopic({ topicId }));
}

export function toggleToast(dispatch, showToast) {
    dispatch(browserActions.toggleToast({ showToast }));
}

export function toggleDrawer(dispatch, targetState) {
    dispatch(browserActions.toggleDrawer({ targetState }));
}

export function updateContent(dispatch, target, newValue) {
    dispatch(browserActions.updateContent({ target, newValue }));
}

export function resetAction(dispatch) {
    dispatch(browserActions.resetAction({}));
}

export function updateBrowser(dispatch, sectionKey, payload) {
    dispatch(browserActions.updateBrowser({ sectionKey, payload }));
}

export function triggerElementUpdate(dispatch) {
    dispatch(browserActions.triggerElementUpdate({}));
}

export function triggerPlaceUpdate(dispatch) {
    dispatch(browserActions.triggerPlaceUpdate({}));
}

export function backToHost(dispatch) {
    dispatch(browserActions.backToHost({}));
}

export function triggerTransition(dispatch, trigger) {
    dispatch(browserActions.triggerTransition({ trigger }));
}

export function continueTransition(dispatch) {
    dispatch(browserActions.continueTransition({}));
}

export function updateInvitationTime(dispatch, newTime) {
    dispatch(browserActions.updateInvitationTime({ newTime }));
}

export function updateBrowserKeys(dispatch, path, payload) {
    dispatch(browserActions.updateBrowserKeys({ path, payload }));
}

export function updateBrowserKey(dispatch, path, newValue) {
    dispatch(browserActions.updateBrowserKey({ path, newValue }));
}
