import defaultCss from './Category.module.css';
import menuCss from './MenuCategory.module.css';

import Item from './Item/Item';
import MenuItem from './MenuItem/MenuItem';
import Photo from './Photo/Photo';

import { useSelector } from 'react-redux';

const Category = (props) => {
    const category = props.category;

    const query = useSelector((state) => state.drawer.query);
    const inQuery = category.keyConcat.includes(query) ? 'block' : 'none';

    const activeContent = useSelector((state) => state.drawer.activeContent);
    const Component = activeContent in customComponent ? customComponent[activeContent] : Item;

    const css = activeContent in customCSS ? customCSS[activeContent] : defaultCss;

    return (
        <div className={css['main-container']} style={{ display: inQuery }}>
            {getCategoryTitle()}
            <div className={css['note-container']}>{category.note}</div>
            <div className={css['items-container']}>
                {category.items.map((item) => (
                    <Component key={item.id} item={item} />
                ))}
            </div>
        </div>
    );

    function getCategoryTitle(props) {
        if (category.bold) {
            const titleChunks = category.name.split(category.bold);
            return (
                <div className={css['title-container']}>
                    <span>{titleChunks[0]}</span>
                    <b>{category.bold}</b>
                    <span>{titleChunks[1]}</span>
                </div>
            );
        }

        return <div className={css['title-container']}>{category.name}</div>;
    }
};

const customCSS = {
    menu_fees: menuCss,
};

const customComponent = {
    menu_fees: MenuItem,
    photos: Photo,
};

export default Category;
