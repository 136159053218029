import css from './Landing.module.css';

import AuthChoice from './AuthChoice/AuthChoice';
import Logo from '../../Shared/Logo/Logo';
import LearnAboutUs from '../LearnAboutUs/LearnAboutUs';

const Landing = (props) => {
    return (
        <div className={css['main-container']}>
            <div className={css['mobile']}>
                <div className={css['welcome-container']}>
                    <div className={css['welcome']}>Welcome to</div>
                </div>
                <div className={css['sexy-logo-container']}>
                    <Logo color='light' size='medium' />
                </div>

                <div className={css['auth-choice']}>
                    <AuthChoice />                    
                </div>

                <LearnAboutUs />
            </div>
        </div>
    );
};

export default Landing;
