// import { loadURLdetails } from '../store/invitationSlice';
import { callCloudFunction } from '../Firebase/CloudFunctions';
import { loadCard } from '../store/managerSlice';

import { updateFirestoreKeys } from '../store/firestoreSlice';
import { processPlacesBrowserData } from '../components/Places/PlacesBrowser/processPlacesBrowserData';
import { unpackInvitations } from '../components/Invitations/InvitationsBrowser/unpackInvitations';
import { loadBrowserElements } from '../store/browserSlice';
import { updateEvents, updateEventsKeys } from '../store/eventsSlice';
import { goTo } from '../store/conductorSlice';
import { updateVideoKeys } from '../store/videoSlice';

export async function getDestination(currentURL, navigate, dispatch) {
    const destination = { product: 'Home', screen: 'Landing' };
    const currentPath = currentURL.pathname;

    const sections = currentPath.split('/');
    const firstSection = '/' + sections[1];

    if (firstSection === '/') {
        return destination;
    }

    if (firstSection in validPaths) {
        const instructions = validPaths[firstSection];
        destination.product = instructions[0];
        destination.screen = instructions[1];

        const callback = instructions[2];
        if (callback) {
            callback(destination, currentURL, dispatch);
        }
    } else {
        if (sections.length === 3) {
            goTo(dispatch, 'Invitations', 'InvitationCard'); // To avoid loading on the landing screen.
            await processInvitationPath(destination, currentURL, dispatch);
        }
    }

    // navigate('/'); //clear URL. Replace for just '/'.
    return destination;
}

const validPaths = {
    '/apply': ['Home', 'Application', null],
    '/about': ['Home', 'About', null],
    '/workbench': ['Workbench', 'Workbench', null],
    '/events': ['Events', 'EventInvitation', processEventPath],
    '/cards': ['Cards', 'CardViewer', processCardPath],
    '/invitation': ['Invitations', 'InvitationCard', processInvitationPath],
};

async function processInvitationPath(destination, currentURL, dispatch) {
    const sections = currentURL.pathname.split('/');
    updateEvents(dispatch, ['card', 'guestView'], true)
    try {
        // Only call this function once is determined the user is not logged in.
        const browserData = await callCloudFunction('getInvitationData', {
            user_doc_id: sections[1],
            card_id: sections[2],
        });

        const guestData = {
            info: { base_address: { coordinates: { lat: 45.5045759, lng: -73.5636561 } } },
        };
        const places = processPlacesBrowserData(browserData.places, guestData);
        const people = browserData.people;
        const invitations = unpackInvitations(browserData.invitations, people, places);
        const events = browserData.events;
        updateFirestoreKeys(dispatch, [], {
            people: { ready: true, elements: people },
            places: { ready: true, elements: places },
            invitations: { ready: true, elements: invitations },
            events: { ready: true, elements: events },
        });

        if (events.elementIds.length > 0) {
            hideTutorial();
            updateEventsKeys(dispatch, ['card'], {
                eventId: events.elementIds[0],
                guestView: true,
            });
            destination.product = 'Events';
            destination.screen = 'EventCard';
            return;
        }

        const invtationIds = invitations.elementIds.length > 0 ? [invitations.elementIds[0]] : [];
        destination.product = 'Invitations';
        destination.screen = 'InvitationCard';
        // loadBrowserElements(dispatch, 'Invitations', invitations, invtationIds, false);

        if(invitations.elementIds.length === 0){
            hideTutorial()
        }
    } catch {
        console.log('Error catched!!!');
        hideTutorial()
        return;
    }

    function hideTutorial(){
        updateVideoKeys(dispatch, ['tutorial'], { show: false, browserSeen: true });
        updateVideoKeys(dispatch, ['css'], { zIndex: 1, opacity: 0, pointerEvents: 'none' });
    }
}

function processEventPath(destination, currentURL, dispatch) {
    const params = new URLSearchParams(currentURL.search);

    const urlDetails = {};
    for (const [key, value] of params) {
        urlDetails[key] = value;
    }
    // loadURLdetails(dispatch, urlDetails);
}

function processCardPath(destination, currentURL, dispatch) {
    const params = new URLSearchParams(currentURL.search);
    const urlDetails = {};
    for (const [key, value] of params) {
        urlDetails[key] = value;
    }
    loadCard(dispatch, urlDetails['id']);
}
