export function parseDate(dateString, timeString = '1200') {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const [year, month, day] = dateString.split('-');

    // Create a Date object from the input string
    const date = new Date(year, month - 1, day); // subtract 1 from the month
    date.setHours(0, 0, 0, 0);

    const parsedDay = date.getDate();
    let weekday = date.toLocaleDateString('en-US', { weekday: 'long' });
    const parsedMonth = months[date.getMonth()].substring(0, 3).toUpperCase();

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const isToday = date.getMonth() === today.getMonth() && date.getDate() === today.getDate();
    const isTonight = isToday && parseInt(timeString) >= 1830;

    if (isToday) {
        weekday = 'Today';
    }

    if (isTonight) {
        weekday = 'Tonight';
    }

    return { day: parsedDay, weekday, month: parsedMonth };
}


export function getNowString(){
    const now = new Date()
    return now.toISOString()
}