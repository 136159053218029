import css from './MenuItem.module.css';

import { useSelector } from 'react-redux';

const MenuItem = (props) => {
    const item = props.item;
    const photo = item.photo;

    const drawer = useSelector((state) => state.drawer);
    const query = drawer.query;
    const inQuery = item.keyConcat.includes(query) ? 'flex' : 'none';

    const showPhotos = drawer['menu_fees'].showPhotos;

    return (
        <div className={css['main-container']} style={{display: inQuery}}>
            {showPhotos && photo && <MenuPhoto photo={photo} />}
            <div className={`${css['name-and-ingredients']} ${showPhotos && photo && css['in-photo']}`}>
                <div className={css['dish-name']}>{item.name}</div>
                <div className={css['dish-ingredients']}>{item.ingredients}</div>
            </div>
            <div className={`${css['price-and-extras']} ${showPhotos && photo && css['in-photo']}`}>
                <div className={css['dish-price']}>${item.price}</div>
                <div className={css['extra-ingredients']}>{item.extras}</div>
            </div>
        </div>
    );
};

const MenuPhoto = (props) => {
    const imgSrc = require('../../../../../../images/food/' + props.photo);

    return (
        <div className={css['photo-container']}>
            <img className={css['menu-photo']} src={imgSrc} alt='' />
        </div>
    );
};

export default MenuItem;
