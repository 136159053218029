import css from './EmptyBrowser.module.css';
import Loading from './Loading/Loading';
import EmptyInvitationsInbox from '../../../Invitations/InvitationsBrowser/EmptyInvitationsInbox/EmptyInvitationsInbox';
import { useSelector } from 'react-redux';

const EmptyBrowser = (props) => {
    const product = useSelector((state) => state.conductor.product);
    const isReady = useSelector((state) => state.firestore.invitations.ready);

    const showEmptyInbox = product === 'Invitations' && isReady;

    return (
        <div className={css['main-container']}>
            {showEmptyInbox ? <EmptyInvitationsInbox /> : <Loading />}
        </div>
    );
};

export default EmptyBrowser;
