import css from './Icon.module.css';

const Icon = (props) => {
    const shape = props.shape || 'round';
    const type = shape !== 'filled' ? 'material-icons-' + shape : 'material-icons';

    const fontSize = props.fontSize || '20px';

    const marginTop = props.marginTop || '0px';
    const marginRight = props.marginRight || '0px';
    const marginBottom = props.marginBottom || '0px';
    const marginLeft = props.marginLeft || '0px';

    const cssClass = props.css || '';

    const style = { fontSize, marginTop, marginRight, marginBottom, marginLeft };

    return (
        <div className={`${css['icon-container']} ${cssClass}`}>
            {props.icon && (
                <span
                    className={`${css['icon']} ${type} ${props.flip && css['flip-icon']} ${
                        props.rotate && css['rotate']
                    } ${props.shadow && css['shadow']}`}
                    style={style}
                >
                    {props.icon}
                </span>
            )}
            {props.text && (
                <div className={css['text-icon']} style={style}>
                    {props.text}
                </div>
            )}
        </div>
    );
};

export default Icon;
