import css from './NavigationButtons.module.css';
import BrowserButtons from '../../BrowserButtons/BrowserButtons';
import TopBarButton from '../../../TopBar/TopBarButton/TopBarButton';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawer, updateBrowserKeys, updateSection } from '../../../../../store/browserSlice';
import { updateAudio, blinkAudio } from '../../../../../store/audioSlice';

const NavigationButtons = (props) => {
    const dispatch = useDispatch();
    const places = useSelector((state) => state.browser.places);
    const product = useSelector((state) => state.browser.product);
    const firestorePlaces = useSelector((state) => state.firestore.places.elements);

    const validPrevious = places.queue.length > 1;
    const validNext = places.queue.length > 1;

    const actions = {
        showPrevious: function () {
            showNew(-1);
        },

        showNext: function () {
            showNew(1);
        },

        toggleDrawer: function () {
            toggleDrawer(dispatch, 'visible');
        },
    };

    function showNew(direction) {
        let newPosition = places.position + direction;

        if (newPosition < 0) {
            newPosition = places.queue.length - 1;
        }

        if (newPosition >= places.queue.length) {
            newPosition = 0;
        }

        const newActiveId = places.queue[newPosition];
        const newPlaybackId = firestorePlaces[newActiveId].playbackId;

        const payload = { position: newPosition, queuedId: newActiveId };
        updateBrowserKeys(dispatch, ['places'], payload);
        updateBrowserKeys(dispatch, ['stage'], { queuedPlaybackId: newPlaybackId });
        updateBrowserKeys(dispatch, ['manager'], { trigger: 'newPlace' });
    }

    return (
        <BrowserButtons>
            <div className={css['main-container']}>
                <TopBarButton
                    onClick={validPrevious ? actions['showPrevious'] : () => {}}
                    iconProps={{ icon: 'reply', fontSize: '72px' }}
                    css={{
                        height: '50px',
                        color: 'var(--orange)',
                        opacity: validPrevious ? 1 : 0,
                    }}
                />
                {product === 'Places' && (
                    <div className={`${css['text-button']}`} onClick={actions['toggleDrawer']}>
                        Save
                    </div>
                )}
                {product === 'Invitations' && (
                    <div className={css['choose-container']} onClick={choosePlace}>
                        <div className={`${css['text-button']} ${css['shiny']}`}>Choose</div>
                        <div className={`${css['text-button']} ${css['white-shadow']}`}>Choose</div>
                    </div>
                )}

                <TopBarButton
                    onClick={validNext ? actions['showNext'] : () => {}}
                    iconProps={{ icon: 'reply', fontSize: '72px', flip: true }}
                    css={{
                        height: '50px',
                        color: 'var(--orange)',
                        opacity: validNext ? 1 : 0,
                    }}
                />
            </div>
        </BrowserButtons>
    );

    function choosePlace() {
        updateBrowserKeys(dispatch, ['invitation', 'where'], { selection: places.activeId });
        updateSection(dispatch, 'when');
        updateAudio(dispatch, ['voiceover', 'track'], 'invitations/when-choice.mp3');
        blinkAudio(dispatch, ['voiceover', 'action'], 'play');
    }
};

export default NavigationButtons;
