import css from './DrawerTopBar.module.css';

import TopBar from '../../TopBar/TopBar';
import TopBarTitle from '../../TopBar/TopBarTitle/TopBarTitle';
import TopBarButton from '../../TopBar/TopBarButton/TopBarButton';

import { useDispatch } from 'react-redux';
import { toggleDrawer, triggerTransition } from '../../../../store/browserSlice';
import { launchNotification } from '../../../../store/notificationSlice';
import { translate } from '../../../../tools/translator';

const DrawerTopBar = (props) => {
    const dispatch = useDispatch();

    function hideDrawer() {
        toggleDrawer(dispatch, 'hidden');
        // Prevent hiding animation from triggering when switching screen.
        setTimeout(()=>{            
            toggleDrawer(dispatch, 'default');
        },500)
        launchNotification(dispatch);
        // triggerTransition(dispatch, 'invitationSent');
    }

    return (
        <TopBar product={props.product}>
            <TopBarTitle
                width='70%'
                paddingLeft='3vh'
                title={translate(props.product, 'product', 'displayName')}
            />
            <TopBarButton
                paddingRight='1vh'
                onClick={hideDrawer}
                iconProps={{
                    icon: 'expand_more',
                    fontSize: '40px',
                    css: css['vertical-shadow'],
                }}
            />
        </TopBar>
    );
};

export default DrawerTopBar;
