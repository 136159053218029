import css from './MenuTopBar.module.css';

import { useDispatch, useSelector } from 'react-redux';

import TopBar from '../../Shared/TopBar/TopBar';
import TopBarButton from '../../Shared/TopBar/TopBarButton/TopBarButton';
import Logo from '../../Shared/Logo/Logo';
import AccountCircle from './AccountCircle/AccountCircle';

import { toggleDrawer } from '../../../store/browserSlice';
import { updateConductorKeys } from '../../../store/conductorSlice';

const MenuTopBar = (props) => {
    const dispatch = useDispatch();

    const curtain = useSelector((state) => state.conductor.curtain);

    return (
        <TopBar product='Black'>
            <div className={`${css['logo-container']}`}>
                <ShinyLogo size='menu' top='5px' left='16px' />
            </div>

            <div className={css['options-container']}>
                <TopBarButton
                    onClick={() => {
                        toggleDrawer(dispatch, 'visible');
                    }}
                    iconProps={{
                        icon: 'event',
                        fontSize: '28px',
                    }}
                />

                <TopBarButton
                    onClick={() => {
                        updateConductorKeys(dispatch, ['modalDialog'], {
                            show: true,
                            template: 'ContactSupport',
                        });
                    }}
                    iconProps={{
                        icon: 'support_agent',
                        fontSize: '30px',
                    }}
                />

                <AccountCircle />
            </div>
        </TopBar>
    );
};

export const ShinyLogo = ({ size, top, left }) => {
    return (
        <>
            <div className={css['white-container']} style={{ top, left }}>
                <Logo size={size} />
            </div>
            <div className={css['shiny-container']} style={{ top, left }}>
                <Logo size={size} color='shiny' />
            </div>
        </>
    );
};

export default MenuTopBar;
