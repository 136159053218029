import css from './TutorialButton.module.css';
import Icon from '../../Icon/Icon';
import { useSelector, useDispatch } from 'react-redux';
import { updateVideo } from '../../../../store/videoSlice';
import { updateVideoKeys } from '../../../../store/videoSlice';
import { updateBrowserKey } from '../../../../store/browserSlice';
import { tutorials } from '../Tutorial';
import { updateAudio, blinkAudio } from '../../../../store/audioSlice';
import { goTo } from '../../../../store/conductorSlice';

const TutorialButton = (props) => {
    const dispatch = useDispatch();
    const video = useSelector((state) => state.video);
    const screen = useSelector((state) => state.conductor.screen);
    const showingTutorial = video.tutorial.show;
    const browserSeen = video.tutorial.browserSeen;

    return <>{selectButton()}</>;

    function selectButton() {
        if (screen === 'About') {
            return <ApplyButton />;
        } else {
            return showingTutorial ? <GotItButton /> : <HelpButton />;
        }
    }

    function HelpButton(props) {
        const playbackId = useSelector((state) => state.browser.player.playbackId);

        function handleClick() {
            updateVideoKeys(dispatch, ['tutorial', 'returnTo'], {
                playbackId,
                css: video.css,
                second: 0, // need to start updating second on video slice.
            });

            updateBrowserKey(dispatch, ['player', 'playbackId'], tutorials[screen]);

            updateVideoKeys(dispatch, ['css'], {
                opacity: 1,
                height: 'var(--currentViewHeight)',
                top: '0px',
                pointerEvents: 'all',
                zIndex: 3,
            });

            updateVideo(dispatch, ['tutorial', 'show'], true);

            setTimeout(() => {
                updateBrowserKey(dispatch, ['player', 'action'], 'play');
            }, 100);
        }

        return (
            <div className={`${css['main-container']} ${css['help-button']}`} onClick={handleClick}>
                <Icon icon='help' fontSize='28px' />
            </div>
        );
    }

    function GotItButton(props) {
        const mobileReady = useSelector((state) => state.browser.player.mobileReady);

        function handleClick() {
            if (!browserSeen) {
                updateVideoKeys(dispatch, ['tutorial'], { browserSeen: true, show: false });
                updateVideoKeys(dispatch, ['css'], { zIndex: 1 });
                updateAudio(dispatch, ['music', 'song'], 'clapping');
                blinkAudio(dispatch, ['music', 'action'], 'play');
                return;
            }

            const returnTo = video.tutorial.returnTo;
            updateBrowserKey(dispatch, ['player', 'playbackId'], returnTo.playbackId);
            // need to add a way to update the current second.
            updateVideoKeys(dispatch, ['css'], returnTo.css);

            updateVideo(dispatch, ['tutorial', 'show'], false);

            setTimeout(() => {
                if (returnTo.playbackId && tutorials[screen] !== returnTo.playbackId) {
                    updateBrowserKey(dispatch, ['player', 'action'], 'play');
                }
            }, 100);
        }

        return (
            <>
                {mobileReady && (
                    <div
                        className={`${css['main-container']} ${css['got-it-button']}`}
                        onClick={handleClick}
                        style={{ backgroundColor: 'var(--aqua)' }}
                    >
                        <div className={css['got-it-text']}>Got it!</div>
                    </div>
                )}
            </>
        );
    }

    function ApplyButton(props) {
        const mobileReady = useSelector((state) => state.browser.player.mobileReady);

        function handleClick() {
            updateVideoKeys(dispatch, ['css'], {
                zIndex: 1,
                opacity: 0,
                pointerEvents: 'none',
            });
            updateBrowserKey(dispatch, ['player', 'action'], 'pause');
            updateVideo(dispatch, ['tutorial', 'show'], false);
            goTo(dispatch, 'Home', 'Application');
        }

        return (
            <>
                {mobileReady && (
                    <div
                        className={`${css['main-container']} ${css['got-it-button']}`}
                        onClick={handleClick}
                        style={{ backgroundColor: 'var(--aqua)' }}
                    >
                        <div className={css['got-it-text']}>Apply!</div>
                    </div>
                )}
            </>
        );
    }
};

export default TutorialButton;
