import css from './Image.module.css';


const Image = (props) => {
    const imgSrc = require('../../../../../images/thumbnails/' + props.content.image);
    return (
        <div
            className={css['main-container']}
        >
            <img className={`${css['image']}`} src={imgSrc} alt='' />
        </div>
    );

};

export default Image;