import { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { continueTransition, updateBrowser } from '../../../../store/browserSlice';

const AudioPlayer = (props) => {
    const dispatch = useDispatch();
    const audioRef = useRef(null);

    const stage = useSelector((state) => state.browser.stage);
    const audio = stage.audio;
    const action = stage.action;

    useEffect(() => {
        if (action === 'play') {
            if (audio) {
                audioRef.current.play();
            } else {
                continueTransition(dispatch);
            }
            updateBrowser(dispatch, 'stage', { action: null });
        }
    }, [audioRef, action]);

    function onEnded() {
        continueTransition(dispatch);
    }

    return (
        <audio
            ref={audioRef}
            src={audio && require('../../../../DummyData/sounds/' + audio)}
            onEnded={onEnded}
        />
    );
};

export default AudioPlayer;
