function generateAvailabilityDates(days) {
    const dates = {};
    const present = new Date();
    const presentMili = present.getTime();

    const miliDay = 86400000; // miliseconds on a given day

    for (let i = 0; i < days; i++) {
        const future = new Date(presentMili + miliDay * i);

        let month_number = future.getMonth() + 1;
            if (month_number < 10) {
                month_number = '0' + month_number;
            }

        const dayKey = future.getFullYear() + '-' + month_number + '-' + future.getDate();

        dates[dayKey] = true;
        
    }
    return dates;
}


export const dummyMember = {
    availability:{dates: generateAvailabilityDates(100)},
    events:{}
}