import css from './Notes.module.css';
import { Fragment } from 'react';

const Notes = ({ notes }) => {
    if (notes === '') {
        return <></>;
    }

    const lines = notes.split('\n');

    return (
        <div className={css['main-container']}>
            {lines.map((line, index) => (
                <Fragment key={index}>
                    {line}
                    <br />
                </Fragment>
            ))}
        </div>
    );
};

export default Notes;
