import './Hours.css';
import { weekDays } from '../../../../../tools/timeConstants';

import TimeBlock from '../TimeBlock/TimeBlock';

const Hours = (props) => {
    let css = 'hour';
    if (
        (props.hour[1] === props.mouseHour ||
            props.hour[2] === props.mouseHour) &&
        props.isMouseIn
    ) {
        css += ' mouseHour';
    }

    return (
        <div className='hours-row'>
            <div className={css}>
                <span className='hour-content'>{props.hour[0]}</span>
            </div>
            <div className='hour-buffer'></div>

            {weekDays.map((day) => (
                <TimeBlock
                    id={day + props.hour[1]}
                    key={day + props.hour[1]}
                    isHalfHour={false}
                />
            ))}
            <div className=''></div>
            <div className=''></div>
            {weekDays.map((day) => (
                <TimeBlock
                    id={day + props.hour[2]}
                    key={day + props.hour[2]}
                    isHalfHour={true}
                />
            ))}
        </div>
    );
};

export default Hours;
