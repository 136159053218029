import css from './SignIn.module.css';

import SignInInput from './SignInInput/SignInInput';
import SignInActions from './SignInActions/SignInActions';
import ConfirmButton from '../../Shared/Buttons/ConfirmButton/ConfirmButton';

import { useState, Fragment } from 'react';

import { auth } from '../../../Firebase/Authentication';
import { sendPasswordResetEmail } from 'firebase/auth';

import { useDispatch } from 'react-redux';
import { conductor } from '../../../store/conductorSlice';

const PasswordReset = (props) => {
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [error, setError] = useState(null);

    function handleEmailChange(event) {
        const enteredEmail = event.target.value;
        setEmail(enteredEmail);
        setValidEmail(enteredEmail.includes('@'));
    }

    const dispatch = useDispatch();

    async function handleSubmit() {
        try {
            await sendPasswordResetEmail(auth, email);
            setEmailSent(true);
        } catch (error) {
            setError(error.message);
        }
    }

    function goToSignIn() {
        dispatch(
            conductor.changeScreen({
                targetScreen: 'SignIn',
                targetProduct: 'Home',
            })
        );
    }

    const content = (
        <main className={css['main-container']}>
            <div className={css['reset-password-title']}>
                Passwords are
                <span className='hide-on-desktop'>
                    <br></br>
                </span>{' '}
                so annoying
            </div>

            <div className={css['form-container']}>
                <SignInInput
                    icon='email'
                    placeholder='Email'
                    onChange={handleEmailChange}
                    value={email}
                    inputType='email'
                />

                {error && <div className={css['error-message']}>Something went wrong</div>}

                <ConfirmButton
                    validInputs={validEmail}
                    product='GoodTimes'
                    handleConfirm={handleSubmit}
                    confirmText='Reset Password!'
                    border={true}
                    marginTop='20px'
                />
            </div>
        </main>
    );

    const EmailSent = (
        <main className={css['main-container']}>
            <div className={css['reset-password-title']}>
                Please check{' '}
                <span className='hide-on-desktop'>
                    <br></br>
                </span>
                your email
            </div>

            <ConfirmButton
                validInputs={true}
                product='GoodTimes'
                handleConfirm={goToSignIn}
                confirmText='Go back to sign in'
                border={true}
                marginTop='40px'
            />
        </main>
    );

    return (
        <Fragment>
            {emailSent ? EmailSent : content}
        </Fragment>
    );
};

export default PasswordReset;
