import css from './BrowserButton.module.css';
import { useDispatch, useSelector } from 'react-redux';

import { showNext, showPrevious } from '../../../../../store/browserSlice';
import { toggleDrawer, keepWatching } from '../../../../../store/browserSlice';

import { updateBrowserEntry } from '../../../../../store/userSlice';
import { nextCountDown } from '../../../../../tools/parameters';

const BrowserButton = (props) => {
    const dispatch = useDispatch();

    const product = useSelector((state) => state.browser.product);
    const player = useSelector((state) => state.browser.player);
    const elementId = useSelector((state) => state.browser.elements.activeId);
    const watchTime = nextCountDown - useSelector((state) => state.browser.timer.nextCountDown);
    const adminMode = useSelector((state) => state.conductor.adminMode);

    const size = props.size || 'medium';
    const boxSizes = {
        verySmall: { height: '56px', width: '56px', fontSize: '15px' },
        small: { height: '60px', width: '60px', fontSize: '20px' },
        medium: { height: '75px', width: '75px', fontSize: '30px' },
        large: { height: '110px', width: '110px', fontSize: '50px' },
    };
    const boxSize = boxSizes[size];

    const iconStyle = props.iconStyle;
    const iconSize = props.iconSize || '54px';

    const timer = props.timer;
    const buttonStyle = props.buttonStyle || {};

    const actions = {
        showPrevious: function () {
            showPrevious(dispatch);
        },
        showNext: function () {
            showNext(dispatch);
            // updateBrowserEntry(dispatch, {
            //     elementType: product.toLowerCase(),
            //     elementId,
            //     watchTime,
            // });
        },
        toggleDrawer: function () {
            toggleDrawer(dispatch, 'visible');
        },

        keepWatching: function () {
            keepWatching(dispatch);
        },
    };

    return (
        <button
            style={{ ...boxSize, ...buttonStyle }}
            className={`${css['button-container']} ${props.product || product}`}
            onClick={actions[props.action]}
            disabled={checkIfDisabled()}
        >
            {/* {timer && !adminMode && <Timer time={timer} />} */}
            {/* tktk after Startupfest delete line below and uncoment above */}
            {timer && <Timer time={timer} />}
            <div className={css['icon-container']} style={{ ...iconStyle }}>
                {props.icon && (
                    <span
                        className={`${css['icon']} material-icons-round ${
                            props.flip && css['flip-icon']
                        } ${props.rotate && css['rotate']}`}
                        style={{ fontSize: iconSize }}
                    >
                        {props.icon}
                    </span>
                )}
                {props.text && (
                    <div className={css['text-icon']} style={props.textStyle}>
                        {props.text}
                    </div>
                )}
            </div>
        </button>
    );

    function checkIfDisabled() {
        if (timer && !adminMode) {
            return true;
        }
        const action = props.action;

        if (action === 'showNext' && !player.validNext) {
            return true;
        }

        if (action === 'showPrevious' && !player.validPrevious) {
            return true;
        }

        return false;
    }
};

const Timer = (props) => {
    const time = props.time;
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;

    let timeDisplay = time;
    if (minutes > 0) {
        let secondsDisplay = seconds.toString();
        if (seconds < 10) {
            secondsDisplay = '0' + secondsDisplay;
        }
        timeDisplay = minutes.toString() + ':' + secondsDisplay;
    }

    return <div className={css['timer-container']}>{timeDisplay}</div>;
};

export default BrowserButton;
