import css from './Map.module.css';

const Map = (props) => {
    return (
        <div className={css['main-container']}>
            <iframe
                src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=place_id:${props.content.placeId}&zoom=13`}
                width='100%'
                height='100%'
                allowFullScreen
                frameBorder='0'
                loading='lazy'
                referrerPolicy='no-referrer-when-downgrade'
                title='google-map'
            ></iframe>
        </div>
    );
};

export default Map;
