import css from './Card.module.css';
import Browser from '../../Shared/Browser/Browser';
import { useSelector } from 'react-redux';

const Card = (props) => {
    const product = props.product;
    const elements = useSelector((state) => state.firestore[product.toLowerCase()].elements);

    const elementIds = props.elementId ? [props.elementId] : elements.elementIds;

    return (
        <Browser
            elements={elements}
            elementIds={elementIds}
            product={product}
            useTimer={false}
            topicsCss='light-topics'
            forceLoad={true}
        ></Browser>
    );
};

export default Card;
