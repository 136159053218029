import Browser from '../Shared/Browser/Browser';

import { useSelector, useDispatch } from 'react-redux';
import { realPeople } from '../../DummyData/People/realPeople';
import { useEffect } from 'react';
import { updateBrowser } from '../../store/browserSlice';

const Cards = (props) => {
    const dispatch = useDispatch();
    const card = useSelector((state) => state.manager.card);
    const endPoint = useSelector((state) => state.browser.manager.endPoint);

    useEffect(() => {
        setTimeout(() => {
            if (endPoint === 'CurtainDown') {
                updateBrowser(dispatch, 'stage', {
                    curtain: 'open',
                    topics: 'open',
                });
                updateBrowser(dispatch, 'player', { zIndex: 1 });
                updateBrowser(dispatch, 'manager', {
                    trigger: null,
                    lastTrigger: null,
                    step: null,
                    endPoint: 'PlayerPlaying',
                });
            }
        }, 500);
    }, []);

    return (
        <Browser
            elements={realPeople}
            elementIds={[card]}
            product='People'
            useTimer={true}
            topicsCss='light-topics'
        ></Browser>
    );
};

export default Cards;
