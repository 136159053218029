import { prepareLabels } from "../../../tools/labels";

const tags_buckets = [
    
    { id: 'venue', name: 'Venue', type: 'bucket', keywords: [], categories: [], items: []},    
    { id: 'food', name: 'Food & Drinks', type: 'bucket', keywords: [], categories: [], items: []},
    { id: 'activity', name: 'Activities', type: 'bucket', keywords: [], categories: [], items: []},

];

const tags_categories = [
    // {id: '', name:'', bold: '', bucket: '', type: 'category', keywords: [], items: []},
    
    {id: 'activity-type', name:'', bold: '', bucket: 'activity', type: 'category', keywords: [], items: []},
    {id: 'venue-type', name:'', bold: '', bucket: 'venue', type: 'category', keywords: [], items: []},    
    {id: 'vibe', name:'Vibe', bold: '', bucket: 'venue', type: 'category', keywords: [], items: []},
    {id: 'meal', name:'Serves', bold: '', bucket: 'food', type: 'category', keywords: [], items: []},
    {id: 'feature', name:'Feature', bold: '', bucket: 'food', type: 'category', keywords: [], items: []},
    {id: 'cuisine', name:'Cuisine', bold: '', bucket: 'food', type: 'category', keywords: [], items: []},
    {id: 'restriction', name:'Diertary restrictions', bold: '', bucket: 'food', type: 'category', keywords: [], items: []},
    {id: 'dish', name:'Dish', bold: '', bucket: 'food', type: 'category', keywords: [], items: []},
];

const tags_items = [
    // {id: '', name:'',  category: '', type: 'topic', keywords: [], items: []},

    {id: 'type_bar', name:'Bar', category: 'venue-type', type: 'topic', keywords: [], items: []},
    {id: 'type_restaurant', name:'Restaurant',  category: 'venue-type', type: 'topic', keywords: [], items: []},
    {id: 'type_cafe', name:'Cafe',  category: 'venue-type', type: 'topic', keywords: [], items: []},
    {id: 'type_activity', name:'Activity',  category: 'venue-type', type: 'topic', keywords: [], items: []},
    {id: 'type_virtual', name:'Virtual',  category: 'venue-type', type: 'topic', keywords: [], items: []},

    {id: 'food_alchohol', name:'Alcohol',  category: 'meal', type: 'topic', keywords: [], items: []},
    {id: 'meal_breakfast', name:'Breakfast',  category: 'meal', type: 'topic', keywords: [], items: []},
    {id: 'meal_brunch', name:'Brunch',  category: 'meal', type: 'topic', keywords: [], items: []},
    {id: 'meal_lunch', name:'Lunch',  category: 'meal', type: 'topic', keywords: [], items: []},
    {id: 'meal_dinner', name:'Dinner',  category: 'meal', type: 'topic', keywords: [], items: []},    
    
    {id: 'food_vegetarian', name:'Vegetarian',  category: 'restriction', type: 'topic', keywords: [], items: []},
    {id: 'food_vegan', name:'Vegan',  category: 'restriction', type: 'topic', keywords: [], items: []},
    {id: 'food_halal', name:'Halal',  category: 'restriction', type: 'topic', keywords: [], items: []},

    {id: 'feature_all-you-can-eat', name:'All you can eat',  category: 'feature', type: 'topic', keywords: [], items: []},

    {id: 'vibe_casual', name:'Casual',  category: 'vibe', type: 'topic', keywords: [], items: []},
    {id: 'vibe_trendy', name:'Trendy',  category: 'vibe', type: 'topic', keywords: [], items: []},
    {id: 'vibe_fancy', name:'Fancy',  category: 'vibe', type: 'topic', keywords: [], items: []},
    {id: 'vibe_quirky', name:'Quirky',  category: 'vibe', type: 'topic', keywords: [], items: []},

    {id: 'cuisine_african', name:'African',  category: 'cuisine', type: 'topic', keywords: [], items: []},
    {id: 'cuisine_american', name:'American',  category: 'cuisine', type: 'topic', keywords: [], items: []},
    {id: 'cuisine_brazilian', name:'Brazilian',  category: 'cuisine', type: 'topic', keywords: [], items: []},
    {id: 'cuisine_caribbean', name:'Caribbean',  category: 'cuisine', type: 'topic', keywords: [], items: []},
    {id: 'cuisine_chinese', name:'Chinese',  category: 'cuisine', type: 'topic', keywords: [], items: []},
    {id: 'cuisine_ethiopian', name:'Ethiopian',  category: 'cuisine', type: 'topic', keywords: [], items: []},
    {id: 'cuisine_french', name:'French',  category: 'cuisine', type: 'topic', keywords: [], items: []},
    {id: 'cuisine_greek', name:'Greek',  category: 'cuisine', type: 'topic', keywords: [], items: []},
    {id: 'cuisine_indian', name:'Indian',  category: 'cuisine', type: 'topic', keywords: [], items: []},
    {id: 'cuisine_italian', name:'Italian',  category: 'cuisine', type: 'topic', keywords: [], items: []},
    {id: 'cuisine_japanese', name:'Japanese',  category: 'cuisine', type: 'topic', keywords: [], items: []},
    {id: 'cuisine_korean', name:'Korean',  category: 'cuisine', type: 'topic', keywords: [], items: []},
    {id: 'cuisine_lebanese', name:'Lebanese',  category: 'cuisine', type: 'topic', keywords: [], items: []},
    {id: 'cuisine_mediterranean', name:'Mediterranean',  category: 'cuisine', type: 'topic', keywords: [], items: []},
    {id: 'cuisine_mexican', name:'Mexican',  category: 'cuisine', type: 'topic', keywords: [], items: []},
    {id: 'cuisine_moroccan', name:'Moroccan',  category: 'cuisine', type: 'topic', keywords: [], items: []},
    {id: 'cuisine_peruvian', name:'Peruvian',  category: 'cuisine', type: 'topic', keywords: [], items: []},
    {id: 'cuisine_russian', name:'Russian',  category: 'cuisine', type: 'topic', keywords: [], items: []},
    {id: 'cuisine_spanish', name:'Spanish',  category: 'cuisine', type: 'topic', keywords: [], items: []},
    {id: 'cuisine_thai', name:'Thai',  category: 'cuisine', type: 'topic', keywords: [], items: []},
    {id: 'cuisine_turkish', name:'Turkish',  category: 'cuisine', type: 'topic', keywords: [], items: []},
    {id: 'cuisine_vietnamese', name:'Vietnamese',  category: 'cuisine', type: 'topic', keywords: [], items: []},
    
    {id: 'dish_tacos', name:'Tacos',  category: 'dish', type: 'topic', keywords: [], items: []},
    {id: 'dish_pizza', name:'Pizza',  category: 'dish', type: 'topic', keywords: [], items: []},
    {id: 'dish_sushi', name:'Sushi',  category: 'dish', type: 'topic', keywords: [], items: []},
    {id: 'dish_poutine', name:'Poutine',  category: 'dish', type: 'topic', keywords: [], items: []},
    {id: 'dish_ramen', name:'Ramen',  category: 'dish', type: 'topic', keywords: [], items: []},
    {id: 'dish_hamburger', name:'Hamburger',  category: 'dish', type: 'topic', keywords: [], items: []},
    
    {id: 'activity_pottery', name:'Pottery',  category: 'activity-type', type: 'topic', keywords: [], items: []},
    {id: 'activity_laser-tag', name:'Laser Tag',  category: 'activity-type', type: 'topic', keywords: [], items: []},
    {id: 'place_arcade', name:'Arcade',  category: 'activity-type', type: 'topic', keywords: [], items: []},
    {id: 'place_karaoke', name:'Karaoke',  category: 'activity-type', type: 'topic', keywords: [], items: []},
    {id: 'place_park', name:'Park',  category: 'activity-type', type: 'topic', keywords: [], items: []},
    {id: 'place_museum', name:'Museum',  category: 'activity-type', type: 'topic', keywords: [], items: []},
    {id: 'place_casino', name:'Casino',  category: 'activity-type', type: 'topic', keywords: [], items: []},
    {id: 'place_ice-rink', name:'Ice Rink',  category: 'activity-type', type: 'topic', keywords: [], items: []},
    {id: 'place_amusement-park', name:'Amusement Park',  category: 'activity-type', type: 'topic', keywords: [], items: []},
    {id: 'place_bowling-alley', name:'Bowling Alley',  category: 'activity-type', type: 'topic', keywords: [], items: []},
    {id: 'place_movie-theater', name:'Movie Theater',  category: 'activity-type', type: 'topic', keywords: [], items: []},
    {id: 'place_boardgame-store', name:'Boardgame Store',  category: 'activity-type', type: 'topic', keywords: [], items: []},
    {id: 'place_boardgame-cafe', name:'Boardgame Cafe',  category: 'activity-type', type: 'topic', keywords: [], items: []},
    {id: 'place_night-club', name:'Night Club',  category: 'activity-type', type: 'topic', keywords: [], items: []},    
    {id: 'place_zoo', name:'Zoo',  category: 'activity-type', type: 'topic', keywords: [], items: []},
];

export const [placesTags, placesTagsMasterRef] = prepareLabels(tags_items, tags_categories, tags_buckets)

