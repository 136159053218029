import css from './PlaceName.module.css';
import Icon from '../../../Icon/Icon';

const PlaceName = (props) => {
    const place = props.place
    
    return (
        <div className={css['main-container']}>
                <div
                    className={css['text-container']}
                    style={{ WebkitTextStrokeColor: 'var(--orange)' }}
                >
                    <div className={css['text-name']}>{place.name}</div>
                    <PlaceSubtitle place={place} />
                </div>
        </div>
    );
};



const PlaceSubtitle = (props) => {
    const place = props.place;
    const bill = place.prices.total.amount;
    
    const travelDistance = place.travelDistance
    const units = travelDistance >= 1000 ? 'km' : 'm'
    
    return (
        <div className={css['place-subtitle']}>
            <div className={css['time-container']}>


                <div className={css['time-number']}>{getPrettyDistance()}</div>
                <div className={css['time-column']}>
                    <div className={css['time-text']}>{units}</div>
                </div>
            </div>

            <div className={css['money-container']}>
                <Icon shape='filled' icon='attach_money' fontSize='14px' />
                <div className={css['money-number']}>{bill}</div>
            </div>
        </div>
    );

    function getPrettyDistance(){
        
        if (travelDistance< 1000) {
            return travelDistance;
          } else {
            let kilometers = travelDistance / 1000;
            return Math.round(kilometers * 10) / 10;
          }
    }
};


export default PlaceName;
