import css from './Photo.module.css';

const Photo = (props) => {
    const imgSrc = require('../../../../../../images/food/' + props.item.src);

    return (
        <div className={css['main-container']}>
            <div className={css['photo-container']}>
                <img className={css['menu-photo']} src={imgSrc} alt='' />
            </div>
        </div>
    );
};

export default Photo;
