import { createSlice } from '@reduxjs/toolkit';
import { updateSlice, updateSliceKeys } from './update-slice';
import { initFunctions } from '../Firebase/Firestore/usersFirestore';
import { updateFirestoreDoc } from '../Firebase/Firestore/CRUD-functions';
import { removeValueFromArray } from '../tools/data-processing';
import { createEventDoc } from '../Firebase/Firestore/eventsFirestore';
import { generateId } from '../tools/generateId';

const userSlice = createSlice({
    name: 'user',
    initialState: initFunctions['user'](),
    reducers: {
        updateUser(state, action) {
            updateSlice(state, action);
        },

        updateUserAndFirestore(state, action) {
            updateSlice(state, action);
            const firestorePayload = {
                [action.payload.path.join('.')]: action.payload.newValue,
            };
            updateFirestoreDoc('users', state.meta.doc_id, firestorePayload);
        },

        updateUserKeys(state, action) {
            updateSliceKeys(state, action);
        },

        updateInvitation(state, action) {
            const payload = action.payload;
            const elementId = payload.elementId;
            const collection_id = payload.elementType;

            const invitationId = payload.targetInvitation;
            const invitationsArray = state[collection_id].manager[elementId]
                ? state[collection_id].manager[elementId].invitations
                : [];
            invitationsArray.push(invitationId);

            const firestorePayload = {};
            firestorePayload[collection_id + '.manager.' + elementId + '.invitations'] =
                invitationsArray;

            const targetArray = state.invitations.manager[invitationId][collection_id];

            if (payload.action === 'addElement') {
                targetArray.push(elementId);
                firestorePayload['invitations.manager.' + invitationId + '.' + collection_id] =
                    targetArray;

                const targetManager = state[collection_id].manager;

                if (!(elementId in targetManager)) {
                    targetManager.element_ids.push(elementId);
                    firestorePayload[collection_id + '.manager.element_ids'] =
                        targetManager.element_ids;

                    const element = payload.element;
                    const newElement = initFunctions[collection_id]();
                    newElement.name = element.name;
                    newElement.thumbnail = element.image;
                    newElement.meta.id = elementId;
                    newElement.invitations = invitationsArray;
                    if (collection_id === 'places') {
                        newElement.meta.place_doc_id = elementId;
                    }

                    targetManager[elementId] = newElement;
                    firestorePayload[collection_id + '.manager.' + elementId] = newElement;

                    if (collection_id === 'people') {
                        newElement.meta.user_doc_id = elementId;

                        const card_id = generateId(5);
                        const event_doc_id = state.meta.doc_id + '_' + elementId + '_' + card_id;

                        const eventInfo = {
                            'meta.event_doc_id': event_doc_id,
                            'host.user_doc_id': state.meta.doc_id,
                            'guest.type': 'user',
                            'guest.user_doc_id': elementId,
                            'guest.manager_id': elementId,
                        };

                        const cardPayload = { type: 'invitation', event_doc_id };
                        state.cards.urls[card_id] = cardPayload;

                        const updatePayload = {
                            ['invitations.manager.' + invitationId + '.people']: targetArray,
                            [collection_id + '.manager.element_ids']: targetManager.element_ids,
                            ['cards.urls.' + card_id]: cardPayload,
                        };

                        createEventDoc(eventInfo, newElement, updatePayload);
                        return;
                    }
                }

                updateFirestoreDoc('users', state.meta.doc_id, firestorePayload);
            }

            if (payload.action === 'removeElement') {
                const index = targetArray.indexOf(elementId);
                if (index > -1) {
                    targetArray.splice(index, 1);
                    firestorePayload[
                        'invitations.manager.' + payload.targetInvitation + '.' + collection_id
                    ] = targetArray;
                }

                const invitationIndex = invitationsArray.indexOf(invitationId);
                if (invitationIndex > -1) {
                    invitationsArray.splice(invitationIndex, 1);
                    firestorePayload[collection_id + '.manager.' + elementId + '.invitations'] =
                        invitationsArray;
                }

                updateFirestoreDoc('users', state.meta.doc_id, firestorePayload);
            }
        },

        updateInvitationsInElements(state, action) {
            const updates = action.payload.updates;

            const firestorePayload = {};

            updates.forEach((u) => {
                const [drawerCollection, drawerId, editorCollection, editorId, action] = u;

                const newArray = [
                    ...state[drawerCollection]['manager'][drawerId][editorCollection],
                ];

                if (action === 'added') {
                    newArray.push(editorId);
                }

                if (action === 'removed') {
                    removeValueFromArray(newArray, editorId);
                }

                state[drawerCollection]['manager'][drawerId][editorCollection] = newArray;

                const firePath = drawerCollection + '.manager.' + drawerId + '.' + editorCollection;
                firestorePayload[firePath] = newArray;
            });

            updateFirestoreDoc('users', state.meta.doc_id, firestorePayload);
        },

        createBrowserEntry(state, action) {
            const payload = action.payload;
            const elementId = payload.elementId;
            const browser = state[payload.elementType].browser;

            if (elementId && !(elementId in browser)) {
                const newEntry = initFunctions['browserEntry']();
                newEntry.doc_id = elementId;
                newEntry.affinity = payload.affinity;

                browser[elementId] = newEntry;
            }
        },

        updateBrowserEntry(state, action) {
            const payload = action.payload;
            const entry = state[payload.elementType].browser[payload.elementId];
            entry.watch_time += payload.watchTime;

            if (entry.watch_time >= 30) {
                entry.seen = true;
            }

            const firestorePath = payload.elementType + '.browser.' + payload.elementId;
            updateFirestoreDoc('users', state.meta.doc_id, { [firestorePath]: entry });
        },
    },
});

const actions = userSlice.actions;
export const userReducer = userSlice.reducer;

export function updateUser(dispatch, path, newValue) {
    dispatch(actions.updateUser({ path, newValue }));
}

export function updateUserAndFirestore(dispatch, path, newValue) {
    dispatch(actions.updateUserAndFirestore({ path, newValue }));
}

export function updateUserKeys(dispatch, path, payload) {
    dispatch(actions.updateUserKeys({ path, payload }));
}

export function updateInvitation(dispatch, payload) {
    dispatch(actions.updateInvitation(payload));
}

export function createBrowserEntry(dispatch, payload) {
    dispatch(actions.createBrowserEntry(payload));
}

export function updateBrowserEntry(dispatch, payload) {
    dispatch(actions.updateBrowserEntry(payload));
}

export function updateInvitationsInElements(dispatch, updates) {
    dispatch(actions.updateInvitationsInElements({ updates }));
}
