import { createSlice } from '@reduxjs/toolkit';
import { updateSlice, updateSliceKeys } from './update-slice';

const initialState = {
    searchBar: 'default',
    product: null,
    query: '',
    card: null,
};

const managerSlice = createSlice({
    name: 'manager',
    initialState,
    reducers: {
        updateManager(state, action) {
            updateSlice(state, action);
        },

        updateManagerKeys(state, action) {
            updateSliceKeys(state, action);
        },

        loadManager(state, action) {
            state.product = action.payload.product;
        },

        updateManagerQuery(state, action) {
            state.query = action.payload.query;
        },

        openSearchBar(state, action) {
            state.searchBar = 'open';
        },

        closeSearchBar(state, action) {
            state.searchBar = 'closed';
        },

        loadCard(state, action) {
            state.card = action.payload.elementId;
        },
    },
});

export const managerReducer = managerSlice.reducer;

const managerActions = managerSlice.actions;

export function updateManager(dispatch, path, newValue) {
    dispatch(managerActions.updateManager({ path, newValue }));
}

export function updateManagerKeys(dispatch, path, payload) {
    dispatch(managerActions.updateManagerKeys({ path, payload }));
}

export function loadManager(dispatch, product) {
    dispatch(managerActions.loadManager({ product }));
}

export function updateManagerQuery(dispatch, query) {
    dispatch(managerActions.updateManagerQuery({ query }));
}

export function openSearchBar(dispatch) {
    dispatch(managerActions.openSearchBar({}));
}

export function closeSearchBar(dispatch) {
    dispatch(managerActions.closeSearchBar({}));
}

export function loadCard(dispatch, elementId) {
    dispatch(managerActions.loadCard({ elementId }));
}
