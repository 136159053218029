import css from './BillItem.module.css';
import BillButton from './BillButton/BillButton';

import { useSelector } from 'react-redux';

const BillItem = (props) => {
    const bill = useSelector((state) => state.browser.infoBox.content.bill);
    if (!bill) {
        return <></>;
    }

    const concept = props.concept;
    const item = bill[props.itemId];

    const showButtons = checkShowButtons();
    const symbol = getSymbol();

    return (
        <div className={`${css['main-container']} ${css[props.css]}`}>
            <div className={css['concept']}>{concept}</div>
            <div className={css['quantity-container']}>
                {showButtons ? <BillButton icon='remove' itemId={props.itemId} /> : <div />}

                <div className={css['quantity']}>
                    {symbol === 'X' && <Symbol symbol={symbol} side='left' />}
                    {item.quantity}
                    {symbol === '%' && <Symbol symbol={symbol} side='right' />}
                </div>

                {showButtons ? <BillButton icon='add'  itemId={props.itemId}/> : <div />}
            </div>
            <div className={css['amount']}>
                <span className={css['money']}>$</span>
                {item.amount}
            </div>
        </div>
    );

    function checkShowButtons() {
        const buttonConcepts = ['Appetizer', 'Main', 'Dessert', 'Alcohol', 'Gratuity'];
        return buttonConcepts.includes(concept);
    }

    function getSymbol() {
        if (['Appetizer', 'Main', 'Dessert', 'Alcohol'].includes(concept)) {
            return 'X';
        }
        if (['Gratuity', 'Taxes'].includes(concept)) {
            return '%';
        }
        return '';
    }
};

const Symbol = (props) => {
    return <div className={`${css['symbol']} ${css[props.side]}`}>{props.symbol}</div>;
};

export default BillItem;
