import css from './StageLights.module.css';

import { useSelector } from 'react-redux';

const StageLights = (props) => {
    const lights = useSelector((state) => state.browser.stage.lights);

    return <div className={`${css['stage-lights']} ${css[lights]}`}></div>;
};

export default StageLights;
