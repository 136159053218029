import css from './Section.module.css';

import Icon from '../../../../Icon/Icon';

import { useDispatch, useSelector } from 'react-redux';

import { updateSection } from '../../../../../../store/browserSlice';
import { updateBrowserKeys, updateBrowser } from '../../../../../../store/browserSlice';
import { updateAudio, blinkAudio } from '../../../../../../store/audioSlice';

const Section = (props) => {
    const section = props.section;
    const topics = useSelector((state) => state.browser.topics);

    const placesQueue = useSelector((state) => state.browser.places.queue);
    const whereSelection = useSelector((state) => state.browser.invitation.where.selection);
    const whenSelection = useSelector((state) => state.browser.invitation.when.selection);
    const firestorePlaces = useSelector((state) => state.firestore.places.elements);

    const activeSection = topics.active.section;
    const isActive = activeSection === section.id;

    const dispatch = useDispatch();

    function handleClick() {
        if (section.id === 'where' && !whereSelection) {
            updateAudio(dispatch, ['voiceover', 'track'], 'invitations/where-choice.mp3');
            blinkAudio(dispatch, ['voiceover', 'action'], 'play');
        }

        if (section.id === 'when' && !whenSelection) {
            updateAudio(dispatch, ['voiceover', 'track'], 'invitations/when-choice.mp3');
            blinkAudio(dispatch, ['voiceover', 'action'], 'play');
        }

        updateSection(dispatch, section.id);

        if (whereSelection && section.id === 'where') {
            const playbackId = firestorePlaces[whereSelection].playbackId;
            updateBrowserKeys(dispatch, ['player'], { playbackId });

            updateBrowser(dispatch, 'places', {
                activeId: whereSelection,
                position: placesQueue.indexOf(whereSelection),
            });
            updateBrowser(dispatch, 'manager', { endPoint: 'VerticalPlayerPlaying' });
            setTimeout(() => {
                updateBrowserKeys(dispatch, ['player'], { action: 'play' });
            }, 100);
        }
    }

    return (
        <div
            className={`${css['main-container']} ${section.theme}  ${
                isActive && css['is-active']
            }  `}
            onClick={handleClick}
        >
            <Icon icon={section.icon} {...section.iconProps} />
            <div className={css['text-container']}>{section.text}</div>
        </div>
    );
};

export default Section;
