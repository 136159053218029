import css from './MiniThumnail.module.css';

import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../Icon/Icon';
import { translate } from '../../../../tools/translator';
import { goTo, toggleCurtain } from '../../../../store/conductorSlice';

const MiniThumbnail = (props) => {
    const dispatch = useDispatch();
    const product = useSelector((state) => state.manager.product);
    const icon = translate(product, 'product', 'icon');

    function handleClick() {
        toggleCurtain(dispatch, true);
        setTimeout(() => {
            goTo(dispatch, product, translate(product, 'product', 'editor'))    
        }, 500);
        
    }

    return (
        <div className={css['outer-container']} onClick={handleClick}>
            <div className={css['switch-container']}>
                <label className={`${css['switch']} ${product}`}>
                    <span className={`${css['slider']} ${css['round']} ${product}`}></span>
                </label>
            </div>

            <div className={`${css['name-container']} ${product}`}>
                <div className={css['text-container']}>New</div>
            </div>
            <div className={`${css['main-container']} ${product}`}>
                <div className={css['icon-container']}>
                    <Icon icon={icon} fontSize='55px' />
                </div>
            </div>
        </div>
    );
};

export default MiniThumbnail;
