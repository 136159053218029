import css from './Week.module.css';
import { weekDays } from '../../../../../tools/timeConstants';
import { useDispatch, useSelector } from 'react-redux';
import { cloneObject } from '../../../../../tools/data-processing';
import { updateUserAndFirestore } from '../../../../../store/userSlice';

export const Week = (props) => {
    const week = props.week;

    return (
        <div className={css['week-box']}>
            {week.map((day) => (
                <Day key={day.date} day={day} month={props.month} />
            ))}
        </div>
    );
};

const Day = (props) => {
    const dispatch = useDispatch();
    const day = props.day;
    const date = day.date;
    const hasPassed = hasDatePassed(date);

    const isOpen = useSelector((state) => state.user.availability.isOpen);
    
    const busy = useSelector((state) => state.user.availability.busy);
    const isBusy = !hasPassed && busy[date];
    
    const eventDates = useSelector(state => state.firestore.events.elements.dates)
    const eventDay = date in eventDates;

    
    const outsider = day.month !== props.month;
    

    let format = css['day-box'];

    if (hasPassed) {
        format += ` ${css['has-passed']}`;
    }

    if (!hasPassed && !eventDay && (!isOpen || isBusy)) {
        format += ` ${css['is-closed']}`;
    }

    if (outsider) {
        format += ` ${css['outsider-day']}`;
    }

    if (eventDay) {
        format += ` ${css['event-day']}`;
    }

    function toggleAvailability() {
        const newBusy = cloneObject(busy);
        if (date in busy) {
            delete newBusy[date];
        } else {
            newBusy[date] = true;
        }
        updateUserAndFirestore(dispatch, ['availability', 'busy'], newBusy);
    }

    return (
        <div className={format} onClick={toggleAvailability}>
            <div className={css['day-content']}>{day.day}</div>
        </div>
    );
    function hasDatePassed(dateString) {
        // Split the date string into year, month, and day components
        const [year, month, day] = dateString.split('-');
      
        // Create a Date object from the input string
        const date = new Date(year, month - 1, day); // subtract 1 from the month
        
        // Get the current date
        const currentDate = new Date();
        
        // Set the time of both dates to midnight to compare only the dates
        date.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);
        
        // Compare the dates
        if (date < currentDate) {
          return true; // The date has passed
        } else {
          return false; // The date is in the future or is the current date
        }
      }
      
};

export const WeekDays = (props) => {
    return (
        <div className={css['week-days']}>
            {weekDays.map((day) => (
                <WeekDay key={day} day={day} />
            ))}
        </div>
    );
};

const WeekDay = (props) => {
    return <div className={css['week-day']}>{props.day}</div>;
};
