import css from './BrowserVideos.module.css';

import VideoPlayer from './VideoPlayer/VideoPlayer';
import VideoLoader from '../../VideoPlayer/VideoLoader/VideoLoader';
import ElementName from './ElementName/ElementName';
import TopicToast from './TopicToast/TopicToast';
import VideoHost from './VideoHost/VideoHost';
import EmptyStage from './EmptyStage/EmptyStage';

const BrowserVideos = (props) => {
    return (
        <div className={css['main-container']}>
            <ElementName />
            {false && <VideoHost />}
            {/* {false && <VideoPlayer />} */}
            <VideoLoader orientation='horizontal'/>
            <EmptyStage />
        </div>
    );
};

export default BrowserVideos;
