import css from './EventPreview.module.css';
import EventThumbnail from './EventThumbnail/EventThumbnail';
import EventDate from './EventDate/EventDate';
import { useSelector } from 'react-redux';

const EventPreview = (props) => {
    // const who = props.event.who;
    // const where= props.event.where;

    const isOn = true;

    const peopleFirestore = useSelector((state) => state.firestore.people.elements);

    const peopleManager = useSelector((state) => state.user.people.manager);

    const who = props.event.who;
    const person = who.type === 'user' ? peopleFirestore[who.id] : peopleManager[who.id];
    
    const where = useSelector((state) => state.firestore.places.elements[props.event.where]);

    const when = props.event.when;

    return (
        <div className={css['main-container']}>
            <EventThumbnail
                name={person.name}
                image={who.type === 'user' ? person.image : person.thumbnail}
                isOn={isOn}
                keywords=''
                product='People'
            />

            <EventThumbnail
                name={where.name}
                image={where.thumbnail}
                isOn={isOn}
                keywords=''
                product='Places'
            />

            <EventDate when={when} isOn={isOn} />
        </div>
    );
};

export default EventPreview;
