import css from './MinimumNotice.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserAndFirestore, updateUser } from '../../../../../store/userSlice';

const MinimumNotice = (props) => {
    return (
        <div className={css['main-container']}>
            <div className={css['label-text']}>
                <div className={css['minimum-sign']}>Minimum</div>
                <div className={css['notice-sign']}> Notice</div>
            </div>

            <div className={css['numbers-container']}>
                <NoticeNumber id='daysHeadsUp' text='Days' />
                <NoticeNumber id='hoursHeadsUp' text='Hours' />
            </div>
        </div>
    );
};

const NoticeNumber = (props) => {
    const dispatch = useDispatch();
    const value = useSelector((state) => state.user.availability[props.id]);
    const minimum = props.id === 'daysHeadsUp' ? 0 : 2;

    function handleChange(event) {
        const eventValue = event.target.value;
        if (eventValue !== '') {
            const newValue = Math.max(minimum, parseInt(eventValue));
            updateUserAndFirestore(dispatch, ['availability', props.id], newValue);
        } else {
            updateUser(dispatch, ['availability', props.id], '');
        }
    }

    return (
        <div className={css['notice-container']}>
            <div className={css['notice-text']}>{props.text}</div>
            <input
                className={css['notice-number']}
                value={value}
                type='number'
                onChange={handleChange}
                min={minimum}
                step={1}
            />
        </div>
    );
};

export default MinimumNotice;
