import { createSlice } from '@reduxjs/toolkit';
import { updateSlice, updateSliceKeys } from './update-slice';

const initialState = {
    card: {
        eventId: null,
        guestView: false,
    },
    elements: { elementIds: [] },
};

const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        updateEvents(state, action) {
            updateSlice(state, action);
        },

        updateEventsKeys(state, action) {
            updateSliceKeys(state, action);
        },
    },
});

export const eventsReducer = eventsSlice.reducer;
const actions = eventsSlice.actions;

export function updateEvents(dispatch, path, newValue) {
    dispatch(actions.updateEvents({ path, newValue }));
}

export function updateEventsKeys(dispatch, path, payload) {
    dispatch(actions.updateEventsKeys({ path, payload }));
}
