import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    queue: null,
    show: false,
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        queueNotification(state, action) {
            state.queue = action.payload.notificationId;
        },

        launchNotification(state, action) {
            if (state.queue) {
                state.show = true;
            }
        },

        clearNotification(state, action) {
            state.queue = null;
            state.show = false;
        },
    },
});

export const notiifcationReducer = notificationSlice.reducer;
const notificationActions = notificationSlice.actions;


export function queueNotification(dispatch, notificationId) {
    dispatch(notificationActions.queueNotification({notificationId}));
}


export function launchNotification(dispatch) {
    dispatch(notificationActions.launchNotification({}));
    setTimeout(() => {
        dispatch(notificationActions.clearNotification({}));
    }, 2000);
}

export function clearNotification(dispatch) {
    dispatch(notificationActions.clearNotification({}));
}


