import css from './TopBarButton.module.css';
import Icon from '../../Icon/Icon';

const TopBarButton = (props) => {
    const width = props.width || 'auto';
    const paddingRight = props.paddingRight || '0';
    const paddingLeft = props.paddingLeft || '0';
    const cssClass = props.css || '';

    const style = { width, paddingRight, paddingLeft, ...props.css };

    return (
        <div className={`${css['main-container']} ${cssClass}`} style={style} onClick={props.onClick}>
            <Icon {...props.iconProps} />
        </div>
    );
};

export default TopBarButton;
