import css from './TopBarTitle.module.css';

const TopBarTitle = (props) => {
    const width = props.width || 'auto';
    const paddingRight = props.paddingRight || '0';
    const paddingLeft = props.paddingLeft || '0';
    const cssClass = props.css || '';

    const style = { width, paddingRight, paddingLeft };

    return (
        <div className={`${css['product-title']} ${cssClass}`} style={style}>
            {props.title}
        </div>
    );
};

export default TopBarTitle;
