import css from './InvitationCard.module.css';
import Card from '../../Cards/Card/Card';
import CardTopBar from '../../Cards/CardTopBar/CardTopBar';
import AcceptButton from '../InvitationsBrowser/AcceptButton/AcceptButton';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect } from 'react';

import { updateVideoKeys } from '../../../store/videoSlice';
import { updateBrowserKey } from '../../../store/browserSlice';
import { updateVideo } from '../../../store/videoSlice';
import { tutorials } from '../../Shared/Tutorial/Tutorial';

const InvitationCard = (props) => {
    const dispatch = useDispatch();

    const elementId = null; //'dave__kundan'
    const video = useSelector((state) => state.video);
    const playbackId = useSelector((state) => state.browser.player.playbackId);
    const browserSeen = video.tutorial.browserSeen;

    useEffect(() => {
        if (!browserSeen) {
            updateVideoKeys(dispatch, ['tutorial', 'returnTo'], {
                playbackId,
                css: video.css,
                second: 0, // need to start updating second on video slice.
            });

            updateBrowserKey(dispatch, ['player', 'playbackId'], tutorials['InvitationCard']);

            updateVideoKeys(dispatch, ['css'], {
                opacity: 1,
                height: 'var(--currentViewHeight)',
                top: '0px',
                pointerEvents: 'all',
                zIndex: 3,
            });

            updateVideo(dispatch, ['tutorial', 'show'], true);
        }
    }, []);

    return (
        <div className={css['main-container']}>
            {false && <CardTopBar product='Invitations' title='Invitation' />}

            {browserSeen && (
                <Fragment>
                    <Card product='Invitations' elementId={elementId} />
                    <AcceptButton />
                </Fragment>
            )}
        </div>
    );
};

export default InvitationCard;
