import css from './VideoPlayer.module.css';

import MuxPlayer from '@mux/mux-player-react';

import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { translate } from '../../../tools/translator';
import { updateTimeLock, triggerTransition } from '../../../store/browserSlice';
import { updateBrowser, updateTopic, updateBrowserKeys } from '../../../store/browserSlice';
import { sections } from '../Browser/Topics/Sections/Sections';
import { blinkAudio } from '../../../store/audioSlice';

const VideoPlayer = (props) => {
    const dispatch = useDispatch();
    const ref = useRef(null);

    const browser = useSelector((state) => state.browser);
    const player = browser.player;
    const stage = browser.stage;
    const [volumeSpeed, setVolumeSpeed] = useState(stage.speed[0]);
    const manager = browser.manager;
    const video = useSelector((state) => state.video);
    const tutorialMode = video.tutorial.show;

    const [lastFullSec, setLastFullSec] = useState(0);

    const activeTheme = getActiveTheme();
    const muted = useSelector((state) => state.audio.muted);

    function handleTimeUpdate(event) {
        if (!player.mobileReady && ref.current.currentTime > 0.2) {
            updateBrowserKeys(dispatch, ['player'], { mobileReady: true });
        }

        const timeLeft = ref.current.duration - ref.current.currentTime;
        if (player.vertical && timeLeft < 1.8 && ref.current.volume === 1) {
            setVolumeSpeed(1500);
            updateBrowser(dispatch, 'player', { volume: 'off' });
        }

        if (player.vertical || video.tutorial.show) {
            return;
        }
        if (timeLeft < 1.8 && !manager.step) {
            // console.log('---- Time left: ', timeLeft);
            if (player.vertical) {
                return;
            }
            triggerTransition(dispatch, 'topicEnded');

            setTimeout(() => {
                ref.current.pause();
                ref.current.currentTime = 0;
                updateTopic(dispatch, 'empty_stage');
            }, stage.speed[0]);
        }

        const currentTime = Math.ceil(ref.current.currentTime);
        if (currentTime - lastFullSec === 1) {
            setLastFullSec((state) => state + 1);
            const useTimer = browser.timer.use;
            if (useTimer) {
                updateTimeLock(dispatch, currentTime);
            }
        } else if (currentTime - lastFullSec !== 0) {
            setLastFullSec(currentTime);
        }
    }

    const action = player.action;
    useEffect(() => {
        if (action === 'play') {
            updateBrowser(dispatch, 'player', { action: null, zIndex: 1 });
            ref.current.play();
            ref.current.volume = 1;
        }

        if (action === 'pause') {
            updateBrowser(dispatch, 'player', { action: null, zIndex: -1 });
            ref.current.pause();
        }
    }, [ref, action]);

    const volume = player.volume;

    const intervalRef = useRef(null);
    useEffect(() => {
        if (volume === 'off') {
            let currentVolume = 1;
            const step = 80;

            const steps = Math.ceil(volumeSpeed / step);
            const magnitude = 1 / steps;

            intervalRef.current = setInterval(() => {
                currentVolume -= magnitude;

                if (currentVolume <= 0) {
                    ref.current.volume = 0;
                    updateBrowser(dispatch, 'player', { volume: null });
                    setVolumeSpeed(stage.speed[0]);
                } else {
                    ref.current.volume = currentVolume;
                }
            }, step);

            return () => {
                clearInterval(intervalRef.current);
            };
        }

        if (volume === 'on') {
            ref.current.volume = 1;
            updateBrowser(dispatch, 'player', { volume: null });
        }
    }, [ref, volume]);

    const showControls = player.showControls;
    const controls =
        lastFullSec >= 4 && showControls
            ? {}
            : {
                  // '--play-button': 'none',
                  '--controls-backdrop-color': 'rgb(0 0 0 / 0%)',
                  '--seek-backward-button': 'none',
                  '--seek-forward-button': 'none',

                  '--time-display': 'none',
                  '--time-range': 'none',
                  '--mute-button': 'none',
                  '--volume-range': 'none',
              };

    if (!tutorialMode) {
        controls['height'] = '100%';
    }

    const color = translate(activeTheme, 'product', 'color');

    return (
        <div
            className={`${css['media-box']} ${tutorialMode && css['tutorial-mode']}`}
            key='player'
            style={video.css}
        >
            {false && <PlayPauseButton />}
            <MuxPlayer
                style={{
                    minHeight: `var(--stage-size)`,

                    width: '100%',
                    minWidth: 'var(--stage-size)',
                    '--media-object-fit': 'cover',
                    '--pip-button': 'none',
                    '--airplay-button': 'none',
                    '--fullscreen-button': 'none',
                    '--playback-rate-button': 'none',
                    '--seek-backward-button': 'none',
                    '--seek-forward-button': 'none',
                    ...controls,
                }}
                ref={ref}
                streamType='on-demand'
                playbackId={player.playbackId}
                metadata={{
                    video_id: 'video-id-54321',
                    video_title: 'Test video title',
                    viewer_user_id: 'user-id-007',
                }}
                primaryColor={color}
                thumbnailTime='0'
                preload='auto'
                onTimeUpdate={handleTimeUpdate}
                onEnded={onEnded}
                muted={muted}
            />
        </div>
    );

    function onEnded() {
        if (player.vertical) {
            updateBrowserKeys(dispatch, ['places'], { view: 'VibeWall' });
            blinkAudio(dispatch, ['music', 'action'], 'play');
        }
    }

    function PlayPauseButton(props) {
        function handleClick() {
            const paused = ref.current.paused;
            if (paused) {
                ref.current.play();
            } else {
                ref.current.pause();
            }
        }

        return <div className={css['play-pause-button']} onClick={handleClick}></div>;
    }

    function getActiveTheme() {
        if (browser.topics.active.section && sections[browser.topics.active.section].theme) {
            return sections[browser.topics.active.section].theme;
        }
        return browser.product;
    }
};

export default VideoPlayer;
