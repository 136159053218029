import css from './Item.module.css';

import { useSelector, useDispatch } from 'react-redux';
import { toggleItem } from '../../../../../../store/drawerSlice';
import { useEffect } from 'react';

const Item = (props) => {
    const dispatch = useDispatch();

    const placeId = useSelector((state) => state.editor.place.meta.place_doc_id);
    const places = useSelector((state) => state.firestore.places.elements);

    const screen = useSelector((state) => state.conductor.screen);

    const drawer = useSelector((state) => state.drawer);
    const item = props.item;

    const isSelected = drawer[drawer.activeContent].masterRef[item.id].selected;

    useEffect(() => {
        if (screen === 'PlaceEditor') {
            const tags = places[placeId].tags;                      
            if (item.id in tags) {
                toggleItem(dispatch, item.id, true);
            }
        }
    }, [placeId]);

    function handleClick() {
        toggleItem(dispatch, item.id, !isSelected);
    }

    const publicTags = useSelector((state) => state.firestore['places'].elements.publicTags);

    if (screen === 'PlacesBrowser' && !(item.id in publicTags)) {
        return <></>;
    }

    const query = drawer.query;

    if (!item.keyConcat) {
        console.log('Item: ', item);
    }

    const inQuery = item.keyConcat.includes(query) ? 'block' : 'none';

    return (
        <div
            className={`${css['drawer-item']} ${drawer.product} ${
                !isSelected && css['not-selected']
            } ${item.shiny && css['large']}`}
            onClick={handleClick}
            style={{ display: inQuery }}
        >
            <div className={`${css['text-container']} ${item.shiny && css['shiny']}`}>
                {item.name}
            </div>
        </div>
    );
};

export default Item;
