export function getElementOptions(elementData) {
    const options = [];

    for (const [id, element] of Object.entries(elementData)) {
        options.push([id, element.name]);
    }

    options.sort((firstEl, secondEl) => {
        if (firstEl[1] < secondEl[1]) {
            return -1;
        }
        if (firstEl[1] > secondEl[1]) {
            return 1;
        }
        return 0;
    });

    return options;
}

export function cloneObject(someObject) {
    return { ...JSON.parse(JSON.stringify(someObject)) };
}

export function cloneObjectsArray(objectsArray) {
    const clonedArray = [];
    objectsArray.forEach((someObject) => {
        clonedArray.push(cloneObject(someObject));
    });
    return clonedArray;
}

export function cloneMegaObjectIntoArray(megaObject) {
    const result = [];
    Object.keys(megaObject).forEach((key) => {
        result.push(cloneObject(megaObject[key]));
    });
    return result;
}

function unpackDoc(docObj) {
    const unpackedDoc = {};

    const keysToBeIgnored = ['meta'];

    Object.keys(docObj).forEach((key) => {
        if (!keysToBeIgnored.includes(key)) {
            unpackedDoc[key] = docObj[key];
        }
    });

    return unpackedDoc;
}

function unpackElementSet(elementSet) {
    const unpackedElementSet = {};

    Object.keys(elementSet).forEach((elementId) => {
        const element = elementSet[elementId];
        const unpackedElement = unpackDoc(element);

        unpackedElement.meta = {
            data_id: elementId,
            type: element.meta.type,
        };

        unpackedElementSet[elementId] = unpackedElement;
    });

    return unpackedElementSet;
}

export function processMemberDoc(memberDocData) {
    const memberData = unpackDoc(memberDocData);

    const collections = ['good_times', 'activities', 'persons', 'locations'];

    for (const collection of collections) {
        memberData[collection] = unpackElementSet(memberData[collection]);
    }

    return memberData;
}


export function selectRandomElement(someArray){
    const index = Math.floor(Math.random() * someArray.length)
    return someArray[index]
}


export function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }

    return array;
}

export function removeValueFromArray(array, value) {
    const index = array.indexOf(value);
    if (index > -1) {
      array.splice(index, 1);
    }
    return array;
  }