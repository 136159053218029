import css from './Photos.module.css';
import { generateId } from '../../../../../tools/generateId';

const Photos = (props) => {
    const photos = props.content.photos
    return (
        <div className={css['main-container']}>
            <div className={css['scroll-area']}>
                {photos.map((photo) => (
                    <Photo key={generateId(5)} photoUrl={photo} />
                ))}
            </div>
        </div>
    );
};

const Photo = (props) => {
    return (
        <div className={css['photo-container']}>
            <img className={css['photo']} src={props.photoUrl} alt='' />
        </div>
    );
};

export default Photos;
