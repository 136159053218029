import css from './LearnAboutUs.module.css';
import { useDispatch } from 'react-redux';
import { goTo } from '../../../store/conductorSlice';
import Logo from '../../Shared/Logo/Logo';

const LearnAboutUs = (props) => {
    const dispatch = useDispatch();

    return (
        <div
            className={css['main-container']}
            onClick={() => {
                goTo(dispatch, 'Home', 'About');
            }}
        >
            <div className={css['inner-container']}>
                <div className={css['text-container']}>Learn about Kins</div>
            </div>
        </div>
    );
};

export default LearnAboutUs;
