import css from './InfoDrawerTopBar.module.css';
import TopBar from '../../../TopBar/TopBar';
import TopBarButton from '../../../TopBar/TopBarButton/TopBarButton';
import { useDispatch, useSelector } from 'react-redux';
import { closeDrawer } from '../../../../../store/drawerSlice';

const InfoDrawerTopBar = (props) => {
    const dispatch = useDispatch();

    const activeId = useSelector((state) => state.browser.places.activeId);

    const elements = useSelector((state) => state.firestore.places.elements);
    const name = activeId && elements[activeId].name;

    return (
        <TopBar product='Places'>
            <div className={css['main-container']}>
                <div className={css['text-container']}> {name} </div>
            </div>

            <TopBarButton
                onClick={() => {
                    closeDrawer(dispatch);
                }}
                width='40px'
                iconProps={{
                    icon: 'chevron_right',
                    fontSize: '40px',
                }}
                css={{ justifyContent: 'flex-end' }}
            />
        </TopBar>
    );
};

export default InfoDrawerTopBar;
