import css from './Button.module.css';

const Button = (props) => {
    return (
        <button
            className={`${css['button-container']} ${props.product} ${props.css}`}
            disabled={props.disabled}
            onClick={props.onClick}
        >
            <div className={`${css['inner-container']}  ${props.innerCss}`}>{props.children}</div>
        </button>
    );
};

export default Button;
