import css from './EditorTopBar.module.css';

import TopBar from '../../TopBar/TopBar';
import TopBarButton from '../../TopBar/TopBarButton/TopBarButton';
import TopBarTitle from '../../TopBar/TopBarTitle/TopBarTitle';
import SaveButton from './SaveButton/SaveButton';

import { useDispatch, useSelector } from 'react-redux';
import { goTo, toggleCurtain } from '../../../../store/conductorSlice';
import { toggleDrawer, updateBrowser } from '../../../../store/browserSlice';

import { translate } from '../../../../tools/translator';

const EditorTopBar = (props) => {
    const dispatch = useDispatch();
    const conductor = useSelector((state) => state.conductor);

    function handleGoBack() {
        toggleCurtain(dispatch, false);
        setTimeout(() => {
            goTo(dispatch, conductor.previous_product, conductor.previous_screen);
            if (conductor.previous_screen.includes('Browser')) {
                toggleDrawer(dispatch, 'visible');
                updateBrowser(dispatch, 'stage', { curtain: 'opened' });
            }
        }, 500);
    }

    const title = translate(props.product, 'product', 'displayName');

    function handleSave(){
        props.onSave()
        handleGoBack()
    }

    function handleDelete() {
        console.log('Deleting...');
    }

    return (
        <TopBar product={props.product} backArrow>
            <TopBarButton
                onClick={handleGoBack}
                width='5%'
                iconProps={{
                    icon: 'chevron_left',
                    fontSize: '40px',
                    marginLeft: '-5px',
                }}
            />

            <TopBarTitle paddingLeft='24px' width='50%' title={title} />

            <div className={css['options-container']}>
                <SaveButton  onSave={handleSave}/>

                <TopBarButton
                    onClick={() => {}}
                    paddingLeft='20px'
                    iconProps={{
                        icon: 'settings',
                        fontSize: '28px',
                        marginBottom: '-1px',
                    }}
                />
            </div>
        </TopBar>
    );
};

export default EditorTopBar;
