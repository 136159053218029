import css from './AltViewButton.module.css';
import Icon from '../../../../Icon/Icon';
import { updateBrowserKeys } from '../../../../../../store/browserSlice';
import { blinkAudio } from '../../../../../../store/audioSlice';
import { useDispatch, useSelector } from 'react-redux';

const AltViewButton = (props) => {
    const dispatch = useDispatch();

    const places = useSelector((state) => state.browser.places);
    const view = places.view;

    const place = useSelector((state) => state.firestore.places.elements[places.activeId]);

    if (place.photos.lenght < 7 || !place.playbackId) {
        return <></>;
    }

    function handleClick() {
        const newView = view === 'VibeWall' ? 'VideoPlayer' : 'VibeWall';
        updateBrowserKeys(dispatch, ['places'], { view: newView });

        if (newView === 'VideoPlayer') {
            blinkAudio(dispatch, ['music', 'action'], 'pause');
            setTimeout(() => {
                updateBrowserKeys(dispatch, ['player'], { action: 'play' });
            }, 200);
        } else {
            blinkAudio(dispatch, ['music', 'action'], 'play');
        }
    }

    return (
        <div className={css['main-container']} onClick={handleClick}>
            <Icon icon={view === 'VibeWall' ? 'theaters' : 'dashboard'} fontSize='32px' />
        </div>
    );
};

export default AltViewButton;
