import css from './InvitationsDrawer.module.css';
import BottomDrawer from '../../Shared/BottomDrawer/BottomDrawer';
import DrawerThumbnail from '../../Shared/BottomDrawer/DrawerThumnail/DrawerThumnail';
import MiniThumbnail from '../../Shared/BottomDrawer/MiniThumnail/MiniThumnail';
import { useSelector } from 'react-redux';

const InvitationsDrawer = (props) => {
    const invitations = useSelector((state) => state.user.invitations.manager);

    const elementIds = invitations.element_ids;

    return (
        <BottomDrawer product='Invitations'>
            <div className={css['light-box']}>
                {elementIds.map((id) => (
                    <DrawerThumbnail
                        key={id}
                        id={id}
                        name={invitations[id]['name']}
                        image={invitations[id].thumbnail}
                        isOn={false}
                        keywords=''
                        product='Invitations'
                    />
                ))}

                
            </div>
            <MiniThumbnail product='Invitations' />
        </BottomDrawer>
    );
};

export default InvitationsDrawer;
