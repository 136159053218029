import { useRef, useEffect } from 'react';
import { songs } from './songs';
// import MuxPlayer from '@mux/mux-player-react';
import { useSelector, useDispatch } from 'react-redux';
import { updateAudio } from '../../../store/audioSlice';

const AudioPlayer = (props) => {
    const dispatch = useDispatch();

    const audio = useSelector((state) => state.audio);
    const speed = useSelector((state) => state.browser.stage.speed);

    const playbackId = songs[audio.music.song];
    const musicRef = useRef(null);
    const musicAction = audio.music.action;
    useEffect(() => {
        if (musicAction === 'play') {
            musicRef.current.play();
            musicRef.current.volume = 1;
        }

        if (musicAction === 'pause') {
            musicRef.current.pause();
        }
    }, [musicRef, musicAction]);

    const voiceoverRef = useRef(null);
    const voiceoverAction = audio.voiceover.action;
    const voiceoverSrc = audio.voiceover.track && require('./audio/' + audio.voiceover.track);
    useEffect(() => {
        if (!voiceoverSrc) {
            return;
        }

        if (voiceoverAction === 'play') {
            voiceoverRef.current.play();
            voiceoverRef.current.volume = 1;
        }

        if (voiceoverAction === 'pause') {
            voiceoverRef.current.pause();
        }
    }, [voiceoverRef, voiceoverAction]);

    // Commenting this out because it was causing audio to be muted after play.
    // const volume = audio.volume;
    // const intervalRef = useRef(null);
    // useEffect(() => {
    //     if (volume === 'off') {
    //         let currentVolume = 1;
    //         const step = 80;
    //         const steps = Math.ceil(speed[0] / step);
    //         const magnitude = 1 / steps;

    //         intervalRef.current = setInterval(() => {
    //             currentVolume -= magnitude;

    //             if (currentVolume <= 0) {
    //                 musicRef.current.volume = 0;
    //                 updateAudio(dispatch, ['volume'], null);
    //                 clearInterval(intervalRef.current);
    //             } else {
    //                 musicRef.current.volume = currentVolume;
    //             }
    //         }, step);
    //         return () => {
    //             clearInterval(intervalRef.current);
    //         };
    //     }
    // }, [volume]);

    return (
        <div style={{ display: 'none' }}>
            {/* <MuxPlayer
                ref={musicRef}
                audio={true}
                streamType='on-demand'
                metadata={{ song: audio.music.song }}
                playbackId={playbackId}
                preload='auto'
                muted={audio.muted}
            /> */}
            <audio ref={musicRef} src={playbackId} muted={audio.muted} />
            <audio ref={voiceoverRef} src={voiceoverSrc} muted={audio.muted} />
        </div>
    );
};

export default AudioPlayer;
