import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { openDrawer } from '../../../../store/drawerSlice';

import { prepareDrawer } from '../../../../tools/prepareDrawer';
import { loadDrawer } from '../../../../store/drawerSlice';
import { cloneObject } from '../../../../tools/data-processing';
import { updateTopic } from '../../../../store/browserSlice';

const RestaurantMenu = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const menu = cloneObject(props.content.menu);
        const [menuItems, menuMasterRef] = prepareDrawer(menu.items, menu.categories, menu.buckets);
        loadDrawer(dispatch, 'Places', menuItems, menuMasterRef, 'menu_fees');
        openDrawer(dispatch, 'menu_fees');
        updateTopic(dispatch, 'empty_stage')
    }, []);

    return <></>;
};

export default RestaurantMenu;
