import { months } from '../../tools/timeConstants';

let lastSaturday = new Date();
lastSaturday.setDate(lastSaturday.getDate() - ((lastSaturday.getDay() + 1) % 7));

function nextDay(x) {
    var now = new Date();
    now.setDate(now.getDate() + ((x + (7 - now.getDay())) % 7));
    return now;
}

let nextWednesday = new nextDay(3)
let nextSunday = new nextDay(0);



export const last_saturday = {
    id: 'last_satuday',

    who: {
        name: 'Lex',
        image: 'lex_400.png',
    },
    where: {
        name: 'Bar Darling',
        image: 'bar-darling.jpeg',
    },
    when: {
        weekday: 'Saturday',
        day: lastSaturday.getDate(),
        month: months[lastSaturday.getMonth()],
        time: '1930',
        isOn: false,
    },
};

export const dummyEvents = [
    {
        id: 'sabrina',

        who: {
            name: 'Sabrina',
            image: 'sabrina_400.png',
        },
        where: {
            name: 'Bowling Alley',
            image: 'bowling_alley_400.png',
        },
        when: {
            weekday: 'Sunday',
            day: nextSunday.getDate(),
            month: months[nextSunday.getMonth()],
            time: '1600',
            isOn: true,
        },
    },


    {
        id: 'ali',

        who: {
            name: 'Ali',
            image: 'ali_v4.png',
        },
        where: {
            name: 'Hockey Arena',
            image: 'madison_400.png',
        },
        when: {
            weekday: 'Wednesday',
            day: nextWednesday.getDate(),
            month: months[nextWednesday.getMonth()],
            time: '1900',
            isOn: true,
        },
    },
];
