import css from './ContactSupport.module.css';
import Icon from '../../Icon/Icon';

const ContactSupport = (props) => {
    return (
        <div className={css['main-container']}>
            <div className={css['modal-title']}>Need help?</div>

            <div className={css['chanels-container']}>
                <ContactChanel
                    instruction='Call'
                    icon='call'
                    iconSize='20px'
                    text='(514) 649 9551'
                />
                <ContactChanel
                    instruction='Text'
                    icon='textsms'
                    iconSize='20px'
                    text='(514) 649 9551'
                />

                <ContactChanel
                    instruction='Email'
                    icon='email'
                    iconSize='20px'
                    text='help@kins.ai'
                />
            </div>
        </div>
    );
};

const ContactChanel = (props) => {
    return (
        <div className={css['chanel-outer-container']}>
            <div className={css['chanel-container']}>
                <div className={css['icon-container']}>
                    <Icon icon={props.icon} fontSize={props.iconSize} />
                    <div className={css['chanel-instructions']}>{props.instruction}</div>
                </div>

                <div className={css['inner-container']}>
                    <div className={css['text-container']}>{props.text}</div>
                </div>
            </div>
        </div>
    );
};

export default ContactSupport;
