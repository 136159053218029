import css from './Thumbnail.module.css';
import OffSign from './OffSign/OffSign';
import ToggleSwitch from './ToggleSwitch/ToggleSwitch';
import NameTag from './NameTag/NameTag';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserAndFirestore } from '../../../../store/userSlice';

const Thumbnail = (props) => {
    const dispatch = useDispatch();
    const product = useSelector((state) => state.manager.product);
    const places = useSelector((state) => state.firestore.places.elements);
    const lcProduct = product && product.toLowerCase();
    const query = useSelector((state) => state.manager.query);
    const element = props.element;
    const imgSrc = getImgSrc(element.thumbnail);
    

    const inQuery = props.keywords.includes(query) ? 'block' : 'none';
    const isOn = element.isOpen;

    return (
        <div className={css['outer-container']} style={{ display: inQuery }}>
            <ToggleSwitch product={product} isOn={isOn} onToggle={handleToggle} />
            <NameTag product={product} id={props.id} element={element} />
            <div className={`${css['main-container']} ${product}`}>
                {!isOn && <OffSign />}
                <img
                    className={`${css['thumbnail-image']} ${isOn ? '' : css['item-off']}`}
                    src={imgSrc}
                    alt=''
                />
            </div>
        </div>
    );

    function handleToggle(newIsOn) {
        const pathArray = [lcProduct, 'manager', props.id, 'isOpen'];
        updateUserAndFirestore(dispatch, pathArray, newIsOn);
    }

    function getImgSrc(image) {

        if (product === 'Places' && props.id in places) {
            const thumbnail = places[props.id].thumbnail;
            if (thumbnail && thumbnail !== image) {
                console.log('UPDATING PLACE MANAGER THUMBNAIL...', thumbnail, image)
                updateUserAndFirestore(
                    dispatch,
                    ['places', 'manager', props.id, 'thumbnail'],
                    thumbnail
                );
                return thumbnail;
            }
        }

        if (image.slice(0, 4) === 'http') {
            return image;
        }
        return require('../../../../images/thumbnails/' + image);
    }
};

export default Thumbnail;
