import css from './SideButtons.module.css';
import TopBarButton from '../../../TopBar/TopBarButton/TopBarButton';
import { openInfoDrawer } from '../../../../../store/drawerSlice';
import { useDispatch, useSelector } from 'react-redux';
import AltViewButton from './AltViewButton/AltViewButton';

const SideButtons = (props) => {
    const dispatch = useDispatch();
    const infoBox = useSelector((state) => state.drawer.infoBox);
    const showMenu = infoBox.Menu.photos.length > 0;
    const showPrices = infoBox.Prices.total.amount !== 0;

    return (
        <div className={css['main-container']}>
            {showPrices && (
                <TopBarButton
                    onClick={() => {
                        openInfoDrawer(dispatch, 'Prices');
                    }}
                    iconProps={{
                        icon: 'receipt_long',
                        fontSize: '30px',
                        shape: 'outlined',
                    }}
                    css={{ height: '60px' }}
                />
            )}

            {showMenu && (
                <TopBarButton
                    onClick={() => {
                        openInfoDrawer(dispatch, 'Menu');
                    }}
                    iconProps={{
                        icon: 'menu_book',
                        fontSize: '30px',
                    }}
                    css={{ height: '60px' }}
                />
            )}

            <TopBarButton
                onClick={() => {
                    openInfoDrawer(dispatch, 'Hours');
                }}
                iconProps={{
                    icon: 'schedule',
                    fontSize: '30px',
                }}
                css={{ height: '60px' }}
            />

            <AltViewButton />

            <TopBarButton
                onClick={() => {
                    openInfoDrawer(dispatch, 'Map');
                }}
                iconProps={{
                    icon: 'location_on',
                    fontSize: '30px',
                }}
                css={{ height: '60px' }}
            />

            <TopBarButton
                onClick={() => {
                    openInfoDrawer(dispatch, 'Photos');
                }}
                iconProps={{
                    icon: 'photo_camera',
                    fontSize: '30px',
                }}
                css={{ height: '60px' }}
            />
        </div>
    );
};

export default SideButtons;
