import css from './DrawerPreview.module.css';
import SectionTitle from './SectionTitle/SectionTitle';
import TinyThumbnail from './TinyThumbnail/TinyThumbnail';
import Icon from '../../Icon/Icon';
import { generateId } from '../../../../tools/generateId';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawer } from '../../../../store/browserSlice';
import { updateEditor } from '../../../../store/editorSlice';
import { translate } from '../../../../tools/translator';

const DrawerPreview = (props) => {
    const dispatch = useDispatch();
    const contentId = props.product.toLowerCase();
    const manager = useSelector((state) => state.user[contentId].manager);

    const photos = getThumbnails();
    const elementCount = photos.length;
    const thumbnails = photos.slice(0, 3);
    const screen = useSelector((state) => state.conductor.screen);

    function handleClick() {
        updateEditor(dispatch, ['drawer', 'content'], contentId);
        toggleDrawer(dispatch, 'visible');
    }

    const place_doc_id = useSelector((state) => state.browser.places.activeId);
    const width = screen === 'PlaceEditor' && place_doc_id ? '96%' : '100%';

    return (
        <div className={css['outer-container']} style={{ width }}>
            <div className={`${css['main-container']} ${props.product}`} onClick={handleClick}>
                <SectionTitle title={props.product} />
                <div className={css['thumnails-container']}>
                    {thumbnails.map((thumbnail) => (
                        <TinyThumbnail key={generateId(5)} imgSrc={thumbnail} />
                    ))}
                    <AndMore product={props.product} number={elementCount} />
                </div>
            </div>
        </div>
    );

    function getThumbnails() {
        const result = [];
        props.elementIds.forEach((elementId) => {
            const element = manager[elementId];
            if (element.isOpen) {
                result.push(element.thumbnail);
            }
        });

        return result;
    }
};

const AndMore = (props) => {
    return (
        <div className={css['and-more-container']}>
            <Icon icon={translate(props.product, 'product', 'icon')} fontSize='24px' />
            <div className={css['and-more-text']}>{props.number}</div>
        </div>
    );
};

export default DrawerPreview;
