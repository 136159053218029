import css from './PlaceEditor.module.css';
import Editor from '../../Shared/Editor/Editor';

import EditorTopBar from '../../Shared/Editor/EditorTopBar/EditorTopBar';
import EditorHead from '../../Shared/Editor/EditorHead/EditorHead';
import PlaceFinder from './PlaceFinder/PlaceFinder';
import DrawerPreview from '../../Shared/Editor/DrawerPreview/DrawerPreview';
import BrowserButtons from '../../Shared/Browser/BrowserButtons/BrowserButtons';

import Card from '../../Cards/Card/Card';
import { Fragment, useEffect } from 'react';
import { updateEditor } from '../../../store/editorSlice';
import { updateBrowser } from '../../../store/browserSlice';
import { useDispatch, useSelector } from 'react-redux';
import { dummyPlacesThumbnails } from '../../People/PersonEditor/peopleThumbnails';
import UploadPhotos from './UploadPhotos/UploadPhotos';

import { createPlace } from '../../../Firebase/Firestore/placesFirestore';
import { updateBrowserKeys } from '../../../store/browserSlice';
import { initPlace } from '../../../Firebase/Firestore/usersFirestore';
import { updateInvitationsInElements, updateUserAndFirestore } from '../../../store/userSlice';

const PlaceEditor = (props) => {
    const dispatch = useDispatch();

    const email = useSelector((state) => state.user.info.email);
    const adminMode = useSelector((state) => state.conductor.adminMode);
    const placesManager = useSelector((state) => state.user.places.manager);

    const place = useSelector((state) => state.editor.place);
    const placeDoc = useSelector((state) => state.editor.placeDoc);
    const places = useSelector((state) => state.firestore.places.elements);

    const place_doc_id = place.meta.place_doc_id;

    const shortAddress = place_doc_id in places ? places[place_doc_id].short_address : '';
    const showCard = place_doc_id in places && getShowCard();

    const playbackId = useSelector((state) => state.browser.player.playbackId);
    const viewDefault = place_doc_id in places && places[place_doc_id].viewDefault;

    useEffect(() => {
        updateEditor(dispatch, ['editing'], 'place');
        updateEditor(dispatch, ['drawer', 'content'], 'invitations');
        updateEditor(dispatch, ['drawer', 'thumbnails'], dummyPlacesThumbnails);

        if (showCard) {
            updateBrowserKeys(dispatch, ['places'], {
                activeId: place_doc_id,
                queue: [place_doc_id],
                position: 0,
            });
        }

        return () => {
            updateEditor(dispatch, ['place'], initPlace());
            if (showCard) {
                updateBrowserKeys(dispatch, ['places'], {
                    activeId: null,
                    queue: [],
                    position: 0,
                });
            }
        };
    }, []);

    useEffect(() => {
        if (showCard && playbackId && viewDefault !== 'VibeWall') {
            updateBrowser(dispatch, 'player', { action: 'play' });
        }
    }, [playbackId, showCard, dispatch, viewDefault]);

    return (
        <Fragment>
            {showCard && (
                <Fragment>
                    <EditorTopBar product='Places' onSave={handleSave} />
                    <Card product='Places' elementId={place.meta.place_doc_id} />
                    <BrowserButtons>
                        {adminMode && <UploadPhotos />}
                        <DrawerPreview product='Invitations' elementIds={place.invitations} />
                    </BrowserButtons>
                </Fragment>
            )}
            {!showCard && (
                <Editor product='Places' onSave={handleSave}>
                    <div className={css['main-container']}>
                        <EditorHead product='Places' elementType='place' />
                        <PlaceFinder shortAddress={shortAddress} />
                        <DrawerPreview product='Invitations' elementIds={place.invitations} />
                    </div>
                    {adminMode && <UploadPhotos />}
                </Editor>
            )}
        </Fragment>
    );

    function handleSave() {
        console.log('Saving place...', 'Email: ', email);
        const thumbnail = place.thumbnail || null;

        if (!(place_doc_id in places)) {
            createPlace(placeDoc, email, thumbnail);
        }

        if (!(place_doc_id in placesManager)) {
            const placesIds = [place_doc_id, ...placesManager.element_ids];
            updateUserAndFirestore(dispatch, ['places', 'manager', 'element_ids'], placesIds);
        }
        updateUserAndFirestore(dispatch, ['places', 'manager', place_doc_id], place);

        const invitationUpdates = [];
        const oldPlace = place_doc_id in placesManager ? placesManager[place_doc_id] : initPlace();
        prepareInvitationsUpdates(oldPlace, place);
        updateInvitationsInElements(dispatch, invitationUpdates);

        function prepareInvitationsUpdates(oldElement, newElement) {
            const { added, removed } = compareArrays(
                oldElement['invitations'],
                newElement['invitations']
            );

            added.forEach((a) => {
                invitationUpdates.push(['invitations', a, 'places', place_doc_id, 'added']);
            });

            removed.forEach((r) => {
                invitationUpdates.push(['invitations', r, 'places', place_doc_id, 'removed']);
            });

            function compareArrays(before, after) {
                const added = after.filter((item) => !before.includes(item));
                const removed = before.filter((item) => !after.includes(item));
                return { added, removed };
            }
        }
    }

    function getShowCard() {
        const firestorePlace = places[place_doc_id];

        if (firestorePlace.playbackId) {
            return true;
        }

        if (firestorePlace.photos.length > 7) {
            return true;
        }

        return false;
    }
};

export default PlaceEditor;
