import css from './EmptyStage.module.css';
import { useSelector } from 'react-redux';

const EmptyStage = (props) => {
    const image = useSelector((state) => state.browser.stage.image);
    const section = useSelector((state) => state.browser.topics.active.section);

    const imageSrc = getImgSrc(image);
    const manager = useSelector((state) => state.browser.manager);
    const show = manager.endPoint === 'EmptyStage';

    if(!show){
        return <></>
    }

    return (
        <div className={`${css['main-container']}`}>
            <img className={css['stage-image']} src={imageSrc} alt='' />
            {false && <div className={css['message']}>Please select a topic</div>}
        </div>
    );

    function getImgSrc() {
        if (!image) {
            return require('../../../../../images/empty-stage.jpeg');
        }

        if (image.slice(0, 4) === 'http') {
            if (section !== 'videos') {
                return image;
            } else {
                return require('../../../../../images/empty-stage.jpeg');
            }
        }

        return require('../../../../../images/thumbnails/' + image);
    }
};

export default EmptyStage;
