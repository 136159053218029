import css from './MuteButton.module.css';
import Icon from '../../../../Icon/Icon';
import { updateAudio } from '../../../../../../store/audioSlice';
import { useDispatch, useSelector } from 'react-redux';

const MuteButton = (props) => {
    const muted = useSelector((state) => state.audio.muted);
    const dispatch = useDispatch();

    function handleClick() {
        updateAudio(dispatch, ['muted'], !muted )
    }

    return (
        <div className={css['main-container']} onClick={handleClick}>
            <Icon icon={muted ? 'volume_off' : 'volume_up'} fontSize='28px' />
        </div>
    );
};

export default MuteButton;
