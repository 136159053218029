import css from './Prices.module.css';

import { useSelector } from 'react-redux';

import BillItem from './BillItem/BillItem';
import Notes from './Notes/Notes';

const Prices = (props) => {
    const bill = useSelector((state) => state.drawer.infoBox['Prices']);

    const showFood = bill.food.price + bill.appetizer.price + bill.main.price + bill.side.price + bill.dessert.price > 0;
    const showDrinks = bill.drink.price + bill.cocktail.price + bill.beer.price + bill.wine.price + bill.soda.price + bill.coffee.price > 0;

    return (
        <div className={css['outer-container']}>
            <div className={css['main-container']}>
                <Notes notes={bill.notes}/>
                
                {bill.activity.price > 0 &&  <div className={css['bill-section']}>Activity</div>}
                <BillItem itemId='activity' css=''/>

                {showFood && <div className={css['bill-section']}>Food</div>}
                <BillItem itemId='food' css='' />
                <BillItem itemId='appetizer' css='' />
                <BillItem itemId='main' css='' />
                <BillItem itemId='side' css='' />
                <BillItem itemId='dessert' css='' />

                {showDrinks && <div className={css['bill-section']}>Drinks</div>}
                <BillItem itemId='drink' css='' />
                <BillItem itemId='cocktail' css='' />
                <BillItem itemId='beer' css='' />
                <BillItem itemId='wine' css='' />
                <BillItem itemId='soda' css='' />
                <BillItem itemId='coffee' css='' />

                <BillItem itemId='subtotal' css='subtotal' />
                <BillItem itemId='taxes' css='taxes' />
                <BillItem itemId='gratuity' css='gratuity' />
                <BillItem itemId='total' css='total' />
            </div>
        </div>
    );
};

export function updateAmounts(oldBill) {
    let newSubtotal = 0;
    [
        'activity',
        'food',
        'appetizer',
        'main',
        'side',
        'dessert',
        'drink',
        'cocktail',
        'beer',
        'wine',
        'soda',
        'coffee',
    ].forEach((itemId) => {
        const item = oldBill[itemId];
        item.amount = item.price * item.quantity;
        newSubtotal += item.amount;
    });
    oldBill.subtotal.amount = newSubtotal;
    oldBill.taxes.amount = Math.round(newSubtotal * (oldBill.taxes.quantity / 100));
    oldBill.gratuity.amount = Math.round(
        (oldBill.gratuity.quantity / 100) * (oldBill.subtotal.amount + oldBill.taxes.amount)
    );

    oldBill.total.amount = oldBill.subtotal.amount + oldBill.taxes.amount + oldBill.gratuity.amount;
    return oldBill;
}

export default Prices;
