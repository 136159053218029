import css from './SignInActions.module.css';

const SignInActions = (props) => {
    
    return (
        <div className={css['actions']}>
            <button
                className={css['confirm']}
                disabled={!props.validInputs}
                onClick={props.onClick}
            >
                {props.confirmText}
            </button>

            <button className={css['cancel']} onClick={props.handleCancel}>
                {props.cancelText}
            </button>
        </div>
    );
};

export default SignInActions;
