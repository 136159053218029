import { nextTimes, times } from "../../../../tools/timeConstants";
import { createStartingTimeBlocks } from "../../../../store/editorSlice";

export function getTimeWindowsFromBlocks(timeBlocks) {
    const openBlocks = [];
    Object.keys(timeBlocks).forEach((time) => {
        if (timeBlocks[time]) {
            openBlocks.push(time);
        }
    });

    openBlocks.sort();

    const windows = [];
    let startBlock = null;
    let lastBlock = null;
    let nextTarget = null;

    for (let i = 0; i < openBlocks.length; i++) {
        const block = openBlocks[i];

        if (!startBlock) {
            startBlock = block;
            lastBlock = block;
            nextTarget = block.slice(0, 3) + nextTimes[block.slice(-4)];
            continue;
        }

        if (nextTarget === block) {
            lastBlock = block;
            nextTarget = block.slice(0, 3) + nextTimes[block.slice(-4)];
            continue;
        }

        if (nextTarget !== block) {
            windows.push(startBlock + '-' + lastBlock);
            startBlock = block;
            lastBlock = block;
            nextTarget = block.slice(0, 3) + nextTimes[block.slice(-4)];
        }
    }

    if (openBlocks.length > 0) {
        windows.push(startBlock + '-' + lastBlock);
    }

    return windows;
}

export function getTimeBlocksFromWindows(windows) {
    const result = createStartingTimeBlocks();
  
    windows.forEach((window) => {
      const [start, end] = window.split('-');
      const startIndex = times.findIndex((time) => time.endsWith(start.slice(-4)));
      const endIndex = times.findIndex((time) => time.endsWith(end.slice(-4)));
  
      const day = start.slice(0, 3);
      for (let i = startIndex; i <= endIndex; i++) {
        result[day + times[i]] = true;
      }
    });
  
    return result;
  }