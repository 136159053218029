import css from './PersonInput.module.css';

import { useSelector, useDispatch } from 'react-redux';
import { updateEditor } from '../../../../store/editorSlice';

const PersonInput = (props) => {
    const dispatch = useDispatch();
    const field = props.field;
    const value = useSelector((state) => state.editor.person[field]);

    return (
        <div className={css['input-container']}>
            <div className={css['input-icon-container']}>
                <span className={`material-icons ${css['input-icon']} People`}>{props.icon}</span>
            </div>
            <input
                className={`${css['input-text']}`}
                placeholder={props.placeholder}
                value={value}
                onChange={(event) => {
                    updateEditor(dispatch, ['person', field], event.currentTarget.value);
                }}
                type={props.type}
            />
        </div>
    );
};

export default PersonInput;
