import css from './EventCardTopBar.module.css';

import TopBar from '../../../Shared/TopBar/TopBar';
import TopBarTitle from '../../../Shared/TopBar/TopBarTitle/TopBarTitle';
import TopBarButton from '../../../Shared/TopBar/TopBarButton/TopBarButton';

import { toggleCurtain } from '../../../../store/conductorSlice';
import { goTo, goBack } from '../../../../store/conductorSlice';
import { updateConductorKeys } from '../../../../store/conductorSlice';

import { useDispatch, useSelector } from 'react-redux';

const EventCardTopBar = (props) => {
    const dispatch = useDispatch();

    const guestView = useSelector(state => state.events.card.guestView)

    return (
        <TopBar product='GoodTimes'>
            {!guestView && (
                <TopBarButton
                    onClick={handleGoBack}
                    width='5%'
                    iconProps={{
                        icon: 'chevron_left',
                        fontSize: '40px',
                        marginLeft: '-5px',
                    }}
                />
            )}

            <TopBarTitle width='50%' paddingLeft='24px' title='Good times' />

            <div className={css['options-container']}>
                {false && (
                    <TopBarButton
                        onClick={() => {}}
                        iconProps={{
                            icon: 'question_answer',
                            fontSize: '26px',
                            css: css['vertical-shadow'],
                        }}
                    />
                )}

                <TopBarButton
                    onClick={() => {
                        updateConductorKeys(dispatch, ['modalDialog'], {
                            show: true,
                            template: 'ContactSupport',
                        });
                    }}
                    iconProps={{
                        icon: 'support_agent',
                        fontSize: '30px',
                    }}
                />
            </div>
        </TopBar>
    );

    function handleGoBack() {
        toggleCurtain(dispatch, false);
        setTimeout(() => {
            goTo(dispatch, 'Menu', 'Menu');
        }, 500);
    }
};

export default EventCardTopBar;
