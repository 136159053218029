import css from './InvitationEditor.module.css';
import Editor from '../../Shared/Editor/Editor';

import EditorHead from '../../Shared/Editor/EditorHead/EditorHead';
import TimeSelect from './TimeSelect/TimeSelect';
import DrawerPreview from '../../Shared/Editor/DrawerPreview/DrawerPreview';
import { invitationThumbnails } from './invitationThumbnails';

import { useEffect } from 'react';
import { updateEditor } from '../../../store/editorSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getTimeWindowsFromBlocks } from './TimeSelect/timeSelectFunctions';
import { cloneObject } from '../../../tools/data-processing';
import { generateId } from '../../../tools/generateId';
import { updateFirestoreDoc } from '../../../Firebase/Firestore/CRUD-functions';
import { updateUser, updateInvitationsInElements } from '../../../store/userSlice';
import { initInvitation } from '../../../Firebase/Firestore/usersFirestore';

const InvitationEditor = (props) => {
    const dispatch = useDispatch();
    const invitation = useSelector((state) => state.editor.invitation);

    const timeBlocks = useSelector((state) => state.editor.timeSelect.blocks);
    const doc_id = useSelector((state) => state.user.meta.doc_id);

    const manager = useSelector((state) => state.user.invitations.manager);
    const elementIds = manager.element_ids;

    useEffect(() => {
        updateEditor(dispatch, ['editing'], 'invitation');
        updateEditor(dispatch, ['drawer', 'content'], 'people');
        updateEditor(dispatch, ['drawer', 'thumbnails'], invitationThumbnails);
    }, []);

    return (
        <Editor product='Invitations' onSave={handleSave}>
            <div className={css['main-container']}>
                <EditorHead product='Invitations' elementType='invitation' />
                <TimeSelect />
                <DrawerPreview product='People' elementIds={invitation.people} />
                <DrawerPreview product='Places' elementIds={invitation.places} />
            </div>
        </Editor>
    );

    function handleSave() {
        const payload = cloneObject(invitation);
        payload.times = getTimeWindowsFromBlocks(timeBlocks);
        let updateElementIds = false;

        const updates = [];

        let invitationId = payload.meta.id;
        const oldInvitation = invitationId ? manager[invitationId] : initInvitation(null, null, null);

        if (!invitationId) {
            invitationId = generateId(5, payload.name);
            payload.meta.id = invitationId;
            updateElementIds = true;
        }

        prepareUpdates(oldInvitation, payload, 'people');
        prepareUpdates(oldInvitation, payload, 'places');

        const pathArray = ['invitations', 'manager', invitationId];
        updateUser(dispatch, pathArray, payload);

        const firePath = pathArray.join('.');
        updateFirestoreDoc('users', doc_id, { [firePath]: payload });

        if (updateElementIds) {
            const newElementIds = [invitationId, ...elementIds];
            updateUser(dispatch, ['invitations', 'manager', 'element_ids'], newElementIds);
            updateFirestoreDoc('users', doc_id, {
                'invitations.manager.element_ids': newElementIds,
            });
        }

        updateInvitationsInElements(dispatch, updates);

        function prepareUpdates(oldElement, newElement, drawerCollection) {                        
            const { added, removed } = compareArrays(
                oldElement[drawerCollection],
                newElement[drawerCollection]
            );

            added.forEach((a) => {
                updates.push([drawerCollection, a, 'invitations', invitationId, 'added']);
            });

            removed.forEach((r) => {
                updates.push([drawerCollection, r, 'invitations', invitationId, 'removed']);
            });

            function compareArrays(before, after) {
                const added = after.filter((item) => !before.includes(item));
                const removed = before.filter((item) => !after.includes(item));
                return { added, removed };
            }
        }
    }
};

export default InvitationEditor;
