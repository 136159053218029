import css from './TimeSelect.module.css';

import { useRef, useEffect } from 'react';

import DaysRuler from './DaysRuler/DaysRuler';
import Hours from './Hours/Hours';

import { dayTimes } from '../../../../tools/timeConstants';

import { useDispatch, useSelector } from 'react-redux';
import { updateEditor, updateTimeBlock } from '../../../../store/editorSlice';

const TimeSelect = (props) => {
    const dispatch = useDispatch();
    const visibleHours = useRef();

    const timeSelect = useSelector((state) => state.editor.timeSelect);    
    const mouseHour = timeSelect.hour;
    const mouseDay = timeSelect.day;
    const isMouseIn = timeSelect.isMouseIn;

    useEffect(() => {
        visibleHours.current.scrollTop = visibleHours.current.scrollHeight;
    }, []);

    function handleMouseDown(event) {
        updateTimeBlock(dispatch, event.target.id)
        updateEditor(dispatch, ['timeSelect', 'isMouseDown'], true)
    }

    function handleMouseUp(event) {
        updateEditor(dispatch, ['timeSelect', 'isMouseDown'], false)
    }

    function handleMouseEnter() {
        updateEditor(dispatch, ['timeSelect', 'isMouseIn'], true)
        
    }

    function handleMouseLeave() {
        updateEditor(dispatch, ['timeSelect', 'isMouseIn'], false)
    }

    return (
        <div className={css['main-container']}>
            <div className={css['time-container']}>
                <div className={css['time-select']}>
                    <DaysRuler mouseDay={mouseDay} isMouseIn={isMouseIn} />
                    <div
                        className={css['visible-hours']}
                        ref={visibleHours}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        {dayTimes.map((time) => (
                            <Hours
                                key={time[0]}
                                hour={time}
                                mouseHour={mouseHour}
                                isMouseIn={isMouseIn}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TimeSelect;
