import css from './AuthChoice.module.css';
import { useDispatch } from 'react-redux';
import { conductor } from '../../../../store/conductorSlice';

const Choice = (props) => {
    return (
        <div className={css['button-container']}>
            <button className={`${css['choice']} ${props.color}`} onClick={props.onClick}>
                <div className={css['choice-text']}>{props.text}</div>
            </button>
        </div>
    );
};

const AuthChoice = (props) => {
    const dispatch = useDispatch();

    function goToAdmissions() {
        dispatch(
            conductor.changeScreen({
                targetScreen: 'Application',
                targetProduct: 'Home',
            })
        );
    }

    function goToSignIn() {
        dispatch(
            conductor.changeScreen({
                targetScreen: 'SignIn',
                targetProduct: 'Home',
            })
        );
    }

    return (
        <div className={css['choice-container']}>
            <Choice text='Apply' color='People' onClick={goToAdmissions} />

            <Choice text='Sign in' color='GoodTimes' onClick={goToSignIn} />
        </div>
    );
};

export default AuthChoice;

//
