import { getNowString } from '../../tools/functions/datesFunctions';
import { callCloudFunction } from '../CloudFunctions';
import { updateFirestoreDoc } from './CRUD-functions';

function intiEventDoc(eventInfo) {
    const eventModel = {
        status: null, // open, happening, closed, archived, deleted,
        current_id: null,

        host: {
            user_doc_id: eventInfo['host.user_doc_id'],
            deleted: false,
        },

        guest: {
            manager_id: eventInfo['guest.manager_id'], // hosts_people_manager_id
            type: eventInfo['guest.type'], // user or guest
            user_doc_id: eventInfo['guest.user_doc_id'],
            reminder: null, // date to get a new notification.
            archived: false,
            deleted: false,
        },

        events: {
            element_ids: [], // created using initEvent every time an invitation is accepted by the guest.
        }, 

        meta: {
            event_doc_id: eventInfo['meta.event_doc_id'],
            created: getNowString(),
            modified: getNowString(),
        },
    };
    return eventModel;
}

export function initEvent() {
    const event = {        
        outcome: null, // happened / canceled / pushed...

        when: {
            date: null, //
            time: null, //
        },

        where: null, // place_doc_id

        host: {            
            actions: {
                accepted: null, // null or timestamps as string representing the moment this become true.
                confirmed: null,
                reconfirmed: null,
                canceled: null,
            },
            messages: [], // history of messages exchanged only with host about this event.
            feedback: {},
            
        },

        guest: {                 
            actions: {
                accepted: null,
                confirmed: null,
                reconfirmed: null,
                canceled: null
            },            
            messages: [], //       
            feedback: {},
        },

        chat: [], // Each element is an object represing a message. Only for logistic purposes. It unlocks 1 hour before the event and closes xx hours later.

        meta: {
            event_id: null, // The event wont have an id untill it has a defined where and when. when_where            
            created: getNowString(),
            modified: getNowString(),
        },

    };

    return event;
}

function initMessage() {
    const message = {
        //     message_id: Timestamp in miliseconds.
        //     channel: ...
        //     sent: null, // date when it was sent.
        //     seen: null // date when it was seen.
        //     purpose: aware = create-awareness / confirm.. The targeted attribute.
        //     template_id:
    };
    return message;
}

export function createEventDoc(eventInfo, person, updatePayload) {
    const event = intiEventDoc(eventInfo);
    callCloudFunction('createEventDoc', {
        event: event,
        person: person,
        updatePayload: updatePayload,
    });
    return;
}

export function updateEventDoc(event_doc_id, updatePayload) {
    updateFirestoreDoc('events', event_doc_id, updatePayload);
}
