import { generateId } from '../../../../../tools/generateId';
import { initPlaceDoc } from '../../../../../Firebase/Firestore/placesFirestore';

import _ from 'lodash';

export function processGooglePayload(payload) {
    console.log(payload);
    const place = initPlaceDoc();
    const thumbnails = { photos: [], elementIds: [], product: 'Places' };

    const photos = payload.photos;
    if (photos) {
        photos.forEach((photo) => {
            const photoUrl = photo.getUrl({ maxHeight: 600 });
            thumbnails.photos.push(photoUrl);

            const photoId = generateId(5);
            const thumbnail = { id: photoId, name: null, image: photoUrl, elementType: 'place' };
            thumbnails.elementIds.push(photoId);
            thumbnails[photoId] = thumbnail;
        });
    }

    place.info.name = payload.name || null;
    place.info.phone = payload.formatted_phone_number || null;
    place.info.website = payload.website || null;

    place.address = getAddress();

    place.hours = payload.opening_hours ? payload.opening_hours.weekday_text : null;

    place.media.bucket_id = generateId(3, _.kebabCase(removeGenericWordsFromString(payload.name).slice(0, 20)));
    place.media.thumbnail = thumbnails.photos[0];

    place.google.place_id = payload.place_id;
    place.google.name = payload.name || null;
    place.google.coordinates.lat = payload.geometry.location.lat();
    place.google.coordinates.lng = payload.geometry.location.lng();

    place.google.formatted_address = payload.formatted_address;
    place.google.types = payload.types;

    return [place, thumbnails];

    function getAddress() {
        const addressComponents = payload.address_components;

        const address = {};

        const google_kins = {
            street_number: 'street_number',
            route: 'route',
            postal_code: 'postal_code',
            neighborhood: 'neighborhood',
            sublocality_level_1: 'sublocality',
            locality: 'locality',
            administrative_area_level_1: 'region',
            country: 'country',
        };

        for (const component of addressComponents) {
            const googleKey = component.types[0];
            if (googleKey in google_kins) {
                const kinsKey = google_kins[googleKey];
                address[kinsKey] = component.long_name;
            }
        }

        return address;
    }
}

function removeGenericWordsFromString(placeName) {
    const str = placeName.toLowerCase();
    const words = ['restaurant', 'bar'];
    // Create a regular expression pattern from the words array
    const pattern = new RegExp(words.join('|'), 'gi');

    // Replace the matched words with an empty string
    const result = str.replace(pattern, '');

    return result;
}
