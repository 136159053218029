import { doc, addDoc, getDoc, getDocs, updateDoc, deleteField, setDoc } from 'firebase/firestore';
import { collection, query, where, deleteDoc, arrayUnion } from 'firebase/firestore';
import { getNowString } from '../../tools/functions/datesFunctions';

import { db } from '../Firebase';

export async function createFirestoreDoc(collection_id, docData) {
    docData.meta.created = getNowString();
    docData.meta.modified = getNowString();

    try {
        const docRef = await addDoc(collection(db, collection_id), docData);
        const docId = docRef._key.path.segments.pop();
        console.log(collection_id + ' document created! ID:', docId);
        return docId;
    } catch (error) {
        console.error('Error adding document: ', error);
    }
}

export async function setFirestoreDoc(collection_id, doc_id, docData) {
    const docRef = doc(db, collection_id, doc_id);

    try {
        await setDoc(docRef, docData);
    } catch (error) {
        console.error('Error adding document: ', error);
    }
}

export async function getFirestoreDoc(collection_id, doc_id) {
    const docRef = doc(db, collection_id, doc_id);
    const targetDoc = await getDoc(docRef); // this is the mistake
    return { ...targetDoc.data(), meta: { doc_id } };
}

export async function getFirestoreDocFromQuery(collection_id, keyPath, condition, keyValue) {
    // e.g. where('meta.member_id', '==', currentUser.uid)

    const membersRef = collection(db, collection_id);
    const q = query(membersRef, where(keyPath, condition, keyValue));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length > 0) {
        const docData = querySnapshot.docs[0].data();
        const docId = querySnapshot.docs[0].ref._path.segments.pop();
        return { ...docData, meta: { doc_id: docId, user_doc_id: docData.meta.user_doc_id } };
    } else {
        return false;
    }
}

export async function updateFirestoreDoc(collection_id, doc_id, payload) {
    // console.log('Updating document...');
    const docRef = doc(db, collection_id, doc_id);
    const res = await updateDoc(docRef, { ...payload, 'meta.modified': getNowString() });
    // console.log(res);
}

export async function pushToFirestoreArray(collection_id, doc_id, arrayPath, elements) {
    const docRef = doc(db, collection_id, doc_id);
    const res = await updateDoc(docRef, {
        [arrayPath]: arrayUnion(elements),
        'meta.modified': getNowString(),
    });
}

export async function deleteFirestoreDocField(collection_id, doc_id, fieldPath) {
    console.log('Deleting document field...');
    const docRef = doc(db, collection_id, doc_id);
    const res = await updateDoc(docRef, {
        [fieldPath]: deleteField(),
        'meta.modified': getNowString(),
    });
    console.log(res);
}

export async function deleteFirestoreDoc(collection_id, doc_id) {
    console.log('Deleting document...');
    const docRef = doc(db, collection_id, doc_id);
    await deleteDoc(docRef);
}
