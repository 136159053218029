import css from './SearchBar.module.css';

import TopBar from '../../TopBar/TopBar';
import Icon from '../../Icon/Icon';
import TopBarButton from '../../TopBar/TopBarButton/TopBarButton';

import { useDispatch, useSelector } from 'react-redux';
import { updateManagerQuery, closeSearchBar } from '../../../../store/managerSlice';

const SearchBar = (props) => {
    const dispatch = useDispatch();

    const product = useSelector((state) => state.manager.product);
    const drawerState = useSelector((state) => state.manager.searchBar);
    const query = useSelector((state) => state.manager.query)

    const nextClass = {
        default: '',
        open: 'show',
        closed: 'hide',
    };

    const drawerClass = nextClass[drawerState];

    function handleChange(event) {
        const query = event.target.value;
        updateManagerQuery(dispatch, query.toLowerCase());
    }

    function handleClose() {
        closeSearchBar(dispatch);
        updateManagerQuery(dispatch, '');
    }

    return (
        <div className={`${css['hidden-drawer']} ${css[drawerClass]}`}>
            <TopBar product={product}>
                <div className={css['search-box']}>
                    <Icon
                        icon='search'
                        css={css['search-icon']}
                        fontSize='26px'
                        marginLeft='-5px'
                        marginBottom='-2px'
                    />
                    <div className={css['border-box']}>
                        <input
                            className={css['search-input']}
                            placeholder='Search'
                            onChange={handleChange}
                            value={query}
                        />
                    </div>
                </div>

                <TopBarButton
                    onClick={handleClose}
                    width='10%'
                    iconProps={{
                        icon: 'chevron_right',
                        fontSize: '40px',
                    }}
                />
            </TopBar>
        </div>
    );
};

export default SearchBar;
