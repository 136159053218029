import css from './MiniThumnail.module.css';

import { useDispatch} from 'react-redux';
import Icon from '../../Icon/Icon';
import { goTo, toggleCurtain } from '../../../../store/conductorSlice';
import { toggleDrawer } from '../../../../store/browserSlice';

const MiniThumbnail = (props) => {
    const dispatch = useDispatch();
    const product = props.product;

    function handleClick() {
        toggleCurtain(dispatch, true);
        setTimeout(() => {
            goTo(dispatch, product, 'InvitationEditor');
            toggleDrawer(dispatch, 'hidden');
        }, 500);
    }

    return (
        <div className={css['outer-container']} onClick={handleClick}>
            <div className={css['switch-container']}>
                <label className={`${css['switch']} ${product}`}>
                    <span className={`${css['slider']} ${css['round']} ${product}`}></span>
                </label>
            </div>

            <div className={`${css['name-container']} ${product}`}>
                <div className={css['text-container']}>New</div>
            </div>
            <div className={`${css['main-container']} ${product}`}>
                <div className={css['icon-container']}>
                    <Icon icon='sports_bar' fontSize='55px' marginTop='-8px' />
                </div>
            </div>
        </div>
    );
};

export default MiniThumbnail;
