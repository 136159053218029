export async function callCloudFunction(action, payload) {
    console.log('PAYLOAD: ', payload);

    const bodyData = JSON.stringify({ action: action, payload });
    // console.log(bodyData)
    const targetURL = getTargetURL();

    try {
        const response = await fetch(targetURL, {
            method: 'POST',
            mode: 'cors',
            body: bodyData,
            headers: { 'Content-Type': 'application/json' },
        });
        const responseData = await response.json();
        console.log(responseData);
        return responseData;
    } catch (error) {
        console.log(error);
    }

    return 'Something went very wrong ...';

    function getTargetURL() {
        const triggerURLs = {
            getBrowserData:
                'https://northamerica-northeast1-kins-development.cloudfunctions.net/process-user-request',
            createEventDoc:
                'https://northamerica-northeast1-kins-development.cloudfunctions.net/process-user-request',
            getInvitationData:
                'https://northamerica-northeast1-kins-development.cloudfunctions.net/process-user-request',

            cancelEvent:
                'https://northamerica-northeast1-kins-development.cloudfunctions.net/process-event-action',
        };

        return triggerURLs[action];
    }
}
