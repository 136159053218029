import { getFirestoreDocFromQuery, setFirestoreDoc, pushToFirestoreArray } from './CRUD-functions';

import { getNowString } from '../../tools/functions/datesFunctions';
import { cloneObject } from '../../tools/data-processing';
import { initPrices } from '../../components/Places/PlaceEditor/UploadPhotos/initPrices';

export function initPlaceDoc() {
    const placeModel = {
        info: {
            name: null,
            phone: null,
            website: null,
            short_address: null,
        },

        tags: {},
        address: {},

        hours: [],
        prices: initPrices(),

        media: {
            thumbnail: null,
            bucket_id: null,
            photos: [],
            video: null,
            song: null,
            menu: [], // Photos URLs
            default: null,
            voiceover: null,
        },

        google: {
            place_id: null,
            name: null,
            coordinates: {},
            formatted_address: null,
            types: [],
        },

        meta: {
            place_doc_id: null,
            
            isPublic: false,
            ready: false, // Photos/video had been manually added.

            created: getNowString(),
            modified: getNowString(),
            created_by: null, // The user_doc_id of the user that created this Place.
        },
    };

    return placeModel;
}

export async function createPlace(place, user_id, thumbnail) {
    const doc = await getFirestoreDocFromQuery(
        'places',
        'google.place_id',
        '==',
        place.google.place_id
    );

    if (doc) {
        console.log('Place already exists!');
        return doc;
    } else {
        const newPlace = cloneObject(place);
        const bucket_id = place.media.bucket_id;
        newPlace.meta.place_doc_id = bucket_id
        newPlace.meta.created_by = user_id;
        newPlace.media.thumbnail = thumbnail;

        setFirestoreDoc('places', bucket_id, newPlace);
        console.log('Place created successfully!');
    }
}

export async function addPlacePhoto(doc_id, photoUrl) {
    pushToFirestoreArray('places', doc_id, 'media.photos', photoUrl);
}
