import { createFirestoreDoc, getFirestoreDocFromQuery, getFirestoreDoc } from './CRUD-functions';
import { getNowString } from '../../tools/functions/datesFunctions';
import { getImageUrl } from '../../tools/getImageUrl';

export function initUser() {
    const userModel = {
        info: {
            name: null,
            email: null,
            phone: {},
            base_address: { coordinates: { lat: 45.5045759, lng: -73.5636561 } },
            birthday: null,
            groups: {}, // e.g. brix, startup_fest, yc
        },

        tags: {},

        availability: {
            busy: {}, // default would be available to only track busy dates.
            isOpen: true,
            daysHeadsUp: 0,
            hoursHeadsUp: 3
        },

        cards: {
            urls:{}, // Each key is a card_id. kins.app/user_doc_id/card_id

            // one for each group
            people: {
                image: null,
                audio: null,
                topics: {},
            },
        },

        invitations: {
            browser: {}, // received invitations
            manager: {
                // issued invitations
                element_ids: ['grab-a-drink', 'grab-coffee'],
                'grab-a-drink': initInvitation(
                    'grab-a-drink',
                    'Grab a Drink',
                    getImageUrl('grab-a-drink')
                ),
                'grab-coffee': initInvitation(
                    'grab-coffee',
                    'Grab Coffee',
                    getImageUrl('grab-coffee')
                ),
            },
        },

        people: {
            browser: {},
            manager: { element_ids: [] },
        },

        places: {
            browser: {},
            manager: { element_ids: [] },
        },

        meta: {
            user_id: null,
            user_doc_id: null,
            created: getNowString(),
            modified: getNowString(),
            in_demo: false,
        },
    };
    return userModel;
}

export function initPerson() {
    const person = {
        name: '',
        thumbnail: '',

        phone: '',
        email: '',
        isOpen: true,
        openAgain: null, //Date when isOpen should be true again.

        invitations: [], //Invitations id that contain this person.

        meta: {
            id: null, // the key that refers to this object. May be the same as the person_doc.
            type: 'user', // user or guest
            user_doc_id: null, // the firebase doc id of the user.
            event_doc_id: null, // Each person added to a manager has a corresponding doc.

            created: getNowString(),
            modified: getNowString(),
        },
    };

    return person;
}

export function initPlace() {
    const place = {
        name: '',
        thumbnail: '',
        
        notes: '',

        isOpen: true,
        openAgain: null, //Date when isOpen should be true again.

        invitations: [], //Invitations Id's that contain this place

        meta: {
            id: null, // the key that refers to this object. May be = place_doc_id.            
            place_doc_id: null, // the firebase doc id of the place.
            google_place_id: '', // Googles place_id
            created: getNowString(),
            modified: getNowString(),
        },
    };

    return place;
}

export function initInvitation(id, name, thumbnail) {
    const invitation = {
        name: name || '',
        thumbnail: thumbnail,
        isOpen: true,

        people: [],
        places: [],
        times: [],

        meta: {
            id,
            created: getNowString(),
            modified: getNowString(),
        },
    };
    return invitation;
}

function initBrowserEntry() {
    const entry = {
        doc_id: null, //
        seen: false,
        watch_time: 0,
        affinity: {},
        first_seen: getNowString(),
    };

    return entry;
}

export const initFunctions = {
    user: initUser,
    invitations: initInvitation,
    people: initPerson,
    places: initPlace,
    browserEntry: initBrowserEntry,
};

function loadAplication(application) {
    const user = initUser();

    user.info.name = application.name;
    user.info.email = application.email;
    user.info.phone = application.phone;
    user.meta.user_id = application.user_id;
    return user;
}

export async function getOrCreateUser(currentUser, application) {
    const doc = await getFirestoreDocFromQuery('users', 'meta.user_id', '==', currentUser.uid);

    if (doc) {
        return doc;
    } else {
        const newUser = loadAplication(application);
        const doc_id = await createFirestoreDoc('users', newUser);
        const res = await getFirestoreDoc('users', doc_id);
        return res;
    }
}
