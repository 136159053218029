import css from './TimeButton.module.css';

import { useSelector, useDispatch } from 'react-redux';
import { updateContent, updateInvitationTime } from '../../../../../../../store/browserSlice';

import Button from '../../../../../../Shared/Buttons/Button/Button';
import Icon from '../../../../../../Shared/Icon/Icon';

const TimeButton = (props) => {
    const dispatch = useDispatch();
    const content = useSelector((state) => state.browser.infoBox.content);

    const times = content.times;
    const currentIndex = times.findIndex((hour) => hour === content.time);

    function handleClick() {
        const change = props.icon === 'add' ? 1 : -1;
        const newTime = times[currentIndex + change];
        updateInvitationTime(dispatch, newTime);
        updateContent(dispatch, 'time', newTime);
    }

    const dissabled =
        (props.icon === 'remove' && currentIndex < 1) ||
        (props.icon === 'add' && currentIndex + 2 > times.length);

    return (
        <Button
            css={css['main-container']}
            innerCss={css['inner-container']}
            product='Invitations'
            onClick={handleClick}
            disabled={dissabled}
        >
            <Icon icon={props.icon} css={css['icon']} />
        </Button>
    );
};

export default TimeButton;
