import css from './Browser.module.css';

import Actions from './Actions/Actions';
import BrowserTopBar from './BrowserTopBar/BrowserTopBar';
import BrowserVideos from './BrowserVideos/BrowserVideos';
import ChoiceScreen from './ChoiceScreen/ChoiceScreen';
import ClearChoice from './ChoiceScreen/ClearChoice/ClearChoice';
import EmptyBrowser from './EmptyBrowser/EmptyBrowser';
import InfoBox from './InfoBox/InfoBox';
import Stage from './Stage/Stage';
import TopicToast from './BrowserVideos/TopicToast/TopicToast';

import Topics from './Topics/Topics';
import InvitationsDrawer from '../../Invitations/InvitationsDrawer/InvitationsDrawer';
import Drawer from '../Drawer/Drawer';
import VerticalVideoPlayer from './VerticalVideoPlayer/VerticalVideoPlayer';

import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadBrowserElements, updateBrowser } from '../../../store/browserSlice';
import { loadDrawer } from '../../../store/drawerSlice';
import MuteButton from './VerticalVideoPlayer/SideButtons/MuteButton/MuteButton';

import { labels } from '../../../tools/labels';
import { createBrowserEntry } from '../../../store/userSlice';
import { updateAudio } from '../../../store/audioSlice';
import { updateVideo } from '../../../store/videoSlice';

const Browser = (props) => {
    const dispatch = useDispatch();
    const product = props.product;
    const conductor = useSelector((state) => state.conductor);
    const card = useSelector((state) => state.manager.card);
    const ready = props.ready;

    useEffect(() => {
        if (
            (conductor.previous_product !== conductor.product &&
                !conductor.previous_screen.includes('Editor')) ||
            props.forceLoad
        ) {
            loadBrowserElements(
                dispatch,
                product,
                props.elements,
                prepareElementIds(props.elementIds),
                props.useTimer
            );

            loadDrawer(dispatch, product, labels[product][0], labels[product][1], 'labels');

            updateAudio(dispatch, ['muted'], false);
            
            updateVideo(dispatch, ['tutorial', 'browserSeen'], true);
        }
    }, [ready]);

    const browser = useSelector((state) => state.browser);

    const elementId = browser.elements.activeId;
    const showChoiceScreen = browser.choiceScreen.show;
    const showInfoBox = browser.infoBox.show;

    const activeSection = browser.topics.active.section;
    const showClearX =
        browser.invitation[activeSection] && browser.invitation[activeSection].selection;

    const isEmpty = browser.elements.queue.length === 0;

    const elementType = product ? product.toLowerCase() : undefined;

    const vertical = product === 'Places' || (activeSection === 'where' && showClearX);
    useEffect(() => {
        updateBrowser(dispatch, 'player', { vertical });
    }, [vertical]);

    useEffect(() => {
        if (elementId && elementId in props.elements) {
            const affinity = props.elements[elementId].affinity;
            createBrowserEntry(dispatch, { elementId, elementType, affinity });
        }
    }, [elementId]);

    const height =
        conductor.screen === 'InvitationCard'
            ? 'var(--currentViewHeight)'
            : 'calc(var(--currentViewHeight) - var(--top-bar-height))';

    return (
        <Fragment>
            <div className={css['outer-flex']} style={{ height }}>
                {showClearX && <ClearChoice />}
                {elementId && vertical && <VerticalVideoPlayer />}
                {isEmpty && <EmptyBrowser />}
                {!isEmpty && !vertical && (
                    <div className={css['main-container']}>
                        <Stage>
                            {elementId && <BrowserVideos />}
                            {showChoiceScreen && <ChoiceScreen />}
                            {showInfoBox && <InfoBox />}
                            <TopicToast />
                        </Stage>
                        <Topics css={props.topicsCss} />
                        {props.children}
                    </div>
                )}
                <InvitationsDrawer />
                <Drawer />
            </div>
            <Actions />
            <MuteButton />
        </Fragment>
    );

    function prepareElementIds(elementIds) {
        // if (!card || conductor.product === 'Cards') {
        //     return shuffleArray(elementIds);
        // }

        if (conductor.product === 'Cards') {
            return [elementIds[0]];
        }

        const newIds = [];

        elementIds.forEach((elementId) => {
            if (elementId !== card) {
                newIds.push(elementId);
            }
        });

        return newIds;
    }
};

export default Browser;
