import { Fragment } from 'react';

import PlacesBrowser from './PlacesBrowser/PlacesBrowser';
import PlaceEditor from './PlaceEditor/PlaceEditor';
import Manager from '../Shared/Manager/Manager';
import PhotoWorkbench from './PhotoWorkbench/PhotoWorkbench';

import { useSelector } from 'react-redux';

const Places = (props) => {
    const screen = useSelector((state) => state.conductor.screen);

    return (
        <Fragment>
            {screen === 'PlacesBrowser' && <PlacesBrowser />}
            {screen === 'PlacesManager' && <Manager product='Places' />}
            {screen === 'PlaceEditor' && <PlaceEditor />}
            {screen === 'PhotoWorkbench' && <PhotoWorkbench />}
        </Fragment>
    );
};

export default Places;
