import css from './Notification.module.css';

import { useSelector } from 'react-redux';

import { translate } from '../../../tools/translator';

const Notification = (props) => {
    const queue = useSelector((state) => state.notification.queue);
    const show = useSelector((state) => state.notification.show);
    
    const notification = queue ? notifications[queue] : notifications['NoNews'];
    const color = translate(notification.product, 'product', 'color');

    return (
        <div className={`${css['main-container']} ${show ? css['show'] : ''}`} style={{color}}>
            <div className={css['icon-container']}>
                <span className={`${css['icon']} material-icons-round`}>{notification.icon}</span>
            </div>
            <div className={css['text-container']}>
                <span className={css['text-content']}>{notification.text}</span>
            </div>
        </div>
    );
};

const notifications = {
    InvitationSent: { product: 'Invitations', text: 'Invitation sent!', icon: 'send' },
    PlaceAdded: { product: 'Invitations', text: 'Place added to invitations', icon: 'check' },

    NoNews: { product: 'Events', text: 'No news', icon: 'check' },
};


export default Notification;
