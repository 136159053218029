import css from '../../ApplicationSubmitted/ApplicationSubmitted.module.css';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateAudio, blinkAudio } from '../../../../store/audioSlice';
import LearnAboutUs from '../../LearnAboutUs/LearnAboutUs';

const AccountNotFound = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        updateAudio(dispatch, ['music', 'song'], 'you-didnt-say-the-magic-word');
        blinkAudio(dispatch, ['music', 'action'], 'play');
    }, []);

    return (
        <div className={css['main-container']}>
            <div className={css['application-title']} style={{ fontSize: '40px', fontWeight:'500' }}>
                {' '}
                Account not found
            </div>

            <div className={css['application-message']}>
                <span className={css['danger']}><b>Kins account not found</b>.</span> If you beleive this
                is a mistake, please contact <b>help@kins.ai</b>
            </div>

            <div className={css['image-container']}>
                <img
                    className={css['image']}
                    src={
                        'https://firebasestorage.googleapis.com/v0/b/kins-development.appspot.com/o/app%2Fdennis-jurassic-park.gif?alt=media&token=059976cd-7a9f-4a5d-aaf5-c4bee8b10cfa'
                    }
                    alt=''
                />
            </div>

            <LearnAboutUs />
        </div>
    );
};

export default AccountNotFound;
