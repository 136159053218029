export const dayTimes = [
    ['12 am', '0000', '0030'],
    ['1 am', '0100', '0130'],
    ['2 am', '0200', '0230'],
    ['3 am', '0300', '0330'],
    ['4 am', '0400', '0430'],
    ['5 am', '0500', '0530'],
    ['6 am', '0600', '0630'],
    ['7 am', '0700', '0730'],
    ['8 am', '0800', '0830'],
    ['9 am', '0900', '0930'],
    ['10 am', '1000', '1030'],
    ['11 am', '1100', '1130'],
    ['12 pm', '1200', '1230'],
    ['1 pm', '1300', '1330'],
    ['2 pm', '1400', '1430'],
    ['3 pm', '1500', '1530'],
    ['4 pm', '1600', '1630'],
    ['5 pm', '1700', '1730'],
    ['6 pm', '1800', '1830'],
    ['7 pm', '1900', '1930'],
    ['8 pm', '2000', '2030'],
    ['9 pm', '2100', '2130'],
    ['10 pm', '2200', '2230'],
    ['11 pm', '2300', '2330'],
];

export const timeNames = {
    '0000': '12 am',
    '0030': '12:30 am',
    '0100': '1 am',
    '0130': '1:30 am',
    '0200': '2 am',
    '0230': '2:30 am',
    '0300': '3 am',
    '0330': '3:30 am',
    '0400': '4 am',
    '0430': '4:30 am',
    '0500': '5 am',
    '0530': '5:30 am',
    '0600': '6 am',
    '0630': '6:30 am',
    '0700': '7 am',
    '0730': '7:30 am',
    '0800': '8 am',
    '0830': '8:30 am',
    '0900': '9 am',
    '0930': '9:30 am',
    1000: '10 am',
    1030: '10:30 am',
    1100: '11 am',
    1130: '11:30 am',
    1200: '12 pm',
    1230: '12:30 pm',
    1300: '1 pm',
    1330: '1:30 pm',
    1400: '2 pm',
    1430: '2:30 pm',
    1500: '3 pm',
    1530: '3:30 pm',
    1600: '4 pm',
    1630: '4:30 pm',
    1700: '5 pm',
    1730: '5:30 pm',
    1800: '6 pm',
    1830: '6:30 pm',
    1900: '7 pm',
    1930: '7:30 pm',
    2000: '8 pm',
    2030: '8:30 pm',
    2100: '9 pm',
    2130: '9:30 pm',
    2200: '10 pm',
    2230: '10:30 pm',
    2300: '11 pm',
    2330: '11:30 pm',
};

export const times = [
    '0000',
    '0030',
    '0100',
    '0130',
    '0200',
    '0230',
    '0300',
    '0330',
    '0400',
    '0430',
    '0500',
    '0530',
    '0600',
    '0630',
    '0700',
    '0730',
    '0800',
    '0830',
    '0900',
    '0930',
    '1000',
    '1030',
    '1100',
    '1130',
    '1200',
    '1230',
    '1300',
    '1330',
    '1400',
    '1430',
    '1500',
    '1530',
    '1600',
    '1630',
    '1700',
    '1730',
    '1800',
    '1830',
    '1900',
    '1930',
    '2000',
    '2030',
    '2100',
    '2130',
    '2200',
    '2230',
    '2300',
    '2330',
];

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const weekDayNames = {
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
    Sun: 'Sunday',
};

export const months = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
];

export const monthNames = {
    JAN: 'January',
    FEB: 'February',
    MAR: 'March',
    APR: 'April',
    MAY: 'May',
    JUN: 'June',
    JUL: 'July',
    AUG: 'August',
    SEP: 'September',
    OCT: 'October',
    NOV: 'November',
    DEC: 'December',
};

export const calendarStartDates = {
    '2022-JUN': '2022-05-30',
    '2022-JUL': '2022-06-27',
    '2022-AUG': '2022-08-01',
    '2022-SEP': '2022-08-29',
    '2022-OCT': '2022-09-26',
    '2022-NOV': '2022-10-31',
    '2022-DEC': '2022-11-28',
    
    '2023-JAN': '2022-12-26',
    '2023-FEB': '2023-01-30',
    '2023-MAR': '2023-02-27',
    '2023-APR': '2023-03-27',
    '2023-MAY': '2023-05-01',
    '2023-JUN': '2023-05-29',
    '2023-JUL': '2023-06-26',
    '2023-AUG': '2023-07-31',
    '2023-SEP': '2023-08-28',
    '2023-OCT': '2023-09-25',
    '2023-NOV': '2023-10-30',
    '2023-DEC': '2023-11-27',

    '2024-JAN': '2024-01-01',
    '2024-FEB': '2024-01-29',
    '2024-MAR': '2024-02-26',
    '2024-APR': '2024-04-01',
    '2024-MAY': '2024-04-29',
    '2024-JUN': '2024-05-27',
    '2024-JUL': '2024-07-01',
    '2024-AUG': '2024-07-29',
    '2024-SEP': '2024-08-26',
    '2024-OCT': '2024-09-30',
    '2024-NOV': '2024-10-28',
    '2024-DEC': '2024-11-25',
};

export const prevNextMonth = {
    '2022-JUN': [false, '2022-JUL'],
    '2022-JUL': ['2022-JUN', '2022-AUG'],
    '2022-AUG': ['2022-JUL', '2022-SEP'],
    '2022-SEP': ['2022-AUG', '2022-OCT'],
    '2022-OCT': ['2022-SEP', '2022-NOV'],
    '2022-NOV': ['2022-OCT', '2022-DEC'],
    '2022-DEC': ['2022-NOV', '2023-JAN'],
    
    '2023-JAN': ['2022-DEC', '2023-FEB'],
    '2023-FEB': ['2023-JAN', '2023-MAR'],
    '2023-MAR': ['2023-FEB', '2023-APR'],
    '2023-APR': ['2023-MAR', '2023-MAY'],
    '2023-MAY': ['2023-APR', '2023-JUN'],
    '2023-JUN': ['2023-MAY', '2023-JUL'],
    '2023-JUL': ['2023-JUN', '2023-AUG'],
    '2023-AUG': ['2023-JUL', '2023-SEP'],
    '2023-SEP': ['2023-AUG', '2023-OCT'],
    '2023-OCT': ['2023-SEP', '2023-NOV'],
    '2023-NOV': ['2023-OCT', '2023-DEC'],
    '2023-DEC': ['2023-NOV', '2024-JAN'],

    '2024-JAN': ['2023-DEC', '2024-FEB'],
    '2024-FEB': ['2024-JAN', '2024-MAR'],
    '2024-MAR': ['2024-FEB', '2024-APR'],
    '2024-APR': ['2024-MAR', '2024-MAY'],
    '2024-MAY': ['2024-APR', '2024-JUN'],
    '2024-JUN': ['2024-MAY', '2024-JUL'],
    '2024-JUL': ['2024-JUN', '2024-AUG'],
    '2024-AUG': ['2024-JUL', '2024-SEP'],
    '2024-SEP': ['2024-AUG', '2024-OCT'],
    '2024-OCT': ['2024-SEP', '2024-NOV'],
    '2024-NOV': ['2024-OCT', '2024-DEC'],
    '2024-DEC': ['2024-NOV', false],
};



export const nextTimes = {
    '0000':'0030',
    '0030':'0100',
    '0100':'0130',
    '0130':'0200',
    '0200':'0230',
    '0230':'0300',
    '0300':'0330',
    '0330':'0400',
    '0400':'0430',
    '0430':'0500',
    '0500':'0530',
    '0530':'0600',
    '0600':'0630',
    '0630':'0700',
    '0700':'0730',
    '0730':'0800',
    '0800':'0830',
    '0830':'0900',
    '0900':'0930',
    '0930':'1000',
    '1000':'1030',
    '1030':'1100',
    '1100':'1130',
    '1130':'1200',
    '1200':'1230',
    '1230':'1300',
    '1300':'1330',
    '1330':'1400',
    '1400':'1430',
    '1430':'1500',
    '1500':'1530',
    '1530':'1600',
    '1600':'1630',
    '1630':'1700',
    '1700':'1730',
    '1730':'1800',
    '1800':'1830',
    '1830':'1900',
    '1900':'1930',
    '1930':'2000',
    '2000':'2030',
    '2030':'2100',
    '2100':'2130',
    '2130':'2200',
    '2200':'2230',
    '2230':'2300',
    '2300':'2330',
    '2330': false
};