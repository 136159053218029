import css from './PhotoCarousel.module.css';
import React, { useState, useRef, useEffect } from 'react';

const PhotoCarousel = (props) => {
    const photos = props.photos;

    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(props.index);
    const [previousPhotoIndex, setPreviousPhotoIndex] = useState(
        props.index > 0 ? props.index - 1 : photos.length - 1
    );

    const [fadeIn, setFadeIn] = useState(false);
    const currentPhoto = photos[currentPhotoIndex];
    const previousPhoto = photos[previousPhotoIndex];
    const intervalRef = useRef(null);
    const timeOutRef = useRef(null);
    const [fistPhoto, setFirstPhoto] = useState(null);

    const speed = 4900;

    useEffect(() => {
        if (photos.length > 0) {
            intervalRef.current = setInterval(() => {
                setCurrentPhotoIndex((currentPhotoIndex) => {
                    let newIndex = currentPhotoIndex + 7;
                    if (newIndex === photos.length) {
                        newIndex = 0;
                    }

                    if (newIndex > photos.length) {
                        newIndex -= photos.length;
                    }
                    return newIndex;
                });
            }, 4900);
        }

        return () => {
            clearInterval(intervalRef.current);            
        };
    }, [photos.length]);

    useEffect(() => {
        if (photos.length > 0) {
            setFadeIn(true);

            timeOutRef.current = setTimeout(() => {
                setFadeIn(false);
                setPreviousPhotoIndex(currentPhotoIndex);
                setFirstPhoto(photos[1]);
            }, 1500);
        }

        return () => {
            clearTimeout(timeOutRef.current);
        };
    }, [currentPhotoIndex]);

    return (
        <div className={css['photo-carousel']} style={{ '--speed': speed + 'ms' }}>
            <img
                className={`${css['current-photo']} ${fadeIn && css['fade-in']}`}
                src={currentPhoto}
                alt='Current'
            />
            {fistPhoto === photos[1] && (
                <img className={css['previous-photo']} src={previousPhoto} alt='Previous' />
            )}
        </div>
    );
};

export default PhotoCarousel;
