export function sortTopics(topics, product) {
    if (topics.length === 0) {
        return [];
    }

    if (product !== 'People') {
        return topics;
    }

    const sharedTopics = topics.filter((topic) => {
        return topic.shared;
    });
    const otherTopics = topics.filter((topic) => {
        return !topic.shared;
    });

    const sharedSorted = sharedTopics.sort(topicSort);
    const otherSorted = otherTopics.sort(topicSort);

    return [...sharedSorted, ...otherSorted];

    function topicSort(a, b) {
        if (a.name.length > b.name.length) {
            return 1;
        }
        return -1;
    }
}

// Old function
// export function sortTopics(topics, product) {
//     if (topics.length === 0) {
//         return [];
//     }

//     if (product !== 'People') {
//         return topics;
//     }

//     // console.log('------- Starting ------ ')

//     const maxRowSize = 44;

//     const result = [];

//     let currentRow = [];
//     let currentRowSize = 0;

//     let stopCurrentLoop = false;

//     const sortedTopics = topics.sort(topicSort);
//     console.log(sortedTopics)
//     sortedTopics.forEach((topic) => {
//         topic.sorted = false;
//     });

//     let pendingTopics = sortedTopics;
//     let smallestPendingSize = getSmallestPendingSize();

//     let loopCount = 0;
//     sortPendingTopics();

//     result.push(...currentRow, ...pendingTopics);

//     return result;

//     function sortPendingTopics() {
//         // console.log('Current row: ', currentRow);
//         loopCount += 1;
//         pendingTopics.forEach((topic) => fillTopicsRow(topic));
//         pendingTopics = updatePendingTopics();
//         stopCurrentLoop = false;

//         if (loopCount < 10 && pendingTopics.length > 0) {
//             smallestPendingSize = getSmallestPendingSize();
//             sortPendingTopics();
//         }
//     }

//     function fillTopicsRow(topic) {
//         if (stopCurrentLoop) {
//             return;
//         }

//         // console.log('Current topic: ', topic.topic);
//         const topicSize = topic.name.length;

//         if (topicSize + currentRowSize <= maxRowSize) {
//             topic.sorted = true;
//             // console.log('>>>> Adding topic to row >>>');
//             currentRow.push(topic);
//             currentRowSize = getRowSize(currentRow);

//             if (currentRowSize + smallestPendingSize > maxRowSize) {
//                 // console.log('Pushing current row...');
//                 result.unshift(...currentRow);
//                 currentRow = [];
//                 currentRowSize = 0;
//                 stopCurrentLoop = true;
//             }
//         }
//         return;
//     }

//     function getSmallestPendingSize() {
//         return pendingTopics[pendingTopics.length - 1].name.length;
//     }

//     function updatePendingTopics() {
//         const newPendingTopics = pendingTopics.filter(function (topic) {
//             return !topic.sorted;
//         });

//         return newPendingTopics;
//     }

//     function getRowSize(topicRow) {
//         let rowSize = 0;
//         topicRow.forEach((topic) => {
//             const multiplier = topic.shared ? 1.3 : 1;
//             rowSize += topic.name.length * multiplier + 7;
//         });
//         return rowSize;
//     }

//     function topicSort(a, b) {
//         // if (a.id === 'trailer') {
//         //     return -1;
//         // }

//         // if (b.id === 'trailer') {
//         //     return 1;
//         // }

//         if (a.shared && !b.shared) {
//             return -1;
//         }
//         if (b.shared && !a.shared) {
//             return 1;
//         }
//         if (a.name.length > b.name.length) {
//             return -1;
//         }
//         return 1;
//     }
// }
