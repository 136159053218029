import css from './SectionTitle.module.css';

const SectionTitle = (props) => {
    return (
        <div className={css['main-container']}>
            <div className={css['text-container']}>{props.title}</div>
        </div>
    );
};

export default SectionTitle;
