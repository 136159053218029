import css from './NameTag.module.css';

const NameTag = (props) => {

    return (
        <div
            className={`${css['main-container']} ${props.product}`}
            style={props.css}
        >
            <div className={css['text-container']}>{props.name}</div>
        </div>
    );
};

export default NameTag;
