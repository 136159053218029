import css from './BillItem.module.css';
import BillButton from './BillButton/BillButton';

import { useSelector } from 'react-redux';
import { Fragment } from 'react';

const BillItem = (props) => {
    const itemId = props.itemId;
    const item = useSelector((state) => state.drawer.infoBox['Prices'][itemId]);

    if (!['subtotal', 'taxes', 'gratuity', 'total'].includes(itemId) && !item.price) {
        return <></>;
    }

    const concept = item.concept || getConcept();
    const showButtons = checkShowButtons();
    const symbol = getSymbol();

    return (
        <Fragment>
            <div className={`${css['main-container']} ${css[props.css]}`}>
                <div className={`${css['concept']} ${item.quantity === 0 && css['no-milk-today']}`}>
                    {concept}
                </div>
                <div className={css['quantity-container']}>
                    {showButtons ? <BillButton icon='remove' itemId={itemId} /> : <div />}

                    <div className={css['quantity']}>
                        {symbol === 'X' && <Symbol symbol={symbol} side='left' />}
                        {item.quantity}
                        {symbol === '%' && <Symbol symbol={symbol} side='right' />}
                    </div>

                    {showButtons ? <BillButton icon='add' itemId={itemId} /> : <div />}
                </div>
                <div className={css['amount']}>
                    {item.amount > 0 && (
                        <Fragment>
                            <span className={css['money']}>$</span>
                            {item.amount}
                        </Fragment>
                    )}
                </div>
            </div>
            {item.units && <div className={css['units']}>{item.units}</div>}
        </Fragment>
    );

    function checkShowButtons() {
        const buttonConcepts = [
            'food',
            'appetizer',
            'main',
            'side',
            'dessert',

            'drink',
            'cocktail',
            'beer',
            'wine',
            'soda',
            'coffee',

            'activity',
            'gratuity',
        ];
        return buttonConcepts.includes(itemId);
    }

    function getSymbol() {
        if (
            [
                'food',
                'appetizer',
                'main',
                'side',
                'dessert',

                'drink',
                'cocktail',
                'beer',
                'wine',
                'soda',
                'coffee',
                'activity',
            ].includes(itemId)
        ) {
            return 'X';
        }
        if (['gratuity', 'taxes'].includes(itemId)) {
            return '%';
        }
        return '';
    }

    function getConcept() {
        const concepts = {
            activity: 'Activity',
            food: 'Food',
            appetizer: 'Appetizer',
            main: 'Main',
            side: 'Side',
            dessert: 'Dessert',
            drink: 'Drink',
            cocktail: 'Cocktail',
            beer: 'Beer',
            wine: 'Wine',
            soda: 'Soda',
            coffee: 'Coffee',
            subtotal: 'Subtotal',
            taxes: 'Taxes',
            gratuity: 'Gratuity',
            total: 'Total',
        };

        return concepts[itemId];
    }
};

const Symbol = (props) => {
    return <div className={`${css['symbol']} ${css[props.side]}`}>{props.symbol}</div>;
};

export default BillItem;
