import css from './CornerControls.module.css';
import CornerButton from './CornerButton/CornerButton';

import { useSelector, useDispatch } from 'react-redux';
import { updateContents } from '../../../../store/drawerSlice';

const CornerControls = (props) => {
    const dispatch = useDispatch();

    const drawer = useSelector((state) => state.drawer);
    const showPhotos = drawer['menu_fees'].showPhotos;

    function togglePhotos() {
        updateContents(dispatch, 'showPhotos', !showPhotos);
        document.getElementById('search-area').scroll(0, 0);
    }

    const icon = showPhotos ? 'visibility_off' : 'fastfood';


    const language = drawer['menu_fees'].language;
    const nextLanguage = {
        'english':{id:'french', text:'Français'},
        'french':{id:'english', text:'English'}
    }

    function toggleLanguage() {    
        updateContents(dispatch, 'language', nextLanguage[language].id);
        
    }


    return (
        <div className={css['main-container']}>
            <CornerButton text='Photos' icon={icon} onClick={togglePhotos} side='left' />
            <CornerButton text={nextLanguage[language].text} icon='language' onClick={toggleLanguage} side='right' />
        </div>
    );
};



export default CornerControls;
