import css from './AccountTopBar.module.css';
import TopBar from '../../Shared/TopBar/TopBar';
import TopBarButton from '../../Shared/TopBar/TopBarButton/TopBarButton';
import TopBarTitle from '../../Shared/TopBar/TopBarTitle/TopBarTitle';
import { goTo, toggleCurtain } from '../../../store/conductorSlice';
import { useDispatch } from 'react-redux';
import { handleSignOut } from '../../../Firebase/Authentication';

const AccountTopBar = (props) => {
    const dispatch = useDispatch();

    function onSignOut() {
        handleSignOut();
        
        toggleCurtain(dispatch, true);
        setTimeout(() => {
            goTo(dispatch, 'Home', 'Landing');
        }, 500);
    }

    return (
        <TopBar product={'Account'} backArrow>
            <TopBarButton
                onClick={() => {
                    toggleCurtain(dispatch, false);
                    setTimeout(() => {
                        goTo(dispatch, 'Menu', 'Menu');
                    }, 500);
                }}
                width='5%'
                iconProps={{
                    icon: 'chevron_left',
                    fontSize: '40px',
                    marginLeft: '-5px',
                }}
            />

            <TopBarTitle paddingLeft='24px' width='50%' title={'My Account'} />

            <div className={css['options-container']}>
                <TopBarButton
                    onClick={onSignOut}
                    iconProps={{
                        icon: 'logout',
                        fontSize: '28px',
                    }}
                />
            </div>
        </TopBar>
    );
};

export default AccountTopBar;
